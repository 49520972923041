import React from "react";
import watch from "redux-watch";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { Modal, Button } from "react-bootstrap";
import {
  FormControlLabel,
  Checkbox,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import * as XLSX from "xlsx";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactTooltip from "react-tooltip";
//import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HistoryIcon from "@material-ui/icons/History";
import InfoIcon from "@material-ui/icons/Info";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SendIcon from "@material-ui/icons/Send";
import ChatIcon from "@material-ui/icons/Chat";
import AttachFileIcon from "@material-ui/icons/AttachFile";
//import CancelIcon from '@material-ui/icons/Cancel';
import Search from "@material-ui/icons/Search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/DeleteForever";
import PrintIcon from "@material-ui/icons/Print";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDropDown from "@material-ui/icons/ArrowDownward";
import ArrowDropup from "@material-ui/icons/ArrowUpward";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckIcon from "@material-ui/icons/Check";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PageLoadingSpinner from "./PageLoadingSpinner.js";
import PageLoadingSpinner2 from "./PageLoadingSpinner2.js";
import conf from "../config.js";
import Main_State from "../states/Main_State.js";
import Util from "../util.js";
import util from "../util.js";
import Routes from "../Routes.js";
import cookies from "../Cookies";
import html2canvas from "html2canvas";
import Interweave from "interweave";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import "../styles/Main.css";

var config = conf.config;

const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration: false, // print the duration of each action?
  timestamp: true, // print the timestamp with each action?

  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.

  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.

  logger: console, // implementation of the `console` API.
  logErrors: true, // should the logger catch, log, and re-throw errors?

  diff: false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
});

var store_remarks = createStore(Main_State.reducer_remarks);
var store_header = createStore(Main_State.reducer_header);
var store_similar = createStore(Main_State.reducer_similar);
var store_pricing = createStore(Main_State.reducer_pricing);
var store_margin = createStore(Main_State.reducer_margin);
var store_errors = createStore(Main_State.reducer_errors);
var store_shipment = createStore(Main_State.reducer_shipment);
var store_station = createStore(Main_State.reducer_station);
var store_routes = createStore(
  Main_State.reducer_routes,
  applyMiddleware(logger)
);
var store_alerts = createStore(Main_State.reducer_alerts);
var store_prices = createStore(Main_State.reducer_prices);
var store_modals = createStore(Main_State.reducer_modals);
var store_history = createStore(Main_State.reducer_history);
var store_schedule = createStore(Main_State.reducer_schedule);
var store_calculator = createStore(Main_State.reducer_calculator);

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      key: Date.now(),
      loaded: false,
    };

    this.load = this.load.bind(this);
  }

  async loadQuoteInfo(quote) {
    if (!quote) return null;
    

    loadConfig(this.props.authorized_stations, this.props.custom_list);
    
    
    let header = quote.header;

    let shipment = quote.shipment;

    let pricing = quote.pricing;

    let routing = quote.routing;

    let routes = routing && routing.routes ? routing.routes : [];

    store_alerts.dispatch({
      type: 1,
      alert: { title: "", msg: "", type: "", show: "false" },
      force: true,
    });

    store_header.dispatch({
      type: 1,
      // station: quote.station,
      // adhoc_ref: quote.adhoc_ref,
      // adhoc_ref_buy: quote.adhoc_ref_buy,
      // user: quote.user,
      external_id: header ? header.external_id : null,
      status: "DRAFT",
    });

    if (shipment)
      store_shipment.dispatch({
        type: 1,
        stackable: shipment.stackable,
        // main_deck: shipment.main_deck,
        // exw: shipment.exw,
        origin: shipment.origin,
        dest: shipment.dest,
        pieces: shipment.pieces,
        gross_weight: shipment.gross_weight,
        volume: shipment.volume,
        // density: shipment.density,
        shc: shipment.shc,
        commodity: shipment.commodity,
        commodity_code: shipment.commodity_code,
        // nature_goods: shipment.nature_goods,
        packing: shipment.packing,
        // uld: quote.shipment.uld,
        // dimension_type: quote.shipment.dimension_type,
        all_stackable: shipment.all_stackable,
        // packing_total_wgt: 0,
        // packing_total_vol: 0,
        // packing_total_pcs: 0,
        packing_stackable: shipment.stackable,
        weight_system: shipment.weight_system,
        length_system: shipment.length_system,
        // agent_city: shipment.agent_city,
        agent: shipment.agent,
        agent_code: shipment.agent_code,
      });

    if (routing)
      store_routes.dispatch({
        type: 1,
        detailed_info: routing.detailed_info,
        routes: { detailed_info: routing.detailed_info, routes: routes },
      });

    if (pricing)
      store_pricing.dispatch({
        type: 1,
        //target_price: pricing.target_price,
        target_per_kg: pricing.target_per_kg,
        // carrier: pricing.carrier,
        // carrier_name: pricing.carrier_name,
        // target_currency: pricing.target_currency,
        // per_kg: pricing.per_kg,
        // volume_unit : pricing.volume_unit
      });

    store_errors.dispatch(checkForm());
   
    // await saveAndCalculatePacking()
    
    //document.getElementById("btn_ocdc").click()
  }

  async loadQuote() {
    var quote_id = this.props.match.params.id;

    //RETRIEVE QUOTATION INTO STATE
    let params = { id: quote_id, region: cookies.region };

    if (cookies.rights && !cookies.rights.quote_view) return;

    if (cookies.region !== "WLD")
      params["authorized_stations"] = this.props.authorized_stations;

    params.user_follow_up = cookies.account;
    params.sales_region = cookies.sales_region;

    //KT01
    if (quote_id && quote_id.indexOf("PLG") >= 0) {
      let res = await Routes.get("/QuoteInfo", params, true);

      let quote = res && res.quote ? res.quote : null;

      if(res && res.error){
        Swal.fire(res.message, "", "warning");
        window.location.href = "/#/List";
        return;
      }else if (!quote) {
        Swal.fire("No shipment found in email", "", "warning");
        window.location.href = "/#/List";
        return;
      }

      await this.loadQuoteInfo(quote);
    } else {
      let res = await Routes.getQuotes(params);

      res = res.quotes ? res.quotes[0] : null;

      store_alerts.dispatch({
        type: 1,
        alert: { title: "", msg: "", type: "", show: "false" },
        force: true,
      });

      if (!res) return;

      let shipment = res.shipment;

      let routing = res.routing;

      let routes = null;
      let detailed_info = true;
      let routes_ = [];
      let attachments = [];

      let expectedTime =
        routing && routing.expectedTime ? routing.expectedTime : "";
      let timeIsNegative =
        routing && routing.timeIsNegative ? routing.timeIsNegative : false;

      try {
        routes = routing;

        try {
          routes = routes.routes;
        } catch (e) {
          //patch if no array
          routes = routes.routes.split(";");
        }

        detailed_info =
          routing.detailed_info !== undefined ? routing.detailed_info : true;

        try {
          routes.forEach((route) => {
            routes_.push(route);
          });
        } catch (e) {
          routes_.push(routes);
        }
      } catch (e) {
        routes = routing.split(";");
        routes.forEach((route) => {
          routes_.push(route);
        });
      }

      let pricing = res.pricing;
      let margin = res.margin;
      let { margin_per_kg } = margin;

      attachments.push({
        file_name: res.file_1_name ? res.file_1_name : "",
        file: res.file_1 ? res.file_1 : "",
      });
      attachments.push({
        file_name: res.file_2_name ? res.file_2_name : "",
        file: res.file_2 ? res.file_2 : "",
      });
      attachments.push({
        file_name: res.file_3_name ? res.file_3_name : "",
        file: res.file_3 ? res.file_3 : "",
      });

      let prefix =
        pricing.carrier_prefix && pricing.carrier !== "ECS"
          ? pricing.carrier_prefix
          : "";
      let serial =
        res.awb_number && res.awb_number.includes("-")
          ? res.awb_number.split("-")[1]
          : "";

      if (!prefix) {
        prefix =
          res.awb_number && res.awb_number.includes("-")
            ? res.awb_number.split("-")[0]
            : "";
      }

      let history_value = {
        id: res.id,
        status: res.status,
        adhoc_ref: res.adhoc_ref,
        adhoc_ref_buy: res.adhoc_ref_buy,
        awb_serial: serial,
        agent: shipment.agent,
        origin: shipment.origin,
        dest: shipment.dest,
        pieces: shipment.pieces,
        gross_weight: shipment.gross_weight,
        volume: shipment.volume,
        chargeable_weight: shipment.chargeable_weight,
        shc: shipment.shc,
        target_price: pricing.target_price,
        target_currency: pricing.target_currency,
        target_per_kg: pricing.target_per_kg,
        carrier: pricing.carrier,
        currency: pricing.currency,
        selling_rate: pricing.per_kg ? pricing.price : pricing.price_total,
        all_in: pricing.all_in,
        per_kg: pricing.per_kg,
        min_weight: pricing.min_chargeable_weight,
        min_amount: pricing.min_amount,
        edit_buy_kg: pricing.all_in
          ? margin_per_kg.all_in_buy_edit
          : margin_per_kg.net_buy_edit,
        edit_buy_currency: margin.edit_buy_currency
          ? margin.edit_buy_currency
          : pricing.currency,
        update_by: cookies.account,
        send_adhoc: false,
        send_ffr: false,
        send_equote: false,
      };

      store_header.dispatch({
        type: 1,
        hq: res.hq,
        station: res.station,
        adhoc_ref: res.adhoc_ref,
        adhoc_ref_buy: res.adhoc_ref_buy,
        user: res.user,
        status: res.status,
        creation_date: new Date(res.time_stamp),
        id: res.id,
        failure_reason: res.failure_reason,
        failure_reason_exp: res.failure_reason_exp,
        last_follow_up: new Date(res.last_follow_up),
        last_updated_by: res.last_updated_by,
        update_history: res.update_history,
        last_update: new Date(res.last_update),
        awb_number: res.awb_number,
        prefix: prefix,
        serial: serial,
        external_id: res.external_id,
        follow_up: res.follow_up,
        follow_up_id: parseInt(res.follow_up_id),
      });

      store_remarks.dispatch({
        type: 1,
        ext_remarks: res.ext_remarks,
        int_remarks: res.int_remarks,
        attachments: attachments,
        attachment_id: res.attachment_id,
        chat_messages: res.chat_messages
          ? res.chat_messages.messages
          : undefined,
        chat_links: res.chat_messages ? res.chat_messages.links : undefined,
      });

      store_shipment.dispatch({
        type: 1,
        service_level: shipment.service_level,
        price_class: shipment.price_class,
        stackable: shipment.stackable,
        main_deck: shipment.main_deck,
        screened: shipment.screened,
        exw: shipment.exw,
        is_min_chargeable_weight: shipment.is_min_chargeable_weight,
        agent: shipment.agent,
        agent_code: shipment.agent_code,
        agent_suggestion: [],
        origin: shipment.origin,
        dest: shipment.dest,
        pieces: shipment.pieces,
        gross_weight: shipment.gross_weight,
        volume: shipment.volume,
        chargeable_weight: shipment.chargeable_weight,
        density: shipment.density,
        shc: shipment.shc,
        commodity: shipment.commodity,
        commodity_code: shipment.commodity_code,
        nature_goods: shipment.nature_goods,
        packing: res.shipment.packing,
        packing_text: res.shipment.packing_text,
        uld: res.shipment.uld,
        dimension_type: res.shipment.dimension_type,
        all_stackable: shipment.all_stackable,
        packing_total_wgt: 0,
        packing_total_vol: 0,
        packing_total_pcs: 0,
        packing_stackable: shipment.packing_stackable,
        weight_system: shipment.weight_system,
        length_system: shipment.length_system,
        skypallet_id: shipment.skypallet_id,
        uld_plan: shipment.uld_plan,
        ops_chargeable_weight: shipment.ops_chargeable_weight,
        ffr_sent: res.ffr_sent,
        email_sent: res.email_sent,
        ffr_sent_date: res.ffr_sent_date ? new Date(res.ffr_sent_date) : null,
        equote_sent: res.equote_sent,
      });

      store_routes.dispatch({
        type: 1,
        expectedTime: expectedTime,
        timeIsNegative: timeIsNegative,
        routes: { detailed_info: detailed_info, routes: routes_ },
      });


      store_pricing.dispatch({
        type: 1,
        price: pricing.price,
        price_total: pricing.price_total,
        target_price: pricing.target_price,
        target_per_kg: pricing.target_per_kg,
        carrier: pricing.carrier,
        carrier_name: pricing.carrier_name,
        carrier_prefix: pricing.carrier_prefix,
        from_date: pricing.from_date,
        to_date: pricing.to_date,
        min_amount: pricing.min_amount,
        min_chargeable_weight: pricing.min_chargeable_weight,
        currency: pricing.currency,
        target_currency: pricing.target_currency,
        all_in: pricing.all_in,
        per_kg: pricing.per_kg,
        display_ocdc_total: pricing.display_ocdc_total,
        uld_price: pricing.uld_price,
        ocdc: pricing.ocdc,
      });

      store_history.dispatch({ type: 1, quote: history_value });
      store_margin.dispatch({ type: 1, all: margin });
      store_errors.dispatch(checkForm());

      let header = store_header.getState();

      let res2 = await Routes.getQuotes({
        limit: 31,
        station: store_header.getState().station,
        exclude_id: header.id,
        agent: shipment.agent_code,
        origin: shipment.origin,
        dest: shipment.dest,
      });

      store_similar.dispatch({ type: 1, prices: res2.quotes });
      store_modals.dispatch({ type: 1, quote_saved: true });

      await getSellingPrices({
        authorized_stations: this.props.authorized_stations,
      });
    }
  }

  async load() {
    await Util.sleep(200);

    var quote_id = this.props.match.params.id;

    if (
      quote_id &&
      this.props.authorized_stations &&
      this.props.authorized_stations.length > 0
    ) {
      await this.loadQuote();
      displaySimilars();
      this.setState({ loaded: true });

      let header = store_header.getState();
      let current_station = this.props.authorized_stations.filter(
        (s) => s.station === header.station
      )[0];

      store_station.dispatch({
        type: 1,
        authorized_stations: this.props.authorized_stations,
        sales_region: current_station ? current_station.sales_region : null,
        origin_zone: current_station ? current_station.origin_zone : null,
        station_carrier: this.props.station_carrier,
        carriers_list: this.props.carriers_list,
        exch_rate: this.props.exch_rate,
      });
    }

    //no need to load quote if no id provided
    if (
      !quote_id &&
      this.props.authorized_stations &&
      this.props.authorized_stations.length > 0
    ) {
      loadConfig(this.props.authorized_stations, this.props.custom_list);
      this.setState({ loaded: true });

      store_station.dispatch({
        type: 1,
        authorized_stations: this.props.authorized_stations,
        station_carrier: this.props.station_carrier,
        carriers_list: this.props.carriers_list,
        exch_rate: this.props.exch_rate,
      });
    }
  }

  componentDidUpdate() {
    let current_input = store_errors.getState().current_input;
    let input = document.getElementById(current_input);
    if (input) input.focus();

    restrictInputAccess();
  }

  async componentDidMount() {
    document.title = "Quantum - Quote";

    store_shipment.subscribe((e, v) => {
      store_modals.dispatch({ type: 1, quote_saved: false });
      store_modals.dispatch({ type: 1, updateAdhoc: true });
      store_margin.dispatch({ type: 1, updateMargin: true });
      store_history.dispatch({
        type: 1,
        last_modified: Date.now(),
        last_active: Date.now(),
      });
    });

    store_margin.subscribe(() => {
      store_modals.dispatch({ type: 1, quote_saved: false });
      store_history.dispatch({
        type: 1,
        last_modified: Date.now(),
        last_active: Date.now(),
      });
    });

    store_pricing.subscribe(() => {
      store_modals.dispatch({ type: 1, quote_saved: false });
      store_margin.dispatch({ type: 1, updateMargin: true });
      store_history.dispatch({
        type: 1,
        last_modified: Date.now(),
        last_active: Date.now(),
      });
    });

    store_routes.subscribe(() => {
      store_modals.dispatch({ type: 1, quote_saved: false });
      store_margin.dispatch({ type: 1, updateMargin: true });
      store_history.dispatch({
        type: 1,
        last_modified: Date.now(),
        last_active: Date.now(),
      });
    });

    store_header.subscribe(() => {
      store_modals.dispatch({ type: 1, quote_saved: false });
      store_history.dispatch({
        type: 1,
        last_modified: Date.now(),
        last_active: Date.now(),
      });
    });

    store_remarks.subscribe(() => {
      store_modals.dispatch({ type: 1, quote_saved: false });
      store_history.dispatch({
        type: 1,
        last_modified: Date.now(),
        last_active: Date.now(),
      });
    });

    store_margin.dispatch({ type: 1, updateMargin: false });
  }

  async onClickSearch(e) {
    let shipment = store_shipment.getState();
    let header = store_header.getState();
    let { authorized_stations } = store_station.getState();

    store_modals.dispatch({ type: 1, page_loading_spinner2: true });
    await getSellingPrices({ authorized_stations: authorized_stations });
    store_modals.dispatch({ type: 1, page_loading_spinner2: false });

    let res2 = await Routes.getQuotes({
      limit: 31,
      station: store_header.getState().station,
      exclude_id: store_header.getState().id,
      agent: shipment.agent_code,
      origin: shipment.origin,
      dest: shipment.dest,
      user_follow_up: cookies.account,
    });

    store_similar.dispatch({ type: 1, prices: res2.quotes });

    let logs = {
      type: "SEARCH",
      info: header.id ? header.id : "",
    };

    await Routes.get("/Logger", logs, true);
  }

  render() {
    if (!this.state.loaded) {
      this.load();
      return null;
    }

    if (!this.props.loggedIn || !cookies.rights || (cookies.rights && !cookies.rights.quote_view)
    ) {
      return window.location.replace(config.front_host);
    }

    if (!this.props.custom_list) {
      return null;
    }

    let modals = store_modals.getState();

    console.log("Freebird: ", this.props.custom_list)

    tabName();

    return (
      <div key={this.state.key} class="" style={{ fontSize: "0.8em" }}>
        <Alert name="top" />
        <Header
          authorized_stations={this.props.authorized_stations}
          custom_list={this.props.custom_list}
        />

        <div class="container-fluid">
          <ModalQuoteHistory />
          <ModalPackingText />
          <ModalPrint custom_list={this.props.custom_list} />
          <ModalDownload custom_list={this.props.custom_list} />
          <ModalQuoted />
          <ModalAllin custom_list={this.props.custom_list} />
          <ModalCloneAttachment />
          <ModalFail
            authorized_stations={this.props.authorized_stations}
            custom_list={this.props.custom_list}
          />
          <ModalDeleteStatus
            authorized_stations={this.props.authorized_stations}
            custom_list={this.props.custom_list}
          />
          <ModalConfirmationEquote
            authorized_stations={this.props.authorized_stations}
            custom_list={this.props.custom_list}
          />
          <ModalBooking
            authorized_stations={this.props.authorized_stations}
            carriers_list={this.props.carriers_list}
            custom_list={this.props.custom_list}
          />
          <ModalSchedule
            carriers_list={this.props.carriers_list}
            custom_list={this.props.custom_list}
          />
          <ModalCalculator
            authorized_stations={this.props.authorized_stations}
            carriers_list={this.props.carriers_list}
            custom_list={this.props.custom_list}
            exch_rate={this.props.exch_rate}
          />
          <ModalSimilar
            show={modals.ModalSimilar}
            authorized_stations={this.props.authorized_stations}
          />
          <ModalSimilarExact
            show={modals.ModalSimilarExact}
            exch_rate={this.props.exch_rate}
          />

          <div class="modal-remark">
            <ModalAttachment />
            <ModalRemarkInt />
            <ModalRemarkExt />
          </div>

          <ModalConfirmationAdhoc
            authorized_stations={this.props.authorized_stations}
          />
          <ModalRateSelect
            authorized_stations={this.props.authorized_stations}
            custom_list={this.props.custom_list}
          />

          <Provider store={store_modals}>
            <PageLoadingSpinner />
          </Provider>

          <div class="row">
            <div class="col-lg-6 col-sm-12 shipment-column">
              <div class="row">
                <ShipmentSection
                  carriers_list={this.props.carriers_list}
                  custom_list={this.props.custom_list}
                  authorized_stations={this.props.authorized_stations}
                />
              </div>

              <div class="row section-routing">
                <RoutingSection
                  carriers_list={this.props.carriers_list}
                  authorized_stations={this.props.authorized_stations}
                  custom_list={this.props.custom_list}
                />
              </div>

              <RemarkSection />
            </div>

            <div class="col-lg-6 col-sm-12 pricing-column">
              <div class="row">
                <div class="card col main-block">
                  <div class="card-header">
                    <h5>
                      <img
                        src={require("../img/discount.png")}
                        alt={"Pricing"}
                      />{" "}
                      Pricing
                    </h5>
                    <button
                      type="button"
                      class="btn btn-primary"
                      tabindex="0"
                      maxlength="0"
                      style={{ marginLeft: "0.3em" }}
                      onClick={async (e) => {
                        this.onClickSearch(e);
                      }}
                    >
                      Search
                    </button>

                    <SimilarButton />
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <h5>Selling Rates</h5>
                    </div>

                    <div class="row">
                      <Provider store={store_modals}>
                        <PageLoadingSpinner2 />
                      </Provider>
                      <SellingTable custom_list={this.props.custom_list} />
                    </div>

                    <div class="row">
                      <h6>AdHoc Proposal</h6>
                    </div>

                    <PriceSection
                      custom_list={this.props.custom_list}
                      carriers_list={this.props.carriers_list}
                      authorized_stations={this.props.authorized_stations}
                    />
                  </div>

                  <OCDCSection />

                  <br />
                </div>
              </div>

              <div class="row">
                {cookies &&
                cookies.rights &&
                cookies.rights.quote_see_margin ? (
                  <MarginSectionNew
                    authorized_stations={this.props.authorized_stations}
                    custom_list={this.props.custom_list}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  configuration: state.configuration,
  authorized_stations: state.authorized_stations,
  shcList: state.shcList,
  priceClassList: state.priceClassList,
  serviceLevelList: state.serviceLevelList,
  shc_csp: state.shc_csp,
  carriers_list: state.carriers_list,
  loggedIn: state.loggedIn,
  custom_list: state.custom_list,
  exch_rate: state.exch_rate,
  station_carrier: state.station_carrier,
}))(Main);

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    let w = watch(store_alerts.getState, "force");
    store_alerts.subscribe(
      w((newVal, oldVal, objectPath) => {
        // console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }
  close(e) {
    store_alerts.dispatch({
      type: 1,
      name: this.props.name,
      alert: { title: "", msg: "", type: "", show: "false" },
    });
  }
  render() {
    let html = [];
    const alerts = store_alerts.getState();
    const { type, msg, title, show } = alerts[this.props.name];
    const { key } = this.state;

    let classValue;
    if (type === "warning") classValue = "alert alert-warning";
    else if (type === "success") classValue = "alert alert-success";
    else if (type === "error") classValue = "alert alert-danger";

    if (show === "true") {
      html = (
        <div class={classValue} role="alert" key={key}>
          <button type="button" class="close" onClick={this.close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="alert-heading">
            <ErrorIcon /> {title}{" "}
          </h4>
          <Interweave content={msg} />
        </div>
      );
      //
    }

    return html;
  }
}

class ModalDimension extends React.Component {
  constructor(props) {
    super(props);
    this.handleFile = this.handleFile.bind(this);
    this.processExcel = this.processExcel.bind(this);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalDimensions");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_shipment.getState, "packing_change2");
    store_modals.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  processExcel(data) {
    const wb = XLSX.read(data, { type: "binary" });
    let firstSheet = wb.SheetNames[0];

    //Read all rows from First Sheet into an JSON array.
    let excelRows = XLSX.utils.sheet_to_row_object_array(wb.Sheets[firstSheet]);

    let packing = excelRows.map(function (r) {
      try {
        let weight_label = Object.keys(r).filter(
          (k) =>
            k.toLowerCase().indexOf("weight") >= 0 ||
            k.toLowerCase().indexOf("wgt") >= 0
        )[0];
        let weight = r[weight_label];

        let length_label = Object.keys(r).filter(
          (k) =>
            k.toLowerCase().indexOf("leng") >= 0 ||
            k.toLowerCase().indexOf("lgt") >= 0
        )[0];
        let length = r[length_label];

        let width_label = Object.keys(r).filter(
          (k) =>
            k.toLowerCase().indexOf("widt") >= 0 ||
            k.toLowerCase().indexOf("wdt") >= 0
        )[0];
        let width = r[width_label];

        let height_label = Object.keys(r).filter(
          (k) =>
            k.toLowerCase().indexOf("heig") >= 0 ||
            k.toLowerCase().indexOf("hgt") >= 0
        )[0];
        let height = r[height_label];

        let pieces_label = Object.keys(r).filter(
          (k) =>
            k.toLowerCase().indexOf("piec") >= 0 ||
            k.toLowerCase().indexOf("pcs") >= 0
        )[0];
        let pieces = parseInt(r[pieces_label]);

        let stackable_label = Object.keys(r).filter(
          (k) => k.toLowerCase().indexOf("stack") >= 0
        )[0];
        let stackable =
          r[stackable_label] &&
          r[stackable_label] !== "0" &&
          r[stackable_label].toLowerCase() !== "no" &&
          r[stackable_label].toLowerCase() !== "n"
            ? true
            : false;

        return {
          ...r,
          weight: weight,
          stackable: stackable,
          turnable: r.turnable === 1,
          toploadable: r.toploadable === 1,
          length: length,
          height: height,
          width: width,
          pieces: pieces,
        };
      } catch (e) {
        return null;
      }
    });

    store_shipment.dispatch({
      type: 1,
      packingExcel: packing,
      packing_change2: 1,
    });
  }

  handleFile(e) {
    var processExcel = this.processExcel;

    try {
      //LOAD FILE
      let file = e.target.files[0];

      if (file.name.indexOf("xlsx") < 0) return;

      var reader = new FileReader();

      if (reader.readAsBinaryString) {
        reader.onload = function (e, processExcel_ = processExcel) {
          processExcel_(e.target.result);
        };
        reader.readAsBinaryString(file);
      } else {
        //For IE Browser.
        reader.onload = function (e, processExcel_ = processExcel) {
          var data = "";
          var bytes = new Uint8Array(e.target.result);
          for (var i = 0; i < bytes.byteLength; i++) {
            data += String.fromCharCode(bytes[i]);
          }

          processExcel_(data);
        };
        reader.readAsArrayBuffer(file);
      }
      return;
    } catch (e) {
      return;
    }
  }

  onHideModal(e) {
    let shipment = store_shipment.getState();
    let change =
      shipment.dimension_type === Util.CONST.ULD
        ? shipment.uld_change
        : shipment.packing_change;
    change = change === undefined ? false : change;
    store_modals.dispatch({
      type: 1,
      modalUpdatePacking: change,
      modalDimensions: change,
    });
    store_errors.dispatch({ type: 1, current_input: "shipment_chg_wgt" });
  }

  onChangeDimensionType(e) {
    let shipment = store_shipment.getState();
    store_shipment.dispatch({
      type: 1,
      dimension_type: e.target.value,
      packing_change: true,
      stackable:
        e.target.value === Util.CONST.ULD ? Util.CONST.NO : Util.CONST.YES,
      all_stackable: e.target.value === Util.CONST.ULD ? false : true,
      packing_stackable:
        e.target.value === Util.CONST.ULD ? Util.CONST.NO : Util.CONST.YES,
    });

    store_modals.dispatch({ type: 1, modalDimensions: false });

    if (!shipment.uld || shipment.uld.length === 0) {
      store_shipment.dispatch({ type: 1, addUldLine: true });
    }

    store_modals.dispatch({ type: 1, modalDimensions: true });
  }

  onClickClose(e) {
    let { packing_change, uld_change, dimension_type } =
      store_shipment.getState();
    let change =
      dimension_type && dimension_type.toLowerCase() === Util.CONST.ULD
        ? uld_change
        : packing_change;
    change = change === undefined ? false : change;
    store_modals.dispatch({ type: 1, modalUpdatePacking: change });
    store_errors.dispatch({ type: 1, current_input: "shipment_chg_wgt" });
    store_modals.dispatch({ type: 1, modalDimensions: change });
  }

  render() {
    let shipment = store_shipment.getState();
    let show = store_modals.getState().modalDimensions;

    return (
      <Modal
        show={show}
        onHide={(e) => {
          this.onHideModal(e);
        }}
        dialogClassName="modal-m"
      >
        <Modal.Header className="dimension_modal_header">
          <Modal.Title style={{ width: "100%" }}>
            <div class="row">
              <div class="col-2">Packing List</div>

              {shipment.dimension_type === Util.CONST.ULD ? null : (
                <div class="col-3">
                  <input
                    type="file"
                    id="hiddenfile"
                    style={{ display: "none" }}
                    name="file"
                    tabIndex="-1"
                    onChange={(e) => {
                      if (e.target.files[0])
                        document.getElementById("selectedfile").innerText =
                          e.target.files[0].name;
                      this.handleFile(e);
                    }}
                  />

                  <button
                    type="button"
                    class="btn btn-success"
                    id="btn_upload"
                    onClick={(e) => {
                      document.getElementById("hiddenfile").click();
                    }}
                  >
                    <img
                      style={{ marginRight: "0.5em" }}
                      src={require("../img/excel.png")}
                      alt=""
                    />
                    Upload an excel file
                  </button>

                  <label
                    style={{ fontSize: "0.5em" }}
                    id="selectedfile"
                    for="btn_upload"
                  >
                    No file chosen
                  </label>
                </div>
              )}

              {shipment.dimension_type === Util.CONST.ULD ? null : (
                <div class="col-2">
                  <a
                    tabIndex="-1"
                    href={require("../misc/packing_list.xlsx")}
                    download="excel_template_packing_list"
                  >
                    <GetAppIcon />
                    <span style={{ fontSize: "0.5em", color: "white" }}>
                      Download the excel template
                    </span>
                  </a>
                </div>
              )}

              {shipment.dimension_type === Util.CONST.ULD ? null : (
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() =>
                      store_modals.dispatch({ type: 1, modalPackingText: true })
                    }
                  >
                    <img
                      style={{ marginRight: "0.5em" }}
                      src={require("../img/font.png")}
                      alt=""
                    />
                    Upload from text
                  </button>
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body key={this.state.key}>
          <div class="row">
            <div class="col-1">
              <label>Type</label>
              <select
                tabIndex="-1"
                id="diminension_type"
                class="form-control dropdown"
                onChange={(e) => {
                  this.onChangeDimensionType(e);
                }}
              >
                <option value={shipment.dimension_type}>
                  {" "}
                  {shipment.dimension_type === Util.CONST.ULD
                    ? Util.CONST.ULD.toUpperCase()
                    : Util.CONST.LOOSE.toUpperCase()}{" "}
                </option>
                {Util.DIMENSION_TYPES.map((d) => {
                  if (d !== shipment.dimension_type)
                    return (
                      <option value={d}>
                        {d === Util.CONST.ULD
                          ? Util.CONST.ULD.toUpperCase()
                          : Util.CONST.LOOSE.toUpperCase()}
                      </option>
                    );
                  else return null;
                })}
              </select>
            </div>

            {shipment.dimension_type === Util.CONST.ULD ? null : (
              <div class="col-1">
                <label>Length system</label>
                <select
                  tabIndex="-1"
                  id="packing_length_system"
                  class="form-control dropdown"
                  onChange={(e) => {
                    store_shipment.dispatch({
                      type: 1,
                      length_system: e.target.value,
                      packing_change: true,
                    });
                  }}
                >
                  <option
                    value={
                      shipment.length_system !== Util.CONST.IMPERIAL
                        ? Util.CONST.METRIC
                        : Util.CONST.IMPERIAL
                    }
                  >
                    {shipment.length_system !== Util.CONST.IMPERIAL
                      ? Util.CONST.CM
                      : Util.CONST.INCH}{" "}
                  </option>
                  <option
                    value={
                      shipment.length_system !== Util.CONST.IMPERIAL
                        ? Util.CONST.IMPERIAL
                        : Util.CONST.METRIC
                    }
                  >
                    {shipment.length_system !== Util.CONST.IMPERIAL
                      ? Util.CONST.INCH
                      : Util.CONST.CM}{" "}
                  </option>
                </select>
              </div>
            )}

            <div class="col-1">
              <label>Weight system</label>
              <select
                tabIndex="-1"
                id="packing_weight_system"
                class="form-control dropdown"
                onChange={(e) => {
                  store_shipment.dispatch({
                    type: 1,
                    weight_system: e.target.value,
                    packing_change: true,
                    uld_change: true,
                  });
                }}
              >
                <option
                  value={
                    shipment.weight_system !== Util.CONST.IMPERIAL
                      ? Util.CONST.METRIC
                      : Util.CONST.IMPERIAL
                  }
                >
                  {shipment.weight_system !== Util.CONST.IMPERIAL
                    ? Util.CONST.KG
                    : Util.CONST.LBS}{" "}
                </option>
                <option
                  value={
                    shipment.weight_system !== Util.CONST.IMPERIAL
                      ? Util.CONST.IMPERIAL
                      : Util.CONST.METRIC
                  }
                >
                  {shipment.weight_system !== Util.CONST.IMPERIAL
                    ? Util.CONST.LBS
                    : Util.CONST.KG}{" "}
                </option>
              </select>
            </div>
          </div>
        </Modal.Body>

        <Modal.Body>
          {shipment.dimension_type === Util.CONST.ULD ? (
            <ULDList custom_list={this.props.custom_list} />
          ) : (
            <PackingList />
          )}

          <div class="row" style={{ textAlign: "center" }}>
            <div class="col-12" style={{ textAlign: "center" }}>
              <Button
                id="packing-save"
                style={{ marginRight: "1em", width: "12em" }}
                variant="success"
                onFocus={(e) => {
                  store_errors.dispatch({
                    type: 1,
                    current_input: e.target.id,
                  });
                  let { current_input } = store_errors.getState();
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let { current_input } = store_errors.getState();
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:white");
                  store_errors.dispatch({ type: 1, current_input: null });
                }}
                onClick={async (e) => {
                  saveAndCalculatePacking();
                }}
              >
                Save and Calculate
              </Button>

              <Button
                variant="danger"
                style={{ width: "10em" }}
                id="packing-close"
                onFocus={(e) => {
                  store_errors.dispatch({
                    type: 1,
                    current_input: e.target.id,
                  });
                  let { current_input } = store_errors.getState();
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let input = document.getElementById("packing-close");
                  if (input) input.setAttribute("style", "color:white");
                  store_errors.dispatch({ type: 1, current_input: null });
                }}
                onClick={(e) => {
                  this.onClickClose(e);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

// Kaoutar
class RoutingOffPoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w = watch(store_routes.getState, "force_line");
    store_routes.subscribe(
      w((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let i = this.props.index;
    let exw = store_shipment.getState().exw;
    let dest = store_routes.getState().routes[i].dest;
    let errors = store_errors.getState();
    let header = store_header.getState();

    return (
      <div class="col" key={this.state.key}>
        {i === 0 ? (
          <label>{window.innerWidth > 1600 ? "Off Point" : "Off Pt"}</label>
        ) : null}
        <input
          style={{
            border:
              (exw ||
                (header.external_id && header.user === Util.CONST.CARGOAI)) &&
              errors.off_points &&
              errors.off_points.indexOf(i) >= 0
                ? "2px solid red"
                : "",
          }}
          id={"routing_offpoint_" + i}
          type="text"
          class="form-control "
          placeholder=""
          maxLength="3"
          defaultValue={dest}
          tabindex="0"
          onFocus={(e) =>
            store_errors.dispatch({ type: 1, current_input: e.target.id })
          }
          onBlur={(e) => {
            let value = e.target.value;
            store_routes.dispatch({
              type: 1,
              index: i,
              dest: value,
              check: true,
            });
            store_errors.dispatch({ type: 1, current_input: "" });
          }}
          data-background-color={Util.COLORS.RED_TOOLTIP}
          data-tip={
            exw && errors.off_points && errors.off_points.indexOf(i) >= 0
              ? "Missing"
              : ""
          }
          delay-hide={1000}
        />

        <ReactTooltip place="top" effect="solid" delayHide={500} />
      </div>
    );
  }
}


class RoutingBoardPoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w = watch(store_routes.getState, "force_line");
    store_routes.subscribe(
      w((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let header = store_header.getState();
    let i = this.props.index;
    let origin = store_routes.getState().routes[i].origin;
    let exw = store_shipment.getState().exw;
    let errors = store_errors.getState();

    return (
      <div class="col" key={this.state.key}>
        {i === 0 ? (
          <label>{window.innerWidth > 1600 ? "Board Point" : "Brd Pt"}</label>
        ) : null}
        <input
          style={{
            border:
              (exw || header.external_id) &&
              errors.board_points &&
              errors.board_points.indexOf(i) >= 0
                ? "2px solid red"
                : "",
          }}
          id={"input-board-point-" + i}
          type="text"
          class="form-control "
          placeholder=""
          maxLength="3"
          defaultValue={origin}
          tabindex="0"
          onFocus={(e) => {
            store_errors.dispatch({ type: 1, current_input: e.target.id });
          }}
          onBlur={(e) => {
            let value = e.target.value;
            value = exw && i === 0 && value !== "EXW" ? "EXW" : value;
            store_routes.dispatch({
              type: 1,
              index: i,
              origin: value,
              check: true,
            });
            store_errors.dispatch({ type: 1, current_input: "" });
          }}
          data-background-color={Util.COLORS.RED_TOOLTIP}
          data-type="error"
          data-tip={
            exw && errors.board_points && errors.board_points.indexOf(i) >= 0
              ? "Missing"
              : ""
          }
          delay-hide={1000}
        />
        <ReactTooltip place="top" effect="solid" delayHide={500} />
      </div>
    );
  }
}
/*
class CalculatorTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    let w = watch(store_calculator.getState, "force");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_calculator.getState, "per_kg");
    store_calculator.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let routes = store_calculator.getState().routing;
    let {
      handle_cost,
      truck_cost,
      oc_cost,
      per_kg,
      currency,
      sell_buy,
      chargeable_weight,
    } = store_calculator.getState();

    handle_cost = handle_cost ? handle_cost : [];
    truck_cost = truck_cost ? truck_cost : [];
    oc_cost = oc_cost ? oc_cost[0] : null;

    let html = [];

    let exch_rate = this.props.exch_rate ? this.props.exch_rate : [];

    let total_cost_trucking = 0;
    let total_cost_handling = 0;
    let total_cost_fuel = 0;
    let total_cost_security = 0;

    //FUEL AND SECURITY
    let oc = oc_cost;

    //FUEL
    let { fuel_total, fuel_rate, fuel_currency } = getFuelFromSell(oc);

    fuel_total = !isNaN(parseFloat(fuel_total)) ? parseFloat(fuel_total) : 0;
    fuel_rate = !isNaN(parseFloat(fuel_rate)) ? parseFloat(fuel_rate) : 0;
    fuel_total =
      fuel_total && currency !== fuel_currency
        ? Util.convert_currency(fuel_total, fuel_currency, currency, exch_rate)
        : fuel_total;
    fuel_rate =
      fuel_rate && currency !== fuel_currency
        ? Util.convert_currency(fuel_rate, fuel_currency, currency, exch_rate)
        : fuel_rate;
    total_cost_fuel = total_cost_fuel + (per_kg ? fuel_rate : fuel_total);

    //SECURITY
    let { security_total, security_rate, security_currency } =
      getSecurityFromSell(oc);

    security_total = !isNaN(parseFloat(security_total))
      ? parseFloat(security_total)
      : 0;
    security_rate = !isNaN(parseFloat(security_rate))
      ? parseFloat(security_rate)
      : 0;
    security_total =
      security_total && currency !== security_currency
        ? Util.convert_currency(
            security_total,
            security_currency,
            currency,
            exch_rate
          )
        : security_total;
    security_rate =
      security_rate && currency !== security_currency
        ? Util.convert_currency(
            security_rate,
            security_currency,
            currency,
            exch_rate
          )
        : security_rate;
    total_cost_security =
      total_cost_security + (per_kg ? security_rate : security_total);

    routes.forEach((route) => {
      let i = routes.indexOf(route);

      let trucking = truck_cost.filter((t) => t.position === i)[0];
      let handling = handle_cost.filter((t) => t.position === i)[0];
      //let fuel_security = oc_cost.filter((o) => o.position === i)[0]

      let trucking_cost = trucking ? trucking.cost : null;
      let handling_cost = handling ? handling.cost : null;
      let last_handling_cost =
        handling && i === routes.length - 1 ? handling.last_handling : null;
      //let oc = fuel_security ? fuel_security.oc : null

      let sell_trucking = trucking_cost
        ? trucking_cost.filter((t) => t.sell_buy === "S")[0]
        : 0;
      let buy_trucking = trucking_cost
        ? trucking_cost.filter((t) => t.sell_buy === "B")[0]
        : 0;

      let sell_trucking_currency = sell_trucking
        ? sell_trucking.currency
        : null;
      let buy_trucking_currency = buy_trucking ? buy_trucking.currency : null;

      sell_trucking = sell_trucking
        ? per_kg
          ? sell_trucking.rate
          : sell_trucking.rate * chargeable_weight
        : 0;
      buy_trucking = buy_trucking
        ? per_kg
          ? buy_trucking.rate
          : buy_trucking.rate * chargeable_weight
        : 0;

      sell_trucking = !isNaN(parseFloat(sell_trucking))
        ? parseFloat(sell_trucking)
        : 0;
      buy_trucking = !isNaN(parseFloat(buy_trucking))
        ? parseFloat(buy_trucking)
        : 0;

      let sell_handling = handling_cost
        ? handling_cost.filter((t) => t.sell_buy === "S")[0]
        : 0;
      let buy_handling = handling_cost
        ? handling_cost.filter((t) => t.sell_buy === "B")[0]
        : 0;

      let last_sell_handling = 0;
      let last_buy_handling = 0;

      if (last_handling_cost) {
        last_sell_handling = last_handling_cost.filter(
          (t) => t.sell_buy === "S"
        )[0];
        last_buy_handling = last_handling_cost.filter(
          (t) => t.sell_buy === "B"
        )[0];
      }

      let sell_handling_currency = sell_handling
        ? sell_handling.currency
        : null;
      let buy_handling_currency = buy_handling ? buy_handling.currency : null;

      let last_sell_handling_currency = last_sell_handling
        ? last_sell_handling.currency
        : null;
      let last_buy_handling_currency = last_buy_handling
        ? last_buy_handling.currency
        : null;

      sell_handling = sell_handling
        ? per_kg
          ? sell_handling.rate
          : sell_handling.rate * chargeable_weight
        : 0;
      buy_handling = buy_handling
        ? per_kg
          ? buy_handling.rate
          : buy_handling.rate * chargeable_weight
        : 0;

      last_sell_handling = last_sell_handling
        ? per_kg
          ? last_sell_handling.rate
          : last_sell_handling.rate * chargeable_weight
        : 0;
      last_buy_handling = last_buy_handling
        ? per_kg
          ? last_buy_handling.rate
          : last_buy_handling.rate * chargeable_weight
        : 0;

      sell_handling = !isNaN(parseFloat(sell_handling))
        ? parseFloat(sell_handling)
        : 0;
      buy_handling = !isNaN(parseFloat(buy_handling))
        ? parseFloat(buy_handling)
        : 0;

      last_buy_handling = !isNaN(parseFloat(last_buy_handling))
        ? parseFloat(last_buy_handling)
        : 0;
      last_sell_handling = !isNaN(parseFloat(last_sell_handling))
        ? parseFloat(last_sell_handling)
        : 0;

      let total_cost_line = 0;
      //Convert
      buy_trucking =
        buy_trucking && currency !== buy_trucking_currency
          ? Util.convert_currency(
              buy_trucking,
              buy_trucking_currency,
              currency,
              exch_rate
            )
          : buy_trucking;
      sell_trucking =
        sell_trucking && currency !== sell_trucking_currency
          ? Util.convert_currency(
              sell_trucking,
              sell_trucking_currency,
              currency,
              exch_rate
            )
          : sell_trucking;

      buy_handling =
        buy_handling && currency !== buy_handling_currency
          ? Util.convert_currency(
              buy_handling,
              buy_handling_currency,
              currency,
              exch_rate
            )
          : buy_handling;
      sell_handling =
        sell_handling && currency !== sell_handling_currency
          ? Util.convert_currency(
              sell_handling,
              sell_handling_currency,
              currency,
              exch_rate
            )
          : sell_handling;

      last_buy_handling =
        last_buy_handling && currency !== last_buy_handling_currency
          ? Util.convert_currency(
              last_buy_handling,
              last_buy_handling_currency,
              currency,
              exch_rate
            )
          : last_buy_handling;
      last_sell_handling =
        last_sell_handling && currency !== last_sell_handling_currency
          ? Util.convert_currency(
              last_sell_handling,
              last_sell_handling_currency,
              currency,
              exch_rate
            )
          : last_sell_handling;

      if (sell_buy === "B") {
        total_cost_line = buy_trucking + buy_handling; //+ (per_kg ? (fuel_rate + security_rate) : (fuel_total + security_total))
        total_cost_trucking = total_cost_trucking + buy_trucking;
        total_cost_handling = total_cost_handling + buy_handling;
        //total_cost_fuel = (total_cost_fuel) + (per_kg ? fuel_rate  : fuel_total )
        //total_cost_security = (total_cost_security) + (per_kg ?  security_rate :   security_total)
      } else if (sell_buy === "S") {
        total_cost_line = sell_trucking + sell_handling; //+ (per_kg ? (fuel_rate + security_rate) : (fuel_total + security_total))
        total_cost_trucking = total_cost_trucking + sell_trucking;
        total_cost_handling = total_cost_handling + sell_handling;
        //total_cost_fuel = (total_cost_fuel) + (per_kg ? fuel_rate  : fuel_total )
        //total_cost_security = (total_cost_security) + (per_kg ?  security_rate :   security_total)
      }

      if (i === routes.length - 1) {
        if (sell_buy === "B" && last_buy_handling) {
          total_cost_line = total_cost_line + last_buy_handling;
          total_cost_handling = total_cost_handling + last_buy_handling;
        } else if (sell_buy === "S" && last_sell_handling) {
          total_cost_line = total_cost_line + last_sell_handling;
          total_cost_handling = total_cost_handling + last_sell_handling;
        }
      }

      trucking = sell_buy === "B" ? buy_trucking : sell_trucking;
      handling = sell_buy === "B" ? buy_handling : sell_handling;
      let last_handling =
        sell_buy === "B" ? last_buy_handling : last_sell_handling;

      html.push(
        <div class="row" key={this.state.key}>
          <div class="col ">
            {i === 0 ? <label>Fuel Surcharge</label> : null}
            <input
              type="text"
              class="form-control"
              defaultValue={
                per_kg
                  ? fuel_rate
                    ? parseFloat(fuel_rate).toFixed(2)
                    : 0
                  : fuel_total
                  ? parseFloat(fuel_total).toFixed(2)
                  : 0
              }
              tabindex="0"
              maxlength="3"
              disabled
            />
          </div>

          <div class="col ">
            {i === 0 ? <label>Security</label> : null}
            <input
              type="text"
              class="form-control"
              defaultValue={
                per_kg
                  ? security_rate
                    ? parseFloat(security_rate).toFixed(2)
                    : 0
                  : security_total
                  ? parseFloat(security_total).toFixed(2)
                  : 0
              }
              tabindex="0"
              maxlength="3"
              disabled
            />
          </div>

          <div class="col ">
            {i === 0 ? <label>Trucking</label> : null}
            <input
              type="text"
              class="form-control"
              defaultValue={trucking ? parseFloat(trucking).toFixed(2) : 0}
              tabindex="0"
              maxlength="3"
              disabled
            />
          </div>

          <div class="col ">
            {i === 0 ? <label>Handling</label> : null}
            <input
              type="text"
              class="form-control"
              defaultValue={
                i === routes.length - 1 && routes[routes.length - 1].dest
                  ? (handling ? parseFloat(handling).toFixed(2) : 0) +
                    " + " +
                    (last_handling ? parseFloat(last_handling).toFixed(2) : 0)
                  : handling
                  ? parseFloat(handling).toFixed(2)
                  : 0
              }
              tabindex="0"
              maxlength="3"
              disabled
            />
            {i === routes.length - 1 && routes[routes.length - 1].dest && (
              <label>{route.origin + " + " + route.dest}</label>
            )}
          </div>

          <div class="col ">
            {i === 0 ? <label>Total</label> : null}
            <input
              type="text"
              class="form-control"
              defaultValue={
                total_cost_line ? parseFloat(total_cost_line).toFixed(2) : 0
              }
              tabindex="0"
              maxlength="3"
              disabled
            />
          </div>
        </div>
      );
    });

    store_calculator.dispatch({
      type: 1,
      total_cost_trucking: total_cost_trucking,
      total_cost_handling: total_cost_handling,
      total_cost_fuel: total_cost_fuel,
      total_cost_security: total_cost_security,
    });

    if (this.props.hidden) return null;
    else
      return (
        <div key={this.state.key} class={this.state.class}>
          {html}
        </div>
      );
  }
}*/

class TotalCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    let w = watch(store_calculator.getState, "force");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_calculator.getState, "per_kg");
    store_calculator.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_calculator.getState, "currency");
    store_calculator.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_calculator.getState, "routing");
    store_calculator.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let { total_cost_trucking, total_cost_handling, total_cost } =
      store_calculator.getState();

    let html = (
      <div class="row" key={this.state.key}>
        <div class="col ">
          <label>Total</label>
          <input
            type="text"
            class="form-control"
            defaultValue={total_cost ? parseFloat(total_cost).toFixed(2) : 0}
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>

        <div class="col">
          <label>Trucking</label>
          <input
            type="text"
            class="form-control"
            defaultValue={
              total_cost_trucking
                ? parseFloat(total_cost_trucking).toFixed(2)
                : 0
            }
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>

        <div class="col ">
          <label>Handling</label>
          <input
            type="text"
            class="form-control"
            defaultValue={
              total_cost_handling
                ? parseFloat(total_cost_handling).toFixed(2)
                : 0
            }
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>

        <div class="col"></div>
      </div>
    );
    return html;
  }
}

class TotalRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    let w = watch(store_calculator.getState, "force");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_calculator.getState, "per_kg");
    store_calculator.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_calculator.getState, "currency");
    store_calculator.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_calculator.getState, "routing");
    store_calculator.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let { total_revenue, cost_security, cost_fuel, net_net } =
      store_calculator.getState();

    let html = (
      <div class="row" key={this.state.key}>
        <div class="col ">
          <label>Total</label>
          <input
            type="text"
            class="form-control"
            defaultValue={
              total_revenue ? parseFloat(total_revenue).toFixed(2) : 0
            }
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>

        <div class="col">
          <label>Net Net</label>
          <input
            type="text"
            class="form-control"
            defaultValue={net_net ? parseFloat(net_net).toFixed(2) : 0}
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>

        <div class="col ">
          <label>Fuel Surcharge</label>
          <input
            type="text"
            class="form-control"
            defaultValue={cost_fuel ? parseFloat(cost_fuel).toFixed(2) : 0}
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>

        <div class="col">
          <label>Security</label>
          <input
            type="text"
            class="form-control"
            defaultValue={
              cost_security ? parseFloat(cost_security).toFixed(2) : 0
            }
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>
      </div>
    );
    return html;
  }
}

class TotalContribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    let w = watch(store_calculator.getState, "force");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_calculator.getState, "per_kg");
    store_calculator.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_calculator.getState, "currency");
    store_calculator.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_calculator.getState, "routing");
    store_calculator.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let { total_contribution } = store_calculator.getState();

    let html = (
      <div class="row" key={this.state.key}>
        <div class="col ">
          <label>Contribution</label>
          <input
            type="text"
            class="form-control"
            defaultValue={
              total_contribution ? parseFloat(total_contribution).toFixed(2) : 0
            }
            tabindex="0"
            maxlength="3"
            disabled
          />
        </div>

        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
    );
    return html;
  }
}

class RoutingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
      current_input: null,
      error_flight_date: "",
      flight_date_idxs: [],
    };
  }

  componentDidUpdate() {
    restrictInputAccess();
    let current_input = store_errors.getState().current_input;
    let input = document.getElementById(current_input);
    if (input) input.focus();
  }

  componentDidMount() {
    let w = watch(store_routes.getState, "force");
    store_routes.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_routes.getState, "detailed_info");
    store_routes.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_errors.getState, "flight_date");
    store_errors.subscribe(
      w3((newVal, oldVal, objectPath) => {
        // console.log('errors %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w4 = watch(store_shipment.getState, "exw");
    store_shipment.subscribe(
      w4((newVal, oldVal, objectPath) => {
        // console.log('exw %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onBlurCarrier(e, i) {
    let carrier = store_pricing.getState().carrier;
    if (e.target.value && !carrier) {
      store_errors.dispatch({
        type: 1,
        current_input: "routing_flight_num" + i,
      });
      updateCarrierInfo(
        e.target.value,
        this.props.custom_list ? this.props.custom_list : []
      );
    }

    store_errors.dispatch({ type: 1, current_input: null });
  }

  async onBlurFlightDate(e, i) {
    let val = e.target.value;

    if (val.match(/^[0-2][1-9]$/) || val.match(/^[1-3][0-1]$/)) {
      let day_input = val;
      let today = new Date();
      let year = today.getFullYear() - 2000;
      let day = parseInt(today.getDate());
      let month = parseInt(today.getMonth() + 1);
      month = parseInt(day_input) < day ? month + 1 : month;

      if (month > 12) {
        month = 1;
        year = year + 1;
      }

      store_routes.dispatch({
        type: 1,
        index: i,
        flight_date: day_input + Util.MONTHS[month - 1] + year,
      });
      store_errors.dispatch({ type: 1, current_input: null });
      this.setState({ key: Date.now() });
    } else if (
      val.match(/^[0-2][1-9][A-z][A-z][A-z]$/) ||
      val.match(/^[1-3][0-1][A-z][A-z][A-z]$/)
    ) {
      let month_input = val.substring(2, 5).toUpperCase();
      let month_input_num = Util.MONTHS.indexOf(month_input);

      if (!month_input || !month_input_num) return;

      month_input_num++;
      let year = new Date().getFullYear() - 2000;
      let today = new Date();
      year =
        parseInt(month_input_num) < parseInt(today.getMonth() + 1)
          ? year + 1
          : year;

      store_routes.dispatch({ type: 1, index: i, flight_date: val + year });
      this.setState({ key: Date.now() });
    }

    let errors = checkForm();
    let { flight_date, flight_date_idxs } = errors;
    store_errors.dispatch(errors);

    if (flight_date) {
      store_errors.dispatch({
        type: 1,
        flight_date: flight_date,
        flight_date_idxs: flight_date_idxs,
      });
    } else if (!flight_date) {
      store_errors.dispatch({ type: 1, flight_date: "", flight_date_idxs: [] });
    }
  }

  onChangeFlightType(e, i) {
    let exw = store_shipment.getState().exw;
    let value = e.target.value;
    if (exw && i === 0 && value !== "Truck") value = "Truck";
    store_routes.dispatch({ type: 1, index: i, flight_type: value });
  }

  render() {
    let routes = store_routes.getState().routes;
    let exw = store_shipment.getState().exw;
    let errors = store_errors.getState();
    let shipment = store_shipment.getState();
    let html = [];

    routes.forEach((route) => {
      let i = routes.indexOf(route);
      html.push(
        <div class="row" key={this.state.key}>
          <RoutingBoardPoint index={i} />
          <RoutingOffPoint index={i} />

          <div class="col ">
            {i === 0 ? <label>Carrier</label> : null}
            <input
              type="text"
              class="form-control"
              tabindex="0"
              id={"routing_carrier_line" + i}
              style={{
                border:
                  errors.carriers_idxs.indexOf(i) >= 0 ? "2px solid red" : "",
              }}
              placeholder=""
              defaultValue={route.carrier}
              maxlength="3"
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onChange={(e) => {
                store_routes.dispatch({
                  type: 1,
                  index: i,
                  carrier: e.target.value,
                });
              }}
              onBlur={(e) => this.onBlurCarrier(e, i)}
              data-background-color={Util.COLORS.RED_TOOLTIP}
              data-type="error"
              data-tip={
                errors.carriers_idxs.indexOf(i) >= 0
                  ? errors.routing_carrier
                  : ""
              }
              delay-hide={1000}
            />
            <ReactTooltip place="top" effect="solid" delayHide={500} />
          </div>

          {store_routes.getState().detailed_info && (
            <div class="col ">
              {i === 0 ? (
                <label>
                  {" "}
                  {window.innerWidth > 1600 ? "Flight Num." : "Flt Num"}{" "}
                </label>
              ) : null}
              <input
                type="text"
                tabindex="0"
                id={"routing_flight_num" + i}
                class="form-control"
                placeholder=""
                defaultValue={route.flight_number}
                maxlength="6"
                onFocus={(e) => {
                  store_errors.dispatch({
                    type: 1,
                    current_input: e.target.id,
                  });
                }}
                onBlur={(e) => {
                  store_errors.dispatch({ type: 1, current_input: null });
                }}
                onChange={(e) =>
                  store_routes.dispatch({
                    type: 1,
                    index: i,
                    flight_number: e.target.value,
                  })
                }
                style={{
                  border:
                    errors.flight_number_idxs &&
                    errors.flight_number_idxs.indexOf(i) >= 0
                      ? "2px solid red"
                      : "",
                }}
              />
            </div>
          )}

       

          <div class="col ">
            {i === 0 ? (
              <label>
                {window.innerWidth > 1600 ? "Oper. Type" : "Op Type"}
              </label>
            ) : null}
            <select
              id={"routing_flight_type" + i}
              class="form-control dropdown"
              tabindex="0"
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
              onBlur={(e) => {
                store_errors.dispatch({ type: 1, current_input: null });
              }}
              onChange={(e) => {
                this.onChangeFlightType(e, i);
              }}
            >
              {<option selected> {route.flight_type} </option>}

              {Util.OPERATION_TYPES.map((t) => {
                // Case carrier= Mix
                if (
                  route.carrier.toLowerCase() === "mix" &&
                  t.toLowerCase() === "flight/truck"
                ) {
                  return (
                    <option key={t} value={t} selected>
                      {t}
                    </option>
                  );
                } else if (t !== route.flight_type) {
                  return (
                    <option key={t} value={t} disabled={exw && i === 0}>
                      {t}
                    </option>
                  );
                }
                return null;
              })}
            </select>
          </div>

          {store_routes.getState().detailed_info
            ? [
                <div class="col ">
                  {i === 0 ? (
                    <label>
                      {" "}
                      {window.innerWidth > 1600 ? "Flight Date" : "Flt Dte"}
                    </label>
                  ) : null}
                  <div class="input-group mb-3">
                    <input
                      style={{
                        border:
                          errors.flight_date_idxs &&
                          errors.flight_date_idxs.indexOf(i) >= 0
                            ? "2px solid red"
                            : "",
                      }}
                      type="text"
                      id={"routing_flight_date" + i}
                      placeholder=""
                      maxlength="7"
                      tabindex="0"
                      defaultValue={route.flight_date}
                      onFocus={(e) => {
                        store_errors.dispatch({
                          type: 1,
                          current_input: e.target.id,
                        });
                      }}
                      onChange={(e) => {
                        store_routes.dispatch({
                          type: 1,
                          index: i,
                          flight_date: e.target.value,
                        });
                      }}
                      onBlur={(e) => this.onBlurFlightDate(e, i)}
                      class="form-control"
                      data-type="warning"
                      data-tip={
                        errors.flight_date_idxs &&
                        errors.flight_date_idxs.indexOf(i) >= 0
                          ? errors.flight_date
                          : ""
                      }
                      delay-hide={1000}
                    />

                    {!errors.flight_date ? null : (
                      <ReactTooltip
                        place="top"
                        effect="solid"
                        delayHide={500}
                      />
                    )}
                    <div class="input-group-prepend ">
                      {errors.flight_date_idxs &&
                      errors.flight_date_idxs.indexOf(i) >= 0 ? (
                        <img
                          src={require("../img/warning-sign.png")}
                          alt="Warning"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>,
                <div class="col ">
                  {i === 0 ? <label>ETD</label> : null}
                  <input
                    type="text"
                    id={"routing_etd" + i}
                    placeholder=""
                    maxlength="5"
                    tabindex="0"
                    defaultValue={route.etd}
                    onChange={(e) =>
                      store_routes.dispatch({
                        type: 1,
                        index: i,
                        etd: e.target.value,
                      })
                    }
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    class="form-control"
                    style={{
                      border:
                        errors.flight_etd_idxs &&
                        errors.flight_etd_idxs.indexOf(i) >= 0
                          ? "2px solid red"
                          : "",
                    }}
                  />
                  <label class="text-warning">
                    {" "}
                    {store_errors.getState().etd}
                  </label>
                </div>,
                <div class="col ">
                  {i === 0 ? <label>ETA</label> : ""}
                  <input
                    type="text"
                    id={"routing_eta" + i}
                    placeholder=""
                    maxlength="10"
                    tabindex="0"
                    defaultValue={route.eta}
                    onChange={(e) =>
                      store_routes.dispatch({
                        type: 1,
                        index: i,
                        eta: e.target.value,
                      })
                    }
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch(checkForm());
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    class="form-control"
                    style={{
                      border:
                        errors.flight_eta_idxs &&
                        errors.flight_eta_idxs.indexOf(i) >= 0
                          ? "2px solid red"
                          : "",
                    }}
                  />
                  <label class="text-warning">
                    {" "}
                    {store_errors.getState().eta}{" "}
                  </label>
                </div>,
                <div class="col ">
                  {i === 0 ? (
                    <label>
                      {" "}
                      {window.innerWidth > 1600 ? "SS. split" : "Split"}{" "}
                    </label>
                  ) : null}
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      id={"routing_split" + i}
                      placeholder=""
                      maxlength="3"
                      tabindex="0"
                      defaultValue={route.shipment_split}
                      onFocus={(e) => {
                        store_errors.dispatch({
                          type: 1,
                          current_input: e.target.id,
                        });
                      }}
                      onBlur={(e) => {
                        store_errors.dispatch({ type: 1, current_input: null });
                      }}
                      onChange={(e) =>
                        store_routes.dispatch({
                          type: 1,
                          index: i,
                          shipment_split: e.target.value,
                        })
                      }
                      class="form-control"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {" "}
                        %{" "}
                      </span>
                    </div>
                  </div>
                </div>,
              ]
            : [
                //WL
                <div class="col ">
                  {i === 0 ? <label>WL Frequency</label> : ""}
                  <input
                    type="text"
                    tabindex="0"
                    class="form-control"
                    placeholder=""
                    id={"week_frenquency" + i}
                    defaultValue={route.weekly_frequency}
                    maxlength="30"
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    onChange={(e) =>
                      store_routes.dispatch({
                        type: 1,
                        index: i,
                        weekly_frequency: e.target.value,
                      })
                    }
                  />
                </div>,
                <div class="col ">
                  {i === 0 ? <label>Operation DOW </label> : ""}
                  <input
                    type="text"
                    tabindex="0"
                    class="form-control"
                    placeholder=""
                    id={"operation_dow" + i}
                    defaultValue={route.operation_dow}
                    maxlength="30"
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    onChange={(e) =>
                      store_routes.dispatch({
                        type: 1,
                        index: i,
                        operation_dow: e.target.value,
                      })
                    }
                  />
                </div>,
              ]}

          {exw && (i === 0 || i === 1) ? (
            <div class="col ">
              {i === routes.length - 1 ? (
                <button
                  id={"routing-button-add-" + i}
                  type="button"
                  class="btn btn-success"
                  onClick={async (e) => {
                    store_routes.dispatch({
                      type: 1,
                      addRoute: true,
                      force: true,
                    });
                    store_errors.dispatch({
                      type: 1,
                      current_input: "input-board-point-" + (i + 1),
                    });
                  }}
                  onFocus={(e) => {
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    });
                    let { current_input } = store_errors.getState();
                    let input = document.getElementById(current_input);
                    if (input) input.setAttribute("style", "color:black");
                  }}
                  onBlur={(e) => {
                    let { current_input } = store_errors.getState();
                    let input = document.getElementById(current_input);
                    if (input) input.setAttribute("style", "color:white");
                    store_errors.dispatch({ type: 1, current_input: null });
                  }}
                >
                  <AddCircleIcon fontSize="small" />
                </button>
              ) : null}
            </div>
          ) : (
            <div class="col">
              {i === 0 && <br />}
              <button
                id={"routing-button-remove-" + i}
                type="button"
                class="btn btn-danger"
                onClick={(e) => {
                  if (routes.length > 1) {
                    store_routes.dispatch({
                      type: 1,
                      removeRoute: true,
                      index: i,
                      force: true,
                    });
                  } else if (routes.length === 1) {
                    store_routes.dispatch({
                      type: 1,
                      removeRoute: true,
                      index: i,
                      force: true,
                    });
                    store_routes.dispatch({
                      type: 1,
                      addRoute: true,
                      force: true,
                    });
                    store_routes.dispatch({
                      type: 1,
                      index: i,
                      origin: shipment.origin,
                    });
                  }
                  store_errors.dispatch({
                    type: 1,
                    current_input: "input-board-point-" + (i - 1),
                  });
                }}
                onFocus={(e) => {
                  store_errors.dispatch({
                    type: 1,
                    current_input: e.target.id,
                  });
                  let { current_input } = store_errors.getState();
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let { current_input } = store_errors.getState();
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:white");
                }}
              >
                <Delete fontSize="small" />
              </button>

              {i === routes.length - 1 || i === 0 ? (
                <button
                  type="button"
                  id={"routing-button-add-" + i}
                  class="btn btn-success"
                  data-tip={
                    routes.length > 1 && i === 0
                      ? "Add top line"
                      : "Add bottom line"
                  }
                  onClick={async (e) => {
                    let top = routes.length > 1 && i === 0 ? true : false;
                    store_routes.dispatch({
                      type: 1,
                      toTop: top,
                      addRoute: true,
                      force: true,
                    });
                    store_errors.dispatch({
                      type: 1,
                      current_input: top
                        ? "input-board-point-" + i
                        : "input-board-point-" + (i + 1),
                    });
                  }}
                  onFocus={(e) => {
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    });
                    let { current_input } = store_errors.getState();
                    let input = document.getElementById(current_input);
                    if (input) input.setAttribute("style", "color:black");
                  }}
                  onBlur={(e) => {
                    let { current_input } = store_errors.getState();
                    let input = document.getElementById(current_input);
                    if (input) input.setAttribute("style", "color:white");
                    store_errors.dispatch({ type: 1, current_input: null });
                  }}
                >
                  <ReactTooltip
                    place="top"
                    type="info"
                    effect="solid"
                    delayHide={500}
                  />
                  {routes.length > 1 && i === 0 ? (
                    <ArrowDropup fontSize="small" />
                  ) : (
                    <ArrowDropDown fontSize="small" />
                  )}
                </button>
              ) : null}
            </div>
          )}
        </div>
      );
    });

    return (
      <div key={this.state.key} class={this.state.class}>
        {html}
      </div>
    );
  }
}

class CalculatorRoutingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
      current_input: null,
    };
  }

  componentDidUpdate() {
    let current_input = store_errors.getState().current_input;
    let input = document.getElementById(current_input);
    if (input) input.focus();
  }

  componentDidMount() {
    let w = watch(store_calculator.getState, "force");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_calculator.getState, "detailed_info");
    store_calculator.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onBlurFlightDate(e, i) {
    let val = e.target.value;

    if (val.match(/^[0-2][1-9]$/) || val.match(/^[1-3][0-1]$/)) {
      let day_input = val;
      let today = new Date();
      let year = today.getFullYear() - 2000;
      let day = parseInt(today.getDate());
      let month = parseInt(today.getMonth() + 1);
      month = parseInt(day_input) < day ? month + 1 : month;

      if (month > 12) {
        month = 1;
        year = year + 1;
      }

      store_calculator.dispatch({
        type: 1,
        index: i,
        updateRoute: true,
        flight_date: day_input + Util.MONTHS[month - 1] + year,
      });
      this.setState({ key: Date.now() });
    } else if (
      val.match(/^[0-2][1-9][A-z][A-z][A-z]$/) ||
      val.match(/^[1-3][0-1][A-z][A-z][A-z]$/)
    ) {
      let month_input = val.substring(2, 5).toUpperCase();
      let month_input_num = Util.MONTHS.indexOf(month_input);

      if (!month_input || !month_input_num) return;

      month_input_num++;
      let year = new Date().getFullYear() - 2000;
      let today = new Date();
      year =
        parseInt(month_input_num) < parseInt(today.getMonth() + 1)
          ? year + 1
          : year;

      store_calculator.dispatch({
        type: 1,
        index: i,
        updateRoute: true,
        flight_date: val + year,
      });
      this.setState({ key: Date.now() });
    }

    store_errors.dispatch({ type: 1, current_input: null });
  }

  async onBlur(e, i) {
    let json = { type: 1, update: true, updateRoute: true, index: i };
    json[e.target.name] = e.target.value.toUpperCase();
    let routing = store_calculator.getState().routing;
    let current_value = routing ? routing[i][e.target.name] : "";
    current_value = current_value ? current_value.toString().toUpperCase() : "";
    if (current_value !== e.target.value.toString().toUpperCase()) {
      store_calculator.dispatch(json);
      await calculateCost();
    }

    store_errors.dispatch({ type: 1, current_input: null });
  }

  render() {
    let calculator = store_calculator.getState();
    let routes = calculator.routing;
    let html = [];

    routes.forEach((route) => {
      let i = routes.indexOf(route);
      html.push(
        <div class="row">
          <div class="col ">
            {i === 0 ? <label>Board Point</label> : null}
            <input
              type="text"
              class="form-control"
              tabindex="0"
              id={"routing_board_point_calc" + i}
              name="origin"
              placeholder=""
              defaultValue={route.origin}
              maxlength="3"
              onBlur={async (e) => {
                this.onBlur(e, i);
              }}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              //onChange = { (e) => { store_calculator.dispatch({type: 1, updateRoute : true, index: i, origin: e.target.value  }) } }
            />
          </div>

          <div class="col ">
            {i === 0 ? <label>Off Point</label> : null}
            <input
              type="text"
              class="form-control"
              tabindex="0"
              id={"routing_off_point_calc" + i}
              name="dest"
              placeholder=""
              defaultValue={route.dest}
              maxlength="3"
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              //onChange = { (e) => { store_calculator.dispatch({type: 1, updateRoute : true, index: i, dest: e.target.value}) } }
              onBlur={async (e) => {
                this.onBlur(e, i);
              }}
            />
          </div>

          <div class="col ">
            {i === 0 ? <label>Carrier</label> : null}
            <input
              type="text"
              class="form-control"
              tabindex="0"
              id={"routing_carrier_line_calc" + i}
              name="carrier"
              placeholder=""
              defaultValue={route.carrier}
              maxlength="3"
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              //onChange = { (e) => { store_calculator.dispatch({type: 1, updateRoute : true, index: i, carrier: e.target.value  }) } }
              onBlur={async (e) => {
                this.onBlur(e, i);
              }}
            />
          </div>

          {store_routes.getState().detailed_info && (
            <div class="col ">
              {i === 0 ? (
                <label>
                  {" "}
                  {window.innerWidth > 1600 ? "Flight Num." : "Flt Num"}{" "}
                </label>
              ) : null}
              <input
                type="text"
                tabindex="0"
                id={"routing_flight_num_calc" + i}
                class="form-control"
                placeholder=""
                defaultValue={route.flight_number}
                maxlength="6"
                onFocus={(e) => {
                  store_errors.dispatch({
                    type: 1,
                    current_input: e.target.id,
                  });
                }}
                onBlur={(e) => {
                  store_errors.dispatch({ type: 1, current_input: null });
                }}
                onChange={(e) =>
                  store_calculator.dispatch({
                    type: 1,
                    updateRoute: true,
                    index: i,
                    flight_number: e.target.value,
                  })
                }
              />
            </div>
          )}

          <div class="col ">
            {i === 0 ? (
              <label>
                {window.innerWidth > 1600 ? "Oper. Type" : "Op Type"}
              </label>
            ) : null}
            <select
              id={"routing_flight_type_calc" + i}
              class="form-control dropdown"
              tabindex="0"
              name="flight_type"
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
              onBlur={async (e) => {
                this.onBlur(e, i);
              }}
              //onChange={ async(e) => {store_calculator.dispatch({type:1, index : i, updateRoute : true, flight_type : e.target.value})}}
            >
              <option selected>{route.flight_type} </option>
              {Util.OPERATION_TYPES.map((t) => {
                if (t !== route.flight_type) return <option>{t}</option>;
                else return null;
              })}
            </select>
          </div>

          {store_calculator.getState().detailed_info
            ? [
                <div class="col ">
                  {i === 0 ? (
                    <label>
                      {" "}
                      {window.innerWidth > 1600 ? "Flight Date" : "Flt Dte"}
                    </label>
                  ) : null}
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      id={"routing_flight_date_calc" + i}
                      placeholder=""
                      maxlength="7"
                      tabindex="0"
                      defaultValue={route.flight_date}
                      onFocus={(e) => {
                        store_errors.dispatch({
                          type: 1,
                          current_input: e.target.id,
                        });
                      }}
                      onChange={(e) => {
                        store_calculator.dispatch({
                          type: 1,
                          updateRoute: true,
                          index: i,
                          flight_date: e.target.value,
                        });
                      }}
                      onBlur={(e) => this.onBlurFlightDate(e, i)}
                      class="form-control"
                    />
                  </div>
                </div>,
                <div class="col ">
                  {i === 0 ? <label>ETD</label> : null}
                  <input
                    type="text"
                    id={"routing_etd_calc" + i}
                    placeholder=""
                    maxlength="5"
                    tabindex="0"
                    defaultValue={route.etd}
                    onChange={(e) =>
                      store_calculator.dispatch({
                        type: 1,
                        updateRoute: true,
                        index: i,
                        etd: e.target.value,
                      })
                    }
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    class="form-control"
                  />
                </div>,
                <div class="col ">
                  {i === 0 ? <label>ETA</label> : ""}
                  <input
                    type="text"
                    id={"routing_eta_calc" + i}
                    placeholder=""
                    maxlength="10"
                    tabindex="0"
                    defaultValue={route.eta}
                    onChange={(e) =>
                      store_calculator.dispatch({
                        type: 1,
                        updateRoute: true,
                        index: i,
                        eta: e.target.value,
                      })
                    }
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    class="form-control"
                  />
                </div>,
                <div class="col ">
                  {i === 0 ? (
                    <label>
                      {" "}
                      {window.innerWidth > 1600 ? "Ship. split" : "Split"}{" "}
                    </label>
                  ) : null}
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      id={"routing_split_calc" + i}
                      placeholder=""
                      maxlength="3"
                      tabindex="0"
                      defaultValue={route.shipment_split}
                      onFocus={(e) => {
                        store_errors.dispatch({
                          type: 1,
                          current_input: e.target.id,
                        });
                      }}
                      onBlur={(e) => {
                        store_errors.dispatch({ type: 1, current_input: null });
                      }}
                      onChange={(e) =>
                        store_calculator.dispatch({
                          type: 1,
                          updateRoute: true,
                          index: i,
                          shipment_split: e.target.value,
                        })
                      }
                      class="form-control"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {" "}
                        %{" "}
                      </span>
                    </div>
                  </div>
                </div>,
              ]
            : [
                <div class="col ">
                  {i === 0 ? <label>Weekly Frequency</label> : ""}
                  <input
                    type="text"
                    tabindex="0"
                    class="form-control"
                    placeholder=""
                    id={"week_frenquency_calc" + i}
                    defaultValue={route.weekly_frequency}
                    maxlength="30"
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    onChange={(e) =>
                      store_calculator.dispatch({
                        type: 1,
                        updateRoute: true,
                        index: i,
                        weekly_frequency: e.target.value,
                      })
                    }
                  />
                </div>,
                <div class="col ">
                  {i === 0 ? <label>Operation DOW </label> : ""}
                  <input
                    type="text"
                    tabindex="0"
                    class="form-control"
                    placeholder=""
                    id={"operation_dow_calc" + i}
                    defaultValue={route.operation_dow}
                    maxlength="30"
                    onFocus={(e) => {
                      store_errors.dispatch({
                        type: 1,
                        current_input: e.target.id,
                      });
                    }}
                    onBlur={(e) => {
                      store_errors.dispatch({ type: 1, current_input: null });
                    }}
                    onChange={(e) =>
                      store_calculator.dispatch({
                        type: 1,
                        updateRoute: true,
                        index: i,
                        operation_dow: e.target.value,
                      })
                    }
                  />
                </div>,
              ]}

          <div class="col">
            {i === 0 && <br />}
            <button
              id={"routing-button-remove-calc" + i}
              type="button"
              class="btn btn-danger"
              onClick={async (e) => {
                if (routes.length > 1) {
                  store_calculator.dispatch({
                    type: 1,
                    removeRoute: true,
                    index: i,
                    force: true,
                  });
                } else if (routes.length === 1) {
                  store_calculator.dispatch({
                    type: 1,
                    removeRoute: true,
                    index: i,
                    force: true,
                  });
                  store_calculator.dispatch({
                    type: 1,
                    addRoute: true,
                    force: true,
                  });
                  store_calculator.dispatch({
                    type: 1,
                    updateRoute: true,
                    index: i,
                    origin: calculator.origin,
                    carrier: calculator.carrier,
                    force: true,
                  });
                }

                await calculateCost();

                let position = i - 1 > 0 ? i - 1 : 0;
                store_errors.dispatch({
                  type: 1,
                  current_input: "routing_board_point_calc" + position,
                });
              }}
            >
              <Delete fontSize="small" />
            </button>

            {i === routes.length - 1 ? (
              <button
                type="button"
                id={"routing-button-add-calc" + i}
                class="btn btn-success"
                onClick={async (e) => {
                  store_calculator.dispatch({
                    type: 1,
                    addRoute: true,
                    force: true,
                  });
                  await calculateCost();
                  store_errors.dispatch({
                    type: 1,
                    current_input: "routing_board_point_calc" + (i + 1),
                  });
                }}
              >
                <AddCircleIcon fontSize="small" />
              </button>
            ) : null}
          </div>
        </div>
      );
    });

    return (
      <div key={this.state.key} class={this.state.class}>
        {html}
      </div>
    );
  }
}


class RoutingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
      expectedTime: 0,
      timeIsNegative: false,
    };
  }

  handleExpectedTimeChange = (event) => {
    let expectedTime = parseInt(event.target.value);
    if (expectedTime < 0) {
      this.setState({ isNegative: true });
    } else {
      this.setState({ expectedTime: expectedTime, isNegative: false });
      store_routes.dispatch({ type: 1, expectedTime: expectedTime });
    }
  };

  componentDidMount() {
    let w = watch(store_routes.getState, "detailed_info");
    store_routes.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_shipment.getState, "exw");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_routes.getState, "force");
    store_routes.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate() {
    restrictInputAccess();
  }

  render() {
    let { routes, expectedTime, timeIsNegative, detailed_info } =
      store_routes.getState();

    routes = routes ? routes : [];
    let user = store_header.getState().user;

    return (
      <div class="card col section-routing" key={this.state.key}>
        <div class="card-header">
          <h5>
            <img src={require("../img/aeroplane.png")} alt={"Routing"} />{" "}
            Routing
          </h5>
          <span class="detailed_info">
            <FormControlLabel
              value="bottom"
              disabled={user === Util.CONST.CARGOAI ? true : false}
              control={
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "checkbox with default color" }}
                  defaultChecked={
                    user === Util.CONST.CARGOAI ? true : detailed_info
                  }
                  onChange={(e) => {
                    store_routes.dispatch({
                      type: 1,
                      detailed_info: e.target.checked,
                    });
                  }}
                />
              }
              label="Detailed Info"
              labelPlacement="left"
            />
          </span>

          <img
            src={require("../img/schedule.svg")}
            alt="Routing & Schedule"
            style={{ maxWidth: "30px" }}
            class="clickable"
            onClick={() => openSchedule(parseRouting())}
          />
        </div>
        <div class="card-body">
          <RoutingTable
            carriers_list={this.props.carriers_list}
            authorized_stations={this.props.authorized_stations}
            custom_list={this.props.custom_list}
          />
          <br />

          {!detailed_info && routes.length > 1 && (
            <div className="div-expected-time" id="div-expected-time">
              <label for="expected-time"> O to D expected time</label>
              <input
                type="number"
                min="0"
                step="1"
                defaultValue={expectedTime ? expectedTime : ""}
                className="input-expected-time"
                name="expected-time"
                maxLength="4"
                onKeyDown={(evt) =>
                  (evt.key === "." || evt.key === "," || evt.key === "e") &&
                  evt.preventDefault()
                }
                onChange={this.handleExpectedTimeChange}
              />
              <label className="label-expected-time">
                {expectedTime === 1 ? "day" : "days"}
              </label>
              {this.state.isNegative && (
                <div style={{ color: "red" }}>
                  {" "}
                  The expected time must be positive{" "}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

class CalculatorRoutingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
    };
  }

  componentDidMount() {
    let w = watch(store_calculator.getState, "detailed_info");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate() {
    //restrictInputAccess()
  }

  render() {
    let calculator = store_calculator.getState();

    return (
      <div class="card col section-routing" key={this.state.key}>
        <div class="card-header">
          <h5>
            <img src={require("../img/aeroplane.png")} alt={"Routing"} />{" "}
            Routing
          </h5>
          <span class="detailed_info">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "checkbox with default color" }}
                  defaultChecked={calculator.detailed_info}
                  onChange={(e) => {
                    store_calculator.dispatch({
                      type: 1,
                      detailed_info: e.target.checked,
                    });
                  }}
                />
              }
              label="Detailed Info"
              labelPlacement="left"
            />
          </span>

          <img
            src={require("../img/schedule.svg")}
            alt="Routing & Schedule"
            style={{ maxWidth: "30px" }}
            class="clickable"
            onClick={(e) => {
              store_calculator.dispatch({ type: 1, schedule: true });
              openSchedule(parseRouting());
            }}
          />
        </div>
        <div class="card-body">
          <CalculatorRoutingTable
            carriers_list={this.props.carriers_list}
            authorized_stations={this.props.authorized_stations}
            custom_list={this.props.custom_list}
          />
          <br />
        </div>
      </div>
    );
  }
}

class CalculatorSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
      show: false,
    };
  }

  componentDidMount() {
    let w = watch(store_calculator.getState, "force");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate() {}

  render() {
    let calculator = store_calculator.getState();
    let routes = store_calculator.getState().routing;
    let {
      rate,
      per_kg,
      all_in,
      sell_buy,
      currency,
      handle_cost,
      truck_cost,

      total_contribution,
      total_revenue,
      total_cost,
      cost_fuel,
      cost_security,
      net_net,
      oc_cost,
      chargeable_weight,
    } = calculator;

    let total_cost_handling = 0;
    let total_cost_trucking = 0;


    rate = !isNaN(parseFloat(rate)) ? parseFloat(rate) : 0;
    chargeable_weight = !isNaN(chargeable_weight)
      ? parseFloat(chargeable_weight)
      : 0;

    handle_cost = handle_cost ? handle_cost : [];
    truck_cost = truck_cost ? truck_cost : [];
    oc_cost = oc_cost ? oc_cost[0] : null;

    let html = [];

    let exch_rate = this.props.exch_rate ? this.props.exch_rate : [];

    //FUEL AND SECURITY
    let oc = oc_cost;

    //FUEL
    let { fuel_total, fuel_rate, fuel_currency } = getFuelFromSell(oc);

    fuel_total = !isNaN(parseFloat(fuel_total)) ? parseFloat(fuel_total) : 0;
    fuel_rate = !isNaN(parseFloat(fuel_rate)) ? parseFloat(fuel_rate) : 0;
    fuel_total =
      fuel_total && currency !== fuel_currency
        ? Util.convert_currency(fuel_total, fuel_currency, currency, exch_rate)
        : fuel_total;

    fuel_rate =
      fuel_rate && currency !== fuel_currency
        ? Util.convert_currency(fuel_rate, fuel_currency, currency, exch_rate)
        : fuel_rate;

    cost_fuel = per_kg ? fuel_rate : fuel_total;
    cost_fuel = !isNaN(parseFloat(cost_fuel)) ? parseFloat(cost_fuel) : 0;

    //SECURITY
    let { security_total, security_rate, security_currency } =
      getSecurityFromSell(oc);

    security_total = !isNaN(parseFloat(security_total))
      ? parseFloat(security_total)
      : 0;
    security_rate = !isNaN(parseFloat(security_rate))
      ? parseFloat(security_rate)
      : 0;
    security_total =
      security_total && currency !== security_currency
        ? Util.convert_currency(
            security_total,
            security_currency,
            currency,
            exch_rate
          )
        : security_total;
    security_rate =
      security_rate && currency !== security_currency
        ? Util.convert_currency(
            security_rate,
            security_currency,
            currency,
            exch_rate
          )
        : security_rate;
    cost_security = per_kg ? security_rate : security_total;
    cost_security = !isNaN(parseFloat(cost_security))
      ? parseFloat(cost_security)
      : 0;

    total_revenue = all_in ? rate : rate + cost_fuel + cost_security;
    net_net = all_in ? rate - cost_fuel - cost_security : rate;

    routes.forEach((route) => {
      let i = routes.indexOf(route);

      let trucking = truck_cost.filter((t) => t.position === i)[0];
      let handling = handle_cost.filter((t) => t.position === i)[0];
      //let fuel_security = oc_cost.filter((o) => o.position === i)[0]

      let trucking_cost = trucking ? trucking.cost : null;
      let handling_cost = handling ? handling.cost : null;
      let last_handling_cost =
        handling && i === routes.length - 1 ? handling.last_handling : null;
      //let oc = fuel_security ? fuel_security.oc : null

      let sell_trucking = trucking_cost
        ? trucking_cost.filter((t) => t.sell_buy === "S")[0]
        : 0;
      let buy_trucking = trucking_cost
        ? trucking_cost.filter((t) => t.sell_buy === "B")[0]
        : 0;

      let sell_trucking_currency = sell_trucking
        ? sell_trucking.currency
        : null;
      let buy_trucking_currency = buy_trucking ? buy_trucking.currency : null;

      sell_trucking = sell_trucking
        ? per_kg
          ? sell_trucking.rate
          : sell_trucking.rate * chargeable_weight
        : 0;
      buy_trucking = buy_trucking
        ? per_kg
          ? buy_trucking.rate
          : buy_trucking.rate * chargeable_weight
        : 0;

      sell_trucking = !isNaN(parseFloat(sell_trucking))
        ? parseFloat(sell_trucking)
        : 0;
      buy_trucking = !isNaN(parseFloat(buy_trucking))
        ? parseFloat(buy_trucking)
        : 0;

      let sell_handling = handling_cost
        ? handling_cost.filter((t) => t.sell_buy === "S")[0]
        : 0;
      let buy_handling = handling_cost
        ? handling_cost.filter((t) => t.sell_buy === "B")[0]
        : 0;

      let last_sell_handling = 0;
      let last_buy_handling = 0;

      if (last_handling_cost) {
        last_sell_handling = last_handling_cost.filter(
          (t) => t.sell_buy === "S"
        )[0];
        last_buy_handling = last_handling_cost.filter(
          (t) => t.sell_buy === "B"
        )[0];
      }

      let sell_handling_currency = sell_handling
        ? sell_handling.currency
        : null;
      let buy_handling_currency = buy_handling ? buy_handling.currency : null;

      let last_sell_handling_currency = last_sell_handling
        ? last_sell_handling.currency
        : null;
      let last_buy_handling_currency = last_buy_handling
        ? last_buy_handling.currency
        : null;

      sell_handling = sell_handling
        ? per_kg
          ? sell_handling.rate
          : sell_handling.rate * chargeable_weight
        : 0;
      buy_handling = buy_handling
        ? per_kg
          ? buy_handling.rate
          : buy_handling.rate * chargeable_weight
        : 0;

      last_sell_handling = last_sell_handling
        ? per_kg
          ? last_sell_handling.rate
          : last_sell_handling.rate * chargeable_weight
        : 0;
      last_buy_handling = last_buy_handling
        ? per_kg
          ? last_buy_handling.rate
          : last_buy_handling.rate * chargeable_weight
        : 0;

      sell_handling = !isNaN(parseFloat(sell_handling))
        ? parseFloat(sell_handling)
        : 0;
      buy_handling = !isNaN(parseFloat(buy_handling))
        ? parseFloat(buy_handling)
        : 0;

      last_buy_handling = !isNaN(parseFloat(last_buy_handling))
        ? parseFloat(last_buy_handling)
        : 0;
      last_sell_handling = !isNaN(parseFloat(last_sell_handling))
        ? parseFloat(last_sell_handling)
        : 0;

      let total_cost_line = 0;
      //Convert
      buy_trucking =
        buy_trucking && currency !== buy_trucking_currency
          ? Util.convert_currency(
              buy_trucking,
              buy_trucking_currency,
              currency,
              exch_rate
            )
          : buy_trucking;

      sell_trucking =
        sell_trucking && currency !== sell_trucking_currency
          ? Util.convert_currency(
              sell_trucking,
              sell_trucking_currency,
              currency,
              exch_rate
            )
          : sell_trucking;

      buy_handling =
        buy_handling && currency !== buy_handling_currency
          ? Util.convert_currency(
              buy_handling,
              buy_handling_currency,
              currency,
              exch_rate
            )
          : buy_handling;

      sell_handling =
        sell_handling && currency !== sell_handling_currency
          ? Util.convert_currency(
              sell_handling,
              sell_handling_currency,
              currency,
              exch_rate
            )
          : sell_handling;

      last_buy_handling =
        last_buy_handling && currency !== last_buy_handling_currency
          ? Util.convert_currency(
              last_buy_handling,
              last_buy_handling_currency,
              currency,
              exch_rate
            )
          : last_buy_handling;
      last_sell_handling =
        last_sell_handling && currency !== last_sell_handling_currency
          ? Util.convert_currency(
              last_sell_handling,
              last_sell_handling_currency,
              currency,
              exch_rate
            )
          : last_sell_handling;

      if (sell_buy === "B") {
        total_cost_line = buy_trucking + buy_handling;
        total_cost_trucking = total_cost_trucking + buy_trucking;
        total_cost_handling = total_cost_handling + buy_handling;
      } else if (sell_buy === "S") {
        total_cost_line = sell_trucking + sell_handling;
        total_cost_trucking = total_cost_trucking + sell_trucking;
        total_cost_handling = total_cost_handling + sell_handling;
      }

      if (i === routes.length - 1) {
        if (sell_buy === "B" && last_buy_handling) {
          total_cost_line = total_cost_line + last_buy_handling;
          total_cost_handling = total_cost_handling + last_buy_handling;
        } else if (sell_buy === "S" && last_sell_handling) {
          total_cost_line = total_cost_line + last_sell_handling;
          total_cost_handling = total_cost_handling + last_sell_handling;
        }
      }

      trucking = sell_buy === "B" ? buy_trucking : sell_trucking;
      handling = sell_buy === "B" ? buy_handling : sell_handling;
      let last_handling =
        sell_buy === "B" ? last_buy_handling : last_sell_handling;
    });

    total_cost = total_cost_handling + total_cost_trucking;
    total_contribution = total_revenue - total_cost;

    store_calculator.dispatch({
      type: 1,
      total_cost_trucking: total_cost_trucking,
      total_cost_handling: total_cost_handling,
      cost_fuel: cost_fuel,
      cost_security: cost_security,
      net_net: net_net,
      total_cost: total_cost,
      total_contribution: total_contribution,
      total_revenue: total_revenue,
    });

    return (
      <div class="card col section-routing">
        <div class="card-header">
          <div class="row">
            <div class="col-2">
              <button
                style={{ margin: "1em", padding: "1em" }}
                type="button"
                class="btn btn-primary"
                onClick={async (e) => {
                  await calculateCost();
                }}
              >
                Calculate Costs
              </button>
            </div>
            <div class="col-1" style={{ paddingTop: "1em" }}>
              <CalculatorSpinner />
            </div>

            <div class="col-2">
              <label>Rate {per_kg ? "Per Kg" : "Total"}</label>
              <input
                type="text"
                id="calculator-rate"
                class={"form-control"}
                defaultValue={rate ? parseFloat(rate).toFixed(2) : ""}
                onChange={(e) => {
                  store_calculator.dispatch({
                    type: 1,
                    rate: e.target.value,
                    force: e.target.value ? true : false,
                  });
                }}
              />
            </div>

            <div class="col-1">
              <label>Unit</label>
              <select
                id="calculator-unit"
                class="form-control dropdown"
                onChange={(e) => {
                  store_calculator.dispatch({
                    type: 1,
                    per_kg: e.target.value === "true",
                    force: true,
                  });
                }}
              >
                <option value={per_kg ? true : false}>
                  {" "}
                  {per_kg ? Util.CONST.PER_KG : Util.CONST.TOTAL}{" "}
                </option>
                <option value={per_kg ? false : true}>
                  {" "}
                  {per_kg ? Util.CONST.TOTAL : Util.CONST.PER_KG}{" "}
                </option>
              </select>
            </div>

            <div class="col-2 checkbox_main_deck">
              <FormControlLabel
                value="bottom"
                label="All-In"
                labelPlacement="top"
                control={
                  <Checkbox
                    color="primary"
                    //inputProps={{ "aria-label": "checkbox with default color" }}
                    defaultChecked={all_in}
                    onChange={(e) => {
                      store_calculator.dispatch({
                        type: 1,
                        all_in: !all_in,
                        force: true,
                      });
                    }}
                  />
                }
              />
            </div>

            <div class="col-2">
              <label>Currency</label>
              <select
                class="form-control dropdown"
                onChange={(e) => {
                  store_calculator.dispatch({
                    type: 1,
                    currency: e.target.value,
                    force: true,
                  });
                }}
              >
                <option value={currency}>{currency}</option>
                {util.CURRENCIES.filter((c) => c !== currency).map((c) => {
                  return <option value={c}> {c} </option>;
                })}
              </select>
            </div>

            <div class="col-2">
              <label>Sell/Buy</label>
              <select
                id="calculator-sell_buy"
                class="form-control dropdown"
                onChange={async (e) => {
                  store_calculator.dispatch({
                    type: 1,
                    sell_buy: e.target.value,
                    force: true,
                  });
                  await calculateCost();
                }}
              >
                <option value={sell_buy === "S" ? "S" : "B"}>
                  {" "}
                  {sell_buy === "S" ? "Sell" : "Buy"}{" "}
                </option>
                <option value={sell_buy === "S" ? "B" : "S"}>
                  {" "}
                  {sell_buy === "S" ? "Buy" : "Sell"}{" "}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div key={this.state.key}>
          <div class="card-header">
            <h5>Total Revenue</h5>
          </div>
          <div class="card-body">
            <TotalRevenue />
          </div>

          <div class="card-header">
            <h5>Total Costs</h5>
          </div>
          <div class="card-body">
            <TotalCost />
          </div>

          <div class="card-header">
            <h5>Total Contribution</h5>
          </div>
          <div class="card-body">
            <TotalContribution />
          </div>
        </div>
      </div>
    );
  }
}

class CalculatorSearchSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w = watch(store_calculator.getState, "force");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  componentDidUpdate() {}

  async onBlurSHC(e, i) {
    let json = { type: 1, update: true, index: i };
    json[e.target.name] = e.target.value.toUpperCase();
    let current_value = store_calculator.getState()[e.target.name];
    current_value = current_value[i];
    current_value = current_value ? current_value.toUpperCase() : "";
    if (current_value !== e.target.value.toUpperCase()) {
      store_calculator.dispatch(json);
      await calculateCost();
    }

    store_errors.dispatch({ type: 1, current_input: null });
  }

  async onChangeStation(e) {
    let station = this.props.authorized_stations.filter(
      (s) => s.station === e.target.value
    )[0];
    store_calculator.dispatch({
      type: 1,
      station: e.target.value,
      sales_region: station ? station.sales_region : null,
      origin_zone: station ? station.origin_zone : null,
      update: true,
    });

    await calculateCost();
  }

  async onBlur(e) {
    let json = { type: 1, update: true };
    json[e.target.name] = e.target.value.toUpperCase();
    let current_value = store_calculator.getState()[e.target.name];
    current_value = current_value ? current_value.toString().toUpperCase() : "";
    if (current_value !== e.target.value.toString().toUpperCase()) {
      store_calculator.dispatch(json);
      await calculateCost();
    }

    store_errors.dispatch({ type: 1, current_input: null });
  }

  render() {
    let calculator = store_calculator.getState();
    let calculator_errors = store_calculator.getState().calculator_errors;

    return (
      <div key={this.state.key}>
        <div class="row">
          <div class="col-2">
            <label>Station</label>
            <select
              class="form-control dropdown"
              id="calculator_station"
              onChange={async (e) => {
                this.onChangeStation(e);
              }}
            >
              <option value={calculator.station}>{calculator.station} </option>
              {this.props.authorized_stations
                .filter((s) => s.station !== calculator.station)
                .map((s) => {
                  return (
                    <option value={s.station}>
                      {s.station_name} - {s.station}{" "}
                    </option>
                  );
                })}
            </select>
          </div>

          <div class="col-4">
            <label>Agent*</label>
            <AutocompleteAgentCalculator />
            <div class="error">
              {calculator_errors && calculator_errors.agent
                ? calculator_errors.agent
                : ""}
            </div>
          </div>

          <div class="col-1">
            <label>Origin*</label>
            <input
              id="calculator_origin"
              name="origin"
              type="text"
              tabindex="0"
              class={"form-control"}
              defaultValue={calculator.origin}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={async (e) => {
                this.onBlur(e);
              }}
              maxlength="3"
            />
            <div class="error">
              {calculator_errors && calculator_errors.origin
                ? calculator_errors.origin
                : ""}
            </div>
          </div>

          <div class="col-1">
            <label>Destination*</label>
            <input
              id="calculator_dest"
              name="dest"
              type="text"
              tabindex="0"
              class={"form-control"}
              defaultValue={calculator.dest}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) => {
                this.onBlur(e);
              }}
              maxlength="3"
            />
            <div class="error">
              {calculator_errors && calculator_errors.dest
                ? calculator_errors.dest
                : ""}
            </div>
          </div>

          <div class="col-1">
            <label>Issue Carrier*</label>
            <input
              id="calculator_carrier"
              type="text"
              tabindex="0"
              name="carrier"
              class={"form-control"}
              defaultValue={calculator.carrier}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) => {
                this.onBlur(e);
              }}
              maxlength="3"
            />
            <div class="error">
              {calculator_errors && calculator_errors.carrier
                ? calculator_errors.carrier
                : ""}
            </div>
          </div>

          <div class="col-2">
            <label>Chargeable Weight*</label>
            <div class="input-group mb-3">
              <input
                id="calculator_chg_wgt"
                name="chargeable_weight"
                type="text"
                tabindex="0"
                class={"form-control"}
                aria-label="Username"
                aria-describedby="basic-addon1"
                defaultValue={calculator.chargeable_weight}
                onFocus={(e) =>
                  store_errors.dispatch({ type: 1, current_input: e.target.id })
                }
                onBlur={async (e) => {
                  this.onBlur(e);
                }}
              />

              <div class="input-group-prepend ">
                <span class="input-group-text" id="basic-addon1">
                  kg
                </span>
              </div>
              <div class="error">
                {calculator_errors && calculator_errors.chargeable_weight
                  ? calculator_errors.chargeable_weight
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <label>SHC 1</label>
            <input
              type="text"
              id="shc_cal_1"
              name="shc"
              class="form-control"
              defaultValue={calculator.shc[0]}
              maxlength="3"
              onBlur={(e) => {
                this.onBlurSHC(e, 0);
              }}
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
            />
          </div>

          <div class="col-2">
            <label>SHC 2</label>
            <input
              type="text"
              id="shc_cal_2"
              class="form-control"
              name="shc"
              defaultValue={calculator.shc[1]}
              maxlength="3"
              onBlur={(e) => {
                this.onBlurSHC(e, 1);
              }}
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
            />
          </div>

          <div class="col-2">
            <label>SHC 3</label>
            <input
              type="text"
              id="shc_cal_3"
              name="shc"
              class="form-control"
              defaultValue={calculator.shc[2]}
              maxlength="3"
              onBlur={(e) => {
                this.onBlurSHC(e, 2);
              }}
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
            />
          </div>

          <div class="col-2">
            <label>SHC 4</label>
            <input
              type="text"
              name="shc"
              id="shc_cal_4"
              class="form-control"
              defaultValue={calculator.shc[3]}
              maxlength="3"
              onBlur={(e) => {
                this.onBlurSHC(e, 3);
              }}
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
            />
          </div>

          <div class="col-2">
            <form style={{ marginTop: "0.5em" }} tabindex="10" noValidate>
              <TextField
                id="calculator__from_date"
                label="From Date"
                name="from_date"
                type="date"
                tabindex="10"
                defaultValue={Util.dateToString3(calculator.from_date)}
                onBlur={async (e) => {
                  this.onBlur(e);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>

          <div class="col-2">
            <form style={{ marginTop: "0.5em" }} tabindex="10" noValidate>
              <TextField
                id="calculator_to_date"
                label="To Date"
                name="to_date"
                type="date"
                tabindex="10"
                defaultValue={Util.dateToString3(calculator.to_date)}
                onBlur={async (e) => {
                  this.onBlur(e);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

class CalculatorSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    let w = watch(store_calculator.getState, "search");
    store_calculator.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let search = store_calculator.getState().search;

    if (!search) return null;
    return (
      <div key={this.state}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
}

class ChatMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
    this.download = this.download.bind(this);
  }

  componentDidMount() {}

  download(e) {
    e.preventDefault();
    let { file, file_name } = this.props;

    try {
      var blob = util.dataURItoBlob(file);
      saveAs(blob, file_name);
    } catch (e) {
      console.log(e.toString());
    }
  }

  removeMessage(user, timestamp) {
    store_remarks.dispatch({
      type: 1,
      remove_message: true,
      user: user,
      timestamp: timestamp,
      force: true,
    });
  }

  render() {
    let { user, message, file_name, timestamp } = this.props;
    const new_ = this.props["new"];
    return (
      <Card
        className={
          "card_chat_message " +
          (user.indexOf("Quantum") >= 0 || !user ? "quantum" : "customer")
        }
      >
        <CardHeader
          className="header_chat_message"
          action={<IconButton aria-label="settings"></IconButton>}
          title={user}
          subheader={util.convertDate(timestamp * 1000, true)}
        />
        <CardContent className="content_chat_message">
          {message &&
            message.split("\n").map((m) => (
              <Typography variant="body2" color="textSecondary" component="p">
                {" "}
                {m}{" "}
              </Typography>
            ))}
          {file_name && (
            <a href="" onClick={this.download}>
              {file_name} <AttachFileIcon />
            </a>
          )}
          {new_ && (
            <span>
              <em>pending </em>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  this.removeMessage(user, timestamp);
                }}
              >
                (remove)
              </a>
            </span>
          )}
        </CardContent>
      </Card>
    );
  }
}

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
      message: "",
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.writeMessage = this.writeMessage.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  componentDidMount() {
    let w = watch(store_remarks.getState, "force");
    store_remarks.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let ele = document.getElementById("chat_frame");
    if (ele) ele.scrollBy(0, ele.scrollHeight);
  }

  componentDidUpdate() {
    let { current_input } = store_errors.getState();
    let ele = document.getElementById(current_input);
    if (ele) ele.focus();

    ele = document.getElementById("chat_frame");
    if (ele) ele.scrollBy(0, ele.scrollHeight);
  }

  writeMessage(e) {
    this.setState({ message: e.target.value });
  }

  sendMessage(e) {
    let { message } = this.state;

    if (util.isDeepEmpty(message)) return;

    store_remarks.dispatch({
      type: 1,
      add_message: true,
      message: message,
      user: cookies.first_name + "/Quantum",
    });
    this.setState({ key: Date.now(), message: "" });
  }

  handleFile(e) {
    let file = e.target.files[0];

    try {
      let validTypes = [
        "pdf",
        "doc",
        "docx",
        "msg",
        "xls",
        "xlsx",
        "jpeg",
        "png",
        "jpg",
      ];
      let name = file.name ? file.name : "";
      let name_split = name.split(".");
      let type = name_split[name_split.length - 1];
      let size = file.size;

      if (!validTypes.includes(type.toLowerCase()))
        throw (
          "The file " +
          name +
          " is wrong format. Only (PDF, EXCEL, DOC, IMAGE, MSG)"
        );

      if (size > 5 * 1048576)
        throw "File : " + name + " is too large. Max size (5M).";

      var reader = new FileReader();

      if (reader.readAsDataURL) {
        reader.onload = function (e) {
          store_remarks.dispatch({
            type: 1,
            add_message: true,
            message: " ",
            user: "quantum",
            file: e.target.result,
            file_name: name,
            force: true,
          });
        };
        reader.readAsDataURL(file);
      }

      return;
    } catch (e) {
      console.log(e.toString());
      return;
    }
  }

  render() {
    let { chat_messages, chat_links } = store_remarks.getState();
    chat_messages = chat_messages ? chat_messages : [];
    const { key } = this.state;

    return (
      <div key={key}>
        <br />
        <div
          class="row"
          style={{
            overflow: "auto",
            height: "10em",
          }}
          id="chat_frame"
        >
          {chat_messages.map((message_) => {
            const { user, message, timestamp, file, file_name } = message_;
            const new_ = message_["new"];
            return [
              !user || user.indexOf("Quantum") >= 0 ? (
                <div class="col-6"></div>
              ) : null,
              <div class="col-6" style={{ marginBottom: "0.2em" }}>
                {
                  <ChatMessage
                    className="chat_message"
                    message={message}
                    user={user}
                    timestamp={timestamp}
                    file_name={file_name}
                    file={file}
                    new={new_}
                  />
                }
              </div>,
              !user || user.indexOf("Quantum") >= 0 ? null : (
                <div class="col-6"></div>
              ),
            ];
          })}
        </div>

        <br />

        <div class="row">
          <div class="col-10">
            <input
              type="text"
              id="chat_input"
              class="form-control"
              placeholder="Type something"
              maxlength="255"
              onChange={this.writeMessage}
              onKeyUp={async (e) => {
                if (e.keyCode === 13) this.sendMessage();
              }}
              style={{ padding: "0.375rem 0.75rem", height: "auto" }}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
            />
          </div>

          <div class="col-2">
            <button
              type="button"
              class="btn btn-primary edit_menu"
              onClick={this.sendMessage}
            >
              <SendIcon />
            </button>

            <input
              type="file"
              id="hiddenfile_chat"
              style={{ display: "none" }}
              name="file"
              tabIndex="-1"
              onChange={(e) => {
                this.handleFile(e);
              }}
            />

            {/*
                <button 
                  type="button"      
                  id = 'btn_upload_chat'
                  class="btn btn-primary edit_menu"
                  onClick={()=>document.getElementById('hiddenfile_chat').click()}
                >
                  <AttachFileIcon/>
                </button>
                */}
          </div>
        </div>

        <div class="row">
          {chat_links && [
            <span class="col-12">Attachments</span>,
            chat_links.map((link) => (
              <span class="col-12">
                <a target="_blank" href={link}>
                  {link}
                </a>
              </span>
            )),
          ]}
        </div>

        <br />
      </div>
    );
  }
}

class RemarkSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      class: props.class,
    };
  }

  componentDidMount() {
    let w = watch(store_remarks.getState, "force");
    store_remarks.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let header = store_header.getState();
    let remarks = store_remarks.getState();
    let attachments = remarks.attachments;
    let attachmentHTML = [];
    let { user, external_id } = header;

    attachments.forEach((a) => {
      if (a.file_name && a.file) {
        let i = attachments.indexOf(a);
        if (i === 0 && a.file && header.user === Util.CONST.CARGOAI) return;
        attachmentHTML.push(
          <div class="row" style={{ marginLeft: "0.1em", fontSize: "0.8em" }}>
            <div class="col-1">
              <a
                href=""
                data-tip={"Delete attachment"}
                delay-hide={1000}
                onClick={(e) => {
                  e.preventDefault();
                  store_remarks.dispatch({
                    type: 1,
                    force: true,
                    addAttachment: true,
                    file_name: "",
                    file: "",
                    index: i,
                    updateAttachment: true,
                  });
                }}
              >
                <Delete fontSize="small" color="secondary" />
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  delayHide={500}
                />
              </a>
            </div>

            <div class="col-8" style={{ paddingTop: "0.2em" }}>
              <a
                download={a.file_name}
                data-tip={"Download attachment"}
                delay-hide={1000}
                href={a.file}
              >
                {a.file_name}
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  delayHide={500}
                />
              </a>
            </div>
          </div>
        );
      }
    });

    return (
      <div class="row section-remark" key={this.state.key}>
        <div class="card col-md-6 col-sm-12">
          <div class="card-header">
            {user && user === Util.CONST.CARGOAI ? (
              <h6>
                {" "}
                <ChatIcon /> Comments{" "}
              </h6>
            ) : (
              <h6>
                {" "}
                <img src={require("../img/remarks_notes.png")} /> External
                Remark
              </h6>
            )}
          </div>

          {(!external_id ||
            (external_id && user && user !== Util.CONST.CARGOAI)) && (
            <div class="card-body">
              <div class="input-group mb-3">
                <textarea
                  class="form-control"
                  aria-label="With textarea"
                  maxlength="499"
                  defaultValue={store_remarks.getState().ext_remarks}
                  onChange={(e) => {
                    store_remarks.dispatch({
                      type: 1,
                      ext_remarks: e.target.value,
                    });
                  }}
                ></textarea>

                <div>
                  <button
                    class="btn btn-success"
                    type="button"
                    id="button-addon1"
                    onClick={() => {
                      store_modals.dispatch({ type: 1, modalRemarkExt: true });
                    }}
                  >
                    <AddCircleIcon fontSize="small" />
                  </button>
                </div>
              </div>
            </div>
          )}

          {user && user === Util.CONST.CARGOAI && <Chat />}
        </div>

        <div class="card col-md-6 col-sm-12">
          <div class="card-header">
            <h6>
              <img
                src={require("../img/remarks_notes.png")}
                alt={"Internal Remark"}
              />{" "}
              Internal Remark{" "}
            </h6>
          </div>

          <div class="card-body">
            <div class="input-group mb-3">
              <textarea
                class="form-control"
                aria-label="With textarea"
                maxlength="500"
                defaultValue={store_remarks.getState().int_remarks}
                onChange={(e) => {
                  store_remarks.dispatch({
                    type: 1,
                    int_remarks: e.target.value,
                  });
                }}
              ></textarea>

              <div class="">
                <div>
                  <button
                    class="btn btn-success"
                    type="button"
                    id="button-addon1"
                    onClick={() => {
                      store_modals.dispatch({ type: 1, modalRemarkInt: true });
                    }}
                  >
                    <AddCircleIcon fontSize="small" />
                  </button>
                </div>

                <div>
                  <button
                    class="btn btn-success"
                    type="button"
                    id="button"
                    data-tip={"Upload attachments"}
                    delay-hide={1000}
                    data-type="dark"
                    onClick={() => {
                      let attachments_copy = [...attachments];
                      store_remarks.dispatch({
                        type: 1,
                        attachments_copy: attachments_copy,
                        error: "",
                      });
                      store_modals.dispatch({ type: 1, modalAttachment: true });
                    }}
                  >
                    <AttachFileIcon fontSize="small" />
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      delayHide={500}
                    />
                  </button>
                </div>
              </div>
            </div>

            {attachmentHTML.length > 0 ? "Attachments" : ""}

            <br />
            <br />

            {attachmentHTML}
          </div>
        </div>
      </div>
    );
  }
}

class PackingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
      stackable_fill: "",
      stackable_fill_invalid: [],
      current_input: null,
      newLine: null,
      removeLine: null,
    };
    this.checkPacking = this.checkPacking.bind(this);
  }

  componentDidMount() {
    let w = watch(store_shipment.getState, "packing_change2");
    store_shipment.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s packing changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now() });
        this.checkPacking();
      })
    );

    let w1 = watch(store_shipment.getState, "weight_system");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_shipment.getState, "length_system");
    store_shipment.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    this.checkPacking();
  }

  componentDidUpdate() {
    restrictInputAccess();

    if (this.state.newLine) {
      const packing = store_shipment.getState().packing;
      let input = document.getElementById(
        "packing_input_pieces" + (packing.length - 1)
      );
      if (input) input.focus();
      this.setState({ newLine: null });
    } else if (this.state.removeLine) {
      const packing = store_shipment.getState().packing;
      let input = document.getElementById(
        "packing_input_pieces" + (packing.length - 1)
      );
      if (input) input.focus();
      this.setState({ removeLine: null });
    } else {
      let { current_input } = store_errors.getState();
      let input = document.getElementById(current_input);
      if (input) input.focus();
    }
  }

  checkPacking() {
    let packing = store_shipment.getState().packing;
    let error = false;

    if (packing && packing.length > 0) {
      let i = 0;
      let invalids = [];

      for (i = 0; i < packing.length; i++) {
        invalids.push({
          pieces: "",
          length: "",
          width: "",
          height: "",
          weight: "",
        });
      }

      for (i = 0; i < packing.length; i++) {
        if (!packing[i].stackable) {
          if (!packing[i].pieces || isNaN(parseInt(packing[i].pieces))) {
            error = true;
            invalids[i].pieces = "is-invalid";
          }

          if (!packing[i].length || isNaN(parseFloat(packing[i].length))) {
            error = true;
            invalids[i].length = "is-invalid";
          }

          if (!packing[i].width || isNaN(parseFloat(packing[i].width))) {
            error = true;
            invalids[i].width = "is-invalid";
          }

          if (!packing[i].height || isNaN(parseFloat(packing[i].height))) {
            error = true;
            invalids[i].height = "is-invalid";
          }
        }
      }

      if (error)
        this.setState({
          key: Date.now(),
          stackable_fill: Util.TEXTS.STACKABLES_FILL_TEXT,
          stackable_fill_invalid: invalids,
        });
      else
        this.setState({
          key: Date.now(),
          stackable_fill: "",
          stackable_fill_invalid: [],
        });
    }
  }

  initStackableInvalid() {
    let packing = store_shipment.getState().packing;
    let invalids = store_errors.getState().stackable_fill_invalid;

    if (!invalids || invalids.length === 0) {
      for (let i = 0; i < packing.length; i++) {
        invalids.push({
          pieces: "",
          length: "",
          width: "",
          height: "",
          weight: "",
        });
      }
    }

    this.setState({ type: 1, stackable_fill_invalid: invalids });
  }

  onChangeStackable(e, i) {
    store_shipment.dispatch({
      type: 1,
      updatePacking: true,
      index: i,
      stackable: e.target.checked,
      packing_change: true,
    });
    let shipment = store_shipment.getState();
    let packing = shipment.packing;
    let stackables = [];

    for (let idx = 0; idx < shipment.packing.length; idx++) {
      stackables.push(packing[idx].stackable);
    }

    if (!stackables.includes(false)) {
      store_shipment.dispatch({
        type: 1,
        updatePacking: true,
        all_stackable: true,
      });
      store_shipment.dispatch({ type: 1, packing_stackable: "Yes" });
    }

    if (!stackables.includes(true)) {
      store_shipment.dispatch({
        type: 1,
        updatePacking: true,
        all_stackable: false,
      });
      store_shipment.dispatch({ type: 1, packing_stackable: "No" });
    }

    if (stackables.includes(true) && stackables.includes(false)) {
      store_shipment.dispatch({ type: 1, packing_stackable: "Partially" });
    }

    if (stackables.includes(false)) {
      store_shipment.dispatch({
        type: 1,
        all_stackable_select: false,
        all_stackable: false,
      });
    }

    this.setState({ key: Date.now() });
  }

  render() {
    let shipment = store_shipment.getState();
    let nbLines = shipment.packing.length;
    let html = [];
    let { stackable_fill_invalid, stackable_fill } = this.state;

   
    if (nbLines === 0) {
      store_shipment.dispatch({ type: 1, addLine: true });
    }

    for (let i = 0; i < nbLines; i++) {
      html.push(
        <tr>
          <td>{i + 1}#</td>

          <td>
            <input
              type="text"
              class="form-control"
              id={"packing_input_pieces" + i}
              style={{
                border:
                  stackable_fill_invalid.length === 0 ||
                  !stackable_fill_invalid[i] ||
                  !stackable_fill_invalid[i].pieces
                    ? ""
                    : "2px solid #ffeeba",
              }}
              maxLength={3}
              defaultValue={
                shipment.packing[i].pieces ? shipment.packing[i].pieces : ""
              }
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  updatePacking: true,
                  index: i,
                  pieces: e.target.value,
                  packing_change: true,
                });
              }}
              onBlur={(e) =>
                store_errors.dispatch({ type: 1, current_input: null })
              }
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
            />
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              defaultValue={
                shipment.packing[i].length ? shipment.packing[i].length : ""
              }
              id={"packing_input_length" + i}
              maxLength={4}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) =>
                store_errors.dispatch({ type: 1, current_input: null })
              }
              style={{
                border:
                  stackable_fill_invalid.length === 0 ||
                  !stackable_fill_invalid[i] ||
                  !stackable_fill_invalid[i].length
                    ? ""
                    : "2px solid #ffeeba",
              }}
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  updatePacking: true,
                  index: i,
                  length: e.target.value,
                  packing_change: true,
                });
              }}
            />
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              id={"packing_input_width" + i}
              maxLength={3}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) =>
                store_errors.dispatch({ type: 1, current_input: null })
              }
              defaultValue={
                shipment.packing[i].width ? shipment.packing[i].width : ""
              }
              style={{
                border:
                  stackable_fill_invalid.length === 0 ||
                  !stackable_fill_invalid[i] ||
                  !stackable_fill_invalid[i].width
                    ? ""
                    : "2px solid #ffeeba",
              }}
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  updatePacking: true,
                  index: i,
                  width: e.target.value,
                  packing_change: true,
                });
              }}
            />
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              id={"packing_input_height" + i}
              maxLength={3}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) =>
                store_errors.dispatch({ type: 1, current_input: null })
              }
              defaultValue={
                shipment.packing[i].height ? shipment.packing[i].height : ""
              }
              style={{
                border:
                  stackable_fill_invalid.length === 0 ||
                  !stackable_fill_invalid[i] ||
                  !stackable_fill_invalid[i].height
                    ? ""
                    : "2px solid #ffeeba",
              }}
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  updatePacking: true,
                  index: i,
                  height: e.target.value,
                  packing_change: true,
                });
              }}
            />
          </td>

          <td>
            <input
              type="text"
              class="form-control "
              defaultValue={
                shipment.packing[i].weight ? shipment.packing[i].weight : ""
              }
              maxLength={8}
              id={"packing_input_weight" + i}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) =>
                store_errors.dispatch({ type: 1, current_input: null })
              }
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  updatePacking: true,
                  index: i,
                  weight: e.target.value,
                  packing_change: false,
                });
              }}
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="form-control "
              id={"packing_input_stackable" + i}
              defaultChecked={shipment.packing[i].stackable}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) => {
                store_errors.dispatch({ type: 1, current_input: null });
              }}
              onChange={(e) => {
                this.onChangeStackable(e, i);
              }}
            />
          </td>

          <td>
            <button
              id={"packing-remove" + i}
              type="button"
              class="btn btn-danger"
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
                let { current_input } = store_errors.getState();
                let input = document.getElementById(current_input);
                if (input) input.setAttribute("style", "color:black");
              }}
              onBlur={(e) => {
                let { current_input } = store_errors.getState();
                let input = document.getElementById(current_input);
                if (input) input.setAttribute("style", "color:white");
                if (i === nbLines - 1)
                  store_errors.dispatch({
                    type: 1,
                    current_input: "packing-add-1",
                  });
              }}
              onClick={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  removeLine: true,
                  index: i,
                  packing_change: true,
                });
                this.setState({ removeLine: true, key: Date.now() });
              }}
            >
              <Delete fontSize="small" />
            </button>

            {i === nbLines - 1 && (
              <button
                type="button"
                id="packing-add-1"
                class="btn btn-success"
                onClick={() => {
                  store_shipment.dispatch({
                    type: 1,
                    addLine: true,
                    packing_change: true,
                  });
                  this.setState({ newLine: true });
                }}
                onFocus={(e) => {
                  store_errors.dispatch({
                    type: 1,
                    current_input: e.target.id,
                  });
                  let { current_input } = store_errors.getState();
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let { current_input } = store_errors.getState();
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:white");
                  store_errors.dispatch({ type: 1, current_input: null });
                }}
              >
                <AddCircleIcon fontSize="small" />
              </button>
            )}
          </td>
        </tr>
      );
    }

    return (
      <div>
        {!stackable_fill ? null : <br />}

        {!stackable_fill ? null : (
          <div class="row">
            <div class="col-12">
              <p
                class={stackable_fill ? "alert alert-warning fade show" : ""}
                role="alert"
                style={{ color: "", textAlign: "center" }}
              >
                {stackable_fill}
              </p>
            </div>
          </div>
        )}

        <table class="table table-striped" key={this.state.key}>
          <thead>
            <tr class="dimension_modal_header">
              <th scope="col">
                <button
                  id="packing-add-2"
                  type="button"
                  class="btn btn-success edit_menu"
                  onClick={() => {
                    store_shipment.dispatch({
                      type: 1,
                      addLine: true,
                      packing_change: true,
                    });
                  }}
                >
                  <AddCircleIcon fontSize="small" />
                </button>
              </th>
              <th scope="col">Pieces</th>
              <th scope="col">
                Length{" "}
                {shipment.length_system === Util.CONST.IMPERIAL
                  ? "(" + Util.CONST.INCH + ")"
                  : "(" + Util.CONST.CM + ")"}{" "}
              </th>
              <th scope="col">
                Width{" "}
                {shipment.length_system === Util.CONST.IMPERIAL
                  ? "(" + Util.CONST.INCH + ")"
                  : "(" + Util.CONST.CM + ")"}{" "}
              </th>
              <th scope="col">
                Height{" "}
                {shipment.length_system === Util.CONST.IMPERIAL
                  ? "(" + Util.CONST.INCH + ")"
                  : "(" + Util.CONST.CM + ")"}{" "}
              </th>
              <th scope="col">
                Total Weight{" "}
                {shipment.weight_system === Util.CONST.IMPERIAL
                  ? "(" + Util.CONST.LBS + ")"
                  : "(" + Util.CONST.KG + ")"}{" "}
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                {" "}
                Stackable
              </th>
              <th scope="col"></th>
            </tr>
            {store_shipment.getState().packing.length > 0 ? (
              <tr>
                <th scope="col">
                  [{store_shipment.getState().packing.length}]
                </th>
                <th scope="col"></th>
                <th scope="col"> </th>
                <th scope="col"></th>
                <th scope="col"> </th>
                <th scope="col"> </th>
                <th scope="col">
                  <input
                    id="all_stackable"
                    type="checkbox"
                    class="form-control"
                    style={{ minWidth: "1.5em", minHeight: "1.5em" }}
                    tabIndex="-1"
                    defaultChecked={store_shipment.getState().all_stackable}
                    onChange={(e) => {
                      store_shipment.dispatch({
                        type: 1,
                        updatePacking: true,
                        all_stackable: e.target.checked,
                        packing_change: true,
                        packing_stackable: e.target.checked ? "Yes" : "No",
                      });
                    }}
                  />
                </th>
                <th scope="col"></th>
              </tr>
            ) : null}
          </thead>

          <tbody>{html}</tbody>
        </table>
      </div>
    );
  }
}

class ULDList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      current_input: null,
      newLine: null,
      uld_settings: [],
    };
  }

  init() {
    //PREFILL REMARKS
    const header = store_header.getState();
    const pricing = store_pricing.getState();
    //// GET BOOKING DEFAULT CONTACT EMAI
    let all_config = this.props.custom_list.filter(
      (c) => c.station === "***" && c.carrier === "***"
    )[0];
    let station_config = this.props.custom_list.filter(
      (c) => c.station === header.station && c.carrier === "***"
    )[0];
    let carrier_config = this.props.custom_list.filter(
      (c) => c.station === header.station && c.carrier === pricing.carrier
    )[0];

    if (
      carrier_config &&
      carrier_config.config.uld_pricing &&
      carrier_config.config.uld_pricing.length > 0
    ) {
      this.setState({ uld_settings: carrier_config.config.uld_pricing });
    } else if (
      station_config &&
      station_config.config.uld_pricing &&
      station_config.config.uld_pricing.length > 0
    ) {
      this.setState({ uld_settings: station_config.config.uld_pricing });
    } else {
      let settings =
        all_config &&
        all_config.config.uld_pricing &&
        all_config.config.uld_pricing.length > 0
          ? all_config.config.uld_pricing
          : [];
      this.setState({ uld_settings: settings });
    }

    store_shipment.dispatch({ type: 1, uld_settings: this.state.uld_settings });
    this.setState({ key: Date.now() });
  }

  componentDidMount() {
    let w = watch(store_shipment.getState, "uld_change2");
    store_shipment.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s uld changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_shipment.getState, "weight_system");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_shipment.getState, "length_system");
    store_shipment.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    this.init();
  }

  componentDidUpdate() {
    if (this.state.newLine) {
      let uld = store_shipment.getState().uld;
      let input = document.getElementById("uld_pieces_" + (uld.length - 1));
      if (input) input.focus();
      this.setState({ newLine: null });
    } else {
      let input = document.getElementById(this.state.current_input);
      if (input) input.focus();
    }
  }

  render() {
    let shipment = store_shipment.getState();
    let uld = store_shipment.getState().uld;
    let nbLines = uld.length ? uld.length : 0;
    let html = [];

    for (let i = 0; i < nbLines; i++) {
      html.push(
        <tr>
          <td>{i + 1}#</td>
          <td>
            <input
              type="text"
              class="form-control "
              maxLength={3}
              id={"uld_pieces_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              defaultValue={uld[i].pieces ? uld[i].pieces : ""}
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  pieces: e.target.value,
                });
              }}
            />
          </td>

          <td>
            <select
              class="form-control dropdown"
              id={"uld_deck_" + i}
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  deck: e.target.value,
                  position: "",
                });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
            >
              <option value={uld[i].deck}>
                {" "}
                {uld[i].deck === Util.CONST.MAIN
                  ? Util.CONST.MAIN_DECK
                  : Util.CONST.LOWER_DECK}{" "}
              </option>
              {Util.DECKS.map((d) => {
                if (d !== uld[i].deck)
                  return (
                    <option value={d}>
                      {d === Util.CONST.MAIN
                        ? Util.CONST.MAIN_DECK
                        : Util.CONST.LOWER_DECK}
                    </option>
                  );
                else return null;
              })}
            </select>
          </td>

          <td>
            <select
              class="form-control dropdown"
              id={"uld_position_" + i}
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  position: e.target.value,
                });
              }}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: null });
              }}
            >
              <option value={uld[i].position}> {uld[i].position} </option>
              {this.state.uld_settings.map((u) => {
                if (u.position !== uld[i].position && u.deck === uld[i].deck)
                  return <option value={u.position}>{u.position}</option>;
                else return null;
              })}
            </select>
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              maxLength={8}
              id={"uld_weight_" + i}
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
              }}
              onBlur={(e) => {
                this.setState({ current_input: "uld_btn_remove_" + i });
              }}
              defaultValue={uld[i].weight ? uld[i].weight : ""}
              onChange={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  uld_change: true,
                  index: i,
                  weight: e.target.value,
                });
              }}
            />
          </td>

          <td>
            <button
              id={"uld_btn_remove_" + i}
              type="button"
              class="btn btn-danger"
              onFocus={(e) => {
                this.setState({ current_input: e.target.id });
                let current_input = this.state.current_input;
                let input = document.getElementById(current_input);
                if (input) input.setAttribute("style", "color:black");
              }}
              onBlur={(e) => {
                let current_input = this.state.current_input;
                let input = document.getElementById(current_input);
                if (input) input.setAttribute("style", "color:white");
                if (i === nbLines - 1)
                  this.setState({ current_input: "bnt_add_1" });
              }}
              onClick={(e) => {
                store_shipment.dispatch({
                  type: 1,
                  removeUldLine: true,
                  uld_change: true,
                  index: i,
                });
                this.setState({ key: Date.now() });
              }}
            >
              <Delete fontSize="small" />
            </button>

            {i === nbLines - 1 && (
              <button
                type="button"
                id="bnt_add_1"
                class="btn btn-success"
                onClick={() => {
                  store_shipment.dispatch({
                    type: 1,
                    addUldLine: true,
                    uld_change: true,
                  });
                  this.setState({ newLine: true });
                  this.setState({ key: Date.now() });
                }}
                onFocus={(e) => {
                  this.setState({ current_input: e.target.id });
                  let current_input = this.state.current_input;
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:black");
                }}
                onBlur={(e) => {
                  let current_input = this.state.current_input;
                  let input = document.getElementById(current_input);
                  if (input) input.setAttribute("style", "color:white");
                  this.setState({ current_input: null });
                }}
              >
                <AddCircleIcon fontSize="small" />
              </button>
            )}
          </td>
        </tr>
      );
    }

    return (
      <div>
        <table class="table table-striped" key={this.state.key}>
          <thead class="dimension_modal_header">
            <tr>
              <th scope="col">
                <button
                  type="button"
                  class="btn btn-success"
                  onClick={() => {
                    store_shipment.dispatch({ type: 1, addUldLine: true });
                    this.setState({ newLine: true });
                    this.setState({ key: Date.now() });
                  }}
                >
                  <AddCircleIcon fontSize="small" />
                </button>
              </th>
              <th scope="col">Pieces</th>
              <th scope="col">Main/Lower Deck</th>
              <th scope="col">Position</th>
              <th scope="col">
                Weight/Piece{" "}
                {shipment.weight_system === Util.CONST.IMPERIAL
                  ? "(" + Util.CONST.LBS + ")"
                  : "(" + Util.CONST.KG + ")"}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>{html}</tbody>
        </table>
      </div>
    );
  }
}

class ModalQuoteHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history: [],
    };
  }

  async componentDidMount() {
    let w = watch(store_modals.getState, "modalQuoteHistory");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }
  render() {
    let html = [];
    let history = store_history.getState().history;
    history = history ? history : [];

    history.forEach((h) => {
      let i = history.indexOf(h);
      let shc = h.shc ? h.shc.split(",") : [];
      shc = shc.filter((s) => s !== "");
      shc = shc ? shc.join(",") : "";

      let target_per_kg =
        h.target_per_kg && h.target_per_kg === "1"
          ? Util.CONST.PER_KG
          : Util.CONST.TOTAL;
      let all_in = h.all_in && h.all_in === "1" ? Util.CONST.ALL_IN : "++";
      let per_kg =
        h.per_kg && h.per_kg === "1" ? Util.CONST.PER_KG : Util.CONST.TOTAL;
      let send_adhoc = h.send_adhoc && h.send_adhoc === "1" ? "X" : "";
      let send_ffr = h.send_ffr && h.send_ffr === "1" ? "X" : "";
      let send_equote = h.send_equote && h.send_equote === "1" ? "X" : "";
      let changes = h.changes ? h.changes : [];

      let selling_rate = h.selling_rate
        ? parseFloat(h.selling_rate).toFixed(2)
        : h.selling_rate;
      selling_rate =
        selling_rate && per_kg === "Total"
          ? util.formatAmount3(parseFloat(h.selling_rate))
          : selling_rate;
      let edit_buy_kg = h.edit_buy_kg
        ? parseFloat(h.edit_buy_kg).toFixed(2)
        : h.edit_buy_kg;

      let target_price = h.target_price
        ? parseFloat(h.target_price).toFixed(2)
        : h.target_price;
      target_price =
        target_price && target_per_kg === "Total"
          ? util.formatAmount3(parseFloat(h.target_price))
          : target_price;

      let gross_weight = parseFloat(h.gross_weight)
        ? util.formatAmount3(parseFloat(h.gross_weight))
        : h.gross_weight;
      let chargeable_weight = parseFloat(h.chargeable_weight)
        ? util.formatAmount3(parseFloat(h.chargeable_weight))
        : h.chargeable_weight;
      let min_weight = parseFloat(h.min_weight)
        ? util.formatAmount3(parseFloat(h.min_weight))
        : h.min_weight;
      let min_amount = parseFloat(h.min_amount)
        ? util.formatAmount3(parseFloat(h.min_amount))
        : h.min_amount;

      html.push(
        <tr>
          <td>
            <strong>{Util.dateToString(new Date(h.time_stamp))}</strong>
          </td>
          <td>
            <strong>{h.update_by}</strong>
          </td>
          <td>
            {changes.includes("origin") || i === 0 ? (
              <strong>{h.origin}</strong>
            ) : (
              h.origin
            )}{" "}
          </td>
          <td>
            {changes.includes("dest") || i === 0 ? (
              <strong>{h.dest}</strong>
            ) : (
              h.dest
            )}{" "}
          </td>
          <td>
            {changes.includes("agent") || i === 0 ? (
              <strong>{h.agent}</strong>
            ) : (
              h.agent
            )}
          </td>
          <td>
            {changes.includes("gross_weight") || i === 0 ? (
              <strong>{gross_weight}</strong>
            ) : (
              gross_weight
            )}
          </td>
          <td>
            {changes.includes("volume") || i === 0 ? (
              <strong>{h.volume}</strong>
            ) : (
              h.volume
            )}
          </td>
          <td>
            {changes.includes("chargeable_weight") || i === 0 ? (
              <strong>{chargeable_weight}</strong>
            ) : (
              chargeable_weight
            )}
          </td>
          <td>
            {changes.includes("shc") || i === 0 ? <strong>{shc}</strong> : shc}
          </td>
          <td>
            {changes.includes("target_currency") || i === 0 ? (
              <strong>{h.target_currency}</strong>
            ) : (
              h.target_currency
            )}
          </td>
          <td>
            {changes.includes("target_price") || i === 0 ? (
              <strong>{target_price}</strong>
            ) : (
              target_price
            )}
          </td>
          <td>
            {changes.includes("target_per_kg") || i === 0 ? (
              <strong>{target_per_kg}</strong>
            ) : (
              target_per_kg
            )}
          </td>
          <td>
            {changes.includes("carrier") || i === 0 ? (
              <strong>{h.carrier}</strong>
            ) : (
              h.carrier
            )}
          </td>
          <td>
            {changes.includes("pricing_currency") || i === 0 ? (
              <strong>{h.currency}</strong>
            ) : (
              h.currency
            )}
          </td>
          <td>
            {changes.includes("selling_rate") || i === 0 ? (
              <strong>{selling_rate}</strong>
            ) : (
              selling_rate
            )}
          </td>
          <td>
            {changes.includes("all_in") || i === 0 ? (
              <strong>{all_in}</strong>
            ) : (
              all_in
            )}
          </td>
          <td>
            {changes.includes("selling_per_kg") || i === 0 ? (
              <strong>{per_kg}</strong>
            ) : (
              per_kg
            )}
          </td>
          <td>
            {changes.includes("min_weight") || i === 0 ? (
              <strong>{min_weight}</strong>
            ) : (
              min_weight
            )}
          </td>
          <td>
            {changes.includes("min_amount") || i === 0 ? (
              <strong>{min_amount}</strong>
            ) : (
              min_amount
            )}
          </td>
          <td>
            {changes.includes("edit_buy_kg") || i === 0 ? (
              <strong>{edit_buy_kg}</strong>
            ) : (
              edit_buy_kg
            )}
          </td>
          <td>
            {changes.includes("edit_buy_currency") || i === 0 ? (
              <strong>{h.edit_buy_currency}</strong>
            ) : (
              h.edit_buy_currency
            )}
          </td>
          <td>
            {changes.includes("status") || i === 0 ? (
              <strong>{h.status}</strong>
            ) : (
              h.status
            )}
          </td>
          <td>
            <strong>{send_adhoc}</strong>
          </td>
          <td>
            <strong>{send_ffr}</strong>
          </td>
          <td>
            <strong>{send_equote}</strong>
          </td>
          <td>
            {changes.includes("adhoc_ref") || i === 0 ? (
              <strong>{h.adhoc_ref}</strong>
            ) : (
              h.adhoc_ref
            )}
          </td>
          <td>
            {changes.includes("awb_serial") || i === 0 ? (
              <strong>{h.awb_serial}</strong>
            ) : (
              h.awb_serial
            )}
          </td>
        </tr>
      );
    });

    let show = store_modals.getState().modalQuoteHistory;
    return (
      <Modal
        class="big-modal"
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalQuoteHistory: false });
        }}
        dialogClassName="modal-xl"
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Quote History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table table-striped table-adhoc">
            <thead class="modal_header">
              <tr>
                <th colSpan={2}></th>
                <th
                  colSpan={7}
                  style={{
                    textAlign: "center",
                    borderRight: "2px solid #ffffff",
                  }}
                >
                  Shipment Details
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: "center",
                    borderRight: "2px solid #ffffff",
                  }}
                >
                  Target Price
                </th>
                <th colSpan={8} style={{ textAlign: "center" }}>
                  Adhoc Proposal
                </th>
                <th colSpan={7}></th>
              </tr>
              <tr>
                <th scope="col">Change Time Stamp</th>
                <th scope="col">Updated By</th>
                <th scope="col">Origin</th>
                <th scope="col">Dest</th>
                <th scope="col">Agent</th>
                <th scope="col">Gross Wgt</th>
                <th scope="col">Vol</th>
                <th scope="col">Chg. Wgt</th>
                <th scope="col">SHC</th>
                <th scope="col">Curr.</th>
                <th scope="col">Rate</th>
                <th scope="col">Unit</th>
                <th scope="col">Issue Carrier</th>
                <th scope="col">Curr.</th>
                <th scope="col">Sell. Rate</th>
                <th scope="col">Type</th>
                <th scope="col">Unit</th>
                <th scope="col">Min. Wgt</th>
                <th scope="col">Min. Amount</th>
                <th scope="col">Buy Adhoc</th>
                <th scope="col">Curr.</th>
                <th scope="col">Status</th>
                <th scope="col">Send Adhoc</th>
                <th scope="col">Send FFR</th>
                <th scope="col">Send e-Quote</th>
                <th scope="col">Adhoc Ref</th>
                <th scope="col">AWB Serial</th>
              </tr>
            </thead>
            <tbody>{html}</tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalSimilar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      similarQuotes: [],
    };
  }

  async componentDidMount() {
    this.setState({ quotes: store_similar.getState().prices });
    let w = watch(store_modals.getState, "ModalSimilar");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_similar.getState, "prices");
    store_modals.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal)
          this.setState({ key: Date.now(), quotes: newVal });
      })
    );
  }

  render() {
    let html = [];
    let quotes = this.state.quotes;
    let shipment = store_shipment.getState();

    if (!quotes) return null;

    quotes.forEach((q) => {
      let p = q.pricing;
      let s = q.shipment;
      let m = q.margin;
      let { margin_per_kg } = m;

      let sell_all_in_rate =
        m.sell_all_in_rate || margin_per_kg.sell_all_in_rate;


      html.push(
        <tr>
          <td>
            {" "}
            <a href={"#/Editor/" + q.id} target="_blank">
              {q.id}
            </a>
          </td>
          <td>
            {isExactQuote(q.id, s) ? (
              <img
                src={require("../img/exclamation_red_16.png")}
                alt="Warning"
              />
            ) : isSimilarQuote(q.id) ? (
              <img src={require("../img/warning-sign.png")} alt="Warning" />
            ) : (
              ""
            )}
          </td>
          <td>{q.status} </td>
          <td>{Util.convertDate(q.time_stamp)}</td>
          <td>{Util.convertDate(p.from_date)}</td>
          <td>{Util.convertDate(p.to_date)}</td>
          <td>{q.user ? q.user : ""}</td>
          <td>
            {s.agent_code === shipment.agent_code ? (
              <strong>{s.agent}</strong>
            ) : (
              s.agent
            )}
          </td>
          <td>{p.carrier}</td>
          <td>{s.origin}</td>
          <td>{s.dest}</td>
          <td>
            {" "}
            {s.commodity === shipment.commodity ? (
              <strong>{s.commodity_code}</strong>
            ) : (
              s.commodity_code
            )}{" "}
          </td>
          <td>{s.shc ? s.shc.join(" ") : null}</td>
          <td>
            {util.formatAmount4(s.chargeable_weight)} {Util.CONST.KG}
          </td>
          <td>{Util.formatDensity(s.density)}</td>
          <td>{p.currency}</td>
          <td>
            {p.per_kg
              ? parseFloat(p.price)
                ? parseFloat(p.price).toFixed(2)
                : p.price
              : parseFloat(p.price_total)
              ? parseFloat(p.price_total)
              : p.price_total}
          </td>
          <td>{p.all_in ? Util.CONST.ALL_IN : "++"} </td>
          <td>{p.per_kg ? Util.CONST.PER_KG : Util.CONST.TOTAL} </td>
          <td>
            {parseFloat(sell_all_in_rate)
              ? parseFloat(sell_all_in_rate).toFixed(2)
              : ""}{" "}
          </td>
        </tr>
      );
    });

    let show = store_modals.getState().ModalSimilar;

    return (
      <Modal
        class="big-modal"
        style={{}}
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, ModalSimilar: false });
        }}
        dialogClassName="modal-xl"
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>
            Previous Quotes
            <br />
            <img src={require("../img/exclamation_red_16.png")} alt="Warning" />
            <span style={{ fontSize: "0.7em" }}>
              <i>Exact same Quote(s)</i>
            </span>
            <br />
            <img src={require("../img/warning-sign.png")} alt="Warning" />
            <span style={{ fontSize: "0.7em" }}>
              <i>Similar Quote(s)</i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table table-striped table-adhoc">
            <thead class="modal_header">
              <tr>
                <th scope="col">ID</th>
                <th scope="col"></th>
                <th scope="col">Status</th>
                <th scope="col">Creation Date</th>
                <th scope="col">From Date</th>
                <th scope="col">To Date</th>
                <th scope="col">Created By</th>
                <th scope="col">Agent</th>
                <th scope="col">Issue Carrier</th>
                <th scope="col">Origin</th>
                <th scope="col">Dest</th>
                <th scope="col">Commodity</th>
                <th scope="col">SHC</th>
                <th scope="col">Chg. Wgt</th>
                <th scope="col">Density</th>
                <th scope="col">Curr</th>
                <th scope="col">Rate</th>
                <th>Type</th>
                <th>Unit</th>
                <th>All-in/kg</th>
              </tr>
            </thead>

            <tbody>{html}</tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalSimilarExact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      similarQuotes: [],
      exch_rate: [],
    };
  }

  async componentDidMount() {
    this.setState({ quotes: store_similar.getState().similarQuotes });

    let w = watch(store_modals.getState, "ModalSimilarExact");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_similar.getState, "similarQuotes");
    store_modals.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal)
          this.setState({ key: Date.now(), quotes: newVal });
      })
    );
  }

  render() {
    let html = [];
    let quotes = this.state.quotes;
    let shipment = store_shipment.getState();
    let header = store_header.getState();
    let pricing = store_pricing.getState();
    let exch_rate = this.props.exch_rate;

    if (!quotes) return null;

    quotes = quotes.filter(
      (q) => q.status !== "DRAFT" && q.status !== "QUEUED"
    );

    quotes.forEach((q) => {
      let p = q.pricing;
      let s = q.shipment;
      let m = q.margin;
      let { margin_per_kg } = m;
      let sell_all_in_rate =
        m.sell_all_in_rate || margin_per_kg.sell_all_in_rate;
      sell_all_in_rate = sell_all_in_rate ? sell_all_in_rate : 0;
      let rate = 0;

      if (pricing.currency !== p.currency) {
        rate = p.per_kg ? p.price : p.price_total;
        rate = Util.convert_currency(
          rate,
          p.currency,
          pricing.currency,
          exch_rate
        );
      } else {
        rate = p.per_kg ? p.price : p.price_total;
      }

      html.push(
        <tr>
          <td>
            {" "}
            {header.station === q.station ? (
              <a
                href={"#/Editor/" + q.id}
                target="_blank"
                onClick={async function (e) {
                  //await Util.sleep(500)
                  //window.location.reload()
                }}
              >
                {q.id}
              </a>
            ) : (
              q.id
            )}
          </td>
          <td>
            {isExactQuote(q.id, s) ? (
              <img
                src={require("../img/exclamation_red_16.png")}
                alt="Warning"
              />
            ) : isSimilarQuote(q.id) ? (
              <img src={require("../img/warning-sign.png")} alt="Warning" />
            ) : (
              ""
            )}
          </td>
          <td>{q.status} </td>
          <td>{Util.convertDate(q.time_stamp)}</td>
          <td>{Util.convertDate(p.from_date)}</td>
          <td>{Util.convertDate(p.to_date)}</td>
          <td>{q.user ? q.user : ""}</td>
          <td>
            {s.agent_code === shipment.agent_code ? (
              <strong>{s.agent}</strong>
            ) : (
              s.agent
            )}
          </td>
          <td>{p.carrier}</td>
          <td>{s.origin}</td>
          <td>{s.dest}</td>
          <td>
            {" "}
            {s.commodity === shipment.commodity ? (
              <strong>{s.commodity_code}</strong>
            ) : (
              s.commodity_code
            )}{" "}
          </td>
          <td>{s.shc ? s.shc.join(" ") : null}</td>
          <td>
            {util.formatAmount4(s.chargeable_weight)} {Util.CONST.KG}
          </td>
          <td>{Util.formatDensity(s.density)}</td>
          <td>{pricing.currency}</td>
          <td>{parseFloat(rate).toFixed(2)} </td>
          <td>{p.all_in ? Util.CONST.ALL_IN : "++"} </td>
          <td>{p.per_kg ? Util.CONST.PER_KG : Util.CONST.TOTAL} </td>
          <td>
            {parseFloat(
              Util.convert_currency(
                sell_all_in_rate,
                p.currency,
                pricing.currency,
                exch_rate
              )
            ).toFixed(2)}{" "}
          </td>
        </tr>
      );
    });

    let show = store_modals.getState().ModalSimilarExact;

    return (
      <Modal
        class="big-modal"
        style={{}}
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, ModalSimilarExact: false });
        }}
        dialogClassName="modal-xl"
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>
            Similar Quotes
            {hasExactQuotes() ? (
              <>
                <br />
                <img
                  src={require("../img/exclamation_red_16.png")}
                  alt="Warning"
                />
                <span style={{ fontSize: "0.7em" }}>
                  <i>Exact same Quote(s)</i>
                </span>
              </>
            ) : (
              ""
            )}
            <br />
            <img src={require("../img/warning-sign.png")} alt="Warning" />
            <span style={{ fontSize: "0.7em" }}>
              <i>Similar Quote(s)</i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table table-striped table-adhoc">
            <thead class="modal_header">
              <tr>
                <th scope="col">ID</th>
                <th scope="col"></th>
                <th scope="col">Status</th>
                <th scope="col">Creation Date</th>
                <th scope="col">From Date</th>
                <th scope="col">To Date</th>
                <th scope="col">Created By</th>
                <th scope="col">Agent</th>
                <th scope="col">Issue Carrier</th>
                <th scope="col">Origin</th>
                <th scope="col">Dest</th>
                <th scope="col">Commodity</th>
                <th scope="col">SHC</th>
                <th scope="col">Chg. Wgt</th>
                <th scope="col">Density</th>
                <th scope="col">Curr</th>
                <th scope="col">Rate</th>
                <th>Type</th>
                <th>Unit</th>
                <th>All-in/kg</th>
              </tr>
            </thead>
            <tbody>{html}</tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalAllin extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalAllin");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  onClickYes(e) {
    e.preventDefault();
    store_margin.dispatch({ type: 1, updateMargin: true });
    store_modals.dispatch({ type: 1, modalAllin: false });
    store_pricing.dispatch({ type: 1, force: true });
  }

  onClickNo(e) {
    let pricing = store_pricing.getState();
    e.preventDefault();
    store_modals.dispatch({ type: 1, modalAllin: false });
    store_pricing.dispatch({ type: 1, all_in: !pricing.all_in, force: true });
  }

  onHide(e) {
    let pricing = store_pricing.getState();
    store_modals.dispatch({ type: 1, modalAllin: false });
    store_pricing.dispatch({ type: 1, all_in: !pricing.all_in, force: true });
  }

  render() {
    let show = store_modals.getState().modalAllin;
    let pricing = store_pricing.getState();
    let prices = store_prices.getState().sell_prices;
    prices = prices ? prices : [];
    let carrier = pricing.carrier;
    let price = prices.filter((p) => p.carrier === carrier)[0];
    let custom_list =
      this.props && this.props.custom_list ? this.props.custom_list : [];
    let config = getConfig(custom_list);
    let msg = null;

    if (price || config) {
      if (!pricing.all_in && config && config.all_in)
        msg =
          "This airline is All-in, do you confirm you wish to quote Net Net ?";
      else if (!pricing.all_in && price && price.all_in_rate === "Y")
        msg =
          "This airline is All-in, do you confirm you wish to quote Net Net ?";
      else if (pricing.all_in && config && !config.all_in)
        msg =
          "This airline is Net Net, do you confirm you wish to quote All-in ?";
    }

    return (
      <Modal
        show={show}
        onHide={(e) => {
          this.onHide(e);
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title> {msg} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickYes(e);
              }}
            >
              Yes
            </button>

            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={async (e) => {
                this.onClickNo(e);
              }}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalQuoted extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalQuoted");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onClickYes(e) {
    e.preventDefault();
    store_header.dispatch({ type: 1, status: "QUOTED" });
    store_modals.dispatch({ type: 1, modalQuoted: false });
    await saveQuote();
  }

  render() {
    let show = store_modals.getState().modalQuoted;

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalQuoted: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Do you want to set status to "QUOTED" ? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickYes(e);
              }}
            >
              Yes
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                store_modals.dispatch({ type: 1, modalQuoted: false });
              }}
            >
              No
            </button>

            <div class="col-3"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalCloneAttachment extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalCloneAttachment");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  onClickYes(e) {
    e.preventDefault();

    store_remarks.dispatch({ type: 1, updateAttachment: true, force: true });
    store_modals.dispatch({ type: 1, modalCloneAttachment: false });
  }

  onClickNo(e) {
    let attachments = [
      { file_name: "", file: "" },
      { file_name: "", file: "" },
      { file_name: "", file: "" },
    ];

    store_remarks.dispatch({ type: 1, attachments: attachments, force: true });
    store_modals.dispatch({ type: 1, modalCloneAttachment: false });
  }

  onHide(e) {
    store_modals.dispatch({ type: 1, modalCloneAttachment: false });
  }

  render() {
    let show = store_modals.getState().modalCloneAttachment;

    return (
      <Modal
        show={show}
        onHide={(e) => {
          this.onHide(e);
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Do you want to keep Quote attachements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickYes(e);
              }}
            >
              Yes
            </button>

            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={async (e) => {
                this.onClickNo(e);
              }}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalUpdatePacking extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    let w = watch(store_modals.getState, "modalUpdatePacking");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  onClickNo(e) {
    e.preventDefault();

    let packing_copy = store_shipment.getState().packing_copy;
    let uld_copy = store_shipment.getState().uld_copy;
    let all_stackable_copy = store_shipment.getState().all_stackable_copy;
    let length_system_copy = store_shipment.getState().length_system_copy;
    let weight_system_copy = store_shipment.getState().weight_system_copy;
    let packing_stackable_copy =
      store_shipment.getState().packing_stackable_copy;

    store_shipment.dispatch({
      type: 1,
      packing: packing_copy,
      uld: uld_copy,
      packing_change: false,
      uld_change: false,
      all_stackable: all_stackable_copy,
      all_stackable_select: all_stackable_copy,
      length_system: length_system_copy,
      weight_system: weight_system_copy,
      packing_stackable: packing_stackable_copy,
    });

    store_modals.dispatch({
      type: 1,
      modalUpdatePacking: false,
      modalDimensions: false,
    });
  }

  render() {
    let show = store_modals.getState().modalUpdatePacking;

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalUpdatePacking: false });
        }}
      >
        <Modal.Header className="dimension_modal_header" closeButton>
          <Modal.Title>Do you want to save changes ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-2"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                saveAndCalculatePacking();
              }}
            >
              Yes
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                this.onClickNo(e);
              }}
            >
              No
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-secondary"
              onClick={(e) => {
                store_modals.dispatch({ type: 1, modalUpdatePacking: false });
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalCalculator extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    let w = watch(store_modals.getState, "modalCalculator");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_calculator.getState, "routing");
    store_calculator.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
        calculateCost();
      })
    );
  }

  onClose() {
    store_modals.dispatch({ type: 1, modalCalculator: false });
  }

  onKeep(e) {
    let calculator = store_calculator.getState();
    let shipment = store_shipment.getState();
    let pricing = store_pricing.getState();
    let header = store_header.getState();

    //if(!calculator.update)
    //return store_modals.dispatch({type : 1, modalCalculator : false})

    store_shipment.dispatch({ type: 1, shc: calculator.shc[0], index: 0 });
    store_shipment.dispatch({ type: 1, shc: calculator.shc[1], index: 1 });
    store_shipment.dispatch({ type: 1, shc: calculator.shc[2], index: 2 });
    store_shipment.dispatch({ type: 1, shc: calculator.shc[3], index: 3 });

    store_shipment.dispatch({
      type: 1,
      origin: calculator.origin ? calculator.origin : shipment.origin,
      dest: calculator.dest ? calculator.dest : shipment.dest,
      agent: calculator.agent ? calculator.agent : shipment.agent,
      agent_code: calculator.agent_code
        ? calculator.agent_code
        : shipment.agent_code,
      chargeable_weight: calculator.chargeable_weight
        ? calculator.chargeable_weight
        : shipment.chargeable_weight,
      force: true,
    });

    store_routes.dispatch({
      type: 1,
      routes: {
        routes: calculator.routing,
        detailed_info: calculator.detailed_info,
      },
      force: true,
    });

    let station = calculator.station ? calculator.station : header.station;
    let _stations = this.props.authorized_stations
      ? this.props.authorized_stations
      : [];
    let _station = _stations.filter((s) => s.station === station)[0];

    store_header.dispatch({
      type: 1,
      station: station,
      sales_region: _station ? _station.sales_region : null,
      origin_zone: _station ? _station.origin_zone : null,
      force: true,
    });

    store_pricing.dispatch({
      type: 1,
      carrier: calculator.carrier ? calculator.carrier : pricing.carrier,
      price:
        calculator.sell_buy === "S" && calculator.per_kg ? calculator.rate : 0,
      price_total:
        calculator.sell_buy === "S" && !calculator.per_kg ? calculator.rate : 0,
      currency: calculator.currency,
      per_kg: calculator.per_kg,
      all_in: calculator.all_in,
      from_date: calculator.from_date
        ? calculator.from_date
        : pricing.from_date,
      to_date: calculator.to_date ? calculator.to_date : pricing.to_date,
      force: true,
    });

    let carrier = calculator.carrier
      ? calculator.carrier
      : calculator.routing && calculator.routing[0]
      ? calculator.routing[0].carrier
      : pricing.carrier;

    updateCarrierInfo(carrier, this.props.custom_list);

    store_modals.dispatch({ type: 1, modalCalculator: false });
  }

  render() {
    let show = store_modals.getState().modalCalculator;

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalCalculator: true });
        }}
        dialogClassName="modal-xl"
      >
        <Modal.Header className="modal_header">
          <Modal.Title>Calculator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <CalculatorSearchSection
              carriers_list={this.props.carriers_list}
              authorized_stations={this.props.authorized_stations}
            />
          </div>

          <br />

          <div class="row">
            <div class="col-6">
              <CalculatorRoutingSection
                carriers_list={this.props.carriers_list}
                authorized_stations={this.props.authorized_stations}
              />
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-11">
                  <CalculatorSection
                    authorized_stations={this.props.authorized_stations}
                    exch_rate={this.props.exch_rate}
                  />
                </div>
              </div>
            </div>
          </div>

          <br />

          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={(e) => {
                this.onKeep(e);
              }}
            >
              Keep Quote Data
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={this.onClose}
            >
              Close
            </button>

            <div class="col-3"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalRefresh extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    let w = watch(store_modals.getState, "modalRefresh");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }
  render() {
    let show = store_modals.getState().modalRefresh;

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalRefresh: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>
            You exchanged information between Quantum and Skypallet, please
            refresh your page.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-5"></div>

            <button
              type="button"
              class="col-2 btn btn-primary"
              onClick={(e) => {
                window.location.reload();
                store_modals.dispatch({ type: 1, modalRefresh: false });
              }}
            >
              Refresh
            </button>

            <div class="col-5"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prefix: null,
      serial: null,
      osi_remarks: "",
      error: null,
      key: Date.now(),
      loading: false,
      success: false,
      ffr: null,
      current_input: "ffr_prefix",
      notify: false,
      user_email: null,
      booking_email: null,
      notify_booking_email: true,
      notify_alternate_email: false,
      alternate_email: null,
      is_kk: false,
      btn_get_awb: false,
      msg: "",
    };

    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.init = this.init.bind(this);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalBooking");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.init();
      })
    );

    let w2 = watch(store_pricing.getState, "carrier");
    store_pricing.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) {
          let { carrier } = store_pricing.getState();
          if (carrier) {
            let carrier_ = this.props.carriers_list.filter(
              (c) => c.code === carrier
            )[0];
            if (carrier_) this.setState({ prefix: carrier_.prefix });
          }
        }
      })
    );

    let w3 = watch(store_header.getState, "serial");
    store_header.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) {
          let { serial } = store_header.getState();
          // if (serial) {
          this.setState({ serial: serial });
          // }
        }
      })
    );

    if (store_pricing.getState().carrier) {
      let carrier = this.props.carriers_list.filter(
        (c) => c.code === store_pricing.getState().carrier
      )[0];
      if (carrier) this.setState({ prefix: carrier.prefix });
    }

    this.init();
  }

  init() {
    //PREFILL REMARKS
    const header = store_header.getState();
    const pricing = store_pricing.getState();
    let serial = header.serial;
    let station_config = this.props.custom_list.filter(
      (c) => c.station === header.station && c.carrier === "***"
    )[0];
    let carrier_config = this.props.custom_list.filter(
      (c) => c.station === header.station && c.carrier === pricing.carrier
    )[0];
    let booking_email = "";
    if (carrier_config && carrier_config.config.booking_contact_email)
      booking_email = carrier_config.config.booking_contact_email;
    else if (station_config && station_config.config.booking_contact_email)
      booking_email = station_config.config.booking_contact_email;

    this.setState({
      serial: serial,
      booking_email: booking_email,
      user_email: cookies.email_contact,
    });
    this.validate();
  }

  componentDidUpdate() {
    let input = document.getElementById(this.state.current_input);

    if (!input) return;

    input.focus();
    input.selectionStart = this.state.selectionStart;
    input.selectionEnd = this.state.selectionStart;
  }

  async confirm(e, send_cargospot = true) {
    e.preventDefault();

    if (!this.validate()) return null;

    try {
      let { adhoc_ref, id, station } = store_header.getState();
      let stations = store_station.getState();
      const { origin, dest, ffr_sent, email_sent, agent_code } = store_shipment.getState();
      const {carrier} = store_pricing.getState()
      let authorized_stations = this.props.authorized_stations;
      let custom_list = this.props.custom_list;
      authorized_stations = authorized_stations
        ? authorized_stations
        : stations.authorized_stations;
      let station_ = authorized_stations.filter(
        (s) => s.station === station
      )[0];
      let {
        ffr,
        notify,
        prefix,
        serial,
        notify_alternate_email,
        notify_booking_email,
        alternate_email,
        booking_email,
        user_email,
      } = this.state;

      this.setState({ loading: true, key: Date.now() });

      store_shipment.dispatch({
        type: 1,
        calculate_price_class: true,
        carrier: store_pricing.getState().carrier,
        sales_region: station_.sales_region,
      });

      store_modals.dispatch({ type: 1, page_loading_spinner: true });

      await saveQuote();
      await util.sleep(100);

      let header = store_header.getState();

      if (!header.id)
        return store_modals.dispatch({ type: 1, page_loading_spinner: false }); //don't insert adhoc if quote has no id

      store_header.dispatch({ type: 1, prefix: prefix, serial: serial });

      if (send_cargospot) {
        await calculateMargin({ station: station_, custom_list: custom_list });

        await util.sleep(100);

        updateSendContact();

        await updateAdhocSell(authorized_stations);

        await updateAdhocBuy(prefix, serial);

        this.validate();

        await util.sleep(200);

        ffr = this.state.ffr;
      }

      let params = {
        message: ffr,
        user_email: notify ? user_email : null,
        adhoc_ref: adhoc_ref,
        id: id,
        prefix: prefix,
        serial: serial,
        origin: origin,
        carrier : carrier,
        agent_code : agent_code,
        dest: dest,
        ffr_sent: ffr_sent, // Pour determiner si le FFR a été envoyé à CSP
        email_sent: email_sent, //Pour determiner si le FFR a été envoyé par email,
        send_cargospot: send_cargospot, // Pour determiner si le FFR doit être envoyé à CSP ou par Email,
        booking_email: notify_booking_email ? booking_email : null,
        alternate_email: notify_alternate_email ? alternate_email : null,
      };

      const res = await Routes.post("/sendFFR", params, true);

      if (!res || res.error)
        throw res && res.message ? res.message : "FFR couldn't be send.";

      store_shipment.dispatch({ type: 1, ffr_sent: send_cargospot });

      store_header.dispatch({ type: 1, status: "BOOKED" });

      if (
        header.follow_up === true &&
        header.status !== "QUOTED" &&
        header.status !== "QUEUED"
      ) {
        followUp();
      }

      store_history.dispatch({ type: 1, send_ffr: true });

      await calculateMargin({ station: station_, custom_list: custom_list });

      await saveQuote();

      this.setState({ loading: false, key: Date.now(), success: true });

      await util.sleep(1200);

      this.setState({ error: null, success: false, key: Date.now() });

      store_modals.dispatch({ type: 1, modalBooking: false });

      store_modals.dispatch({ type: 1, page_loading_spinner: false });
    } catch (e) {
      store_modals.dispatch({ type: 1, page_loading_spinner: false });
      return this.setState({
        loading: false,
        key: Date.now(),
        error: e.toString(),
      });
    }
  }

  async cancel(e) {
    this.setState({ loading: false, key: Date.now() });
    store_modals.dispatch({ type: 1, modalBooking: false });
  }

  async handleChange(e) {
    e.preventDefault();
    let json = {};

    json[e.target.name] = e.target.value;
    json.key = Date.now();
    this.setState(json, () => this.validate());
    this.state.selectionStart = e.target.selectionStart;
  }

  async validate() {
    let {
      prefix,
      serial,
      osi_remarks,
      is_kk,
      booking_email,
      user_email,
      alternate_email,
    } = this.state;
    let header = store_header.getState();

    if (header.hq) {
      osi_remarks = "HQ TEST BY : " + cookies.user + " | " + osi_remarks;
    }
    try {
      if (!prefix) throw "Please input prefix.";
      else if (isNaN(prefix)) throw "Please input a valid prefix.";
      else if (prefix.toString().length !== 3)
        throw "Prefix must be 3 digits-length.";
      else if (!serial) throw "Please input serial.";
      else if (isNaN(serial)) throw "Please input a valid serial.";
      else if (serial.toString().length !== 8)
        throw "Serial must be 8 digits-length.";
      else if (parseInt(serial.substring(0, 7)) % 7 !== parseInt(serial[7]))
        throw "AWB Serial is invalid.";
      else if (
        (booking_email &&
          booking_email.toLowerCase().indexOf("cargospot@quito.aero") >= 0) ||
        (user_email &&
          user_email.toLowerCase().indexOf("cargospot@quito.aero") >= 0) ||
        (alternate_email &&
          alternate_email.toLowerCase().indexOf("cargospot@quito.aero") >= 0)
      )
        throw "You cannot send the FFR copy to cargospot@quito.aero.";

      let ffr = await generateFFR(prefix, serial, osi_remarks, is_kk);
      this.setState({ key: Date.now(), error: null, ffr: ffr });
      return true;
    } catch (e) {
      this.setState({ key: Date.now(), error: e.toString(), ffr: null });
      return false;
    }
  }

  async onChangeKK(e) {
    e.preventDefault();
    let json = {};
    json.is_kk = e.target.checked;
    json.key = Date.now();
    this.setState(json, () => this.validate());
  }

  async getAWB(e) {
    e.preventDefault();

    let msg = "";

    let get_awb_circle = document.getElementById("get_awb_circle");
    if (get_awb_circle) get_awb_circle.setAttribute("style", "display:yes");

    let carrier = store_pricing.getState().carrier;
    let station = store_header.getState().station;
    let agent_code = store_shipment.getState().agent_code;

    if (!carrier) msg = "Carrier missing.";

    if (!station) msg = "Station missing.";

    if (!agent_code) msg = "Agent missing.";

    if (msg) {
      let json = {};
      json.msg = msg;
      json.btn_get_awb = false;
      json.key = Date.now();
      this.setState(json, () => this.validate());
    } else {
      let params = {
        issue_carrier: carrier,
        origin: station,
        customer_code: agent_code,
      };

      let awb = await Routes.get("/AWBStock", params, true);

      if (!awb || !awb.prefix || !awb.serial) {
        msg = "No AWB available. Try manually please.";
        let json = {};
        json.msg = msg;
        json.btn_get_awb = false;
        json.key = Date.now();
        this.setState(json, () => this.validate());
      } else {
        let json = {};
        json.prefix = awb.prefix;
        json.serial = awb.serial;
        json.msg = "";
        json.btn_get_awb = true;
        json.key = Date.now();
        this.setState(json, () => this.validate());
      }
    }

    if (get_awb_circle) get_awb_circle.setAttribute("style", "display:none");
  }

  render() {
    let show = store_modals.getState().modalBooking;
    let {
      error,
      user_email,
      booking_email,
      prefix,
      serial,
      osi_remarks,
      loading,
      key,
      btn_get_awb,
      msg,
      success,
      ffr,
      notify,
      notify_alternate_email,
      notify_booking_email,
      alternate_email,
      is_kk,
    } = this.state;

    const { ffr_sent, email_sent, ffr_sent_date } = store_shipment.getState();

    return (
      <Modal show={show} onHide={this.cancel}>
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>
            <h2 style={{ color: "white" }}>Booking</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body key={key}>
          <center>
            {loading && (
              <>
                <CircularProgress color="inherit" />
                <br />
                Sending FFR ...
              </>
            )}
          </center>

          {msg && (
            <div class="alert alert-warning" role="alert">
              {msg}
            </div>
          )}

          {success && (
            <div class="alert alert-success" role="alert">
              Your FFR has been sent {email_sent ? "(by email only)" : ""}.
            </div>
          )}

          {ffr_sent && !success && !error && (
            <div class="alert alert-warning" role="alert">
              Booking has already been made{" "}
              {ffr_sent_date
                ? "(" + Util.dateToString(new Date(ffr_sent_date)) + ")"
                : ""}
              .<br />
              You will receive an email copy of the FFR, but keep in mind{" "}
              <strong> it will not create/update a booking </strong>in
              Cargospot. <br />
            </div>
          )}

          {!ffr_sent && email_sent && !success && !error && (
            <div class="alert alert-warning" role="alert">
              Booking was already sent per email with the AWB Number filled in
              below.
              <br />
              You can send the FFR to CargoSpot, and/or send more copies of the
              FFR per email.
            </div>
          )}

          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {
            <div class="row">
              <div class="col-12" style={{ textAlign: "center" }}>
                <button
                  type="button"
                  class={"col-2 btn btn-success"}
                  disabled={
                    serial || ffr_sent || (btn_get_awb && serial) ? true : false
                  }
                  onClick={async (e) => {
                    this.getAWB(e);
                  }}
                >
                  Get AWB
                </button>
              </div>

              <div
                class="col-12"
                id="get_awb_circle"
                style={{ display: "none" }}
              >
                <center>
                  <br />
                  <CircularProgress color="inherit" />
                </center>
              </div>

              <div class="col-6">
                <label>Prefix</label>
                <input
                  type="text"
                  class="form-control"
                  defaultValue={prefix}
                  disabled={
                    (ffr_sent || email_sent) && !success && !error
                      ? true
                      : false
                  }
                  name="prefix"
                  id="ffr_prefix"
                  onFocus={(e) => this.setState({ current_input: e.target.id })}
                  onChange={this.handleChange}
                  maxlength="3"
                />
              </div>

              <div class="col-6">
                <label>Serial</label>
                <input
                  type="text"
                  name="serial"
                  id="ffr_serial"
                  class="form-control"
                  disabled={
                    (ffr_sent || email_sent) && !success && !error
                      ? true
                      : false
                  }
                  defaultValue={serial}
                  onFocus={(e) => this.setState({ current_input: e.target.id })}
                  onChange={this.handleChange}
                  maxlength="8"
                />
              </div>

              <div class="col-12">
                <br />
                <label>OSI Remarks</label>
                <input
                  type="text"
                  name="osi_remarks"
                  id="ffr_osi"
                  class="form-control"
                  disabled={
                    (ffr_sent || email_sent) && !success && !error
                      ? true
                      : false
                  }
                  defaultValue={osi_remarks}
                  onChange={this.handleChange}
                  onFocus={(e) => this.setState({ current_input: e.target.id })}
                  maxlength="53"
                />
              </div>
            </div>
          }

          {ffr && [
            <div class="row col">
              <VisibilityIcon />
              <span style={{ margin: "0.15em" }}>Preview</span>
            </div>,
            <div class="row col">
              <FormControlLabel
                value="bottom"
                label={
                  " KK the first flight segment (include pre-forwarding truck, if any)"
                }
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    defaultChecked={is_kk}
                    onChange={async (e) => {
                      this.onChangeKK(e);
                    }}
                  />
                }
              />
            </div>,
            <div class="row col">
              <Interweave content={ffr} />
            </div>,
          ]}

          <div class="row">
            <div class="col-6">
              <br />
              <FormControlLabel
                value="bottom"
                label={" Send a copy to booking contact"}
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    defaultChecked={notify_booking_email}
                    onChange={(e) => {
                      this.setState({
                        key: Date.now(),
                        notify_booking_email: !notify_booking_email,
                      });
                    }}
                  />
                }
              />
            </div>

            <div class="col-5">
              {!booking_email || util.validateEmail(booking_email, true) ? (
                <label style={{ color: "white" }}>_</label>
              ) : (
                <label class="error">Email is invalid</label>
              )}
              <input
                type="text"
                class={
                  "form-control " +
                  (!booking_email || util.validateEmail(booking_email, true)
                    ? ""
                    : "is-invalid")
                }
                defaultValue={booking_email}
                name="booking_email"
                id="ffr_booking_email"
                onFocus={(e) => this.setState({ current_input: e.target.id })}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                maxlength="200"
              />
            </div>

            <div class="col-6">
              <br />
              <FormControlLabel
                value="bottom"
                label={" Send a copy to me"}
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    defaultChecked={notify}
                    onChange={(e) => {
                      this.setState({ key: Date.now(), notify: !notify });
                    }}
                  />
                }
              />
            </div>

            <div class="col-5">
              {!user_email || util.validateEmail(user_email, true) ? (
                <label style={{ color: "white" }}>_</label>
              ) : (
                <label class="error">Email is invalid</label>
              )}
              <input
                type="text"
                class={
                  "form-control " +
                  (!user_email || util.validateEmail(user_email, true)
                    ? ""
                    : "is-invalid")
                }
                defaultValue={user_email}
                name="user_email"
                id="ffr_user_email"
                onFocus={(e) => this.setState({ current_input: e.target.id })}
                onChange={this.handleChange}
                maxlength="30"
              />
            </div>

            <div class="col-6">
              <br />
              <FormControlLabel
                value="bottom"
                label={" Send a copy to "}
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    defaultChecked={notify_alternate_email}
                    onChange={(e) => {
                      this.setState({
                        key: Date.now(),
                        notify_alternate_email: !notify_alternate_email,
                      });
                    }}
                  />
                }
              />
            </div>

            <div class="col-5">
              {!alternate_email || util.validateEmail(alternate_email, true) ? (
                <label style={{ color: "white" }}>_</label>
              ) : (
                <label class="error">Email is invalid</label>
              )}
              <input
                type="text"
                class={
                  "form-control " +
                  (!alternate_email || util.validateEmail(alternate_email, true)
                    ? ""
                    : "is-invalid")
                }
                defaultValue={alternate_email}
                placeholder="enter email address"
                name="alternate_email"
                id="ffr_alternate_email"
                onFocus={(e) => this.setState({ current_input: e.target.id })}
                onChange={this.handleChange}
                maxlength="200"
              />
            </div>

            <div class="col-3"></div>

            <button
              type="button"
              class={"col-2 btn " + (ffr_sent ? "btn-primary" : "btn-success")}
              onClick={(e) => this.confirm(e, true)}
              data-tip={this.state.error}
              delay-hide={1000}
              disabled={this.state.error}
            >
              {ffr_sent ? (
                <span>
                  {" "}
                  Send copy <br /> (no booking)
                </span>
              ) : (
                "Confirm Booking"
              )}
              <ReactTooltip
                place="top"
                type="error"
                effect="solid"
                delayHide={500}
              />
            </button>

            {ffr_sent && !success && !error ? null : (
              <>
                <div class="col-1"></div>

                <button
                  type="button"
                  class={"col-2 btn btn-primary"}
                  onClick={(e) => {
                    store_shipment.dispatch({ type: 1, email_sent: true });
                    this.confirm(e, false);
                  }}
                  data-tip={this.state.error}
                  delay-hide={1000}
                  disabled={
                    this.state.error || btn_get_awb
                      ? true
                      : (notify && user_email) ||
                        (notify_booking_email && booking_email) ||
                        (notify_alternate_email && notify_alternate_email)
                      ? false
                      : true
                  }
                >
                  <span>
                    {" "}
                    Send FFR per Email only <br /> (no booking) <br />
                  </span>
                  <ReactTooltip
                    place="top"
                    type="error"
                    effect="solid"
                    delayHide={500}
                  />
                </button>
              </>
            )}

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={this.cancel}
            >
              Cancel
            </button>

            <div class="col-3"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalPackingText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packing_text: "",
    };

    this.uploadTxt = this.uploadTxt.bind(this);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalPackingText");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  uploadTxt(e) {
    let str = document.getElementById("textarea_packing").value;
    this.setState({ packing_text: str });

    if (!str) return;

    let text = "×";
    str = str.toLowerCase().replaceAll(text, "x");
    str = str.split("\n");

    //console.log("freeText1", str);

    if (str) store_shipment.dispatch({ type: 1, packing_text: str });

    //console.log("freeText2", str);

    let packing = [];

    /* Copy-Paste your packing list text */

    for (let i = 0; i < str.length; i++) {
      
      if (
        str[i] &&
        str[i].match(
          /^[0-9]+(\s)?(pcs|pc|p)(\s)?(\|)(\s)?[0-9]+.?[0-9]*(\s)?(cm)(\s)?(x)(\s)?[0-9]+.?[0-9]*(\s)?(cm)(\s)?x(\s)?[0-9]+.?[0-9]*(\s)?(cm)(\s)?(\|)(\s)?[0-9]+.?[0-9]*?(\s)(kg)\/pc$/g
        )
      ) {
        //1 pcs | 120 cm x 80 cm x 200 cm | 266.0 kg/pc
        console.log("Dims1");
        let line = str[i];
        line = line.replaceAll(" ", "");
        line = line.split("|");

        let pieces = line[0] ? line[0].replace(/[a-z]/g, "") : 0;
        let weight = line[2]
          ? line[2].replace("kg/pc", "").replace(",", ".")
          : 0;
        let dims = line[1] ? line[1].replaceAll("cm", "").split("x") : null;
        let length = dims && dims[0] ? dims[0] : null;
        let width = dims && dims[1] ? dims[1] : null;
        let height = dims && dims[2] ? dims[2] : null;

        length = length ? parseInt(length) : null;
        width = width ? parseInt(width) : null;
        height = height ? parseInt(height) : null;

        packing.push({
          pieces: pieces,
          length: length,
          height: height,
          width: width,
          weight: weight,
          stackable: true,
        });
      } else if (
        str[i] &&
        str[i].match(
          /^[0-9]+(\s)?(plt|pcs|pc|p)(\s)?(\/)(\s)?[0-9]+(\s)?(x)(\s)?[0-9]+(\s)?(x)(\s)?[0-9]+(\s)?(\/)(\s)?[0-9]+(?:[.,][0-9]*)?(\s)?(kg|k)?$/g
        )
      ) {
        //10 plt / 1000 x 1200 x 1500 / 500,20 kg
        console.log("Dims2");
        let line = str[i];
        line = line.replaceAll(" ", "");
        let values = line.split("/");
        let pieces = values[0] ? values[0].replace(/[a-z]/g, "") : 0;
        let dims = values[1] ? values[1].split("x") : null; // "120x80x50"
        let weight = values[2]
          ? values[2].replace(/[a-z]/g, "")
          : 0;

        let length = dims && dims[0] ? dims[0] : null;
        let width = dims && dims[1] ? dims[1] : null;
        let height = dims && dims[2] ? dims[2] : null;


        length = length ? parseInt(length) : null;
        width = width ? parseInt(width) : null;
        height = height ? parseInt(height) : null;

        weight = weight ? parseFloat(weight.replace(',', '.')) : null;

        packing.push({
          pieces: pieces,
          length: length,
          height: height,
          width: width,
          weight: weight,
          stackable: true,
        });
      } else if (
        str[i] &&
        str[i].match(
          /^[0-9]+(\s)?(pcs|pc|p)?(\s)?(x|\*)(\s)?[0-9]+(\s)?(cm)(\s)?(x|\*)(\s)?[0-9]+(\s)?(cm)(\s)?(x|\*)(\s)?[0-9]+(\s)?(cm)(\s)?([0-9]+(\s)?(kg|k))?/g
        )
      ) {
        //10 pcs x 20 cm x 15 cm x 5 cm 0.5 kg
        //1 × 59 cm × 40 cm × 15 cm 4 kg
        //^[0-9]+(\s)?(pcs|pc|p)?(\s)?(x)(\s)?[0-9]+(\s)?(cm)(\s)?(x)(\s)?[0-9]+(\s)?(cm)(\s)?(x)(\s)?[0-9]+(\s)?(cm)(\s)?[0-9]+(\s)?(kg|k)$
        console.log("Dims3");
        let line = str[i];
        line = line.replaceAll(" ", "");
        let values = [];

        if (line.indexOf("x") >= 0) values = line.split("x");
        else if (line.indexOf("*") >= 0) values = line.split("*");

        let pieces = values[0] ? values[0].replace(/[a-z]/g, "") : 0;
        let length = values[1] ? values[1].replace(/[a-z]/g, "") : 0;
        let width = values[2] ? values[2].replace(/[a-z]/g, "") : 0;
        let height_weight = values[3] ? values[3].split("cm") : null;

        let height =
          height_weight && height_weight[0]
            ? height_weight[0].replace(/[a-z]/g, "")
            : 0;
        let weight =
          height_weight && height_weight[1]
            ? height_weight[1].replace(/[a-z]/g, "")
            : 0;

        length = length ? parseInt(length) : null;
        width = width ? parseInt(width) : null;
        height = height ? parseInt(height) : null;

        packing.push({
          pieces: pieces,
          length: length,
          height: height,
          width: width,
          weight: weight,
          stackable: true,
        });
      } else if (
        str[i] &&
        str[i].match(
          /^[0-9]+(\s)?(cm)?(\s)?(\/)(\s)?[0-9]+(\s)?(cm)?(\s)?(\/)(\s)?[0-9]+(\s)?(cm)?(\s)?$/g
        )
      ) {
        //70 / 37 / 33
        //70 cm / 37 cm / 33 cm
        console.log("Dims4");

        let line = str[i];
        line = line.replaceAll(" ", "");

        let values = [];

        values = line.split("/");

        let pieces = 1;
        let length = values[0] ? values[0].replace(/[a-z]/g, "") : 0;
        let width = values[1] ? values[1].replace(/[a-z]/g, "") : 0;
        let height = values[2] ? values[2].replace(/[a-z]/g, "") : 0;

        length = length ? parseInt(length) : null;
        width = width ? parseInt(width) : null;
        height = height ? parseInt(height) : null;

        packing.push({
          pieces: pieces,
          length: length,
          height: height,
          width: width,
          weight: 0,
          stackable: true,
        });
      } else if (
        str[i] &&
        str[i].match(
          /^\d+(\.\d+)?(\s)?(cm)?(\s)?x(\s)?\d+(\.\d+)?(\s)?(cm)?(\s)?x(\s)?\d+(\.\d+)?(\s)?(cm)?$/g
        )
      ) {
        //40.00x30.00x20.00 or 40.00 x 30.00 x 20.00
        //40.00cm x 30.00cm x 20.00cm or 40.00 cm x 30.00 cm x 20.00 cm or 40.00cmx30.00cmx20.00cm
        console.log("Dims5");
        let line = str[i];
        line = line.replaceAll(" ", "");

        let values = [];

        values = line.split("x");

        let pieces = 1;
        let length = values[0] ? values[0].replace(/[a-z]/g, "") : 0;
        let width = values[1] ? values[1].replace(/[a-z]/g, "") : 0;
        let height = values[2] ? values[2].replace(/[a-z]/g, "") : 0;

        length = length ? parseInt(length) : null;
        width = width ? parseInt(width) : null;
        height = height ? parseInt(height) : null;

        packing.push({
          pieces: pieces,
          length: length,
          height: height,
          width: width,
          weight: 0,
          stackable: true,
        });
      } else if (
        str[i].match(
           // /^[0-9]+\s[0-9]+(\s)?(x|\*)(\s)?[0-9]+(\s)?(x|\*)(\s)?[0-9]+\s[0-9]+[.,]?[0-9]*(\s)?(kg|k)?$/
              /^[0-9]+\s[0-9]+(\s)?(x|\*)(\s)?[0-9]+(\s)?(x|\*)(\s)?[0-9]+\s(?:cm\s)?[0-9]+[.,]?[0-9]*(\s)?(kg|k)?$/

          )
      ) {
        // 1 50 x 50 x 50 12 kg
        // 1 50 x 50 x 50 12.5 kg
        // 1 50 x 50 x 50 cm 16.9 kg
        // 1 50 x 50 x 50 17,85 kg
        // 1 50 x 50 x 50 17,85 k
        console.log("Dims6");

        let line = str[i];
        let values = line.match(/\b\d+(?:[.,]\d+)?\b/g);

        let pieces = parseInt(values[0]);
        let length = parseInt(values[1]);
        let width = parseInt(values[2]);
        let height = parseInt(values[3]);
        let weight = parseFloat(values[4].replace(",", "."));

    
        packing.push({
            pieces: pieces,
            length: length,
            height: height,
            width: width,
            weight: weight,
            stackable: true,
        });
      } else {
        console.log("Dims7");

        let weight = str[i].match(
          /([0-9]*\S)?[0-9]+((\s){1,5})?(kg|lb|kgs|lbs|l|k)/
        );
        weight = weight ? weight[0] : null;
       

        let dim = weight
          ? str[i]
              .replace(weight, "")
              .match(
                /(([0-9]+((\s){1,5})?(\*|x|X){1,3})?((\s){1,5})?[0-9]+((\s){1,5})?(\*|x|X|(\s)){1,3}((\s){1,5})?[0-9]+((\s){1,5})?(\*|x|X|(\s)){1,3}((\s){1,5})?[0-9]+)/g
              )
          : str[i].match(
              /(([0-9]+((\s){1,5})?(\*|x|X|(\s)){1,3})?((\s){1,5})?[0-9]+((\s){1,5})?(\*|x|X|(\s)){1,3}((\s){1,5})?[0-9]+((\s){1,5})?(\*|x|X|(\s)){1,3}((\s){1,5})?[0-9]+)/g
            );

        dim = dim ? dim[0] : null;

        let length,
          width,
          height = null;

        if (dim) {
          let pieces = str[i]
            .replace(dim, "")
            .replace(weight, "")
            .replace(/\D/g, "");

          pieces = parseInt(pieces) ? parseInt(pieces) : 0;
          weight = weight
            ? parseFloat(weight.replace(/(kg|lbs|lb|kgs|k|l)/, "").replace(",", "."))
            : 0;
          dim = dim.replace(/\D+/g, ",");
          dim = dim.split(",");

          if (dim.length === 3) {
            length = parseInt(dim[0]);
            width = parseInt(dim[1]);
            height = parseInt(dim[2]);

            packing.push({
              pieces: pieces,
              length: length,
              height: height,
              width: width,
              weight: weight,
              stackable: true,
            });
          } else if (dim.length === 4) {
            pieces = parseInt(dim[0]) ? parseInt(dim[0]) : pieces;
            length = parseInt(dim[1]);
            width = parseInt(dim[2]);
            height = parseInt(dim[3]);

            packing.push({
              pieces: pieces,
              length: length,
              height: height,
              width: width,
              weight: weight,
              stackable: true,
            });
          }
        }
      }
    }

    store_shipment.dispatch({
      type: 1,
      packing: packing,
      packing_change2: true,
    });

    store_modals.dispatch({ type: 1, modalPackingText: false });
  }

  render() {
    let show = store_modals.getState().modalPackingText;
    let { packing_text } = store_shipment.getState();

    packing_text = packing_text ? packing_text.join("\n") : "";

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalPackingText: false });
        }}
      >
        <Modal.Header className="dimension_modal_header" closeButton>
          <Modal.Title>Copy-Paste your packing list text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <textarea
              class="form-control"
              maxlength="50000"
              id="textarea_packing"
              defaultValue={packing_text}
            ></textarea>
          </div>

          <div class="row">
            <div class="col-5"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={this.uploadTxt}
            >
              Validate
            </button>

            <div class="col-5"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalConfirmationAdhoc extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalConfirmationAdhoc");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onClickYes(e) {
    e.preventDefault();

    store_modals.dispatch({ type: 1, modalConfirmationAdhoc: false });
    store_header.dispatch({ type: 1, send_adhoc_disable: true });
    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    let authorized_stations = this.props.authorized_stations;
    if (!authorized_stations)
      authorized_stations = store_station.getState().authorized_stations;

    //Adhoc send History
    updateSendContact();
    //Adhoc save
    await updateAdhocSell(authorized_stations);
    await updateAdhocBuy();
    await saveQuote();

    store_modals.dispatch({ type: 1, page_loading_spinner: false });
    store_header.dispatch({ type: 1, send_adhoc_disable: false });
    store_modals.dispatch({ type: 1, updateAdhoc: false });
    store_margin.dispatch({ type: 1, updateMargin: false });
  }

  onClickNo(e) {
    e.preventDefault();
    store_modals.dispatch({ type: 1, modalConfirmationAdhoc: false });
    store_modals.dispatch({ type: 1, updateAdhoc: false });
  }

  render() {
    let show = store_modals.getState().modalConfirmationAdhoc;

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalConfirmationAdhoc: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>
            {!isNaN(parseInt(store_header.getState().adhoc_ref))
              ? "Do you want to update adhoc in Cargospot ? "
              : "Do you want to insert adhoc price into Cargospot ?"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickYes(e);
              }}
            >
              Yes
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                this.onClickNo(e);
              }}
            >
              No
            </button>

            <div class="col-3"></div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalRateSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalRateSelect");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onClickYes(e) {
    let s = store_prices.getState().selected_rate;

    if (!s) return store_modals.dispatch({ type: 1, modalRateSelect: false });

    //console.log("freeSelectedRate", s);

    let {
      carrier,
      price,
      price_total,
      min_amount,
      all_in,
      currency,
      deal_code,
    } = rateInfo(s);

    store_pricing.dispatch({
      type: 1,
      carrier: carrier,
      price: price,
      price_total: price_total,
      min_amount: min_amount,
      all_in: all_in,
      currency: currency,
      force: true,
    });

    store_margin.dispatch({ type: 1, deal_code: deal_code });
    store_margin.dispatch({
      type: 1,
      marginPerkg: true,
      net_buy_edit: "",
      all_in_buy_edit: "",
    });
    store_margin.dispatch({
      type: 1,
      marginInTotal: true,
      net_buy_edit: "",
      all_in_buy_edit: "",
    });

    store_modals.dispatch({ type: 1, modalRateSelect: false });

    updateCarrierInfo(carrier, this.props.custom_list);

    let authorized_stations = this.props.authorized_stations
      ? this.props.authorized_stations
      : [];
    let custom_list = this.props.custom_list ? this.props.custom_list : [];

    let { station } = store_header.getState();
    let _station = authorized_stations.filter((s) => s.station === station)[0];

    await calculateMargin({ station: _station, custom_list: custom_list });
  }

  onClickNo(e) {
    e.preventDefault();
    store_modals.dispatch({ type: 1, modalRateSelect: false });
  }

  render() {
    let show = store_modals.getState().modalRateSelect;
    let { per_kg } = store_pricing.getState();
    let s = store_prices.getState().selected_rate;
    let { carrier, price, price_total, all_in, currency, selling_type } =
      rateInfo(s);
    price = price ? parseFloat(price).toFixed(2) : "";
    price_total = price_total ? parseFloat(price_total).toFixed(2) : "";
    // message = message + " " + (rate.all_in_rate && rate.all_in_rate === "Y" ? rate.rate_all_in : rate.rate + (rate.loc_addon_rate ? rate.loc_addon_rate : 0)) + "/" +

    let message = "";
    message = " Do you want to use " + carrier;
    message = message + " " + selling_type;
    message = message + " " + currency;
    message =
      message +
      " " +
      (per_kg ? price : price_total) +
      (per_kg ? " /kg " : " Total ") +
      (all_in ? "All-In" : "Net-Net");

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalRateSelect: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>{"Confirmation"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              {" "}
              <b>{message}</b>
            </div>
            <div class="col-1"></div>
          </div>

          <br />

          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickYes(e);
              }}
            >
              Yes
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                this.onClickNo(e);
              }}
            >
              No
            </button>

            <div class="col-3"></div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display_rate: true,
      validity_date: "",
    };
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalDownload");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async downloadExcel(e) {
    e.preventDefault();
    //CHECK DATE RANGE
    let header = store_header.getState();
    let shipment = store_shipment.getState();
    let pricing = store_pricing.getState();
    let remarks = store_remarks.getState();
    let stations = store_station.getState();
    let authorized_stations = stations.authorized_stations;
    let rate = pricing.per_kg ? pricing.price : pricing.price_total;
    let { display_rate, validity_date } = this.state;
    let station = authorized_stations.filter(
      (s) => s.station === store_header.getState().station
    )[0];

    validity_date = validity_date
      ? Util.dateToString2(new Date(validity_date))
      : "DATE";

    let params = {
      agent: shipment.agent_code,
      branch: "",
      sales_region:
        station && station.sales_region
          ? station.sales_region
          : cookies.sales_region,
    };

    let agent = await Routes.get("/Agent", params, true);
    agent = agent ? agent[0] : null;

    let custom_list =
      this.props && this.props.custom_list ? this.props.custom_list : [];
    let config = getConfig(custom_list);
    //let quote_main_color = config.quote_main_color
    //let quote_font_color = config.quote_font_color
    const base64ImageLogo = config.logo ? config.logo : null;
    let logo = null;

    let packing = shipment.packing ? shipment.packing : [];
    packing = packing.map((p) => {
      return (
        p.length +
        "x" +
        p.width +
        "x" +
        p.height +
        " " +
        (shipment.length_system === Util.CONST.METRIC
          ? Util.CONST.CM
          : Util.CONST.INCH) +
        " / " +
        (p.pieces + " piece") +
        (p.pieces && p.pieces > 1 ? "s" : "")
      );
    });

    packing = packing ? packing.join("\n") : "";

    let uld = shipment.uld ? shipment.uld : [];
    uld = uld.map((u) => {
      return u.pieces + u.position;
    });
    uld = uld.join("/");

    ///////FORMATTAGE DES DATA
    const workbook = new Excel.Workbook();
    workbook.creator = "Quantum App";

    if (base64ImageLogo) {
      logo = workbook.addImage({
        base64: base64ImageLogo,
        extension: "png",
      });
    }

    const worksheet = workbook.addWorksheet("quotes_list");

    if (base64ImageLogo) worksheet.addImage(logo, "B1:C1");

    /////TOP HEADER
    worksheet.getColumn("A").width = 22;
    worksheet.getColumn("C").width = 22;
    worksheet.getColumn("D").width = 22;
    if (base64ImageLogo) {
      worksheet.getRow(1).height = 30;
    }

    if (shipment.packing && shipment.packing.length > 1) {
      worksheet.getRow(6).height = parseInt(shipment.packing.length) * 15;
    }

    worksheet.getCell("A1").value =
      "ACF/" + (pricing.carrier ? pricing.carrier : "");

    worksheet.mergeCells("B1:C1");
    worksheet.getCell("A1").style = {
      alignment: {
        vertical: "middle",
        wrapText: true,
      },
    };

    worksheet.getCell("D1").value =
      header.adhoc_ref || header.id
        ? header.adhoc_ref
          ? "Ref : " + header.adhoc_ref
          : "ID : " + header.id
        : "";
    worksheet.getCell("D1").style = {
      alignment: {
        vertical: "middle",
        wrapText: true,
      },
    };

    //AGENT
    worksheet.getCell("A2").value = "Agent";
    worksheet.getCell("B2").value = agent ? agent.name : "";
    worksheet.mergeCells("B2:D2");

    //ORIGIN
    worksheet.getCell("A3").value = "Origin";
    worksheet.getCell("B3").value = shipment.origin
      ? shipment.origin.toUpperCase()
      : "";

    //DEST
    worksheet.getCell("C3").value = "Destination";
    worksheet.getCell("D3").value = shipment.dest
      ? shipment.dest.toUpperCase()
      : "";

    //PIECES
    worksheet.getCell("A4").value = "Pieces";
    worksheet.getCell("B4").value = shipment.pieces
      ? shipment.pieces.toString()
      : "";

    //NET WEIGHT
    worksheet.getCell("C4").value = "Chargeable Weight";
    worksheet.getCell("D4").value = shipment.chargeable_weight
      ? shipment.chargeable_weight.toString()
      : "";

    //VOLUME
    worksheet.getCell("A5").value = "volume";
    worksheet.getCell("B5").value = shipment.volume
      ? shipment.volume.toString() + " m3"
      : "";
    worksheet.mergeCells("B5:D5");

    //DIMENSIONS
    worksheet.getCell("A6").value = "Dimensions";
    worksheet.getCell("A6").style = {
      alignment: {
        vertical: "middle",
        wrapText: true,
      },
    };

    worksheet.getCell("B6").value =
      shipment.dimension_type === Util.CONST.ULD ? uld : packing; //"120x100x120/20"
    worksheet.mergeCells("B6:D6");

    worksheet.getCell("B6").style = {
      alignment: {
        vertical: "middle",
        wrapText: true,
      },
    };

    //COMMODITY
    worksheet.getCell("A7").value = "Commodity";
    worksheet.getCell("B7").value = shipment.commodity_code
      ? shipment.commodity_code.toString()
      : "";
    worksheet.mergeCells("B7:D7");

    //RATE
    worksheet.getCell("A8").value = "Rate requested";
    worksheet.getCell("B8").value =
      (display_rate ? rate : "RATE") +
      (pricing.all_in ? " All-In" : " Net-Net") +
      " EX " +
      shipment.origin; //"1.80 AI EX CDG"
    worksheet.mergeCells("B8:D8");

    //DATE AVAILABILITY
    worksheet.getCell("A9").value = "Date availability";
    worksheet.getCell("B9").value =
      (validity_date ? validity_date : "") +
      " AT " +
      (shipment.origin ? shipment.origin : "");
    worksheet.mergeCells("B9:D9");

    //STACKABLE
    worksheet.getCell("A10").value = "Stackable";
    worksheet.getCell("B10").value =
      shipment.stackable === "Yes"
        ? "STACKABLE"
        : shipment.stackable === "Partially"
        ? "PARTIALLY"
        : "NOT STACKABLE";
    worksheet.mergeCells("B10:D10");

    let rows = 10;

    for (let i = 1; i <= rows; i++) {
      worksheet.getRow(i).eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: "€" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    let cells = [
      "A1",
      "A2",
      "A3",
      "A4",
      "A5",
      "A6",
      "A7",
      "A8",
      "A9",
      "A10",
      "D1",
      "C3",
      "C4",
    ];

    cells.forEach((c) => {
      let cell = worksheet.getCell(c);
      if (cell) {
        cell.border = {
          top: { style: "thin", color: "€" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        cell.fill = {
          type: "pattern",
          //pattern:'darkVertical',
          //bgColor:{argb:'FFC107'},
          //fgColor:{argb:'FFC107'},
        };

        cell.font = {
          name: "Arial Black",
          color: { argb: "00000000" },
          family: 2,
          size: 10,
        };
      }
    });

    workbook.xlsx.writeBuffer("export.xlsx").then(function (data) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let filename =
        "SUMMARY_" +
        shipment.origin +
        "_" +
        shipment.dest +
        "_" +
        util.convertDate(new Date(), false, ".") +
        ".XLSX";
      saveAs(blob, filename);
    });

    let logs = {
      type: "DOWNLOAD_EXCEL_TEMPLATE",
      info: header.id ? header.id : "",
    };
    await Routes.get("/Logger", logs, true);
  }

  render() {
    let show = store_modals.getState().modalDownload;
    let carriers = ["CK", "5C"];
    let carrier = store_pricing.getState().carrier;
    carrier = carrier ? carrier : "";

    return (
      <Modal
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalDownload: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Download Excel Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-2"></div>

            <div class="col-4">
              <TextField
                label="Validity Date"
                type="date"
                tabindex="10"
                defaultValue={this.state.validity_date}
                onChange={(e) => {
                  this.setState({ validity_date: e.target.value });
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div class="col-4">
              <FormControlLabel
                value="bottom"
                control={
                  <Checkbox
                    defaultChecked={
                      carriers.indexOf(carrier) >= 0
                        ? false
                        : this.state.display_rate
                    }
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    onChange={(e) =>
                      this.setState({ display_rate: e.target.checked })
                    }
                  />
                }
                label="Display Rate"
                labelPlacement="top"
              />
            </div>

            <div class="col-2"></div>
          </div>

          <br />

          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                e.preventDefault();
                this.downloadExcel(e);
                store_modals.dispatch({ type: 1, modalDownload: false });
              }}
            >
              Download
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                store_modals.dispatch({ type: 1, modalDownload: false });
              }}
            >
              Cancel
            </button>

            <div class="col-3"></div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalConfirmationEquote extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalConfirmationEquote");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let show = store_modals.getState().modalConfirmationEquote;
    return (
      <Modal
        dialogClassName="modal-print"
        show={show}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalConfirmationEquote: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Do you want to send e-Quote ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-3"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={() =>
                sendEquote(
                  store_station.getState().authorized_stations,
                  this.props.custom_list
                )
              }
            >
              Yes
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                store_modals.dispatch({
                  type: 1,
                  modalConfirmationEquote: false,
                });
              }}
            >
              No
            </button>

            <div class="col-3"></div>
          </div>

          <QuoteTemplate custom_list={this.props.custom_list} />
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalAttachment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      isDragActive: false,
    };
  }

  // Méthode pour vérifier si tous les emplacements sont remplis
  allAttachmentsFilled() {
    const attachments = store_remarks.getState().attachments;
    return attachments.every(
      (attachment) => attachment.file_name !== "" && attachment.file !== ""
    );
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalAttachment");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_remarks.getState, "force");
    store_modals.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  uploadFile(e, i) {
    try {
      let validTypes = [
        "pdf",
        "doc",
        "docx",
        "msg",
        "xls",
        "xlsx",
        "jpeg",
        "png",
        "jpg",
      ];
      let file = e.target.files[0];
      let name = file.name;
      let name_split = name.split(".");
      let type = name_split[name_split.length - 1];
      let size = file.size;
      let error = "";

      if (!validTypes.includes(type.toLowerCase())) {
        error =
          "The file " +
          name +
          " is wrong format. Only (PDF, EXCEL, DOC, IMAGE, MSG)";
      }

      if (size > 5 * 1048576) {
        // 1M
        error = "File : " + name + " is too large. Max size (5M).";
      }

      store_remarks.dispatch({ type: 1, force: true, error: error });

      if (error) return;

      var reader = new FileReader();

      if (reader.readAsDataURL) {
        reader.onload = function (e) {
          store_remarks.dispatch({
            type: 1,
            force: true,
            addAttachment: true,
            file_name: name,
            file: e.target.result,
            index: i,
          });
        };

        reader.readAsDataURL(file);
      }

      return;
    } catch (e) {
      console.log(e.toString());
      return;
    }
  }

  uploadFileNew(file, i) {
    try {
      let validTypes = [
        "pdf",
        "doc",
        "docx",
        "msg",
        "xls",
        "xlsx",
        "jpeg",
        "png",
        "jpg",
      ];
      //let file = e.target.files[0];
      let name = file.name;
      let name_split = name.split(".");
      let type = name_split[name_split.length - 1];
      let size = file.size;
      let error = "";

      if (!validTypes.includes(type.toLowerCase())) {
        error =
          "The file " +
          name +
          " is wrong format. Only (PDF, EXCEL, DOC, IMAGE, MSG)";
      }

      if (size > 5 * 1048576) {
        // 1M
        error = "File : " + name + " is too large. Max size (5M).";
      }

      store_remarks.dispatch({ type: 1, force: true, error: error });

      if (error) return;

      var reader = new FileReader();

      if (reader.readAsDataURL) {
        reader.onload = function (e) {
          store_remarks.dispatch({
            type: 1,
            force: true,
            addAttachment: true,
            file_name: name,
            file: e.target.result,
            index: i,
          });
        };

        reader.readAsDataURL(file);
      }

      return;
    } catch (e) {
      console.log(e.toString());
      return;
    }
  }

  onHide() {
    store_remarks.dispatch({
      type: 1,
      force: true,
      attachments: store_remarks.getState().attachments_copy,
    });
    store_modals.dispatch({ type: 1, modalAttachment: false });
  }

  async onClickSave(e) {
    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    let quote_id = await saveQuote();
    await saveAttachment(quote_id);
    store_modals.dispatch({ type: 1, page_loading_spinner: false });
    store_modals.dispatch({ type: 1, modalAttachment: false });
  }

  onClickCancel(e) {
    store_remarks.dispatch({
      type: 1,
      force: true,
      attachments: store_remarks.getState().attachments_copy,
    });
    store_modals.dispatch({ type: 1, modalAttachment: false });
  }

  onDrop = (acceptedFiles, rejected, e) => {
    const { attachments } = store_remarks.getState();
    // Find the number of empty slots available
    const emptySlotsCount = attachments.filter(
      (attachment) => !attachment.file_name && !attachment.file
    ).length;
    // Si le nombre de fichiers sélectionnés dépasse le nombre d'emplacements vides disponibles, afficher une alerte
    if (acceptedFiles.length > emptySlotsCount) {
      //alert("Maximum 3 files allowed");
      Swal.fire({
        title: "Error!",
        text: "Maximum 3 files allowed",
        icon: "error",
        confirmButtonText: "Close",
      });

      return;
    }

    // Trouver le premier emplacement vide pour ajouter chaque fichier
    acceptedFiles.forEach((file, index) => {
      const emptySlotIndex = attachments.findIndex(
        (attachment) => !attachment.file_name && !attachment.file
      );

      this.uploadFileNew(file, emptySlotIndex);

      // Update state with new attachments
      this.setState(
        (prevState) => ({
          isDragActive: false,
          attachments: [...attachments],
        }),
        () => {
          // Update 'attachments' with updated objects
        }
      );
    });
  };

  removeFile(index) {
    const updatedAttachments = [...store_remarks.getState().attachments];

    // Vider l'emplacement de fichier à l'index spécifié
    updatedAttachments[index] = {
      file_name: "",
      file: "",
    };

    store_remarks.dispatch({
      type: 1,
      force: true,
      attachments: updatedAttachments,
    });
  }

  render() {
    let show = store_modals.getState().modalAttachment;
    let attachments = store_remarks.getState().attachments;
    let error = store_remarks.getState().error;
    const { isDragActive } = this.state;
    const isMaxAttachmentsReached = this.allAttachmentsFilled();
    const dropzoneDisabledStyle = {
      backgroundColor: "#989E9F",
      border: "2px dashed gray",
      padding: "20px",
      minWidth: "400px",
      minHeight: "200px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      opacity: "0.5",
      cursor: " not-allowed",
      pointerEvents: "none",
    };

    return (
      <Modal
        show={show}
        dialogClassName="modal-attachment"
        key={this.state.key}
        onHide={() => {
          this.onHide();
        }}
        className="ModalAttach"
      >
        <Modal.Header className="modal_header">
          <Modal.Title>Attachments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ color: "red", textAlign: "center" }}>{error}</p>
          </div>

          <br />
          <div style={{ display: "flex" }}>
            {/* Dropzone left */}
            <div className="dropzone" style={{ flex: "1" }}>
              <Dropzone
                onDrop={(a, r, e) => this.onDrop(a, r, e)}
                onDragOver={() => this.setState({ isDragActive: true })}
                onDragLeave={() => this.setState({ isDragActive: false })}
                disabled={isMaxAttachmentsReached} // Disable Dropzone if all slots are full
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    {!isDragActive ? (
                      <div
                        {...getRootProps()}
                        style={
                          isMaxAttachmentsReached
                            ? dropzoneDisabledStyle
                            : {
                                border: "1px solid black",
                                padding: "20px",
                                backgroundColor: "#F9FEFF",
                                minWidth: "400px",
                                minHeight: "200px",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }
                        }
                      >
                        <CloudUploadIcon style={{ fontSize: 48 }} />{" "}
                        {/* Icon above text */}
                        <input {...getInputProps()} />
                        <p>
                          {"Drag and drop your files here, or"} <br />{" "}
                          <u> {"Upload files"} </u>
                        </p>
                      </div>
                    ) : (
                      <div
                        {...getRootProps()}
                        style={{
                          border: "2px solid black",
                          padding: "20px",
                          backgroundColor: "lightgray",
                          borderStyle: "dashed solid",
                          minWidth: "400px",
                          minHeight: "200px",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <CloudUploadIcon
                          style={{ fontSize: 48, transform: "rotate(180deg)" }}
                        />{" "}
                        {/* Dropzone left*/}
                        <input {...getInputProps()} />
                        <p>{"Yes, drop your files now "}</p>
                      </div>
                    )}
                  </section>
                )}
              </Dropzone>
            </div>

            {/* File names on right */}
            <div
              style={{ flex: "1", display: "flex", flexDirection: "column" }}
            >
              <ul style={{ fontSize: "15px" }}>
                {attachments.map(
                  (attachment, index) =>
                    attachment.file_name && (
                      <li
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <button
                          onClick={() => this.removeFile(index)}
                          style={{
                            marginLeft: "10px",
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                          }}
                        >
                          <Delete style={{ fontSize: "20px", color: "red" }} />
                        </button>

                        <p style={{ margin: "0" }}>{attachment.file_name}</p>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>

          {/* <div class="row">
            <div class="col-4"></div>

            <button
              type="button"
              class="col-2 btn btn-success"
              onClick={async (e) => {
                this.onClickSave(e);
              }}
            >
              Save
            </button>

            <div class="col-1"></div>

            <button
              type="button"
              class="col-2 btn btn-danger"
              onClick={(e) => {
                this.onClickCancel(e);
              }}
            >
              Cancel
            </button>
          </div> */}
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success"
            onClick={(e) => {
              this.onClickSave(e);
            }}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={(e) => {
              this.onClickCancel(e);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class ModalRemarkInt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };

    this.style = { height: "30em" };
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalRemarkInt");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_remarks.getState, "force");
    store_remarks.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let remarks = store_remarks.getState();
    let show = store_modals.getState().modalRemarkInt;

    return (
      <Modal
        show={show}
        key={this.state.key}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalRemarkInt: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Internal Remark</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <textarea
            class="form-control"
            aria-label="With textarea"
            maxlength="500"
            defaultValue={remarks.int_remarks}
            onChange={(e) => {
              store_remarks.dispatch({
                type: 1,
                force: true,
                int_remarks: e.target.value,
              });
            }}
            style={this.style}
          ></textarea>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalRemarkExt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };

    this.style = { height: "30em" };
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalRemarkExt");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_remarks.getState, "force");
    store_remarks.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let remarks = store_remarks.getState();
    let show = store_modals.getState().modalRemarkExt;

    return (
      <Modal
        show={show}
        key={this.state.key}
        onHide={() => {
          store_modals.dispatch({ type: 1, modalRemarkExt: false });
        }}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>External Remark</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <textarea
            class="form-control"
            aria-label="With textarea"
            maxlength="150"
            defaultValue={remarks.ext_remarks}
            onChange={(e) => {
              store_remarks.dispatch({
                type: 1,
                force: true,
                ext_remarks: e.target.value,
              });
            }}
            style={this.style}
          ></textarea>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalFail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      oldState: store_header.getState().status,
      newState: store_header.getState().status,
      oldFailureReasonExp: store_header.getState().failure_reason_exp,
      oldFailureReason: store_header.getState().failure_reason,
      error: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalFail");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_header.getState, "status");
    store_header.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal)
          this.setState({ key: Date.now(), oldState: oldVal, newVal: newVal });
      })
    );
  }

  async handleCancel() {
    store_header.dispatch({
      type: 1,
      status: this.state.oldState,
      // failure_reason : this.state.oldFailureReason,
      failure_reason_exp: "", //this.state.oldFailureReasonExp,
    });
    this.setState({ error: false });
    store_modals.dispatch({ type: 1, modalFail: false });
    await saveQuote();
  }

  async handleConfirm() {
    //
    let { failure_reason, failure_reason_exp, user } = store_header.getState();

    if (!failure_reason) {
      this.setState({ error: true });
      return;
    }

    let { authorized_stations } = store_station.getState();
    let custom_list = this.props.custom_list;

    if (
      !failure_reason_exp &&
      ((user && user.toLowerCase() === Util.CONST.CARGOAI) ||
        failure_reason.toLowerCase() === "other")
    ) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }

    store_modals.dispatch({ type: 1, modalFail: false });

    if (user && user.toLowerCase() !== Util.CONST.CARGOAI) await saveQuote();

    if (user && user.toLowerCase() === Util.CONST.CARGOAI)
      await sendEquote(authorized_stations, custom_list);
  }

  render() {
    let show = store_modals.getState().modalFail;
    let { failure_reason, failure_reason_exp, user, id } =
      store_header.getState();
    failure_reason_exp = failure_reason_exp ? failure_reason_exp : "";
    failure_reason = failure_reason ? failure_reason : "";

    return (
      <Modal show={show} dialogClassName="modal-m" onHide={this.handleCancel}>
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Status update for quote {id}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-4"></div>

            <div class="col-4">
              {this.state.error && !failure_reason ? (
                <p style={{ color: "red" }}>Choose a reason please.</p>
              ) : null}
              <label>Reason for rejection</label>
              <select
                id="header-select-fail"
                class="custom-select"
                onChange={(e) => {
                  store_header.dispatch({
                    type: 1,
                    failure_reason: e.target.value,
                  });
                  this.setState({ key: Date.now() });
                }}
              >
                {<option value={failure_reason}> {failure_reason}</option>}
                {Util.REJECTED_REASONS.map((r) => {
                  if (r !== failure_reason)
                    return <option value={r}> {r} </option>;
                  else return null;
                })}
              </select>

              <br />
              <br />

              {this.state.error && failure_reason ? (
                <p style={{ color: "red" }}>Give a detailled reason please.</p>
              ) : null}
              {((user && user.toLowerCase() === Util.CONST.CARGOAI) ||
                (failure_reason &&
                  failure_reason.toLowerCase() === "other")) && (
                <textarea
                  class="form-control"
                  style={{ border: this.state.error ? "1px solid red" : "" }}
                  aria-label="With textarea"
                  maxlength="300"
                  defaultValue={failure_reason_exp}
                  onChange={(e) => {
                    store_header.dispatch({
                      type: 1,
                      failure_reason_exp: e.target.value,
                    });
                  }}
                ></textarea>
              )}
              <br />
              <center>
                <button
                  type="button"
                  class="btn btn-success"
                  onClick={this.handleConfirm}
                >
                  Confirm
                </button>

                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </center>
            </div>

            <div class="col-4"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalDeleteStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      oldState: store_header.getState().status,
      newState: store_header.getState().status,
      error: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalDeleteStatus");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_header.getState, "status");
    store_header.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal)
          this.setState({ key: Date.now(), oldState: oldVal, newVal: newVal });
      })
    );
  }

  async handleCancel() {
    store_header.dispatch({
      type: 1,
      status: this.state.oldState,
      failure_reason: "",
    });
    this.setState({ error: false });
    store_modals.dispatch({ type: 1, modalDeleteStatus: false });
    await saveQuote();
  }

  async handleConfirm() {
    let failure_reason = store_header.getState().failure_reason;

    if (!failure_reason) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }

    store_modals.dispatch({ type: 1, modalDeleteStatus: false });
    await saveQuote();
  }

  render() {
    let show = store_modals.getState().modalDeleteStatus;
    let { failure_reason } = store_header.getState();
    let { user } = store_header.getState();
    failure_reason = failure_reason ? failure_reason : "";

    return (
      <Modal show={show} dialogClassName="modal-m" onHide={this.handleCancel}>
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>Please specify delete reason</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-4"></div>

            <div class="col-4">
              <br />
              <br />

              {this.state.error ? (
                <p style={{ color: "red" }}>Give a reason please.</p>
              ) : null}

              <textarea
                class="form-control"
                style={{ border: this.state.error ? "1px solid red" : "" }}
                aria-label="With textarea"
                maxlength="100"
                defaultValue={failure_reason}
                onChange={(e) => {
                  store_header.dispatch({
                    type: 1,
                    failure_reason: e.target.value,
                  });
                }}
              ></textarea>

              <br />
              <center>
                <button
                  type="button"
                  class="btn btn-success"
                  onClick={this.handleConfirm}
                >
                  Confirm
                </button>

                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </center>
            </div>

            <div class="col-4"></div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class ModalPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalPrint");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_header.getState, "id");
    store_modals.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_header.getState, "adhoc_ref");
    store_modals.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async handlePDF() {
    let shipment = store_shipment.getState();
    let header = store_header.getState();
    let modalPrint = document.getElementById("modalPrint");
    let pages = 1;
    var pdf = null;

    modalPrint.style.paddingRight = "48px";

    let img_main = await util.html2img(modalPrint);

    if (img_main.img_height > 800) {
      let section_notes = document.getElementById("section-notes");

      section_notes.style.padding = "20px";
      section_notes.style.borderBottom = "0px";
      section_notes.style.borderBottom = "25px";

      let img_notes = await util.html2img(section_notes);

      if (img_notes.img_height > 800) {
        section_notes.style.fontSize = "0.8em";
        img_notes = await util.html2img(section_notes);
      }

      modalPrint.removeChild(section_notes);
      img_main = await util.html2img(modalPrint);

      if (img_main.img_height > 800) {
        // If too much without sections notes

        let section_shipment = document.getElementById("section-shipment");
        section_shipment.style.padding = "20px";
        section_shipment.style.borderBottom = "0px";
        let img_shipment = await util.html2img(section_shipment);

        if (img_shipment.img_height > 800) {
          section_shipment.style.fontSize = "0.8em";
          img_shipment = await util.html2img(section_shipment);
        }

        if (img_shipment.img_height + img_notes.img_height > 800) {
          pages = 3;
          modalPrint.removeChild(section_shipment);
          let img_main = await util.html2img(modalPrint);
          //CANCEL TRIM
          section_notes.style.padding = "";
          section_shipment.style.padding = "";
          section_shipment.style.paddingTop = "1em";
          section_notes.style.borderTop = "1px solid black";
          section_notes.style.paddingTop = "1em";
          section_notes.style.fontSize = "1em";
          section_shipment.style.fontSize = "1em";

          modalPrint.appendChild(section_shipment);
          modalPrint.appendChild(section_notes);

          //create pdf pages
          pdf = await util.img2pdf(img_main);
          pdf = await util.img2pdf(img_shipment, pdf);
          pdf = await util.img2pdf(img_notes, pdf);
        } else {
          pages = 2;
          //ADDITIONAL TRIM
          modalPrint.removeChild(section_shipment);

          let img_main = await util.html2img(modalPrint);
          let temp = document.getElementById("temp");

          temp.appendChild(section_shipment);
          temp.appendChild(section_notes);

          //section_shipment.style.borderBottom = "1px solid black"

          let img_temp = await util.html2img(temp);
          temp.removeChild(section_shipment);
          temp.removeChild(section_notes);

          //CANCEL TRIM
          section_notes.style.padding = "";
          section_shipment.style.padding = "";

          section_shipment.style.paddingTop = "1em";
          section_notes.style.paddingTop = "1em";
          section_notes.style.fontSize = "1em";
          section_shipment.style.fontSize = "1em";

          modalPrint.appendChild(section_shipment);
          modalPrint.appendChild(section_notes);

          //create pdf pages
          pdf = await util.img2pdf(img_main);
          pdf = await util.img2pdf(img_temp, pdf);
        }
      } else {
        //Notes in second page

        pages = 2;

        //CANCEL TRIM
        section_notes.style.padding = "";
        section_notes.style.paddingTop = "1em";
        section_notes.style.paddingBottom = "1em";
        section_notes.style.fontSize = "1em";
        modalPrint.appendChild(section_notes);

        //create pdf pages
        pdf = await util.img2pdf(img_main);
        pdf = await util.img2pdf(img_notes, pdf);
      }
    } else {
      //All in same page
      pages = 1;
      pdf = await util.img2pdf(img_main);
    }

    let ele = document.createElement("img");
    ele.src = require("../img/quantum-logo.jpg");

    let div = document.createElement("div");
    div.appendChild(ele);

    document.body.appendChild(div);
    let logo = await util.html2img(div);
    document.body.removeChild(div);

    let img_height = logo.img_height;
    let img_width = logo.img_width;
    let ratio = img_width / img_height;

    pdf.setTextColor(0, 52, 87);

    for (let i = 1; i < pages + 1; i++) {
      pdf.setPage(i);
      pdf.addImage(logo.data, "JPEG", 10, 288, 200, 200 / ratio);

      //DATE & PAGES
      pdf.text("page " + i + " of " + pages, 190, 290, "center");
      pdf.text(
        "Generated  " + util.dateToString4(new Date()),
        100,
        290,
        "center"
      );
    }
    //SAVE
    pdf.save(
      "QUOTE_" +
        (header.adhoc_ref ? header.adhoc_ref : header.id) +
        "_" +
        shipment.origin +
        "_" +
        shipment.dest
    );

    let logs = {
      type: "DOWNLOAD_PDF_TEMPLATE",
      info: header.id ? header.id : "",
    };
    await Routes.get("/Logger", logs, true);
  }


  async handlePDFNew() {
    let shipment = store_shipment.getState();
    let header = store_header.getState();
    let modalPrint = document.getElementById("modalPrint");
    let MarginRIGHLEFT = 10
    let MarginTOPBOTTOM = 20
    let opts = {
      callback: function(doc) {

         let pdfName = "QUOTE_" + (header.adhoc_ref ? header.adhoc_ref : header.id) + "_" + shipment.origin + "_" + shipment.dest
         const pageCount = doc.internal.getNumberOfPages()
         //let y = 840
         for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          //doc.internal.pageSize.height = 830
          
          doc.setTextColor(util.COLOR_PALETTE.ECS_MAIN);
          doc.setFontSize(6)
          doc.text('QUANTUM', 40 , doc.internal.pageSize.height - 10)
          doc.text('Generated ' + util.dateToString4(new Date()), doc.internal.pageSize.width/2 , doc.internal.pageSize.height - 10, 'center')
          doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width - 50 , doc.internal.pageSize.height - 10)
          //console.log('##freePDFS', doc.internal.pageSize.height)
        }

         doc.save(pdfName);
      },
      margin: [MarginTOPBOTTOM, MarginRIGHLEFT, MarginTOPBOTTOM, MarginRIGHLEFT],
      autoPaging: 'text',
      //x: 15,
      //y: 15,
      //width: 190, //target width in the PDF document
      //windowWidth: 675 //window width in CSS pixels
  }

    let pdf = await util.html2pdf(modalPrint, opts)

    let logs = {
      type: "DOWNLOAD_PDF_TEMPLATE",
      info: header.id ? header.id : "",
    };
    await Routes.get("/Logger", logs, true);

  }

  async handleIMG() {
    //création d'un CANVAS qui contient le screenshot avec html2canvas
    let modalPrint = document.getElementById("modalPrint");
    let header = store_header.getState();
    //modalPrint.style.width = "800px"

    html2canvas(modalPrint, { scale: 1 }).then((canvas) => {
      //create IMG  from CANVAS
      var img = document.createElement("img");
      img.src = canvas.toDataURL();

      var div = document.createElement("div");
      div.contentEditable = true;
      div.appendChild(img);
      document.body.appendChild(div);

      var range = null;

      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(div);
        range.select();
      } else if (window.getSelection) {
        var selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(div);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      document.execCommand("Copy");
      document.body.removeChild(div);
    });

    let logs = {
      type: "COPY_IMAGE_TEMPLATE",
      info: header.id ? header.id : "",
    };
    await Routes.get("/Logger", logs, true);
  }

  async copyHTML() {
    let el = document.getElementById("htmlCopy");
    el.setAttribute("style", "display:yes");
    //el.style.border = '1px solid black'
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    document.execCommand("copy");
    sel.removeAllRanges(); //unselect
    el.setAttribute("style", "display:none");

    let header = store_header.getState();
    let logs = {
      type: "COPY_HTML_TEMPLATE",
      info: header.id ? header.id : "",
    };
    await Routes.get("/Logger", logs, true);
  }

  onHide() {
    store_modals.dispatch({ type: 1, modalPrint: false });
    let pricing = store_pricing.getState();
    if (
      store_header.getState().status === "DRAFT" &&
      ((pricing.per_kg && pricing.price !== "") ||
        (!pricing.per_kg && pricing.price_total !== "")) &&
      checkMandatory() &&
      !routeHasEmpty()
    )
      store_modals.dispatch({ type: 1, modalQuoted: true });
  }

  render() {
    //ADJUST PRINT FROM CONFIG
    let show = store_modals.getState().modalPrint;
    let header = store_header.getState();

    return (
      <Modal
        show={show}
        dialogClassName="modal-print"
        onCopy={(e) => {
          console.log(window.getSelection());
        }}
        onHide={this.onHide}
      >
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            <button
              type="button"
              class="btn btn-success"
              style={{ "margin-left": "1em" }}
              onClick={this.handlePDFNew}
            >
              Download PDF
            </button>

            <button
              type="button"
              class="btn btn-success"
              style={{ "margin-left": "1em" }}
              onClick={this.handleIMG}
            >
              Copy Image
            </button>

            <button
              type="button"
              class="btn btn-success"
              style={{ "margin-left": "1em" }}
              onClick={this.copyHTML}
            >
              Copy HTML
            </button>

            {header.id &&
              (header.station === "PAR" || cookies.region === "WLD") && (
                <button
                  type="button"
                  class="btn btn-success"
                  style={{ "margin-left": "1em" }}
                  onClick={(e) => {
                    store_modals.dispatch({ type: 1, modalDownload: true });
                  }}
                >
                  Download Excel
                </button>
              )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <QuoteTemplate custom_list={this.props.custom_list} />
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

class QuoteTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {}

  render() {
    let shipment = store_shipment.getState();
    let pricing = store_pricing.getState();
    let remarks = store_remarks.getState();
    let margin = store_margin.getState();
    let { margin_per_kg, margin_in_total } = margin;
    let { routes, expectedTime } = store_routes.getState();
    let header = store_header.getState();
    let uld = shipment.uld ? shipment.uld : [];
    let uld_price = pricing.uld_price ? pricing.uld_price : [];
    let rountingHTML = [];
    let transits = [];

    //ADJUST PRINT FROM CONFIG
    let custom_list =
      this.props && this.props.custom_list ? this.props.custom_list : [];
    let config = getConfig(custom_list);
    let gha_info = config && config.gha_info ? config.gha_info.filter((c) => c.departure_airport === shipment.origin) : []
   
    const mapGHA = (str) => {

      return str ? str.split(/[\n;]+/).map((item, index) => (
        <>
          {item}
          <br />
        </>
      )) : "";
    };


    let quote_main_color = config.quote_main_color;
    let quote_font_color = config.quote_font_color;

    console.log("##freeConfig", config, gha_info)


    //DISPLAY EMAL
    let display_email = !config.contact_email ? "" : config.contact_email === Util.CONST.DEFAULT ? config.default_contact : cookies.email_contact;
    let display_user_name = config ? config.display_user_name : false;
    let user_name = "";
    user_name = user_name + (cookies.first_name ? cookies.first_name : "");
    user_name = user_name + (cookies.last_name ? " " + cookies.last_name.toUpperCase() : "");

    //BUILDING ROUTES HTML TABLE
    if (store_routes.getState().detailed_info)
      routes.forEach((r) => {
        if (!r.dest) return;

        rountingHTML.push(
          <tr>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.origin}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.dest}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.carrier}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.flight_number}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.flight_type}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.flight_date}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.etd}{" "}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.eta}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.shipment_split !== "" && r.shipment_split !== undefined
                ? r.shipment_split + "%"
                : ""}
            </td>
          </tr>
        );

        //SHIPEMENT TRANSIT
        if (
          shipment.origin !== r.origin &&
          r.origin !== "" &&
          r.origin !== "EXW"
        ) {
          transits.push(r.origin);
        }
      });
    else
      routes.forEach((r) => {
        if (!r.dest) return;

        rountingHTML.push(
          <tr>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.origin}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.dest}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.carrier}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.carrier.toLowerCase() === "mix"
                ? "Flight/Truck"
                : r.flight_type}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.weekly_frequency}
            </td>
            <td style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
              {r.operation_dow}
            </td>
          </tr>
        );

        //SHIPEMENT TRANSIT
        if (
          shipment.origin !== r.origin &&
          r.origin !== "" &&
          r.origin !== "EXW"
        ) {
          transits.push(r.origin);
        }
      });

    //SETTING PACKING LINES FROM SHIPMENT INFO
    let packing = [];

    shipment.packing.forEach((p) => {
      if (p.pieces || p.length || p.width || p.height || p.weight)
        packing.push(p);
    });

    //TOTAL_CHARGES_SUM
    let total_charges_ocdc = [];

    total_charges_ocdc = pricing.ocdc.map((o) => {
      return isNaN(o.total_charges) ? 0 : parseFloat(o.total_charges);
    });

    let sum_total_charge = 0;
    total_charges_ocdc.forEach((o) => {
      if (!o || isNaN(o)) {
        store_pricing.dispatch({ type: 1, display_ocdc_total: false });
      }
      sum_total_charge = sum_total_charge + (isNaN(o) ? 0 : parseFloat(o));
    });

    let is_different =
      shipment.dimension_type !== Util.CONST.ULD &&
      shipment.chargeable_weight_manual &&
      ((shipment.chargeable_weight &&
        parseFloat(shipment.chargeable_weight).toFixed(2) !==
          parseFloat(shipment.chargeable_weight_manual).toFixed(2)) ||
        (pricing.min_chargeable_weight &&
          parseFloat(pricing.min_chargeable_weight) >
            parseFloat(shipment.chargeable_weight_manual)))
        ? true
        : false;
    let view =
      header.station === "CDG" &&
      (pricing.carrier === "RJ" ||
        pricing.carrier === "NAB" ||
        pricing.carrier === "YD")
        ? false
        : true;

    let fuel_rates = null;

    if (margin_per_kg.fuel_rate && (margin.fuel_min || margin.fuel_max)) {
      fuel_rates = !margin_per_kg.fuel_rate ? null : 
      (!margin.fuel_min ? "" : "MIN " + margin.fuel_min + " | ") 
      + (margin_per_kg.fuel_rate ? parseFloat(margin_per_kg.fuel_rate).toFixed(2) : "" ) 
      + (!margin.fuel_max ? "" : " | MAX " + margin.fuel_max);
      fuel_rates = fuel_rates && fuel_rates === margin_per_kg.fuel_rate.toString()  ? "" : fuel_rates;
    }

    let security_rates = null;

    if (margin_per_kg.security_rate && (margin.security_min || margin.security_max)) {
      security_rates = (!margin.security_min ? "" : "MIN " + margin.security_min + " | ") 
      + (margin_per_kg.security_rate ? parseFloat(margin_per_kg.security_rate).toFixed(2): "") 
      + (!margin.security_max ? "" : " | MAX " + margin.security_max);
      security_rates =  security_rates && security_rates === margin_per_kg.security_rate.toString() ? "": security_rates;
    }

    const ref = React.createRef();
    const ref1 = React.createRef();

    return [
      <div
        style={{
          fontSize: "0.7em",
          border: "1px solid black",
          padding: "1.5em",
          display: "none",
        }}
        id="htmlCopy"
      >

        <table style={{ border: "1px solid black", padding: "1em" }}>
          {header.status && header.status === Util.CONST.DRAFT && (
            <tr>
              <td colSpan="6" style={{ border: "1px solid red", color: "red" }}>
                <center>{Util.TEXTS.DRAFT_TEXT}</center>
              </td>
            </tr>
          )}

          {header.failure_reason_exp && (
            <tr>
              <td colSpan="6" style={{ border: "1px solid red", color: "red" }}>
                <center>Rejection reason :</center>
                <Interweave content={formatText(header.failure_reason_exp)} />
              </td>
            </tr>
          )}

          <tr>
            <td colSpan="2">
              <strong>
                {" "}
                Reference for Booking :{" "}
                {header.adhoc_ref ? header.adhoc_ref : header.id}
              </strong>
            </td>

            <td colSpan="2" style={{ textAlign: "center" }}>
              {config.logo && (
                <img
                  src={config.logo}
                  alt="logo"
                  style={{ maxWidth: "15em", maxHeight: "8em" }}
                />
              )}
              <br />
            </td>

            <td colSpan="2" style={{ textAlign: "right" }}>
              {display_user_name || display_email ? (
                <strong>Contact : </strong>
              ) : null}
              {display_user_name ? user_name : null}
              {display_user_name && display_email ? <br /> : null}
              {display_email ? display_email : null}
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "center", padding: "1em"}}>
              <div
                style={{ height: "10em", borderRadius : '1em', background: quote_main_color,color: quote_font_color }}
              > 
                <br />
                  {!pricing.carrier_name ? "-" : pricing.carrier_name}
                  <br />
                  <strong>{pricing.carrier}</strong>
                  <br />
                  {!pricing.carrier_prefix
                    ? "-"
                    : "AWB Prefix: " + pricing.carrier_prefix}
                  <br />
                  {"."}
              </div>
            </td>

            <td style={{ textAlign: "center", padding: "1em" }}>
              <div style={{ height: "10em", borderRadius : '1em', background: quote_main_color,color: quote_font_color  }}>
                <br />
                <strong>
                  {shipment.exw ? "EXW" : shipment.origin} - {shipment.dest}
                </strong>
                {transits.length === 0 ? <br /> : <br />}
                {transits.length === 0 ? "-" : <i>Via</i>}
                {transits.length === 0 ? <br /> : <br />}
                {transits.length === 0 ? "-" : transits.join(", ")}
                <br />
                {"."}
              </div>
            </td>

            <td style={{ textAlign: "center", padding: "1em"  }}>
              <div style={{height: "10em", borderRadius : '1em', background: quote_main_color,color: quote_font_color  }}>
                <br />
                {shipment.dimension_type === Util.CONST.ULD
                  ? Util.CONST.TOTAL
                  : ""}
                {pricing.all_in
                  ? Util.CONST.ALL_IN
                  : Util.CONST.NET_NET + (view ? "*" : "")}
                <br />
                <strong>
                  {Util.formatAmount1(
                    pricing.per_kg ? pricing.price : pricing.price_total,
                    pricing.currency
                  )}
                  {pricing.per_kg ? Util.CONST.PER_KG : ""}
                </strong>
                {!shipment.commodity_code ? null : (
                  <>
                    <br />
                    {shipment.commodity_code ? "Commodity" : null}
                    <br />
                    <strong>
                      {shipment.commodity_code ? shipment.commodity_code : null}{" "}
                    </strong>
                  </>
                )}
                <br />
              </div>
            </td>

            <td colSpan="2" style={{ textAlign: "center", padding: "1em"  }}>
              <div style={{ height: "10em" , borderRadius : '1em',background: quote_main_color,color: quote_font_color}} >
                <br />
                {!shipment.chargeable_weight
                  ? ""
                  : "Chargeable Weight" +
                    (is_different && pricing.all_in ? "*" : "") +
                    (is_different && !pricing.all_in
                      ? "*" + (view ? "*" : "")
                      : "")}
                <br />
                <strong>
                  {Util.formatAmount4(shipment.chargeable_weight) +
                    (!shipment.chargeable_weight ? "" : Util.CONST.KG)}
                </strong>
                {shipment.dimension_type === Util.CONST.ULD ? <br /> : null}
                {shipment.dimension_type === Util.CONST.ULD
                  ? uld_price
                      .map((u) => u.pieces + " " + u.position)
                      .join(" / ")
                  : null}
                {shipment.dimension_type === Util.CONST.ULD ? null : <br />}
                <br />
                {"."}
              </div>
            </td>

            <td style={{ textAlign: "center", padding: "1em"  }}>
              <div style={{height: "10em", borderRadius : '1em', background: quote_main_color,color: quote_font_color}} >
                <br />
                Validity
                <br />
                <strong>{Util.dateToString2(pricing.from_date)}</strong>
                <br />
                <strong>{Util.dateToString2(pricing.to_date)}</strong>
                <br />
    
                {"."}
              </div>
            </td>
          </tr>

          {(is_different || !pricing.all_in) && (
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                {!pricing.all_in && view && (
                  <div style={{ color: "red" }}>
                    {<strong>*{Util.TEXTS.SECURITY_FUEL_NOT_INCLUDES}</strong>}
                  </div>
                )}

                {is_different && (
                  <div style={{ color: "red" }}>
                    {
                      <strong>
                        {is_different
                          ? (!pricing.all_in && view ? "*" : "") +
                            "*" +
                            Util.TEXTS.ADJUST_WEIGHT
                          : ""}
                      </strong>
                    }
                  </div>
                )}
              </td>
            </tr>
          )}

          <tr>
            <td colSpan="6" style={{ textAlign: "center" }}>
              <strong>{!shipment.agent ? "" : "Agent : "}</strong>
              {shipment.agent}
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              <img src={require("../img/charge.png")} />
              <span style={{ marginLeft: "1em" }}>
                <strong> Charges</strong>
              </span>

              <br />

              {shipment.dimension_type !== Util.CONST.ULD ? (
                <div style={{ marginLeft: "3.5em" }}>
                  <br />
                  {pricing.all_in ? "All in Rate" : Util.CONST.NET_NET}
                  {pricing.per_kg
                    ? " " + Util.CONST.PER_KG2 + " (Chg.Wgt)"
                    : " (Total)"}{" "}
                  :
                  {Util.formatAmount1(
                    pricing.per_kg ? pricing.price : pricing.price_total,
                    pricing.currency
                  )}
                  <br />
                  {!pricing.all_in
                    ? !margin_per_kg.fuel_rate
                      ? ""
                      : "Fuel (" +
                        margin.fuel_code +
                        ") " +
                        Util.CONST.PER_KG2 +
                        " (" +
                        margin.fuel_type +
                        "): " +
                        Util.formatAmount1(
                          margin_per_kg.fuel_rate,
                          margin.fuel_currency
                        ) +
                        (fuel_rates ? "-" + fuel_rates : "")
                    : ""}
                  {!pricing.all_in && margin_per_kg.fuel_rate ? <br /> : ""}
                  {!pricing.all_in
                    ? !margin_per_kg.security_rate
                      ? ""
                      : (margin.security_code === "XDC"
                          ? "Covid Surcharge"
                          : "Security") +
                        " (" +
                        margin.security_code +
                        ") " +
                        Util.CONST.PER_KG2 +
                        " (" +
                        margin.security_type +
                        "): " +
                        Util.formatAmount1(
                          margin_per_kg.security_rate,
                          margin.security_currency
                        ) +
                        (security_rates ? "-" + security_rates : "")
                    : ""}
                  {!pricing.all_in && margin_per_kg.security_rate ? <br /> : ""}
                  {!margin.sell_total
                    ? ""
                    : "Total Freight Charges: " +
                      Util.formatAmount1(margin.sell_total, pricing.currency)}
                  <br />
                  {!pricing.min_chargeable_weight
                    ? ""
                    : "Mininum Chargeable Weight: " +
                      Util.formatAmount3(pricing.min_chargeable_weight) +
                      " " +
                      Util.CONST.KG}
                  {!pricing.min_chargeable_weight ? "" : <br />}
                  {!pricing.min_amount
                    ? ""
                    : "Min Amount: " +
                      Util.formatAmount1(pricing.min_amount, pricing.currency)}
                </div>
              ) : (
                <div style={{ marginLeft: "3.5em" }}>
                  <br />
                  {uld_price.map((u) => {
                    return (
                      <div>
                        {u.pieces} {u.position}: {u.selling_rate} {u.currency}
                        {u.per_kg ? Util.CONST.PER_KG : Util.CONST.ULD}, Pivot{" "}
                        {u.pivot_weight} kg - O/P {u.over_pivot_rate}
                        <br />
                      </div>
                    );
                  })}
                  {pricing.all_in ? "All in Rate" : Util.CONST.NET_NET}
                  {pricing.per_kg
                    ? " " + Util.CONST.PER_KG2 + " (Chg.Wgt)"
                    : " (Total)"}{" "}
                  :
                  {Util.formatAmount1(
                    pricing.per_kg ? pricing.price : pricing.price_total,
                    pricing.currency
                  )}
                  <br />
                  {!pricing.all_in
                    ? !margin_per_kg.fuel_rate
                      ? ""
                      : "Fuel (" +
                        margin.fuel_code +
                        ") " +
                        Util.CONST.PER_KG2 +
                        " (" +
                        margin.fuel_type +
                        "): " +
                        Util.formatAmount1(
                          margin_per_kg.fuel_rate,
                          margin.fuel_currency
                        ) +
                        (fuel_rates ? "-" + fuel_rates : "")
                    : ""}
                  {!pricing.all_in && margin_per_kg.fuel_rate ? <br /> : ""}
                  {!pricing.all_in
                    ? !margin.security_rate
                      ? ""
                      : "Security (" +
                        margin.security_code +
                        ") " +
                        Util.CONST.PER_KG2 +
                        " (" +
                        margin.security_type +
                        "): " +
                        Util.formatAmount1(
                          margin_per_kg.security_rate,
                          margin.security_currency
                        ) +
                        (security_rates ? "-" + security_rates : "")
                    : ""}
                  {!pricing.all_in && margin_per_kg.security_rate ? <br /> : ""}
                  {!margin.sell_total
                    ? ""
                    : "Total Freight Charges: " +
                      Util.formatAmount1(margin.sell_total, pricing.currency)}
                  <br />
                </div>
              )}
            </td>

            <td colSpan="3">
              <img
                alt={"Shipment Details"}
                src={require("../img/packing_pc.png")}
              />
              <span style={{ marginLeft: "1em" }}>
                <strong> Shipment Details</strong>
              </span>

              <br />

              <span style={{ marginLeft: "3.5em" }}>
                <br />
                {!shipment.pieces || shipment.pieces === "0"
                  ? ""
                  : Util.formatAmount3(shipment.pieces) + " piece(s), "}
                {Util.formatAmount3(shipment.gross_weight) +
                  (!shipment.gross_weight ? "" : " " + Util.CONST.KG + ", ")}
                {shipment.volume}{" "}
                {!shipment.volume ? (
                  ""
                ) : (
                  <span>
                    m<sup>3</sup>,
                  </span>
                )}
                {!shipment.density
                  ? ""
                  : " density " +
                    Util.formatDensity(shipment.density).split("|")[0]}
              </span>
              <br />
              {!shipment.shc[0] &&
              !shipment.shc[1] &&
              !shipment.shc[2] &&
              !shipment.shc[3] ? (
                ""
              ) : (
                <span style={{ marginLeft: "3.5em" }}>
                  {shipment.shc
                    ? shipment.shc
                        .filter((s) => {
                          return s !== "";
                        })
                        .join(", ")
                    : ""}
                  <br />
                </span>
              )}

              {shipment.dimension_type !== Util.CONST.ULD && (
                <span style={{ marginLeft: "3.5em" }}>
                  Stackable: {shipment.stackable}
                  <br />
                </span>
              )}

              <span style={{ marginLeft: "3.5em" }}>
                Main Deck: {shipment.main_deck ? Util.CONST.YES : Util.CONST.NO}
                <br />
              </span>

              {!shipment.nature_goods ? (
                ""
              ) : (
                <span style={{ marginLeft: "3.5em" }}>
                  Nature of Goods: {shipment.nature_goods}
                  <br />
                </span>
              )}

              {!shipment.commodity ? (
                ""
              ) : (
                <span style={{ marginLeft: "3.5em" }}>
                  Commodity: {shipment.commodity}
                  <br />
                </span>
              )}
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              <img
                alt={"Others Charges"}
                src={require("../img/other_charge.png")}
              />
              <span style={{ marginLeft: "1em" }}>
                <strong> Other Charges Due Carrier</strong>
              </span>

              <br />

              <div>
                <br />
                {pricing.ocdc.map((o) => {
                  return (
                    <div style={{ marginLeft: "3.5em" }}>
                      {o.name} ({o.based_on}) - {o.value} - {o.currency}
                      <br />
                    </div>
                  );
                })}

                {pricing.ocdc.length > 0 && pricing.display_ocdc_total ? (
                  <div style={{ marginLeft: "3.5em" }}>
                    <strong>
                      Total Charges Due Carrier:{" "}
                      {parseFloat(sum_total_charge).toFixed(2)}{" "}
                      {pricing.currency}*
                    </strong>
                    <br />
                    <i style={{ fontSize: "0.8em" }}>
                      *{Util.TEXTS.CHARGES_TEXT}
                    </i>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {!pricing.ocdc || pricing.ocdc.length === 0 ? (
                <div style={{ marginLeft: "3.5em" }}>
                  {Util.TEXTS.REFER_TEXT}
                </div>
              ) : (
                ""
              )}
            </td>

            <td colSpan="3">
              <img
                alt={"Packing List"}
                src={require("../img/packing_list.png")}
              />
              {shipment.dimension_type !== Util.CONST.ULD ? (
                !packing || packing.length === 0 ? (
                  <span style={{ marginLeft: "1em" }}>
                    <strong> {Util.TEXTS.NO_PACKING_TEXT}</strong>
                  </span>
                ) : packing.length > 3 &&
                  packing.length > 2 * pricing.ocdc.length ? (
                  <span style={{ marginLeft: "1em" }}>
                    <strong>
                      {" "}
                      Pieces - L*W*H{" "}
                      {shipment.length_system !== Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.CM + ")"
                        : "(" + Util.CONST.INCH + ")"}{" "}
                      - Weight
                      {shipment.weight_system === Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.LBS + ")"
                        : "(" + Util.CONST.KG + ")"}{" "}
                      - Stackability
                    </strong>
                    <br />
                    <span style={{ marginLeft: "3.5em" }}>
                      <br />
                      <i>{Util.TEXTS.SEE_PACKING_BELOW}</i>
                    </span>
                  </span>
                ) : (
                  <span>
                    <span style={{ marginLeft: "1em" }}>
                      <strong>
                        {" "}
                        Pieces - L*W*H{" "}
                        {shipment.length_system !== Util.CONST.IMPERIAL
                          ? "(" + Util.CONST.CM + ")"
                          : "(" + Util.CONST.INCH + ")"}{" "}
                        - Weight
                        {shipment.weight_system === Util.CONST.IMPERIAL
                          ? "(" + Util.CONST.LBS + ")"
                          : "(" + Util.CONST.KG + ")"}{" "}
                        - Stackability
                      </strong>
                      <br />
                    </span>

                    <br />

                    {packing.map((p) => {
                      return (
                        <span style={{ marginLeft: "3.5em" }}>
                          {p.pieces > 1 ? p.pieces + " pcs" : p.pieces + " pc"}{" "}
                          - {p.length} * {p.width} * {p.height}
                          {!p.weight || isNaN(p.weight)
                            ? ""
                            : " - " +
                              Util.formatAmount3(p.weight) +
                              " " +
                              (shipment.weight_system === Util.CONST.IMPERIAL
                                ? Util.CONST.LBS + " "
                                : Util.CONST.KG + " ")}{" "}
                          - {p.stackable ? "Stackable" : "Not Stackable"}
                          <br />
                        </span>
                      );
                    })}
                  </span>
                )
              ) : !uld || uld.length === 0 ? (
                <span style={{ marginLeft: "1em" }}>
                  <strong>{util.TEXTS.NO_ULD_TEXT}</strong>
                </span>
              ) : uld.length > 3 && uld.length > 2 * pricing.ocdc.length ? (
                <span style={{ marginLeft: "1em" }}>
                  <strong>
                    Pieces - Deck - Position - Weight/pc{" "}
                    {shipment.weight_system === Util.CONST.IMPERIAL
                      ? "(" + Util.CONST.LBS + ")"
                      : "(" + Util.CONST.KG + ")"}
                  </strong>
                  <br />
                  <span style={{ marginLeft: "3.5em" }}>
                    <i>{Util.TEXTS.SEE_ULD_BELOW}</i>
                  </span>
                </span>
              ) : (
                <span>
                  <span style={{ marginLeft: "1em" }}>
                    <strong>
                      Pieces - Deck - Position - Weight/pc{" "}
                      {shipment.weight_system === Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.LBS + ")"
                        : "(" + Util.CONST.KG + ")"}
                    </strong>
                    <br />
                  </span>
                  {uld.map((u) => {
                    return (
                      <span style={{ marginLeft: "3.5em" }}>
                        <br />
                        {u.pieces > 1 ? u.pieces + " pcs" : u.pieces + " pc"} -
                        {u.deck === Util.CONST.MAIN
                          ? Util.CONST.MAIN_DECK
                          : Util.CONST.LOWER_DECK}{" "}
                        - {u.position}
                        {!u.weight || isNaN(u.weight)
                          ? ""
                          : " - " +
                            Util.formatAmount3(u.weight) +
                            " " +
                            (shipment.weight_system === Util.CONST.IMPERIAL
                              ? Util.CONST.LBS + " "
                              : Util.CONST.KG + " ")}
                        <br />
                      </span>
                    );
                  })}
                </span>
              )}
            </td>
          </tr>

          {rountingHTML.length > 0 && (
            <>
              <tr>
                <td colSpan="6">
                  <img
                    alt={"Routings"}
                    src={require("../img/plane_routing.png")}
                  />
                  <span style={{ marginLeft: "1em" }}>
                    <strong> Routing</strong>
                  </span>

                  <br />
                  <br />

                  <table style={{ width: "980px" }}>
                    <thead>
                      {store_routes.getState().detailed_info ? (
                        <tr style={{ background: "#F2F3F4" }}>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Brd Point
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Off Point
                          </th>
                          <th style={{ width: "10em", textAlign: "left" }}>
                            Carrier
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Flight Number
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Oper Type
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Flight Date
                          </th>
                          <th style={{ width: "10em", textAlign: "left" }}>
                            ETD
                          </th>
                          <th style={{ width: "10em", textAlign: "left" }}>
                            ETA
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Ship. Split
                          </th>
                        </tr>
                      ) : (
                        <tr style={{ background: "#F2F3F4" }}>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Brd Point
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Off Point
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Carrier
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Oper. Type
                          </th>
                          <th style={{ width: "20em", textAlign: "left" }}>
                            Weekly Frequency
                          </th>
                          <th style={{ width: "15em", textAlign: "left" }}>
                            Operation DOW
                          </th>
                        </tr>
                      )}
                    </thead>

                    {rountingHTML}
                  </table>
                </td>
              </tr>

              <tr>
                <td colSpan={6}>
                  {expectedTime !== undefined &&
                    expectedTime !== null &&
                    expectedTime !== 0 &&
                    expectedTime &&
                    routes.length !== 1 && (
                      <span className="days-expected">
                        Expected {routes[0].origin} -{" "}
                        {routes[routes.length - 1].dest} time is {expectedTime}{" "}
                        {expectedTime === 1 ? "day" : "days"}
                      </span>
                    )}
                </td>
              </tr>
            </>
          )}

          {gha_info && gha_info.length > 0 ?
          <>
            <tr>
              <td colSpan="3">
                <img alt={"GHA"} src={require("../img/delivery_man.png")} />
                <span style={{ marginLeft: "1em" }}>
                  <strong> GHA Information</strong>
                </span>
                <br />
              </td>
              <td colSpan="3">
              </td>          
              
            </tr>

            {gha_info.map((g,index)=> {

              return(
              <tr>
                <td colSpan="3">
                  <div  style={{ marginLeft: "3.5em" }}>
                    <br />
                   <strong>Name {g.oper_type ? "| " + g.oper_type : ""} : </strong>{g.gha_name ? mapGHA(g.gha_name) : ""}
                    <br />
                    <strong>Address : </strong>{g.gha_address ? mapGHA(g.gha_address) : ""}
          
                  </div>
                </td>
  
                {
                  <td colSpan="3">
                  <div  style={{ marginLeft: "3.5em" }}>
                    <br/>
                    <strong>Opening Hours : </strong>{g.gha_opening_hours ? mapGHA(g.gha_opening_hours) : "" }   
                    <br/>
                    <strong>Additional Information : </strong>{g.additional_infos ? mapGHA(g.additional_infos) : "" }             
                  </div>
                </td>
                
                }
              </tr>
              )

            })}
      
          </>
           : null
           
           }

          {remarks.ext_remarks && (
            <tr>
              <td colSpan="6">
                <img alt={"Remarks"} src={require("../img/remark.png")} />
                <span style={{ marginLeft: "1em" }}>
                  <strong> Remarks</strong>
                </span>

                <br />

                <div style={{ marginLeft: "3.5em" }}>
                  <br />
                  <Interweave content={remarks.ext_remarks} />
                </div>
              </td>
            </tr>
          )}

          {shipment.dimension_type !== Util.CONST.ULD ? (
            packing.length > 3 &&
            packing.length > 2 * pricing.ocdc.length && (
              <tr>
                <td colSpan="6">
                  <img src={require("../img/packing_list.png")} />
                  <span style={{ marginLeft: "1em" }}>
                    <strong>
                      {" "}
                      Pieces - L*W*H{" "}
                      {shipment.length_system !== Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.CM + ")"
                        : "(" + Util.CONST.INCH + ")"}{" "}
                      - Weight
                      {shipment.weight_system === Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.LBS + ")"
                        : "(" + Util.CONST.KG + ")"}{" "}
                      - Stackability
                    </strong>
                  </span>

                  <br />
                  <br />

                  {packing.map((p) => {
                    return (
                      <span style={{ marginLeft: "3.5em" }}>
                        {p.pieces > 1 ? p.pieces + " pcs" : p.pieces + " pc"} -{" "}
                        {p.length} * {p.width} * {p.height}
                        {!p.weight || isNaN(p.weight)
                          ? ""
                          : "- " +
                            Util.formatAmount3(p.weight) +
                            " " +
                            (shipment.weight_system === Util.CONST.IMPERIAL
                              ? Util.CONST.LBS + " "
                              : Util.CONST.KG + " ")}{" "}
                        - {p.stackable ? "Stackable" : "Not Stackable"}
                        <br />
                      </span>
                    );
                  })}
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan="6">
                <img src={require("../img/packing_list.png")} />
                <span style={{ marginLeft: "1em" }}>
                  <strong>
                    Pieces - Deck - Position - Weight/pc{" "}
                    {shipment.weight_system === Util.CONST.IMPERIAL
                      ? "(" + Util.CONST.LBS + ")"
                      : "(" + Util.CONST.KG + ")"}
                  </strong>
                </span>

                <br />

                {uld.map((u) => {
                  return (
                    <span style={{ marginLeft: "3.5em" }}>
                      <br />
                      {u.pieces > 1 ? u.pieces + " pcs" : u.pieces + " pc"} -
                      {u.deck === Util.CONST.MAIN
                        ? Util.CONST.MAIN_DECK
                        : Util.CONST.LOWER_DECK}{" "}
                      - {u.position}
                      {!u.weight || isNaN(u.weight)
                        ? ""
                        : " - " +
                          Util.formatAmount3(u.weight) +
                          " " +
                          (shipment.weight_system === Util.CONST.IMPERIAL
                            ? Util.CONST.LBS + " "
                            : Util.CONST.KG + " ")}
                      <br />
                    </span>
                  );
                })}
              </td>
            </tr>
          )}

          {config.airline_conditions && (
            <tr>
              <td colSpan="6">
                <img alt={"Conditions"} src={require("../img/condition.png")} />
                <span style={{ marginLeft: "1em" }}>
                  <strong> Airline Notes and Conditions </strong>
                </span>

                <br />

                <div style={{ marginLeft: "3.5em" }}>
                  <br />
                  <Interweave content={config.airline_conditions} />
                </div>
              </td>
            </tr>
          )}

          {config.sales_conditions && (
            <tr>
              <td colSpan="6">
                <img alt={"Conditions"} src={require("../img/condition.png")} />
                <span style={{ marginLeft: "1em" }}>
                  <strong> General Notes and Conditions </strong>
                </span>

                <br />

                <div style={{ marginLeft: "3.5em" }}>
                  <br />
                  <Interweave content={config.sales_conditions} />
                </div>
              </td>
            </tr>
          )}
        </table>
      </div>,
      <div
        style={{
          fontSize: "0.7em",
          borderRight: "0px",
          borderLeft: "0px",
          padding: "1.5em",
        }}
        ref={ref}
        id="modalPrint"
      >
        {header.status && header.status === "DRAFT" && (
          <>
            <div class="row">
              <div
                class="col-12"
                style={{ border: "1px solid red", color: "red" }}
              >
                <center>{Util.TEXTS.DRAFT_TEXT}</center>
              </div>
            </div>
            <br />
          </>
        )}

        {header.failure_reason_exp && (
          <>
            <div class="row">
              <div
                class="col-12"
                style={{ border: "1px solid red", color: "red" }}
              >
                <center>Rejection reason :</center>
                <Interweave content={formatText(header.failure_reason_exp)} />
              </div>
            </div>
            <br />
          </>
        )}

        <div
          class="row"
          style={{
            border: "1px solid black",
            borderBottom: "0px",
            borderRight: "0px",
            borderLeft: "0px",
            paddingTop: "1em",
            paddingBottom: "1em",
          }}
        >
          <div class="col-4" style={{ paddingLeft: "0.1em" }}>
            <strong>
              {" "}
              Reference for Booking :{" "}
              {header.adhoc_ref ? header.adhoc_ref : header.id}
            </strong>
          </div>

          <div class="col-4" style={{ textAlign: "center" }}>
            {config.logo && (
              <img
                src={config.logo}
                alt="logo"
                style={{ maxWidth: "12em", maxHeight: "5em" }}
              />
            )}
            <br />
          </div>

          <div
            class="col-4"
            style={{ textAlign: "right", paddingRight: "0.2em" }}
          >
            {display_user_name || display_email ? (
              <strong>Contact : </strong>
            ) : null}
            {display_user_name ? user_name : null}
            {display_user_name && display_email ? <br /> : null}
            {display_email ? display_email : null}
          </div>
        </div>

        <div
          class="row"
          style={{
            border: "1px solid black",
            borderTop: "0px",
            borderBottom: "0px",
            borderRight: "0px",
            borderLeft: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <div
            class="col-2"
            style={{
              textAlign: "center",
              background: quote_main_color,
              color: quote_font_color,
              marginRight: "2.2em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.8em",
              borderRadius : "1em"

            }}
          >
            <div>
              {pricing.carrier_name}
              <br />
              <strong>{pricing.carrier}</strong>
              <br />
              {!pricing.carrier_prefix
                ? ""
                : "AWB Prefix: " + pricing.carrier_prefix}
            </div>
          </div>

          <div
            class="col-2"
            style={{
              textAlign: "center",
              background: quote_main_color,
              color: quote_font_color,
              marginRight: "2.2em",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.8em",
              borderRadius : "1em"
            }}
          >
            <div >
              <strong>
                {shipment.exw ? "EXW" : shipment.origin} - {shipment.dest}
              </strong>
              {transits.length === 0 ? "" : <br />}
              {transits.length === 0 ? "" : <i>Via</i>}
              {transits.length === 0 ? "" : <br />}
              {transits.length === 0 ? "" : transits.join(", ")}
            </div>
          </div>

          <div
            class="col-2"
            style={{
              textAlign: "center",
              background: quote_main_color,
              color: quote_font_color,
              marginRight: "2.2em",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.8em",
              borderRadius : "1em"

            }}
          >
            <span>
              {shipment.dimension_type === Util.CONST.ULD
                ? Util.CONST.TOTAL + " "
                : ""}
              {pricing.all_in
                ? Util.CONST.ALL_IN
                : Util.CONST.NET_NET + (view ? "*" : "")}

              <br />

              <strong>
                {Util.formatAmount1(
                  pricing.per_kg ? pricing.price : pricing.price_total,
                  pricing.currency
                )}
                {pricing.per_kg ? Util.CONST.PER_KG : ""}
              </strong>

              {!shipment.commodity_code ? null : (
                <>
                  <br />
                  {shipment.commodity_code ? "Commodity" : null}
                  <br />
                  <strong>
                    {shipment.commodity_code ? shipment.commodity_code : null}{" "}
                  </strong>
                </>
              )}
            </span>
          </div>

          <div
            class="col-2"
            style={{
              textAlign: "center",
              background: quote_main_color,
              color: quote_font_color,
              marginRight: "2.2em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.8em",
              borderRadius : "1em"

            }}
          >
            <span>
              {!shipment.chargeable_weight
                ? ""
                : Util.TEXTS.CHARGEABLE_WEIGHT +
                  (is_different && pricing.all_in ? "*" : "") +
                  (is_different && !pricing.all_in
                    ? "*" + (view ? "*" : "")
                    : "")}
              <br />
              <strong>
                {Util.formatAmount4(shipment.chargeable_weight) +
                  (!shipment.chargeable_weight ? "" : " " + Util.CONST.KG)}
              </strong>
              {shipment.dimension_type === Util.CONST.ULD ? <br /> : null}
              {shipment.dimension_type === Util.CONST.ULD
                ? uld_price.map((u) => u.pieces + " " + u.position).join(" / ")
                : null}
            </span>
          </div>

          <div
            class="col-2"
            style={{
              textAlign: "center",
              background: quote_main_color,
              color: quote_font_color,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.8em",
              borderRadius : "1em"

            }}
          >
            <span>
              Validity
              <br />
              <strong>{Util.dateToString2(pricing.from_date)}</strong>
              <br />
              <strong>{Util.dateToString2(pricing.to_date)}</strong>
            </span>
          </div>
        </div>

        {!pricing.all_in && view && (
          <div class="row">
            <div class="col-3"></div>
            <div class="col-6" style={{ color: "red", textAlign: "center" }}>
              <strong>*{Util.TEXTS.SECURITY_FUEL_NOT_INCLUDES}</strong>
            </div>
            <div class="col-3"></div>
          </div>
        )}

        {is_different && (
          <div class="row">
            <div class="col-3"></div>
            <div class="col-6" style={{ color: "red", textAlign: "center" }}>
              <strong>
                {is_different
                  ? (!pricing.all_in && view ? "*" : "") +
                    "*" +
                    Util.TEXTS.ADJUST_WEIGHT
                  : ""}
              </strong>
            </div>
            <div class="col-3"></div>
          </div>
        )}

        <div
          class="row"
          style={{
            border: "1px solid black",
            borderTop: "0px",
            borderRight: "0px",
            borderLeft: "0px",
          }}
        >
          <div class="col-12" style={{ textAlign: "center", padding: "1em" }}>
            <strong>{!shipment.agent ? "" : "Agent : "}</strong>
            {shipment.agent}
          </div>
        </div>

        <div
          class="row"
          style={{
            border: "1px solid black",
            borderTop: "0px",
            borderBottom: "0px",
            borderRight: "0px",
            borderLeft: "0px",
          }}
        >
          <div
            class="col-6"
            style={{
              borderRight: "1px solid black",
              paddingTop: "1em",
              paddingLeft: "0.1em",
            }}
          >
            <img src={require("../img/charge.png")} />
            <strong>
              <span style={{ marginLeft: "1em" }}>Charges</span>
            </strong>

            <br />

            {shipment.dimension_type !== Util.CONST.ULD ? (
              <div style={{ marginLeft: "3.5em" }}>
                {pricing.all_in ? "All in Rate" : Util.CONST.NET_NET}
                {pricing.per_kg
                  ? " " + Util.CONST.PER_KG2 + " (Chg.Wgt)"
                  : " (Total)"}{" "}
                :
                {Util.formatAmount1(
                  pricing.per_kg ? pricing.price : pricing.price_total,
                  pricing.currency
                )}
                <br />
                {!pricing.all_in
                  ? !margin_per_kg.fuel_rate
                    ? ""
                    : "Fuel(" +
                      margin.fuel_code +
                      ") " +
                      Util.CONST.PER_KG2 +
                      " (" +
                      margin.fuel_type +
                      "): " +
                      Util.formatAmount1(
                        margin_per_kg.fuel_rate,
                        margin.fuel_currency
                      ) + 
                      (fuel_rates ? "-" + fuel_rates : "")
                  : ""}
                {!pricing.all_in && margin_per_kg.fuel_rate ? <br /> : ""}
                {!pricing.all_in
                  ? !margin_per_kg.security_rate
                    ? ""
                    : (margin.security_code === "XDC"
                        ? "Covid Surcharge"
                        : "Security") +
                      " (" +
                      margin.security_code +
                      ") " +
                      Util.CONST.PER_KG2 +
                      " (" +
                      margin.security_type +
                      "): " +
                      Util.formatAmount1(
                        margin_per_kg.security_rate,
                        margin.security_currency
                      ) +
                      (security_rates ? "-" + security_rates : "")
                  : ""}
                {!pricing.all_in && margin_per_kg.security_rate ? <br /> : ""}
                {!margin.sell_total
                  ? ""
                  : "Total Freight Charges: " +
                    Util.formatAmount1(
                      margin.sell_total,
                      pricing.currency
                    )}{" "}
                <br />
                {!pricing.min_chargeable_weight
                  ? ""
                  : "Mininum Chargeable Weight: " +
                    Util.formatAmount3(pricing.min_chargeable_weight) +
                    " " +
                    Util.CONST.KG}
                {!pricing.min_chargeable_weight ? "" : <br />}
                {/*kb {!pricing.min_amount
                  ? ""
                  : "Min Amount: " +
                    Util.formatAmount1(pricing.min_amount, pricing.currency)} */}
              </div>
            ) : (
              <div style={{ marginLeft: "3.5em" }}>
                {uld_price.map((u) => {
                  return (
                    <div>
                      {u.pieces} {u.position}: {u.selling_rate} {u.currency}
                      {u.per_kg ? Util.CONST.PER_KG : Util.CONST.PER_ULD}, Pivot{" "}
                      {u.pivot_weight} kg - O/P {u.over_pivot_rate}
                      <br />
                    </div>
                  );
                })}
                {pricing.all_in ? "All in Rate" : Util.CONST.NET_NET}
                {pricing.per_kg
                  ? " " + Util.CONST.PER_KG2 + " (Chg.Wgt)"
                  : " (Total)"}{" "}
                :
                {Util.formatAmount1(
                  pricing.per_kg ? pricing.price : pricing.price_total,
                  pricing.currency
                )}
                <br />
                {!pricing.all_in
                  ? !margin_per_kg.fuel_rate
                    ? ""
                    : "Fuel (" +
                      margin.fuel_code +
                      ") " +
                      Util.CONST.PER_KG2 +
                      " (" +
                      margin.fuel_type +
                      "): " +
                      Util.formatAmount1(
                        margin_per_kg.fuel_rate,
                        margin.fuel_currency
                      ) +
                      (fuel_rates ? "-" + fuel_rates : "")
                  : ""}
                {!pricing.all_in && margin_per_kg.fuel_rate ? <br /> : ""}
                {!pricing.all_in
                  ? !margin_per_kg.security_rate
                    ? ""
                    : "Security (" +
                      margin.security_code +
                      ") " +
                      Util.CONST.PER_KG2 +
                      " (" +
                      margin.security_type +
                      "): " +
                      Util.formatAmount1(
                        margin_per_kg.security_rate,
                        margin.security_currency
                      ) +
                      (security_rates ? "-" + security_rates : "")
                  : ""}
                {!pricing.all_in && margin_per_kg.security_rate ? <br /> : ""}
                {!margin.sell_total
                  ? ""
                  : "Total Freight Charges: " +
                    Util.formatAmount1(margin.sell_total, pricing.currency)}
                <br />
              </div>
            )}
          </div>

          <div class="col-6" style={{ paddingTop: "1em" }}>
            <img alt={"Packing List"} src={require("../img/packing_pc.png")} />
            <strong>
              <span style={{ marginLeft: "1em" }}>Shipment Details</span>
            </strong>

            <br />

            <span style={{ marginLeft: "3.5em" }}>
              {!shipment.pieces || shipment.pieces === "0"
                ? ""
                : Util.formatAmount3(shipment.pieces) + " piece(s), "}
              {Util.formatAmount3(shipment.gross_weight) +
                (!shipment.gross_weight ? "" : " " + Util.CONST.KG + ", ")}
              {shipment.volume}{" "}
              {!shipment.volume ? (
                ""
              ) : (
                <span>
                  m<sup>3</sup>,
                </span>
              )}
              {!shipment.density
                ? ""
                : " density " +
                  Util.formatDensity(shipment.density).split("|")[0]}
            </span>

            <br />

            {!shipment.shc[0] &&
            !shipment.shc[1] &&
            !shipment.shc[2] &&
            !shipment.shc[3] ? (
              ""
            ) : (
              <span style={{ marginLeft: "3.5em" }}>
                {shipment.shc
                  ? shipment.shc
                      .filter((s) => {
                        return s !== "";
                      })
                      .join(", ")
                  : ""}
                <br />
              </span>
            )}

            {shipment.dimension_type !== Util.CONST.ULD && (
              <span style={{ marginLeft: "3.5em" }}>
                Stackable: {shipment.stackable}
                <br />
              </span>
            )}

            <span style={{ marginLeft: "3.5em" }}>
              Main Deck: {shipment.main_deck ? Util.CONST.YES : Util.CONST.NO}
              <br />
            </span>

            {!shipment.nature_goods ? (
              ""
            ) : (
              <span style={{ marginLeft: "3.5em" }}>
                Nature of Goods: {shipment.nature_goods}
                <br />
              </span>
            )}

            {!shipment.commodity ? (
              ""
            ) : (
              <span style={{ marginLeft: "3.5em" }}>
                Commodity: {shipment.commodity}
                <br />
              </span>
            )}
          </div>
        </div>

        <div
          class="row"
          style={{
            border: "1px solid black",
            borderTop: "0px",
            borderRight: "0px",
            borderLeft: "0px",
          }}
        >
          <div
            class="col-6"
            style={{
              borderRight: "1px solid black",
              paddingTop: "1em",
              paddingLeft: "0.1em",
            }}
          >
            <img alt={"Charges"} src={require("../img/other_charge.png")} />
            <strong>
              <span style={{ marginLeft: "1em" }}>
                Other Charges Due Carrier
              </span>
            </strong>

            <br />

            {pricing.ocdc.map((o) => {
              return (
                <div style={{ marginLeft: "3.5em" }}>
                  {o.name} ({o.based_on}) - {o.value} - {o.currency}
                  <br />
                </div>
              );
            })}

            {pricing.ocdc.length > 0 && pricing.display_ocdc_total ? (
              <div style={{ marginLeft: "3.5em" }}>
                <strong>
                  Total Charges Due Carrier:{" "}
                  {parseFloat(sum_total_charge).toFixed(2)} {pricing.currency}*
                </strong>
                <br />
                <i style={{ fontSize: "0.8em" }}>*{Util.TEXTS.CHARGES_TEXT}</i>
              </div>
            ) : (
              ""
            )}

            {!pricing.ocdc || pricing.ocdc.length === 0 ? (
              <div style={{ marginLeft: "3.5em" }}>{Util.TEXTS.REFER_TEXT}</div>
            ) : (
              ""
            )}

            <br />
          </div>

          <div class="col-6" style={{ paddingTop: "1em", paddingRight: "0px" }}>
            <img
              alt={"Packing List"}
              src={require("../img/packing_list.png")}
            />

            {shipment.dimension_type !== Util.CONST.ULD ? (
              !packing || packing.length === 0 ? (
                <span style={{ marginLeft: "1em" }}>
                  <strong>{Util.TEXTS.NO_PACKING_TEXT}</strong>
                </span>
              ) : packing.length > 3 &&
                packing.length > 2 * pricing.ocdc.length ? (
                <span style={{ marginLeft: "1em" }}>
                  <strong>
                    Pieces - L*W*H{" "}
                    {shipment.length_system !== Util.CONST.IMPERIAL
                      ? "(" + Util.CONST.CM + ")"
                      : "(" + Util.CONST.INCH + ")"}{" "}
                    - Weight
                    {shipment.weight_system === Util.CONST.IMPERIAL
                      ? "(" + Util.CONST.LBS + ")"
                      : "(" + Util.CONST.KG + ")"}{" "}
                    - Stackability
                  </strong>
                  <br />
                  <span style={{ marginLeft: "3.5em" }}>
                    <i>{Util.TEXTS.SEE_PACKING_BELOW}</i>
                  </span>
                </span>
              ) : (
                <span>
                  <span style={{ marginLeft: "1em" }}>
                    <strong>
                      Pieces - L*W*H{" "}
                      {shipment.length_system !== Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.CM + ")"
                        : "(" + Util.CONST.INCH + ")"}{" "}
                      - Weight
                      {shipment.weight_system === Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.LBS + ")"
                        : "(" + Util.CONST.KG + ")"}{" "}
                      - Stackability
                    </strong>
                    <br />
                  </span>

                  {packing.map((p) => {
                    return (
                      <span style={{ marginLeft: "3.5em" }}>
                        {p.pieces > 1 ? p.pieces + " pcs" : p.pieces + " pc"} -{" "}
                        {p.length} * {p.width} * {p.height}
                        {!p.weight || isNaN(p.weight)
                          ? ""
                          : "- " +
                            Util.formatAmount3(p.weight) +
                            " " +
                            (shipment.weight_system === Util.CONST.IMPERIAL
                              ? Util.CONST.LBS + " "
                              : Util.CONST.KG + " ")}{" "}
                        - {p.stackable ? " Stackable" : " Not Stackable"}
                        <br />
                      </span>
                    );
                  })}
                </span>
              )
            ) : !uld || uld.length === 0 ? (
              <span style={{ marginLeft: "1em" }}>
                <strong>{Util.TEXTS.NO_ULD_TEXT}</strong>
              </span>
            ) : uld.length > 3 && uld.length > 2 * pricing.ocdc.length ? (
              <span style={{ marginLeft: "1em" }}>
                <strong>
                  Pieces - Deck - Position - Weight/pc{" "}
                  {shipment.weight_system === Util.CONST.IMPERIAL
                    ? "(" + Util.CONST.LBS + ")"
                    : "(" + Util.CONST.KG + ")"}
                </strong>
                <br />
                <span style={{ marginLeft: "3.5em" }}>
                  <i>{Util.TEXTS.SEE_ULD_BELOW}</i>
                </span>
              </span>
            ) : (
              <span>
                <span style={{ marginLeft: "1em" }}>
                  <strong>
                    Pieces - Deck - Position - Weight/pc{" "}
                    {shipment.weight_system === Util.CONST.IMPERIAL
                      ? "(" + Util.CONST.LBS + ")"
                      : "(" + Util.CONST.KG + ")"}
                  </strong>
                  <br />
                </span>

                {uld.map((u) => {
                  return (
                    <span style={{ marginLeft: "3.5em" }}>
                      {u.pieces > 1 ? u.pieces + " pcs" : u.pieces + " pc"} -
                      {u.deck === Util.CONST.MAIN
                        ? Util.CONST.MAIN_DECK
                        : Util.CONST.LOWER_DECK}{" "}
                      - {u.position}
                      {!u.weight || isNaN(u.weight)
                        ? ""
                        : "- " +
                          Util.formatAmount3(u.weight) +
                          " " +
                          (shipment.weight_system === Util.CONST.IMPERIAL
                            ? Util.CONST.LBS + " "
                            : Util.CONST.KG + " ")}
                      <br />
                    </span>
                  );
                })}
              </span>
            )}

            <br />
          </div>
        </div>

        {/* here KT-OPER */}
        {rountingHTML.length > 0 && (
          <div
            class="row"
            style={{
              border: "1px solid black",
              borderTop: "0px",
              borderRight: "0px",
              borderLeft: "0px",
              paddingTop: "1em",
            }}
          >
            <div
              class="col-12"
              style={{ paddingRight: "0px", paddingLeft: "0.1em" }}
            >
              <img alt={"Routing"} src={require("../img/plane_routing.png")} />
              <span style={{ marginLeft: "1em" }}>
                <strong>Routing</strong>
              </span>

              <br />
              <br />

              {/* Routing in customer template */}
              <table style={{ width: "100%" }}>
                <thead>
                  {store_routes.getState().detailed_info ? (
                    <tr style={{ background: "#F2F3F4" }}>
                      <th>Brd Point</th>
                      <th>Off Point</th>
                      <th>Carrier</th>
                      <th>Flight Number</th>
                      <th>Oper Type</th>
                      <th>Flight Date</th>
                      <th>ETD</th>
                      <th>ETA</th>
                      <th>Ship. Split</th>
                    </tr>
                  ) : (
                    <tr style={{ background: "#F2F3F4" }}>
                      <th>Brd Point</th>
                      <th>Off Point</th>
                      <th>Carrier</th>
                      <th>Oper. Type</th>
                      <th>Weekly Frequency</th>
                      <th>Operation DOW</th>
                    </tr>
                  )}
                </thead>

                {rountingHTML}

            
                {expectedTime !== undefined &&
                  expectedTime !== null &&
                  expectedTime !== 0 &&
                  expectedTime &&
                  routes.length !== 1 && (
                    <span className="days-expected">
                      Expected {routes[0].origin} -{" "}
                      {routes[routes.length - 1].dest} time is {expectedTime}{" "}
                      {expectedTime === 1 ? "day" : "days"}
                    </span>
                  )}
              </table>

              <br />
            </div>
          </div>
        )}

        {gha_info && gha_info.length > 0 ?

          <div
            class="row"
            style={{
              border: "1px solid black",
              borderTop: "0px",
              borderRight: "0px",
              borderLeft: "0px",
              paddingTop: "1em",
            }}
          >
            <br />
            <div class="col-12" style={{ paddingLeft: "0.1em" }}>
              <img alt={"GHA"} src={require("../img/delivery_man.png")} />
              <span style={{ marginLeft: "1em" }}>
                <strong>GHA information</strong>
              </span>
              <br />
              {gha_info.map((g,index)=> {
                console.log('##freeINDex', index)
                return(
                <>
                <div class = "row" style={{ marginLeft: "3.5em" }}>
                  <div class = "col-6">
                    <strong>Name {g.oper_type ? "| " + g.oper_type : ""} : </strong>{g.gha_name ? mapGHA(g.gha_name) : ""}
                      <br/>
                    <strong>Adress : </strong>{g.gha_address ? mapGHA(g.gha_address) : ""}
                  </div>
          
                  <div class="col-6">
                    <strong>Opening Hours : </strong>{g.gha_opening_hours ? mapGHA(g.gha_opening_hours) : "" }
                      <br/>
                    <strong>Additional Information : </strong>{g.additional_infos ? mapGHA(g.additional_infos) : "" }             
                  </div>
                </div>
               {gha_info.length > 1  && index < gha_info.length - 1 ? <hr style={{border: '1px dotted' }}/> : null}
                </>
                  )

                })
              }
              <br />
            </div>
      
          </div> : null
        }

        {remarks.ext_remarks && (
          <div
            class="row"
            style={{
              border: "1px solid black",
              borderTop: "0px",
              borderRight: "0px",
              borderLeft: "0px",
              paddingTop: "1em",
            }}
          >
            <br />
            <div class="col-12" style={{ paddingLeft: "0.1em" }}>
              <img alt={"Remarks"} src={require("../img/remark.png")} />
              <span style={{ marginLeft: "1em" }}>
                <strong>Remarks</strong>
              </span>

              <br />

              <div style={{ marginLeft: "3.5em" }}>
                <Interweave content={remarks.ext_remarks} />
                <br />
              </div>

              <br />
            </div>
          </div>
        )}

        {shipment.dimension_type === Util.CONST.ULD
          ? uld.length > 3 &&
            uld.length > 2 * pricing.ocdc.length && (
              <div
                class="row"
                id="section-shipment"
                style={{
                  border: "1px solid black",
                  borderTop: "0px",
                  borderRight: "0px",
                  borderLeft: "0px",
                  paddingTop: "1em",
                }}
              >
                <br />

                <div class="col-12" style={{ paddingLeft: "0.1em" }}>
                  <img src={require("../img/packing_list.png")} />
                  <span style={{ marginLeft: "1em" }}>
                    <strong>
                      Pieces - Deck - Position - Weight/pc{" "}
                      {shipment.weight_system === Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.LBS + ")"
                        : "(" + Util.CONST.KG + ")"}
                    </strong>
                  </span>

                  <br />

                  {uld.map((u) => {
                    return (
                      <span style={{ marginLeft: "3.5em" }}>
                        {u.pieces > 1 ? u.pieces + " pcs" : u.pieces + " pc"} -
                        {u.deck === Util.CONST.MAIN
                          ? Util.CONST.MAIN_DECK
                          : Util.CONST.LOWER_DECK}{" "}
                        - {u.position}
                        {!u.weight || isNaN(u.weight)
                          ? ""
                          : "- " +
                            Util.formatAmount3(u.weight) +
                            " " +
                            (shipment.weight_system === Util.CONST.IMPERIAL
                              ? Util.CONST.LBS + " "
                              : Util.CONST.KG + " ")}
                        <br />
                      </span>
                    );
                  })}

                  <br />
                </div>
              </div>
            )
          : packing.length > 3 &&
            packing.length > 2 * pricing.ocdc.length && (
              <div
                class="row"
                id="section-shipment"
                style={{
                  border: "1px solid black",
                  borderTop: "0px",
                  borderRight: "0px",
                  borderLeft: "0px",
                  paddingTop: "1em",
                }}
              >
                <br />

                <div class="col-12" style={{ paddingLeft: "0.1em" }}>
                  <img
                    alt={"Packing List"}
                    src={require("../img/packing_list.png")}
                  />
                  <span style={{ marginLeft: "1em" }}>
                    <strong>
                      Pieces - L*W*H{" "}
                      {shipment.length_system !== Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.CM + ")"
                        : "(" + Util.CONST.INCH + ")"}{" "}
                      - Weight
                      {shipment.weight_system === Util.CONST.IMPERIAL
                        ? "(" + Util.CONST.LBS + ")"
                        : "(" + Util.CONST.KG + ")"}{" "}
                      - Stackability
                    </strong>
                  </span>

                  <br />

                  {packing.map((p) => {
                    return (
                      <span
                        style={{
                          marginLeft: "3.5em",
                        }}
                      >
                        {p.pieces > 1 ? p.pieces + " pcs" : p.pieces + " pc"} -{" "}
                        {p.length} * {p.width} * {p.height}
                        {!p.weight || isNaN(p.weight)
                          ? ""
                          : "- " +
                            Util.formatAmount3(p.weight) +
                            " " +
                            (shipment.weight_system === Util.CONST.IMPERIAL
                              ? Util.CONST.LBS + " "
                              : Util.CONST.KG + " ")}{" "}
                        - { p.stackable ? "Stackable" : "Not Stackable"}
                        <br />
                      </span>
                    );
                  })}

                  <br />
                </div>
              </div>
            )}

        <div
          class="row"
          id="section-notes"
          style={{
            border: "1px solid black",
            borderTop: "0px",
            borderRight: "0px",
            borderLeft: "0px",
            paddingTop: "1em",
          }}
        >
          <div class="col-12" style={{ paddingLeft: "0.1em" }}>
            {config.airline_conditions && (
              <div>
                <img alt={"Conditions"} src={require("../img/condition.png")} />
                <span style={{ marginLeft: "1em" }}>
                  <strong>Airline Notes and Conditions </strong>
                </span>

                <br />

                <div style={{ marginLeft: "3.5em" }}>
                  <Interweave content={config.airline_conditions} />
                </div>

                <br />
              </div>
            )}

            {config.sales_conditions && (
              <div>
                <img alt={"Conditions"} src={require("../img/condition.png")} />
                <span style={{ marginLeft: "1em" }}>
                  <strong>General Notes and Conditions </strong>
                </span>

                <br />

                <div style={{ marginLeft: "3.5em" }}>
                  <Interweave content={config.sales_conditions} />
                </div>

                <br />
              </div>
            )}
          </div>
        </div>
      </div>,
      <div
        style={{
          fontSize: "0.7em",
          borderRight: "0px",
          borderLeft: "0px",
          padding: "1.5em",
        }}
        ref={ref1}
        id="temp"
      ></div>,
    ];
  }
}


class AutocompleteAgent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w2 = watch(store_shipment.getState, "agent_suggestion");
    store_shipment.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_modals.getState, "modalAgent");
    store_modals.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onKeyUp(e) {
    let shipment = store_shipment.getState();

    if (
      e.keyCode !== 8 &&
      !(
        (e.keyCode >= 48 && e.keyCode <= 90) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      )
    )
      return;

    let v = document.getElementById("autocomplete_agent").value;
    v = v ? v.replace(/'/g, "") : "";
    //if(!v) return
    let authorized_stations = store_station.getState().authorized_stations;
    let station = authorized_stations.filter(
      (s) => s.station === store_header.getState().station
    )[0];
    let params = {
      agent: v,
      branch: shipment.agent_city,
      sales_region:
        station && station.sales_region
          ? station.sales_region
          : cookies.sales_region,
    };

    let agent_suggestion = await Routes.get("/Agent", params, true);

    agent_suggestion = agent_suggestion ? agent_suggestion : [];

    store_shipment.dispatch({
      type: 1,
      agent_suggestion: agent_suggestion,
      agent_code:
        v === "" || store_shipment.getState().agent !== v ? "" : undefined,
      agent: v,
    });
  }

  onChange(e, v) {
    if (!v) return;
    store_shipment.dispatch({
      type: 1,
      agent: v.name + " - " + v.branch + " - " + v.city + " - " + v.code,
      agent_code: v.code,
    });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
  }

  render() {
    let state_shipment = store_shipment.getState();

    return (
      <Autocomplete
        id="autocomplete_agent"
        options={state_shipment.agent_suggestion}
        renderInput={(params) => {
          return (
            <div>
              <TextField
                {...params}
                delay-hide={1000}
                data-background-color={Util.COLORS.RED_TOOLTIP}
                data-type={store_errors.getState().agent ? "error" : "warning"}
                data-tip={
                  store_errors.getState().agent
                    ? store_errors.getState().agent
                    : store_errors.getState().agent_code
                    ? store_errors.getState().agent_code
                    : ""
                }
                class="form-control"
                margin="normal"
                placeholder={state_shipment.agent}
                fullWidth
              />
              <ReactTooltip place="top" effect="solid" delayHide={500} />
            </div>
          );
        }}
        onKeyUp={async (e) => {
          this.onKeyUp(e);
        }}
        onChange={async (e, v) => {
          this.onChange(e, v);
        }}
        size="small"
        style={{ fontSize: "0.2em" }}
        getOptionLabel={function (option) {
          return (
            option.name +
            " - " +
            option.branch +
            " - " +
            option.city +
            " - " +
            option.code
          );
        }}
        renderOption={function (option) {
          let agent =
            option.name +
            " - " +
            option.branch +
            " - " +
            option.city +
            " - " +
            option.code;
          let agent_query = store_shipment.getState().agent;
          agent = Util.AgentFormat(agent, agent_query);
          let agent_a = <Interweave content={agent} />;
          return agent_a;
        }}
      />
    );
  }
}

class AutocompleteAgentCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w2 = watch(store_calculator.getState, "agent_suggestion");
    store_calculator.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_modals.getState, "modalAgentCalculator");
    store_modals.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onKeyUp(e) {
    let calculator = store_calculator.getState();

    if (
      e.keyCode !== 8 &&
      !(
        (e.keyCode >= 48 && e.keyCode <= 90) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      )
    )
      return;

    let v = document.getElementById("autocomplete_agent_calculator").value;
    v = v ? v.replace(/'/g, "") : "";

    let authorized_stations = store_station.getState().authorized_stations;
    let station = authorized_stations.filter(
      (s) => s.station === store_calculator.getState().station
    )[0];
    let params = {
      agent: v,
      branch: calculator.agent_city,
      sales_region:
        station && station.sales_region
          ? station.sales_region
          : cookies.sales_region,
    };

    let agent_suggestion = await Routes.get("/Agent", params, true);

    agent_suggestion = agent_suggestion ? agent_suggestion : [];

    store_calculator.dispatch({
      type: 1,
      agent_suggestion: agent_suggestion,
      agent_code:
        v === "" || store_calculator.getState().agent !== v ? "" : undefined,
      agent: v,
    });
  }

  async onChange(e, v) {
    if (!v) return;
    store_calculator.dispatch({
      type: 1,
      agent: v.name + " - " + v.branch + " - " + v.city + " - " + v.code,
      agent_code: v.code,
    });
    await calculateCost();
  }

  render() {
    let state_calculator = store_calculator.getState();

    return (
      <Autocomplete
        id="autocomplete_agent_calculator"
        options={state_calculator.agent_suggestion}
        renderInput={(params) => {
          return (
            <div>
              <TextField
                {...params}
                class="form-control"
                margin="normal"
                placeholder={state_calculator.agent}
                fullWidth
              />
              <ReactTooltip place="top" effect="solid" delayHide={500} />
            </div>
          );
        }}
        onKeyUp={async (e) => {
          this.onKeyUp(e);
        }}
        onChange={async (e, v) => {
          this.onChange(e, v);
        }}
        size="small"
        style={{ fontSize: "0.2em" }}
        getOptionLabel={function (option) {
          return (
            option.name +
            " - " +
            option.branch +
            " - " +
            option.city +
            " - " +
            option.code
          );
        }}
        renderOption={function (option) {
          let agent =
            option.name +
            " - " +
            option.branch +
            " - " +
            option.city +
            " - " +
            option.code;
          let agent_query = store_calculator.getState().agent;
          agent = Util.AgentFormat(agent, agent_query);
          let agent_a = <Interweave content={agent} />;
          return agent_a;
        }}
      />
    );
  }
}

class StatusDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.STATUS = Util.STATUS;
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    store_header.subscribe(() => {
      this.setState({ key: Date.now() });
    });

    let w1 = watch(store_routes.getState, "changed");
    store_routes.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        //if(oldVal!==newVal)
        this.setState({ key: Date.now() });
      })
    );

    restrictInputAccess();
  }

  async onChange(e) {
    let header = store_header.getState();

    if ((!checkMandatory() || routeHasEmpty()) && e.target.value !== "DRAFT")
      return;

    store_header.dispatch({ type: 1, status: e.target.value });

    if (e.target.value === "REJECTED")
      store_modals.dispatch({ type: 1, modalFail: true });

    if (e.target.value !== "REJECTED")
      store_header.dispatch({ type: 1, failure_reason_exp: "" });

    if (e.target.value === "DELETED")
      store_modals.dispatch({ type: 1, modalDeleteStatus: true });

    if (
      header.follow_up === true &&
      e.target.value !== "QUOTED" &&
      e.target.value !== "QUEUED"
    ) {
      await util.sleep(100);
      followUp();
    }
  }

  render() {
    let header = store_header.getState();
    let { status } = header;
    let { key } = this.state;
    let bgcolor = "";
    let status_props = util.STATUS.filter((s) => s.name === status)[0];

    if (!status_props)
      status_props = util.STATUS.filter((s) => s.name === Util.CONST.DRAFT)[0];

    bgcolor = status_props.color;

    let style = {
      fontSize: "0.7em",
      backgroundColor: bgcolor,
      color: header.status === "QUOTED" ? "black" : "white",
    };

    let price = store_pricing.getState().per_kg
      ? store_pricing.getState().price
      : store_pricing.getState().price_total;

    return (
      <div
        data-tip={
          !checkMandatory() || routeHasEmpty()
            ? Util.TEXTS.MANDATORY_FIELDS_TEXT
            : null
        }
        delay-hide={1000}
      >
        <select
          id="header-status"
          class="form-control dropdown"
          tabindex="-1"
          style={style}
          key={key}
          onChange={async (e) => {
            this.onChange(e);
          }}
        >
          <option>{header.status}</option>

          {this.STATUS.filter((s) => s.name !== "QUEUED").map((s) => {
            s = s.name;

            if (s === header.status) return;

            if (
              header.external_id &&
              header.user &&
              header.user === Util.CONST.CARGOAI &&
              s === "QUOTED" &&
              (header.status === "REJECTED" || header.status === "CANCELLED")
            )
              return <option>{s}</option>;
            else if (
              header.external_id &&
              header.user &&
              header.user === Util.CONST.CARGOAI &&
              header.status === "QUEUED" &&
              s === "REJECTED"
            )
              return <option>{s}</option>;
            else if (
              header.external_id &&
              header.user &&
              header.user === Util.CONST.CARGOAI &&
              header.status === "QUOTED"
            )
              return <option>{s}</option>;
            else if (
              header.external_id &&
              header.user &&
              header.user === Util.CONST.CARGOAI
            )
              return <option disabled="true">{s}</option>;
            else if (s !== "DRAFT" && !price)
              return <option disabled="true">{s}</option>;
            else if (s === "EXPIRED")
              return <option disabled="true">{s}</option>;
            else if (
              (s === "DELETED" &&
                cookies.role !== "admin" &&
                cookies.role !== "station_admin") ||
              (header.external_id &&
                header.user &&
                header.user === Util.CONST.CARGOAI)
            )
              return <option disabled="true">{s}</option>;
            else return <option>{s}</option>;
          })}
        </select>
        <ReactTooltip place="top" type="error" effect="solid" delayHide={500} />
      </div>
    );
  }
}

class SimilarButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    let w1 = watch(store_shipment.getState, "origin");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_shipment.getState, "dest");
    store_shipment.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onClickMore() {
    let { carrier } = store_pricing.getState();
    let {
      origin,
      dest,
      commodity_code,
      gross_weight,
      volume,
      chargeable_weight,
      dimension_type,
    } = store_shipment.getState();
    let { id, station } = store_header.getState();

    if (origin && dest) {
      store_modals.dispatch({ type: 1, page_loading_spinner: true });

      let res2 = await Routes.getQuotes({
        limit: 30,
        station: station,
        exclude_id: id,
        exclude_status: "QUEUED,DRAFT",
        origin: origin,
        dest: dest,
        user_follow_up: cookies.account,
      });

      if (
        carrier &&
        commodity_code &&
        (gross_weight ||
          (dimension_type === Util.CONST.ULD && chargeable_weight)) &&
        volume &&
        station
      ) {
        let parmas = {
          exclude_id: id,
          region: cookies.region,
          station: station,
          origin: origin,
          dest: dest,
          carrier: carrier,
          gross_weight: gross_weight,
          chargeable_weight: chargeable_weight,
          volume: volume,
          commodity_code: commodity_code,
          dimension_type: dimension_type,
        };

        let similars = await Routes.get("/SimilarQuotes", parmas, true);

        store_similar.dispatch({ type: 1, similarQuotes: similars.quotes });
      }

      store_similar.dispatch({ type: 1, prices: res2.quotes });

      store_modals.dispatch({
        type: 1,
        page_loading_spinner: false,
        ModalSimilar: true,
      });

      let logs = {
        type: "MORE",
        info: id ? id : "",
      };

      await Routes.get("/Logger", logs, true);
    }
  }

  render() {
    let { origin, dest } = store_shipment.getState();

    return (
      <button
        key={this.state.key}
        delay-hide={1000}
        data-background-color={Util.COLORS.RED_TOOLTIP}
        data-type="error"
        data-tip={origin && dest ? "" : Util.TEXTS.ORIGIN_DEST_FILL_TEXT}
        type="button"
        class="btn btn-primary"
        tabindex="0"
        onClick={async () => {
          this.onClickMore();
        }}
      >
        More
        <ReactTooltip place="top" type="error" effect="solid" delayHide={500} />
      </button>
    );
  }
}

class DensityField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    let w1 = watch(store_shipment.getState, "gross_weight");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_shipment.getState, "volume");
    store_shipment.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let shipment = store_shipment.getState();

    return (
      <div class="col-md-2 col-sm-6" key={this.state.key}>
        <label>Density</label>
        <input
          type="text"
          tabindex="0"
          class="form-control "
          maxlength="10"
          disabled
          defaultValue={Util.formatDensity(shipment.density)}
        />
      </div>
    );
  }
}

class ShipmentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      current_input: null,
    };
  }

  componentDidUpdate() {
    let current_input = store_errors.getState().current_input;
    let input = document.getElementById(current_input);
    if (input) input.focus();
  }

  componentDidMount() {
    let w1 = watch(store_shipment.getState, "force");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_errors.getState, "volume");
    store_errors.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('errors volume %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_errors.getState, "origin");
    store_errors.subscribe(
      w3((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w4 = watch(store_errors.getState, "dest");
    store_errors.subscribe(
      w4((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w5 = watch(store_errors.getState, "gross_weight");
    store_errors.subscribe(
      w5((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w7 = watch(store_errors.getState, "chargeable_weight");
    store_errors.subscribe(
      w7((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w8 = watch(store_errors.getState, "pieces");
    store_errors.subscribe(
      w8((newVal, oldVal, objectPath) => {
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w9 = watch(store_shipment.getState, "gross_weight");
    store_shipment.subscribe(
      w9((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w10 = watch(store_shipment.getState, "volume");
    store_shipment.subscribe(
      w10((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let wA11 = watch(store_shipment.getState, "chargeable_weight");
    store_shipment.subscribe(
      wA11((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onChangeOrigin(e) {
    let { exw } = store_shipment.getState();
    let { routes } = store_routes.getState();

    store_shipment.dispatch({ type: 1, origin: e.target.value });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });

    if (routes.length > 0 && !exw) {
      store_routes.dispatch({
        type: 1,
        force: true,
        index: 0,
        origin: e.target.value,
      });
    }

    store_similar.dispatch({ type: 1, exact: false, similar: false });
  }

  async onDestinationChange(e) {
    store_shipment.dispatch({ type: 1, dest: e.target.value });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
    store_similar.dispatch({ type: 1, exact: false, similar: false });
  }

  onChangeEXW(e) {
    let shipment = store_shipment.getState();
    
    store_shipment.dispatch({ type: 1, exw: e.target.checked });

    

    let { origin } = store_shipment.getState();
    let { routes } = store_routes.getState();

    if (e.target.checked) {
      if (routes.length < 2)
        store_routes.dispatch({ type: 1, addRoute: true, force: true });

      store_routes.dispatch({
        type: 1,
        force: true,
        index: 0,
        origin: "EXW",
        flight_type: "Truck",
      });
    } else {
      if (routes.length >= 1)
        store_routes.dispatch({
          type: 1,
          force: true,
          index: 0,
          origin: origin,
          dest: "",
          flight_type: "Flight",
        });

      if (routes.length === 2 && !routes[1].origin && !routes[1].dest)
        store_routes.dispatch({
          type: 1,
          removeRoute: true,
          index: 1,
          force: true,
        });
    }
   }

  onChangeVolume(e) {
    store_margin.dispatch({ type: 1, updateMargin: true });
    store_shipment.dispatch({
      type: 1,
      volume: e.target.value.replace(",", "."),
    });
    store_similar.dispatch({ type: 1, exact: false, similar: false });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
  }

  onChangeSHC(e, i) {
    let { sales_region, authorized_stations } = store_station.getState();
    let { carrier } = store_pricing.getState();
    let header = store_header.getState();
    let station = authorized_stations.filter(
      (s) => s.station === header.station
    )[0];

    sales_region = station ? station.sales_region : sales_region;
    store_shipment.dispatch({ type: 1, index: i, shc: e.target.value });
    store_shipment.dispatch({
      type: 1,
      calculate_price_class: true,
      carrier: carrier,
      sales_region: sales_region,
    });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
    store_similar.dispatch({ type: 1, exact: false, similar: false });
  }

  onDimensionClick() {
    let shipment = store_shipment.getState();
    let packing_copy = [...shipment.packing];
    let uld_copy = [...shipment.uld];
    let all_stackable_copy = shipment.all_stackable;
    let length_system_copy = shipment.length_system;
    let weight_system_copy = shipment.weight_system;
    let packing_stackable_copy = shipment.packing_stackable;

    store_shipment.dispatch({
      type: 1,
      packing_copy: packing_copy,
      uld_copy: uld_copy,
      all_stackable_copy: all_stackable_copy,
      weight_system_copy: weight_system_copy,
      length_system_copy: length_system_copy,
      packing_stackable_copy: packing_stackable_copy,
    });

    store_modals.dispatch({ type: 1, modalDimensions: true });
  }

  onPiecesChange(e) {
    let pieces = e.target.value.replace(",", "")
    pieces = isNaN(parseInt(pieces)) ? "" : pieces
    store_shipment.dispatch({
      type: 1,
      pieces: pieces,
      p: true,
    });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
    store_margin.dispatch({ type: 1, updateMargin: true });
  }

  onChangeGrossWeight(e) {
    store_shipment.dispatch({
      type: 1,
      gross_weight: e.target.value.replace(",", "."),
    });
    store_margin.dispatch({ type: 1, updateMargin: true });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
    store_similar.dispatch({ type: 1, exact: false, similar: false });
  }

  onChangeChargeableWeight(e) {
    store_shipment.dispatch({ type: 1, chargeable_weight: e.target.value });
    this.isMinCW() 
    store_margin.dispatch({ type: 1, updateMargin: true });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
  }



  isMinCW() {

    let { packing, chargeable_weight, gross_weight, is_min_chargeable_weight } = store_shipment.getState();

    if (is_min_chargeable_weight) {
        
        const totalPieces = packing.reduce((acc, curr) => acc + curr.pieces, 0);
        const totalLength = packing.reduce((acc, curr) => acc + curr.length, 0);
        const totalWidth = packing.reduce((acc, curr) => acc + curr.width, 0);
        const totalHeight = packing.reduce((acc, curr) => acc + curr.height, 0);

        let volumeWeight = totalPieces * (totalLength / 100) * (totalWidth / 100) * (totalHeight / 100) * 166.66;

        let set_min_cw = null;

        chargeable_weight !== gross_weight && chargeable_weight !== volumeWeight
            ? (set_min_cw = chargeable_weight)
            : (set_min_cw = null);

        store_pricing.dispatch({
            type: 1,
            force: true,
            min_chargeable_weight: set_min_cw,
        });
    } else {
        store_pricing.dispatch({
            type: 1,
            force: true,
            min_chargeable_weight: null,
        });
    }

}

  async onClickSkypalletIcon(e) {
    let shipment = store_shipment.getState();
    let header = store_header.getState();
    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    store_shipment.dispatch({
      type: 1,
      skypallet_id: shipment.skypallet_id ? shipment.skypallet_id : "",
    });
    let id = await saveQuote();
    store_modals.dispatch({ type: 1, page_loading_spinner: false });

    if (!id) {
      return;
    } else {
      document.getElementById("skypallet_ref").href =
        config.skypallet_end_point + config.skypallet_query + id;
      document.getElementById("skypallet_ref").click();
      store_modals.dispatch({ type: 1, modalRefresh: true });

      let logs = {
        type: "SEND_TO_SKYPALLET",
        info: header.id ? header.id : "",
      };

      await Routes.get("/Logger", logs, true);
    }
  }

  onClickInfo(e) {
    let shipment = store_shipment.getState();

    if (!shipment.skypallet_id) {
      return;
    } else {
      let skypallet_id = shipment.skypallet_id;
      document.getElementById("skypallet_ref").href =
        config.skypallet_end_point + skypallet_id + "/result";
      document.getElementById("skypallet_ref").click();
    }
  }

  onChangeStackabale(e) {
    store_shipment.dispatch({ type: 1, stackable: e.target.value });

    if (
      (e.target.value === Util.CONST.PARTIALLY ||
        e.target.value === Util.CONST.NO) &&
      store_shipment.getState().packing.length === 0
    ) {
      store_modals.dispatch({ type: 1, modalDimensions: true });
    }
  }

  checkPackingList() {
    let { packing, gross_weight } = store_shipment.getState();
    let result = true;
    let empty_check = false;
    let fill_check = false;

    if (!packing || packing.length === 0) {
      result = false;
      return result;
    }

    if (!gross_weight) {
      result = false;
      return result;
    }

    for (let i = 0; i < packing.length; i++) {
      if (
        !packing[i].pieces ||
        !packing[i].length ||
        !packing[i].width ||
        !packing[i].height
      ) {
        result = false;
      }

      if (!packing[i].weight) {
        empty_check = true;
      } else {
        fill_check = true;
      }
    }

    if (empty_check && fill_check) {
      result = false;
    }

    return result;
  }


  render() {
    let shipment = store_shipment.getState();
    let errors = store_errors.getState();
    let modals = store_modals.getState();
    let header = store_header.getState();
    let { attachments } = store_remarks.getState(); 
    let pricing = store_pricing.getState();
   
    // let station = this.props.authorized_stations.filter((s)=> s.station === header.station  )[0]


    return (
      <div class="card col" key={this.state.key}>
        <div class="card-header">
          <h5 class="">
            <img src={require("../img/box.png")} alt={"Shipment"} /> Shipment
          </h5>
        </div>

        <div class="card-body shipment-section">
          <div class="row">
            <div class="col-md-2 col-sm-6">
              <label>Branch Apt.</label>
              <input
                id="shipment_branch"
                type="text"
                tabindex="0"
                class="form-control"
                defaultValue={shipment.agent_city}
                onChange={(e) => {
                  store_shipment.dispatch({
                    type: 1,
                    agent_city: e.target.value,
                  });
                }}
                maxlength="3"
              />
            </div>

            <div
              class={
                "col-md-5 col-sm-8 important-input  " +
                (errors.agent
                  ? "is-invalid"
                  : errors.agent_code
                  ? "is-warning"
                  : "")
              }
            >
              <label>Agent*</label>
              <AutocompleteAgent />
            </div>

            <div
              class="col-md-1 col-sm-4"
              style={{ textAlign: "center", marginTop: "0.4em", padding: "0" }}
            >
              <FormControlLabel
                value="bottom"
                control={
                  <Checkbox
                    defaultChecked={shipment.exw}
                    color="primary"
                    onChange={(e) => this.onChangeEXW(e)}
                  />
                }
                label="EXW"
                labelPlacement="top"
              />
            </div>

            <div class="col-md-2 col-sm-4 important-input">
              <label>Origin*</label>
              <input
                id="shipment_origin"
                type="text"
                tabindex="0"
                class={
                  "form-control  " +
                  (errors.origin !== "" && errors.origin !== undefined
                    ? "is-invalid"
                    : "")
                }
                defaultValue={shipment.origin}
                onFocus={(e) =>
                  store_errors.dispatch({ type: 1, current_input: e.target.id })
                }
                onBlur={(e) => {
                  store_errors.dispatch({ type: 1, current_input: "" });
                }}
                onChange={this.onChangeOrigin}
                delay-hide={1000}
                data-tip={errors.origin !== "" ? errors.origin : ""}
                data-background-color={Util.COLORS.RED_TOOLTIP}
                data-type="error"
                maxlength="3"
              />
              <ReactTooltip place="top" effect="solid" delayHide={500} />
            </div>

            <div class="col-md-2 col-sm-4 important-input">
              <label>Destination*</label>
              <input
                id="shipment_dest"
                type="text"
                tabindex="0"
                class={
                  "form-control " +
                  (errors.dest !== "" && errors.dest !== undefined
                    ? "is-invalid"
                    : "")
                }
                defaultValue={shipment.dest}
                onChange={(e) => {
                  this.onDestinationChange(e);
                }}
                onFocus={(e) =>
                  store_errors.dispatch({ type: 1, current_input: e.target.id })
                }
                onBlur={(e) => {
                  store_errors.dispatch({ type: 1, current_input: "" });
                }}
                maxlength="3"
                data-background-color={Util.COLORS.RED_TOOLTIP}
                data-type="error"
                delay-hide={1000}
                data-tip={errors.dest !== "" ? errors.dest : ""}
              />
              <ReactTooltip
                place="top"
                type="error"
                effect="solid"
                delayHide={500}
              />
            </div>
          </div>

          <br />

          <div class="row">
            <Button
              class="col btn btn-secondary "
              onClick={() => {
                this.onDimensionClick();
              }}
            >
              Dimensions
            </Button>

            <ModalUpdatePacking />
            <ModalRefresh />
            <ModalDimension
              show={modals.modalDimensions}
              custom_list={this.props.custom_list}
            />

            <div class="col-md-1 col-sm-6">
              <label>Pieces</label>
              <input
                type="number"
                tabindex="0"
                id="shipment-pieces"
                class={
                  "form-control " +
                  ((errors.pieces || errors.pieces_mismatch) && "is-invalid")
                }
                placeholder=""
                defaultValue={shipment.pieces}
                maxlength="3"
                onChange={(e) => {
                  this.onPiecesChange(e);
                }}
                data-background-color={Util.COLORS.RED_TOOLTIP}
                data-type="error"
                delay-hide={1000}
                data-tip={
                  errors.pieces
                    ? errors.pieces
                    : errors.pieces_mismatch
                    ? errors.pieces_mismatch
                    : ""
                }
              />
              <ReactTooltip
                place="top"
                type="error"
                effect="solid"
                delayHide={500}
              />
            </div>

            <div class="col-md-2 col-sm-6 important-input">
              <label>
                Gross Weight
                {shipment.dimension_type === Util.CONST.ULD ? "" : "*"}
              </label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  tabindex="0"
                  id="shipment_gross_weight"
                  disabled={
                    shipment.dimension_type === Util.CONST.ULD ? true : false
                  }
                  class={
                    "form-control " +
                    ((errors.weight_mismatch || errors.gross_weight) &&
                      "is-invalid")
                  }
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  maxlength="7"
                  defaultValue={shipment.gross_weight}
                  onChange={(e) => {
                    this.onChangeGrossWeight(e);
                  }}
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                  onBlur={(e) => {
                    store_errors.dispatch({ type: 1, current_input: null });
                  }}
                  data-background-color={Util.COLORS.RED_TOOLTIP}
                  data-type="error"
                  delay-hide={1000}
                  data-tip={
                    errors.gross_weight
                      ? errors.gross_weight
                      : errors.weight_mismatch
                      ? errors.weight_mismatch
                      : ""
                  }
                />
                <ReactTooltip
                  place="top"
                  type="error"
                  effect="solid"
                  delayHide={500}
                />

                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    kg
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-sm-6 important-input">
              <label>Volume*</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  tabindex="0"
                  id="shipment-volume"
                  class={
                    "form-control " +
                    ((errors.volume_mismatch || errors.volume) && "is-invalid")
                  }
                  maxlength="10"
                  defaultValue={shipment.volume}
                  aria-label=""
                  aria-describedby="basic-addon1"
                  onChange={this.onChangeVolume}
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                  onBlur={(e) => {
                    store_errors.dispatch({ type: 1, current_input: null });
                  }}
                  data-background-color={Util.COLORS.RED_TOOLTIP}
                  data-type="error"
                  delay-hide={1000}
                  data-tip={
                    errors.volume
                      ? errors.volume
                      : errors.volume_mismatch
                      ? errors.volume_mismatch
                      : ""
                  }
                />
                <ReactTooltip
                  place="top"
                  type="error"
                  effect="solid"
                  delayHide={500}
                />
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    m<sup>3</sup>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-sm-6 important-input" key={this.state.key}>
              <label>Chg. Weight*</label>
              <div class="input-group mb-3">
                <input
                  id="shipment_chg_wgt"
                  type="text"
                  key=""
                  tabindex="0"
                  disabled={
                    shipment.dimension_type === Util.CONST.ULD ? true : false
                  }
                  defaultValue={shipment.chargeable_weight}
                  class={
                    "form-control " +
                    (errors.chargeable_weight ? "is-invalid" : "")
                  }
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                  onBlur={(e) => {
                    store_errors.dispatch({ type: 1, current_input: null });
                  }}
                  onChange={(e) => {
                    this.onChangeChargeableWeight(e);
                  }}
                  data-background-color={Util.COLORS.RED_TOOLTIP}
                  data-type="error"
                  delay-hide={1000}
                  data-tip={
                    errors.chargeable_weight ? errors.chargeable_weight : ""
                  }
                />
                <ReactTooltip
                  place="top"
                  type="error"
                  effect="solid"
                  delayHide={500}
                />
                <div class="input-group-prepend ">
                  <span class="input-group-text" id="basic-addon1">
                    kg
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-1 col-sm-1" style={{ textAlign: "center", marginLeft : "-1.8em"}}>
              <FormControlLabel
                value="bottom"
                control={
                  <Checkbox
                    defaultChecked={shipment.is_min_chargeable_weight}
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      store_shipment.dispatch({ type: 1, is_min_chargeable_weight: isChecked});
                      this.isMinCW()
                    }}
                  />
                }
                label="Min CW" 
                labelPlacement="top"
              />
            </div>



            <DensityField />

            {shipment.dimension_type !== Util.CONST.ULD ? (
              <div class="col-md-1" style={{ marginTop: "2em", padding: 0 }}>
                <a id="skypallet_ref" href="" target="_blank" hidden />
                <img
                  style={{
                    cursor: "pointer",
                    height: "1.5em",
                    marginRight: "0.5em",
                  }}
                  data-type="dark"
                  delay-hide={1000}
                  data-tip={
                    !this.checkPackingList()
                      ? Util.TEXTS.PACKING_MANDATORY_FILL_TEXT
                      : Util.TEXTS.SEND_TO_SKYPALLET_TEXT
                  }
                  src={require("../img/skypallet.ico")}
                  onClick={async (e) => {
                    if (!this.checkPackingList()) return;
                    this.onClickSkypalletIcon(e);
                  }}
                />

                <ReactTooltip
                  place="top"
                  type="error"
                  effect="solid"
                  delayHide={500}
                />

                {!shipment.ops_chargeable_weight &&
                !shipment.uld_plan ? null : (
                  <InfoIcon
                    style={{ cursor: "pointer" }}
                    color="primary"
                    data-type="info"
                    delay-hide={1000}
                    data-tip={
                      "Ops Chargeable Weight: " +
                      (shipment.ops_chargeable_weight
                        ? shipment.ops_chargeable_weight + " " + Util.CONST.KG
                        : "") +
                      " | ULD Plan: " +
                      (shipment.uld_plan ? shipment.uld_plan : "")
                    }
                    onClick={(e) => {
                      this.onClickInfo(e);
                    }}
                  >
                    <ReactTooltip
                      place="top"
                      type="error"
                      effect="solid"
                      delayHide={500}
                    />
                  </InfoIcon>
                )}
              </div>
            ) : null}

            

          </div>

          <div class="row">
            <div class="col-1">
              <label>Stackable</label>
              <div class="input-group mb-3">
                <select
                  class="form-control dropdown"
                  id="shipment-stackable"
                  style={{
                    border: errors.stackable_mismatch ? "2px solid orange" : "",
                  }}
                  onChange={(e) => {
                    this.onChangeStackabale(e);
                  }}
                  data-background-color={Util.COLORS.RED_TOOLTIP}
                  data-type="warning"
                  delay-hide={1000}
                  data-tip={
                    errors.stackable_mismatch ? errors.stackable_mismatch : ""
                  }
                >
                  <option>{store_shipment.getState().stackable} </option>
                  {util.STACKABLES.filter(
                    (s) => s !== store_shipment.getState().stackable
                  ).map((s) => {
                    return <option value={s}> {s} </option>;
                  })}
                </select>

                <div class="input-group-prepend ">
                  {errors.stackable_mismatch ? (
                    <img
                      src={require("../img/warning-sign.png")}
                      alt="Warning"
                    />
                  ) : null}
                </div>
              </div>
              <ReactTooltip
                place="top"
                type="error"
                effect="solid"
                delayHide={500}
              />
            </div>

            {/* <div class="col-md-2 col-sm-6" style={{ textAlign: "center" }}>
              <FormControlLabel
                value="bottom"
                control={
                  <Checkbox
                    defaultChecked={shipment.screened}
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    onChange={(e) => {
                      store_shipment.dispatch({
                        type: 1,
                        screened: !shipment.screened,
                      });
                    }}
                  />
                }
                label={window.innerWidth > 1600 ? "Screened" : "Screened"}
                labelPlacement="top"
              />
            </div> */}

            <div class="col-md-2 col-sm-6" style={{ textAlign: "center" }}>
              <FormControlLabel
                value="bottom"
                control={
                  <Checkbox
                    defaultChecked={shipment.main_deck}
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    onChange={(e) => {
                      store_shipment.dispatch({
                        type: 1,
                        main_deck: !shipment.main_deck,
                      });
                    }}
                  />
                }
                label={window.innerWidth > 1600 ? "Main Deck" : "M.D."}
                labelPlacement="top"
              />
            </div>

            <div class="col-md-2 col-sm-6">
              <label>Target All-In price</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  maxlength="6"
                  defaultValue={pricing.target_price}
                  onChange={(e) => {
                    store_pricing.dispatch({
                      type: 1,
                      target_price: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div class="col-md-2 col-sm-6">
              <label>Unit</label>
              <select
                id="shipment-target-unit"
                class="form-control dropdown"
                onChange={(e) => {
                  store_pricing.dispatch({
                    type: 1,
                    target_per_kg: e.target.value === "true",
                  });
                }}
              >
                <option value={pricing.target_per_kg ? true : false}>
                  {" "}
                  {pricing.target_per_kg
                    ? Util.CONST.PER_KG1
                    : Util.CONST.TOTAL}{" "}
                </option>
                <option value={pricing.target_per_kg ? false : true}>
                  {" "}
                  {pricing.target_per_kg
                    ? Util.CONST.TOTAL
                    : Util.CONST.PER_KG1}{" "}
                </option>
              </select>
            </div>

            <div class="col-md-2 col-sm-6">
              <label>Currency</label>
              <select
                id="shipment-target-currency"
                class="form-control dropdown"
                onChange={(e) => {
                  store_pricing.dispatch({
                    type: 1,
                    target_currency: e.target.value,
                  });
                }}
              >
                <option>{store_pricing.getState().target_currency}</option>
                {util.CURRENCIES.filter(
                  (c) => c !== store_pricing.getState().target_currency
                ).map((c) => {
                  return <option value={c}> {c} </option>;
                })}
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label>SHC 1</label>
              <input
                type="text"
                class="form-control"
                defaultValue={shipment.shc[0]}
                maxlength="3"
                onChange={(e) => {
                  this.onChangeSHC(e, 0);
                }}
              />
            </div>

            <div class="col">
              <label>SHC 2</label>
              <input
                type="text"
                class="form-control"
                defaultValue={shipment.shc[1]}
                maxlength="3"
                onChange={(e) => {
                  this.onChangeSHC(e, 1);
                }}
              />
            </div>

            <div class="col">
              <label>SHC 3</label>
              <input
                type="text"
                class="form-control"
                defaultValue={shipment.shc[2]}
                maxlength="3"
                onChange={(e) => {
                  this.onChangeSHC(e, 2);
                }}
              />
            </div>

            <div class="col">
              <label>SHC 4</label>
              <input
                type="text"
                class="form-control"
                defaultValue={shipment.shc[3]}
                maxlength="3"
                onChange={(e) => {
                  this.onChangeSHC(e, 3);
                }}
              />
            </div>

            {header.user === Util.CONST.CARGOAI &&
              attachments[0] &&
              attachments[0].file && (
                <div class="col-1" style={{ marginTop: "2em", padding: 0 }}>
                  <center>
                    <a id="cargoai_attachment" href="" target="_blank" hidden />
                    <img
                      data-type="dark"
                      delay-hide={1000}
                      data-tip={"CargoAI Shipment Summary"}
                      style={{
                        cursor: "pointer",
                        height: "1.8em",
                        marginRight: "0.5em",
                      }}
                      src={require("../img/pdf.png")}
                      alt="Cargoai attachment"
                      onClick={() => {
                        if (!attachments[0].file) return;

                        let doc = document.getElementById("cargoai_attachment");

                        if (!doc) return;

                        document.getElementById("cargoai_attachment").href =
                          attachments[0].file;
                        document.getElementById("cargoai_attachment").click();
                      }}
                    />
                  </center>
                  <ReactTooltip
                    place="top"
                    type="error"
                    effect="solid"
                    delayHide={500}
                  />
                </div>
              )
            }

            <CommodityField />

            <div class="col-md-2 col-sm-6">
              <label>Nature of goods</label>
              <input
                type="text"
                class="form-control"
                placeholder=""
                maxlength="20"
                defaultValue={shipment.nature_goods}
                onChange={(e) => {
                  store_shipment.dispatch({
                    type: 1,
                    nature_goods: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <br />
        </div>
      </div>
    );
  }
}

class MarginSectionNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.styles = {
      line: {
        borderRight: "2px solid #0076ad",
        padding: "1em",
        margin: "0.5em",
      },

      title: {
        fontSize: "0.8em",
      },
    };
  }

  componentDidMount() {
    let w1 = watch(store_margin.getState, "force");
    store_margin.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_station.getState, "exch_rate");
    store_station.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    store_margin.subscribe(() => {
      this.setState({ key: Date.now() });
    });
    store_pricing.subscribe(() => {
      this.setState({ key: Date.now() });
    });
  }

  componentDidUpdate() {
    let input = document.getElementById(store_errors.getState().current_input);
    if (input) input.focus();
  }

  async calculateMargin() {
    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    let { station } = store_header.getState();
    let authorized_stations = this.props.authorized_stations;
    let station_ = authorized_stations.filter((s) => s.station === station)[0];
    let custom_list = this.props.custom_list;
    await calculateMargin({ station: station_, custom_list: custom_list });
    await saveQuote();
    store_modals.dispatch({ type: 1, page_loading_spinner: false });
  }

  async handleChange(e) {
    let { per_kg } = store_margin.getState();

    let json = { type: 1 };
    json[e.target.name] = e.target.value;

    if (e.target.name === "all_in_buy_edit")
      json["all_in_buy_edit2"] = e.target.value;

    if (e.target.name === "net_buy_edit")
      json["net_buy_edit2"] = e.target.value;

    if (per_kg) json.marginPerkg = true;
    else json.marginInTotal = true;

    if (
      e.target.name === "edit_buy_currency" ||
      e.target.name === "margin_currency_selected"
    ) {
      json.marginPerkg = false;
      json.marginPerkg = false;
    }

    store_margin.dispatch(json);
  }

  async handleBlur(e) {
    this.handleChange(e);
    await util.sleep(100);
    this.calculateMargin();
    store_errors.dispatch({ type: 1, current_input: null });
  }

  render() {
    let errors = store_errors.getState();
    let shipment = store_shipment.getState();
    let pricing = store_pricing.getState();
    let { exch_rate } = store_station.getState();
    let { chargeable_weight } = shipment;

    let {
      buy_currency,
      buy_rate_type,
      margin_currency_selected,
      edit_buy_currency,
      per_kg,
      commission,
      margin_per_kg,
      margin_in_total,
    } = store_margin.getState();

    let {
      buy_all_in_rate,
      buy_net_net_rate,
      net_buy_edit,
      all_in_buy_edit,
      sell_all_in_rate,
      sell_net_net_rate,
      fuel_rate,
      security_rate,
      truck_buy,
      truck_buy_edit,
      handling_buy,
      handling_buy_edit,
      loc_addon_buy,
      loc_addon_buy_edit,
      truck_sell,
      truck_sell_edit,
      handling_sell,
      handling_sell_edit,
      loc_addon_sell,
      loc_addon_sell_edit,
    } = per_kg ? margin_per_kg : margin_in_total;

    //console.log("#freeSectionMargin", store_margin.getState());

    let { currency, all_in } = pricing;

    let net_buy_flown = 0;
    let net_sell_flown = 0;
    let total_flown_ = 0;
    let total_all_in_ = 0;
    let total_net_net_ = 0;
    let total_fuel_ = 0;
    let total_security_ = 0;
    let total_trucking_ = 0;
    let total_handling_ = 0;
    let total_loc_addon_ = 0;
    let margin_value_per_kg = 0;
    let margin_value_in_total = 0;

    buy_currency = buy_currency ? buy_currency : currency;
    edit_buy_currency = edit_buy_currency ? edit_buy_currency : buy_currency;
    margin_currency_selected = margin_currency_selected
      ? margin_currency_selected
      : currency;

    margin_value_per_kg = margin_per_kg.margin;
    margin_value_in_total = margin_in_total.margin;

    let buy_all_in_rate_ = util.convert_currency(
      buy_all_in_rate,
      edit_buy_currency,
      margin_currency_selected,
      exch_rate
    );

    let buy_net_net_rate_ = util.convert_currency(
      buy_net_net_rate,
      edit_buy_currency,
      margin_currency_selected,
      exch_rate
    );

    let sell_all_in_rate_ = util.convert_currency(
      sell_all_in_rate,
      currency,
      margin_currency_selected,
      exch_rate
    );

    let sell_net_net_rate_ = util.convert_currency(
      sell_net_net_rate,
      currency,
      margin_currency_selected,
      exch_rate
    );

    let fuel_rate_ = util.convert_currency(
      fuel_rate,
      currency,
      margin_currency_selected,
      exch_rate
    );

    let security_rate_ = util.convert_currency(
      security_rate,
      currency,
      margin_currency_selected,
      exch_rate
    );

    let truck_buy_ = util.convert_currency(
      truck_buy,
      edit_buy_currency,
      margin_currency_selected,
      exch_rate
    );

    let handling_buy_ = util.convert_currency(
      handling_buy,
      edit_buy_currency,
      margin_currency_selected,
      exch_rate
    );

    let loc_addon_buy_ = util.convert_currency(
      loc_addon_buy,
      edit_buy_currency,
      margin_currency_selected,
      exch_rate
    );

    let truck_sell_ = util.convert_currency(
      truck_sell,
      currency,
      margin_currency_selected,
      exch_rate
    );

    let handling_sell_ = util.convert_currency(
      handling_sell,
      currency,
      margin_currency_selected,
      exch_rate
    );

    let loc_addon_sell_ = util.convert_currency(
      loc_addon_sell,
      currency,
      margin_currency_selected,
      exch_rate
    );

    net_buy_flown =
      buy_net_net_rate_ - truck_buy_ - handling_buy_ - loc_addon_buy_;
    net_sell_flown =
      sell_net_net_rate_ - truck_sell_ - handling_sell_ - loc_addon_sell_;

    margin_value_per_kg = util.convert_currency(
      margin_value_per_kg,
      currency,
      margin_currency_selected,
      exch_rate
    );

    margin_value_in_total = util.convert_currency(
      margin_value_in_total,
      currency,
      margin_currency_selected,
      exch_rate
    );

    total_all_in_ = sell_all_in_rate_ - buy_all_in_rate_;
    total_net_net_ = sell_net_net_rate_ - buy_net_net_rate_;
    total_fuel_ = fuel_rate_ - fuel_rate_;
    total_security_ = security_rate_ - security_rate_;
    total_trucking_ = truck_sell_ - truck_buy_;
    total_handling_ = handling_sell_ - handling_buy_;
    total_loc_addon_ = loc_addon_sell_ - loc_addon_buy_;
    total_flown_ = net_sell_flown - net_buy_flown;

    if (sell_net_net_rate_ === buy_net_net_rate_) {
      //commssion
      total_flown_ =
        sell_net_net_rate_ -
        total_trucking_ -
        total_handling_ -
        total_loc_addon_;
    }

    //let config = getConfig(this.props.custom_list)

    if (!exch_rate) return null;

    return (
      <div class="card col section-margin" key={this.state.key}>
        <div class="card-header row">
          <h5>
            <img alt={"Margin"} src={require("../img/calculator.png")} /> Margin
          </h5>

          <div className="col-2">
            <button
              type="button"
              class="btn btn-primary "
              onClick={async (e) => {
                e.preventDefault();
                this.calculateMargin();
                let logs = { type: "CALCULATE_MARGIN" };
                await Routes.get("/Logger", logs, true);
              }}
            >
              Calculate Margin
            </button>
          </div>

          <div className="col-2">
            <input class="form-control" disabled value={"Buy Currency "} />
          </div>
          <div className="col-1">
            <select
              id="edit_buy_currency"
              class="form-control dropdown"
              name="edit_buy_currency"
              onChange={this.handleChange}
            >
              <option>{edit_buy_currency}</option>
              {util.CURRENCIES.filter((c) => c !== edit_buy_currency).map(
                (c) => {
                  return <option value={c}> {c} </option>;
                }
              )}
            </select>
          </div>

          <div className="col-1" style={{ marginLeft: "1em" }}>
            <input class="form-control" disabled value={"Unit"} />
          </div>

          <div className="col-1">
            <select
              id=""
              class="form-control dropdown"
              onChange={async (e) => {
                store_margin.dispatch({
                  type: 1,
                  per_kg: e.target.value === "true",
                  force: true,
                  updateMargin: true,
                });

                this.calculateMargin();
              }}
            >
              <option value={per_kg ? true : false}>
                {" "}
                {per_kg ? Util.CONST.PER_KG : Util.CONST.TOTAL}{" "}
              </option>
              <option value={per_kg ? false : true}>
                {" "}
                {per_kg ? Util.CONST.TOTAL : Util.CONST.PER_KG}{" "}
              </option>
            </select>
          </div>

          <div className="col-2" style={{ marginLeft: "1em" }}>
            <input class="form-control" disabled value={"Display Currency"} />
          </div>

          <div className="col-1">
            <select
              id="margin_currency_selected"
              class="form-control dropdown"
              name="margin_currency_selected"
              onChange={this.handleChange}
            >
              <option>{margin_currency_selected}</option>
              {util.CURRENCIES.filter(
                (c) => c !== margin_currency_selected
              ).map((c) => {
                return <option value={c}> {c} </option>;
              })}
            </select>
          </div>
        </div>

        <div className="row">
          <div style={this.styles.line} className="col-6">
            <div className="row">
              <div className="col-4"></div>

              <div className="col-4" style={this.styles.title}>
                <strong>
                  {net_buy_edit || all_in_buy_edit ? "EDIT" : buy_rate_type} BUY
                </strong>{" "}
                ({margin_currency_selected})
              </div>

              <div className="col-4" style={this.styles.title}>
                <div class="label-edit">
                  <strong>Edit Buy</strong> <i>{per_kg ? "/kg" : "Total"}</i>
                </div>
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-4">All-in</div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control"
                  value={
                    buy_all_in_rate_
                      ? parseFloat(buy_all_in_rate_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control  margin-edit"
                  disabled={!all_in}
                  defaultValue={
                    all_in_buy_edit
                      ? parseFloat(all_in_buy_edit).toFixed(2)
                      : null
                  }
                  id="margin_all_in_buy_edit"
                  name="all_in_buy_edit"
                  onBlur={this.handleBlur}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-4">Net Net</div>
              <div className="col-4">
                <input
                  type="text"
                  class="form-control"
                  value={
                    buy_net_net_rate_
                      ? parseFloat(buy_net_net_rate_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control  margin-edit"
                  disabled={all_in}
                  defaultValue={
                    net_buy_edit ? parseFloat(net_buy_edit).toFixed(2) : null
                  }
                  id="margin_net_buy_edit"
                  name="net_buy_edit"
                  onBlur={this.handleBlur}
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-4">Fuel</div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control"
                  value={fuel_rate_ ? parseFloat(fuel_rate_).toFixed(2) : null}
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-4">Security</div>
              <div className="col-4">
                <input
                  type="text"
                  class="form-control"
                  value={
                    security_rate_
                      ? parseFloat(security_rate_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-4">Trucking</div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control"
                  value={truck_buy_ ? parseFloat(truck_buy_).toFixed(2) : null}
                  disabled
                />
              </div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control  margin-edit"
                  defaultValue={
                    truck_buy_edit ? parseFloat(truck_buy_edit).toFixed(2) : ""
                  }
                  name="truck_buy_edit"
                  id="truck_buy_edit"
                  onBlur={this.handleBlur}
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-4">Handling</div>
              <div className="col-4">
                <input
                  type="text"
                  class="form-control"
                  value={
                    handling_buy_ ? parseFloat(handling_buy_).toFixed(2) : null
                  }
                  disabled
                />
              </div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control  margin-edit"
                  defaultValue={
                    handling_buy_edit
                      ? parseFloat(handling_buy_edit).toFixed(2)
                      : ""
                  }
                  name="handling_buy_edit"
                  id="handling_buy_edit"
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                  onBlur={this.handleBlur}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-4">Add-on</div>
              <div className="col-4">
                <input
                  type="text"
                  class="form-control"
                  value={
                    loc_addon_buy_
                      ? parseFloat(loc_addon_buy_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>

              <div className="col-4">
                <input
                  type="text"
                  class="form-control  margin-edit"
                  defaultValue={
                    loc_addon_buy_edit
                      ? parseFloat(loc_addon_buy_edit).toFixed(2)
                      : ""
                  }
                  name="loc_addon_buy_edit"
                  id="loc_addon_buy_edit"
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                  onBlur={this.handleBlur}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-4">Net Flown</div>
              <div className="col-4">
                <input
                  type="text"
                  class={
                    "form-control " + (net_buy_flown < 0 ? "is-invalid" : "")
                  }
                  value={
                    net_buy_flown != null
                      ? parseFloat(net_buy_flown).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>
            </div>
          </div>

          <div style={this.styles.line} className="col-3">
            <div className="row">
              <div className="col-6" style={this.styles.title}>
                {" "}
                <strong>SELL</strong> ({margin_currency_selected})
              </div>
              <div className="col-6" style={this.styles.title}>
                <div className="label-edit">
                  Edit Sell <i>{per_kg ? "/kg" : "Total"}</i>
                </div>
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  class="form-control"
                  value={
                    sell_all_in_rate_
                      ? parseFloat(sell_all_in_rate_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  class="form-control"
                  value={
                    sell_net_net_rate_
                      ? parseFloat(sell_net_net_rate_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  class="form-control"
                  value={fuel_rate_ ? parseFloat(fuel_rate_).toFixed(2) : null}
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  class="form-control"
                  value={
                    security_rate_
                      ? parseFloat(security_rate_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  class="form-control"
                  value={
                    truck_sell_ ? parseFloat(truck_sell_).toFixed(2) : null
                  }
                  disabled
                />
              </div>

              <div className="col-6">
                <input
                  type="text"
                  class="form-control margin-edit"
                  defaultValue={
                    truck_sell_edit
                      ? parseFloat(truck_sell_edit).toFixed(2)
                      : ""
                  }
                  name="truck_sell_edit"
                  id="truck_sell_edit"
                  onBlur={this.handleBlur}
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  class="form-control"
                  value={
                    handling_sell_
                      ? parseFloat(handling_sell_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>

              <div className="col-6">
                <input
                  type="text"
                  class="form-control margin-edit"
                  defaultValue={
                    handling_sell_edit
                      ? parseFloat(handling_sell_edit).toFixed(2)
                      : ""
                  }
                  name="handling_sell_edit"
                  id="handling_sell_edit"
                  onBlur={this.handleBlur}
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  class="form-control"
                  value={
                    loc_addon_sell_
                      ? parseFloat(loc_addon_sell_).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>

              <div className="col-6">
                <input
                  type="text"
                  class="form-control margin-edit"
                  defaultValue={
                    loc_addon_sell_edit
                      ? parseFloat(loc_addon_sell_edit).toFixed(2)
                      : ""
                  }
                  name="loc_addon_sell_edit"
                  id="loc_addon_sell_edit"
                  onBlur={this.handleBlur}
                  onFocus={(e) =>
                    store_errors.dispatch({
                      type: 1,
                      current_input: e.target.id,
                    })
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                <input
                  type="text"
                  class={
                    "form-control " + (net_sell_flown < 0 ? "is-invalid" : "")
                  }
                  value={
                    net_sell_flown != null
                      ? parseFloat(net_sell_flown).toFixed(2)
                      : null
                  }
                  disabled
                />
              </div>
            </div>

            <br />
          </div>

          <div style={this.styles.line} className="col-2">
            <div className="row">
              <div className="col-12" style={this.styles.title}>
                <strong>Total</strong> ({margin_currency_selected})
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_all_in_ < 0 ? "is-invalid" : "")
                  }
                  value={
                    total_all_in_ ? parseFloat(total_all_in_).toFixed(2) : 0
                  }
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_net_net_ < 0 ? "is-invalid" : "")
                  }
                  value={
                    total_net_net_ ? parseFloat(total_net_net_).toFixed(2) : 0
                  }
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_fuel_ < 0 ? "is-invalid" : "")
                  }
                  value={total_fuel_ ? parseFloat(total_fuel_).toFixed(2) : 0}
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_security_ < 0 ? "is-invalid" : "")
                  }
                  value={
                    total_security_ ? parseFloat(total_security_).toFixed(2) : 0
                  }
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_trucking_ < 0 ? "is-invalid" : "")
                  }
                  value={
                    total_trucking_ ? parseFloat(total_trucking_).toFixed(2) : 0
                  }
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_handling_ < 0 ? "is-invalid" : "")
                  }
                  value={
                    total_handling_ ? parseFloat(total_handling_).toFixed(2) : 0
                  }
                  disabled
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_loc_addon_ < 0 ? "is-invalid" : "")
                  }
                  value={
                    total_loc_addon_
                      ? parseFloat(total_loc_addon_).toFixed(2)
                      : 0
                  }
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  class={
                    "form-control " + (total_flown_ < 0 ? "is-invalid" : "")
                  }
                  value={total_flown_ ? parseFloat(total_flown_).toFixed(2) : 0}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            {" "}
            <strong>Margin</strong>
          </div>

          <div class="col-2">
            <label>
              <strong>Total</strong>
            </label>
            <input
              type="text"
              class={
                "form-control " + (margin_value_in_total < 0 && "is-invalid")
              }
              value={
                (margin_value_in_total
                  ? parseFloat(margin_value_in_total).toFixed(2)
                  : margin_value_in_total) +
                " " +
                margin_currency_selected
              }
              disabled
            />
            <label class="invalid">
              <strong>{errors.margin}</strong>
            </label>
          </div>

          <div class="col-2">
            <label>
              <strong>Per kg</strong>
            </label>
            <input
              type="text"
              class={
                "form-control " +
                (margin_value_per_kg < 0 ? "is-invalid" : "is-valid")
              }
              value={
                (margin_value_per_kg
                  ? parseFloat(margin_value_per_kg).toFixed(2)
                  : margin_value_per_kg) +
                " " +
                margin_currency_selected +
                Util.CONST.PER_KG
              }
              disabled
            />
          </div>

          {commission ? (
            <div class="col-1">
              <label>
                <strong>Commission</strong>
              </label>
              <input
                type="text"
                class="form-control"
                value={!commission ? "" : commission + " %"}
                disabled
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

class OCDCSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    store_pricing.subscribe((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if (oldVal !== newVal) this.setState({ key: Date.now() });
    });

    store_errors.subscribe(() => {
      this.setState({ key: Date.now() });
    });

    let w = watch(store_pricing.getState, "ocdc");
    store_pricing.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_shipment.getState, "shc");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_pricing.getState, "carrier");
    store_pricing.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w4 = watch(store_shipment.getState, "origin");
    store_shipment.subscribe(
      w4((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w5 = watch(store_shipment.getState, "dest");
    store_shipment.subscribe(
      w5((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w6 = watch(store_shipment.getState, "agent");
    store_shipment.subscribe(
      w6((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w7 = watch(store_shipment.getState, "volume");
    store_shipment.subscribe(
      w7((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w8 = watch(store_shipment.getState, "gross_weight");
    store_shipment.subscribe(
      w8((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w9 = watch(store_shipment.getState, "chargeable_weight");
    store_shipment.subscribe(
      w9((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  checkMandatoryOCDC() {
    let errors = checkForm();
    let error = false;

    for (const err in errors) {
      switch (err) {
        case "chargeable_weight":
          error = true;
          break;
        case "gross_weight":
          error = true;
          break;
        case "dest":
          error = true;
          break;
        case "origin":
          error = true;
          break;
        case "issue_carrier":
          error = true;
          break;
        case "agent":
          error = true;
          break;
        case "agent_code":
          error = true;
          break;
        case "volume":
          error = true;
          break;
        default:
      }
    }

    return !error;
  }

  async onChangeOCDCCode(e, i) {
    store_pricing.dispatch({
      type: 1,
      index: i,
      ocdc: true,
      code: e.target.value.toUpperCase(),
    });

    if (e.target.value.length === 3) {
      ocdcByCode(e.target.value, i);
    } else {
      try {
        document.getElementById("name_" + i).value = "";
        document.getElementById("base_on_" + i).value = "";
        //document.getElementById('currency_'+i).value = ""
        document.getElementById("value_" + i).value = "";
        document.getElementById("total_charges_" + i).value = "";
      } catch (error) {
        console.log(error);
      }
    }
  }

  async searchOCDC(e) {
    if (!this.checkMandatoryOCDC()) return;

    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    await calculateOCDC();
    store_modals.dispatch({ type: 1, page_loading_spinner: false });
  }

  render() {
    let pricing = store_pricing.getState();
    let ocdc_list = pricing.ocdc;
    let html = [];

    ocdc_list.forEach((ocdc) => {
      let i = ocdc_list.indexOf(ocdc);

      html.push(
        <div class="row route_col">
          <div
            class="col-1"
            style={{ paddingTop: i === 0 ? "0.5em" : "" }}
            onClick={(e) => {
              store_pricing.dispatch({
                type: 1,
                ocdc: true,
                index: i,
                ocdc_removeLine: true,
              });
              this.setState({ key: Date.now() });
            }}
          >
            {i === 0 && <br />}
            <Delete fontSize="small" color="secondary" />
          </div>

          <div class="col-1">
            {i === 0 ? <label>Code</label> : ""}
            <input
              type="text"
              class="form-control "
              placeholder=""
              defaultValue={ocdc.code}
              tabindex="0"
              maxlength="3"
              onChange={async (e) => {
                this.onChangeOCDCCode(e, i);
              }}
            />
          </div>

          <div class="col-2 ">
            {i === 0 ? <label>Name</label> : ""}
            <input
              type="text"
              class="form-control "
              placeholder=""
              defaultValue={FirstWordUppercase(ocdc.name)}
              tabindex="0"
              id={"name_" + i}
              onChange={(e) =>
                store_pricing.dispatch({
                  type: 1,
                  index: i,
                  ocdc: true,
                  name: e.target.value.toUpperCase(),
                })
              }
            />
          </div>

          <div class="col-2 ">
            {i === 0 ? <label>Based On</label> : ""}
            <input
              type="text"
              class="form-control "
              placeholder=""
              defaultValue={FirstWordUppercase(ocdc.based_on)}
              tabindex="0"
              id={"base_on_" + i}
              onChange={(e) =>
                store_pricing.dispatch({
                  type: 1,
                  index: i,
                  ocdc: true,
                  based_on: e.target.value.toUpperCase(),
                })
              }
            />
          </div>

          <div class="col-1 ">
            {i === 0 ? <label>Currency</label> : ""}
            <select
              class="form-control dropdown"
              tabindex="0"
              id={"currency_" + i}
              onChange={(e) =>
                store_pricing.dispatch({
                  type: 1,
                  index: i,
                  ocdc: true,
                  oc_currency: e.target.value.toUpperCase(),
                })
              }
            >
              <option> {store_pricing.getState().ocdc[i].currency} </option>
              {util.CURRENCIES.filter(
                (c) => c !== store_pricing.getState().ocdc[i].currency
              ).map((c) => {
                return <option value={c}> {c} </option>;
              })}
            </select>
          </div>

          <div class="col-3 ">
            {i === 0 ? <label>Value</label> : ""}
            <input
              type="text"
              class="form-control"
              placeholder=""
              defaultValue={FirstWordUppercase(ocdc.value)}
              tabindex="0"
              id={"value_" + i}
              onChange={(e) =>
                store_pricing.dispatch({
                  type: 1,
                  index: i,
                  ocdc: true,
                  value: e.target.value.toUpperCase(),
                })
              }
            />
          </div>

          <div class="col-2 ">
            {i === 0 ? <label>Total Charges</label> : ""}
            <input
              type="text"
              class="form-control "
              placeholder=""
              id={"total_charges_" + i}
              defaultValue={ocdc.total_charges}
              tabindex="0"
              onChange={(e) =>
                store_pricing.dispatch({
                  type: 1,
                  index: i,
                  ocdc: true,
                  total_charges: e.target.value.toUpperCase(),
                })
              }
            />
          </div>
        </div>
      );
    });

    return (
      <div key={this.state.key}>
        <div class="row">
          <h6 style={{ marginLeft: "1em" }}>
            OCDC
            <button
              type="button"
              id="ocdc-add"
              data-tip={"Add new OCDC"}
              data-type="info"
              style={{ marginLeft: "0.35em" }}
              class="btn btn-success"
              onClick={(e) => {
                store_pricing.dispatch({ type: 1, ocdc_add: true });
                this.setState({ key: Date.now() });
              }}
            >
              <AddCircleIcon fontSize="small" />
            </button>
            <button
              type="button"
              id="ocdc-remove"
              data-tip={"Delete all OCDC"}
              data-type="info"
              class="btn btn-danger"
              onClick={(e) => {
                store_pricing.dispatch({ type: 1, ocdc_remove: true });
                this.setState({ key: Date.now() });
              }}
            >
              <Delete fontSize="small" />
            </button>
            <button
              type="button"
              class="btn btn-primary"
              id="btn_ocdc"
              style={{ marginRight: "1em" }}
              delay-hide={1000}
              data-tip={
                !this.checkMandatoryOCDC()
                  ? Util.TEXTS.MANDATORY_FIELDS_TEXT
                  : ""
              }
              data-type="error"
              data-background-color={Util.COLORS.RED_TOOLTIP}
              onClick={async (e) => {
                this.searchOCDC(e);
              }}
            >
              <Search fontSize="small" /> Search OCDC
            </button>
            <Eye />
            <ReactTooltip place="top" effect="solid" delayHide={500} />
          </h6>
        </div>

        <div style={{ marginLeft: "1em" }}>{html}</div>
      </div>
    );
  }
}

class ULDPricingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidUpdate() {
    let input = document.getElementById(store_errors.getState().current_input);
    if (input) input.focus();
  }

  componentDidMount() {
    let w = watch(store_pricing.getState, "carrier");
    store_pricing.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w1 = watch(store_pricing.getState, "uld_price_change");
    store_pricing.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w6 = watch(store_similar.getState, "exact");
    store_similar.subscribe(
      w6((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w7 = watch(store_errors.getState, "from_date");
    store_errors.subscribe(
      w7((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w8 = watch(store_errors.getState, "to_date");
    store_errors.subscribe(
      w8((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w9 = watch(store_errors.getState, "flight_date");
    store_errors.subscribe(
      w9((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  calculateChargeableWeight() {
    let weightT = 0;
    let piecesT = 0;
    //let volumeT = 0;
    let chargeable_weightT = 0;
    //let main_deck = false
    let { uld, gross_weight } = store_shipment.getState();
    let { uld_price } = store_pricing.getState();

    uld.forEach((ele) => {
      let pieces = isNaN(parseInt(ele.pieces)) ? 0 : parseInt(ele.pieces);
      let weight = isNaN(parseFloat(ele.weight)) ? 0 : parseFloat(ele.weight);
      let position = ele.position ? ele.position : "";

      piecesT = piecesT + pieces;
      weightT = weightT + pieces * weight;

      for (let i = 0; i < uld_price.length; i++) {
        if (position && uld_price[i].position === position) {
          let pivot = uld_price[i].pivot_weight;
          pivot = isNaN(parseFloat(pivot)) ? 0 : parseFloat(pivot);
          chargeable_weightT = chargeable_weightT + pieces * pivot;
        }
      }
    });

    let chargeable_weight = "";
    if (gross_weight && gross_weight > 0) {
      chargeable_weight =
        chargeable_weightT && chargeable_weightT > gross_weight
          ? chargeable_weightT
          : gross_weight;
    } else {
      chargeable_weight =
        chargeable_weightT && chargeable_weightT > 0 ? chargeable_weightT : "";
    }

    if (chargeable_weight) {
      store_shipment.dispatch({
        type: 1,
        chargeable_weight: chargeable_weight,
      });
    }
  }

  render() {
    let pricing = store_pricing.getState();
    let shipment = store_shipment.getState();
    let uld = shipment.uld ? shipment.uld : [];
    // let uld_settings = shipment.uld_settings
    let uld_price = pricing.uld_price ? pricing.uld_price : [];
    let html = [];
    let errors = checkForm();
    let selling_rate_idxs = errors.selling_rate_idxs
      ? errors.selling_rate_idxs
      : [];
    let is_invalid = "";

    uld_price.forEach((u) => {
      let i = uld_price.indexOf(u);
      let currency = u.currency ? u.currency : pricing.currency;
      let item = uld.filter((up) => up.position === u.position)[0];
      let weight = item && item.weight ? item.weight : null;
      is_invalid =
        u.per_kg &&
        weight &&
        ((u.pivot_weight && weight > u.pivot_weight && !u.over_pivot_rate) ||
          !u.pivot_weight)
          ? "is-invalid"
          : "";
      html.push(
        <div class="row route_col">
          <div class="col-2">
            {i === 0 ? <label>Type</label> : ""}
            <input
              type="text"
              id={"uld_type_" + i}
              class="form-control"
              defaultValue={u.pieces + "x" + u.position}
              tabindex="0"
              disabled={true}
              onChange={(e) => {
                store_pricing.dispatch({
                  type: 1,
                  update_uld_price: true,
                  position: e.target.value,
                  index: i,
                });
              }}
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
              onBlur={(e) => {
                store_errors.dispatch({ type: 1, current_input: "" });
              }}
            />
          </div>

          <div class="col-2">
            {i === 0 ? <label>Pivot Weight</label> : ""}
            <input
              id={"uld_pivot_weight" + i}
              type="text"
              class={"form-control"}
              defaultValue={u.pivot_weight}
              tabindex="1"
              onChange={(e) => {
                store_pricing.dispatch({
                  type: 1,
                  update_uld_price: true,
                  pivot_weight: e.target.value,
                  index: i,
                });
                //this.calculateChargeableWeight();
                formatULDPrice();
                //calculateULDPrice();
              }}
              onFocus={(e) => {
                store_errors.dispatch({ type: 1, current_input: e.target.id });
              }}
              onBlur={(e) => {
                store_errors.dispatch({ type: 1, current_input: "" });
                store_pricing.dispatch({ type: 1, uld_price_change: true });
              }}
            />
          </div>

          <div class="col-1">
            {i === 0 ? <label>Unit</label> : ""}
            <select
              id={"uld-pricing-unit" + i}
              class="form-control dropdown"
              tabIndex={0}
              //disabled={i === 0 ? false : true}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) => {
                store_errors.dispatch({ type: 1, current_input: "" });
              }}
              onChange={(e) => {
                store_pricing.dispatch({
                  type: 1,
                  update_uld_price: true,
                  per_kg: e.target.value === "true",
                  index: i,
                });
                formatULDPrice();
                store_pricing.dispatch({ type: 1, uld_price_change: true });
              }}
            >
              <option value={u.per_kg ? true : false}>
                {" "}
                {u.per_kg ? Util.CONST.PER_KG : Util.CONST.PER_ULD}{" "}
              </option>
              <option value={u.per_kg ? false : true}>
                {" "}
                {u.per_kg ? Util.CONST.PER_ULD : Util.CONST.PER_KG}{" "}
              </option>
            </select>
          </div>

          <div class="col-1">
            {i === 0 ? <label>U. Rate</label> : ""}
            <input
              id={"uld_rate" + i}
              type="text"
              class="form-control"
              defaultValue={u.uld_rate ? u.uld_rate : ""}
              tabindex="0"
              onChange={(e) => {
                store_pricing.dispatch({
                  type: 1,
                  update_uld_price: true,
                  uld_rate: e.target.value,
                  index: i,
                });
                formatULDPrice();
                //calculateULDPrice();
              }}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) => {
                store_pricing.dispatch({ type: 1, uld_price_change: true });
                store_errors.dispatch({ type: 1, current_input: "" });
              }}
            />
          </div>

          <div class="col-2">
            {i === 0 ? <label>Over-pivot Rate/kg</label> : ""}
            <input
              id={"uld_over_pivot" + i}
              type="text"
              class={"form-control " + is_invalid}
              defaultValue={u.over_pivot_rate}
              tabindex="0"
              onChange={(e) => {
                store_pricing.dispatch({
                  type: 1,
                  update_uld_price: true,
                  over_pivot_rate: e.target.value,
                  index: i,
                });
                formatULDPrice();
                //calculateULDPrice();
              }}
              onBlur={(e) => {
                store_pricing.dispatch({ type: 1, uld_price_change: true });
                store_errors.dispatch({ type: 1, current_input: "" });
              }}
            />
          </div>

          <div class="col-2 important-input">
            {i === 0 ? <label>Selling Rate</label> : ""}
            <div class="input-group mb-3">
              <input
                id={"uld_selling_rate" + i}
                type="text"
                class={
                  "form-control " +
                  (selling_rate_idxs && selling_rate_idxs.indexOf(i) >= 0
                    ? "is-invalid"
                    : "")
                }
                defaultValue={u.selling_rate}
                tabindex="0"
                onFocus={(e) => {
                  displaySimilars();
                  let { autoPopup } = store_similar.getState();
                  if (hasExactQuotes() && autoPopup) {
                    store_modals.dispatch({ type: 1, ModalSimilarExact: true });
                    store_similar.dispatch({ type: 1, autoPopup: false });
                  }
                }}
                onBlur={(e) => {
                  store_errors.dispatch({ type: 1, current_input: "" });
                }}
                onChange={(e) => {
                  store_pricing.dispatch({
                    type: 1,
                    update_uld_price: true,
                    selling_rate: e.target.value,
                    index: i,
                  });
                  //calculateULDPrice();
                }}
              />

              {i === 0 &&
                (store_similar.getState().exact ||
                  store_similar.getState().similar) && (
                  <div
                    class="input-group-prepend"
                    data-type={
                      store_similar.getState().exact
                        ? "error"
                        : store_similar.getState().similar
                        ? "warning"
                        : ""
                    }
                    delay-hide={1000}
                    data-background-color={Util.COLORS.RED_TOOLTIP}
                    data-tip={
                      store_similar.getState().exact
                        ? "Display exact same quote(s)"
                        : store_similar.getState().similar
                        ? "Display similar quote(s)"
                        : ""
                    }
                    onClick={(e) => {
                      displaySimilars();
                      if (
                        store_similar.getState().similar ||
                        store_similar.getState().exact
                      ) {
                        store_modals.dispatch({
                          type: 1,
                          ModalSimilarExact: true,
                        });
                      }
                    }}
                  >
                    <span class="input-group-text" id="basic-addon1">
                      {store_similar.getState().exact ? (
                        <img
                          src={require("../img/exclamation_red_16.png")}
                          alt="Warning"
                        />
                      ) : store_similar.getState().similar ? (
                        <img
                          src={require("../img/warning-sign.png")}
                          alt="Warning"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <ReactTooltip
                      place="top"
                      type="error"
                      effect="solid"
                      delayHide={500}
                    />
                  </div>
                )}
            </div>
          </div>

          <div class="col-2">
            {i === 0 ? <label>Currency</label> : ""}
            <select
              class="form-control dropdown"
              disabled={i === 0 ? false : true}
              tabindex="0"
              onChange={(e) => {
                store_pricing.dispatch({
                  type: 1,
                  update_uld_price: true,
                  currency: e.target.value,
                  index: i,
                });
                formatULDPrice();
                store_pricing.dispatch({ type: 1, uld_price_change: true });
              }}
              onBlur={(e) => {}}
            >
              <option value={currency}> {currency} </option>
              {util.CURRENCIES.filter((c) => c !== currency).map((c) => {
                return <option value={c}> {c} </option>;
              })}
            </select>
          </div>
        </div>
      );
    });

    return (
      <div key={this.state.key}>
        <div style={{ marginLeft: "1em" }}>{html}</div>
      </div>
    );
  }
}


class PriceSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now(), 
      current_input: null, 
      autoPopup: true ,
      dateChangedByUser: false,
      carrierChangedByUser: false,
    };
    //this.onBlurCarrier.bind(this);
    this.onChangeCarrier.bind(this);
  }

  componentDidUpdate() {
    // let input = document.getElementById(store_errors.getState().current_input)
    // if(input) input.focus()
  }

  componentDidMount() {
    let w1 = watch(store_pricing.getState, "force");
    store_pricing.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal, oldVal!==newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_pricing.getState, "per_kg");
    store_pricing.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w22 = watch(store_pricing.getState, "currency");
    store_pricing.subscribe(
      w22((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_errors.getState, "price");
    store_errors.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('errors volume %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w4 = watch(store_errors.getState, "issue_carrier");
    store_errors.subscribe(
      w4((newVal, oldVal, objectPath) => {
        //console.log('errors volume %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w5 = watch(store_similar.getState, "similar");
    store_similar.subscribe(
      w5((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w6 = watch(store_similar.getState, "exact");
    store_similar.subscribe(
      w6((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w7 = watch(store_shipment.getState, "dimension_type");
    store_shipment.subscribe(
      w7((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w8 = watch(store_errors.getState, "from_date");
    store_errors.subscribe(
      w8((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w9 = watch(store_errors.getState, "to_date");
    store_errors.subscribe(
      w9((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w10 = watch(store_errors.getState, "flight_date");
    store_errors.subscribe(
      w10((newVal, oldVal, objectPath) => {
        //console.log(' similar %s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  async onBlurCarrier(authorized_stations) {
    store_errors.dispatch({ type: 1, current_input: "" });
  }

  async updatePrices(authorized_stations) {

    store_modals.dispatch({ type: 1, page_loading_spinner2: true });
    await getSellingPrices({ authorized_stations: authorized_stations });
    store_modals.dispatch({ type: 1, page_loading_spinner2: false });

    let pricing = store_pricing.getState();
    let prices = store_prices.getState().sell_prices;
    let carrier = pricing.carrier;
    let price = prices.filter((p) => p.carrier === carrier)[0];
    let custom_list = this.props && this.props.custom_list ? this.props.custom_list : [];
    let config = getConfig(custom_list);

    let to_date = undefined
    let quote_validity_period = config.quote_validity_period ? config.quote_validity_period : null
    
    if(quote_validity_period)
      to_date = Util.getCurrentDate(quote_validity_period);
    
    if(to_date)
      store_pricing.dispatch({ type: 1, to_date: to_date});

    if (config && config.all_in)
      store_pricing.dispatch({ type: 1, force: true, all_in: true });
    else if (price && price.all_in_rate === "Y")
      store_pricing.dispatch({ type: 1, force: true, all_in: true });
    else store_pricing.dispatch({ type: 1, force: true, all_in: false });
  }

  onChangeCarrier(e, carriers_list, authorized_stations) {
    let carrier_new = e.target.value.toUpperCase().trim();
    let header = store_header.getState();
    let shipment = store_shipment.getState();
    let station_ = this.props.authorized_stations.filter( (s) => s.station === header.station)[0];
    let custom_list = this.props && this.props.custom_list ? this.props.custom_list : [];

    
    //will need to update margin later
    store_margin.dispatch({ type: 1, updateMargin: true });
    store_similar.dispatch({ type: 1, exact: false, similar: false });
    //certaines compagnies aériennes ont un code spécial
    let issue_carrier = Util.CARRIER_TO_ISSUE_CARRIER[carrier_new] ? Util.CARRIER_TO_ISSUE_CARRIER[carrier_new] : carrier_new;

    //on récupère les infos
    let carrier = carriers_list.filter((c) => c.code === issue_carrier.toUpperCase())[0];


    store_pricing.dispatch({
      type: 1,
      carrier: carrier_new,
      carrier_name: carrier !== undefined ? carrier.name : "",
      carrier_prefix: carrier !== undefined ? carrier.prefix : "",
      all_in_rate_oc_list: carrier !== undefined ? carrier.all_in_rate_oc_list : "",
      def_oc_list: carrier !== undefined ? carrier.def_oc_list : "",
    });


    store_shipment.dispatch({
      type: 1,
      calculate_price_class: true,
      carrier: carrier_new.trim(),
      sales_region: station_ ? station_.sales_region : "",
    });

    let awb_prefix = carrier !== undefined && carrier.prefix !== "ECS" ? carrier.prefix : "";
    let awb_serial = store_header.getState().serial;
    let awb_number = awb_prefix + "-" + awb_serial === "-" ? "" : awb_prefix + "-" + awb_serial;
    
    store_header.dispatch({
      type: 1,
      prefix: awb_prefix,
      awb_number: awb_number,
    });

    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });

    if (shipment.dimension_type === Util.CONST.ULD) {
      loadULDConfig(this.props.custom_list);
      store_shipment.dispatch({ type: 1, calculateUld: true });
      let reset = true;
      formatULDPrice(reset);
    }

    this.updatePrices(authorized_stations);
  }

  onChangeAllIn(e) {
    let pricing = store_pricing.getState();
    //we reset buy edit values in case it was edited before in a different mode
    if (!pricing.all_in) {
      store_margin.dispatch({
        type: 1,
        marginPerkg: true,
        marginInTotal: true,
        net_buy_edit: 0,
        net_buy_edit2: 0,
      });
    } else {
      store_margin.dispatch({
        type: 1,
        marginPerkg: true,
        marginInTotal: true,
        all_in_buy_edit: 0,
        all_in_buy_edit2: 0,
      });
    }

    //LETS CHECK IF CURRENT CARRIER PRICE IS ALL IN OR NOT
    let prices = store_prices.getState().sell_prices;
    let carrier = pricing.carrier;
    let custom_list =
      this.props && this.props.custom_list ? this.props.custom_list : [];
    let config = getConfig(custom_list);
    let price = prices.filter((p) => {
      return p.carrier === carrier;
    })[0];
    let all_in = !pricing.all_in;

    if (!all_in && config && config.all_in) {
      store_pricing.dispatch({ type: 1, all_in: !pricing.all_in });
      return store_modals.dispatch({ type: 1, modalAllin: true });
    } else if (price && !all_in && price.all_in_rate === "Y") {
      store_pricing.dispatch({ type: 1, all_in: !pricing.all_in });
      return store_modals.dispatch({ type: 1, modalAllin: true });
    } else if (all_in && config && !config.all_in) {
      store_pricing.dispatch({ type: 1, all_in: !pricing.all_in });
      return store_modals.dispatch({ type: 1, modalAllin: true });
    } else {
      store_margin.dispatch({ type: 1, updateMargin: true });
      store_pricing.dispatch({ type: 1, all_in: !pricing.all_in });
    }
  }

  async onFocusPrice(e) {
    store_errors.dispatch({ type: 1, current_input: e.target.id });
    displaySimilars();
    let { autoPopup } = store_similar.getState();
    if (hasExactQuotes() && autoPopup) {
      store_modals.dispatch({ type: 1, ModalSimilarExact: true });
      store_similar.dispatch({ type: 1, autoPopup: false });
    }
  }

  onChangePrice(e) {
    let pricing = store_pricing.getState();

    store_margin.dispatch({ type: 1, updateMargin: true });

    if (pricing.per_kg)
      store_pricing.dispatch({ type: 1, price: e.target.value });
    else {
      store_pricing.dispatch({
        type: 1,
        price_total: e.target.value,
        min_amount: e.target.value,
      });
    }
  }

  render() {
    let errors = store_errors.getState();
    let shipment = store_shipment.getState();
    let pricing = store_pricing.getState();

    return (
      <div
        key={this.state.key}
        onBlur={() => this.setState({ current_input: null })}
      >
        <div class="row">
          <div class="col-4">
            <form tabindex="10" noValidate>
              <TextField
                id="date"
                label="From Date"
                type="date"
                tabindex="10"
                defaultValue={Util.dateToString3(pricing.from_date)}
                onChange={(e) => {
                  store_pricing.dispatch({
                    type: 1,
                    force: true,
                    from_date: new Date(e.target.value),
                  });
                  store_errors.dispatch(checkForm());
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <div class="invalid">
              {errors.from_date ? errors.from_date : ""}{" "}
            </div>
          </div>


{/* Kaoutar Date / Carrier */}
          <div class="col-3">
            <form tabindex="10" noValidate>
              <TextField
                id="date"
                label="To Date"
                type="date"
                tabindex="10"
                defaultValue={Util.dateToString3(pricing.to_date)}
                onChange={(e) => {
                  store_pricing.dispatch({
                    type: 1,
                    to_date: new Date(e.target.value),
                  });
                 
                  store_errors.dispatch(checkForm());
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
           
            <div class="invalid">
              {errors.to_date
                ? errors.to_date
                : errors.to_date_1
                ? errors.to_date_1
                : ""}{" "}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2 important-input">
            <label>Issue Carrier*</label>
            <input
              type="text"
              id="pricing_carrier"
              class={
                "form-control " + (errors.issue_carrier ? "is-invalid" : "")
              }
              placeholder=""
              maxlength="3"
              defaultValue={pricing.carrier}
              onFocus={(e) =>
                store_errors.dispatch({ type: 1, current_input: e.target.id })
              }
              onBlur={(e) => {
                this.onBlurCarrier(this.props.authorized_stations);
                store_errors.dispatch({ type: 1, current_input: "" });
              }}
              onChange={(e) =>
              {
                this.setState({ carrierChangedByUser: true })
                this.onChangeCarrier(
                  e,
                  this.props.carriers_list,
                  this.props.authorized_stations
                )
              }
                
              }
              data-background-color={Util.COLORS.RED_TOOLTIP}
              data-type="error"
              delay-hide={1000}
              data-tip={errors.issue_carrier !== "" ? errors.issue_carrier : ""}
            />
            <ReactTooltip
              place="top"
              type="error"
              effect="solid"
              delayHide={500}
            />
          </div>

          {shipment.dimension_type === Util.CONST.ULD ? (
            <div class="col-9">
              <ULDPricingSection />
            </div>
          ) : (
            <>
              <div class="col-2 important-input">
                <label>
                  {" "}
                  {pricing.per_kg ? "Selling Rate*" : "Selling Total*"}{" "}
                </label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    id="pricing_price"
                    class={"form-control " + (errors.price ? "is-invalid" : "")}
                    placeholder=""
                    defaultValue={
                      pricing.per_kg ? pricing.price : pricing.price_total
                    }
                    maxlength="8"
                    onFocus={async (e) => {
                      this.onFocusPrice(e);
                    }}
                    onBlur={async (e) => {
                      store_errors.dispatch({ type: 1, current_input: "" });
                    }}
                    onChange={(e) => {
                      this.onChangePrice(e);
                    }}
                    data-background-color={Util.COLORS.RED_TOOLTIP}
                    data-type="error"
                    delay-hide={1000}
                    data-tip={errors.price ? errors.price : ""}
                  />

                  {(store_similar.getState().exact ||
                    store_similar.getState().similar) && (
                    <div
                      class="input-group-prepend"
                      data-type={
                        store_similar.getState().exact
                          ? "error"
                          : store_similar.getState().similar
                          ? "warning"
                          : ""
                      }
                      delay-hide={1000}
                      data-background-color={Util.COLORS.RED_TOOLTIP}
                      data-tip={
                        store_similar.getState().exact
                          ? "Display exact same quote(s)"
                          : store_similar.getState().similar
                          ? "Display similar quote(s)"
                          : ""
                      }
                      onClick={(e) => {
                        displaySimilars();
                        if (
                          store_similar.getState().similar ||
                          store_similar.getState().exact
                        ) {
                          store_modals.dispatch({
                            type: 1,
                            ModalSimilarExact: true,
                          });
                        }
                      }}
                    >
                      <span class="input-group-text" id="basic-addon1">
                        {store_similar.getState().exact ? (
                          <img
                            src={require("../img/exclamation_red_16.png")}
                            alt="Warning"
                          />
                        ) : store_similar.getState().similar ? (
                          <img
                            src={require("../img/warning-sign.png")}
                            alt="Warning"
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  )}
                </div>
                <ReactTooltip
                  place="top"
                  type="error"
                  effect="solid"
                  delayHide={500}
                />
              </div>

              <div class="col-1">
                <label>Unit</label>
                <select
                  id="pricing-unit"
                  class="form-control dropdown"
                  onChange={(e) => {
                    store_pricing.dispatch({
                      type: 1,
                      per_kg: e.target.value === "true",
                      price: e.target.value === "true" && pricing.price_total && shipment.chargeable_weight ? parseFloat(pricing.price_total)/parseFloat(shipment.chargeable_weight)  : "" ,
                      price_total: e.target.value !== "true" && pricing.price && shipment.chargeable_weight ? parseFloat(pricing.price)*parseFloat(shipment.chargeable_weight)  : "" ,
                      min_amount: 0,
                      min_chargeable_weight: 0,
                    });

                    store_margin.dispatch({
                      type: 1,
                      reset: true,
                      force: true,
                    });

                    store_margin.dispatch({
                      type: 1,
                      per_kg: e.target.value === "true",
                      force: true,
                    });
                  }}
                >
                  <option value={pricing.per_kg ? true : false}>
                    {" "}
                    {pricing.per_kg ? Util.CONST.PER_KG : Util.CONST.TOTAL}{" "}
                  </option>
                  <option value={pricing.per_kg ? false : true}>
                    {" "}
                    {pricing.per_kg ? Util.CONST.TOTAL : Util.CONST.PER_KG}{" "}
                  </option>
                </select>
              </div>

              <div class="col-2">
                <label>Currency</label>
                <CurrencyDropdown />
              </div>

              {!pricing.per_kg
                ? null
                : [
                    <div class="col-2">
                      <label>Min. Chg. Wgt.</label>
                      <div class="input-group mb-3">
                        <input
                          id="shipment_chg_wgt"
                          type="text"
                          maxlength="6"
                          class={
                            "form-control " +
                            (errors.min_chargeable_weight ? "is-invalid" : "")
                          }
                          placeholder=""
                          aria-label="min_amount"
                          aria-describedby="basic-addon1"
                          defaultValue={
                            pricing.min_chargeable_weight
                              ? parseFloat(
                                  pricing.min_chargeable_weight
                                ).toFixed(2)
                              : null
                          }
                          data-background-color={Util.COLORS.RED_TOOLTIP}
                          data-type={"error"}
                          delay-hide={1000}
                          data-tip={
                            errors.min_chargeable_weight
                              ? errors.min_chargeable_weight
                              : ""
                          }
                          onChange={(e) => {
                            store_pricing.dispatch({
                              type: 1,
                              min_chargeable_weight: e.target.value.replace(
                                ",",
                                "."
                              ),
                            });
                          }}
                        />

                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            kg
                          </span>
                        </div>
                        <ReactTooltip
                          place="top"
                          type="error"
                          effect="solid"
                          delayHide={500}
                        />
                      </div>
                    </div>,

                    <div class="col-2">
                      <label>Min. Amount</label>
                      <div class="input-group mb-3">
                        <input
                          id="shipment_chg_wgt"
                          type="text"
                          maxlength="8"
                          defaultValue={
                            pricing.min_amount
                              ? parseFloat(pricing.min_amount).toFixed(2)
                              : null
                          }
                          onChange={(e) => {
                            store_pricing.dispatch({
                              type: 1,
                              min_amount: e.target.value.replace(",", "."),
                            });
                          }}
                          class="form-control"
                          placeholder=""
                          aria-label="min_amount"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>,
                  ]}
            </>
          )}

          <div class="col-1" style={{ textAlign: "center" }}>
            <div class="checkbox_main_deck">
              <FormControlLabel
                value="bottom"
                label="All-In"
                labelPlacement="top"
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    defaultChecked={pricing.all_in}
                    onChange={(e) => {
                      this.onChangeAllIn(e);
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CommodityField extends React.Component {
  constructor(props) {
    super(props);
    this.curr_list = ["EUR", "USD", "GBP"];
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    let w2 = watch(store_shipment.getState, "commodity");
    store_shipment.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    return (
      <div class="col" key={this.state.key}>
        <label>Commodity</label>
        <input
          type="text"
          class="form-control"
          defaultValue={store_shipment.getState().commodity}
          disabled
        />
      </div>
    );
  }
}

class CurrencyDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.curr_list = Util.CURRENCIES;
  }

  componentDidMount() {
    let w1 = watch(store_pricing.getState, "currency");
    store_shipment.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let current = store_pricing.getState().currency;
 
    return (
      <select
        id="pricing-currency-dropdown"
        class="form-control dropdown"
        onChange={(e) => {
          store_pricing.dispatch({ type: 1, currency: e.target.value });
        }}
      >
        <option value={current}>{current}</option>
        {util.CURRENCIES.filter(
          (c) => c !== store_pricing.getState().currency
        ).map((c) => {
          return <option value={c}> {c} </option>;
        })}
      </select>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      key: Date.now(),
      current_input: null,
    };

    this.clickEquote = this.clickEquote.bind(this);
    this.sendCargospot = this.sendCargospot.bind(this);
    this.save = this.save.bind(this);
    this.newQuote = this.newQuote.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    store_prices.subscribe(() => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({ show: true, key: Date.now() });
    });

    store_header.subscribe(() => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({ show: true, key: Date.now() });
    });

    store_shipment.subscribe(() => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({ show: true, key: Date.now() });
    });

    store_pricing.subscribe(() => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({ show: true, key: Date.now() });
    });

    store_routes.subscribe(() => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({ show: true, key: Date.now() });
    });
  }

  componentDidUpdate() {
    let current_input = document.getElementById(this.state.current_input);
    if (current_input) current_input.focus();
  }

  onChangePrefix(e) {
    let awb_prefix = e.target.value;

    store_header.dispatch({ type: 1, prefix: awb_prefix });

    if (awb_prefix && awb_prefix.length === 3) {
      let next_input = document.getElementById("awb_serial");
      if (next_input) next_input.focus();
    }
  }

  onChangeSerial(e) {
    store_header.dispatch({ type: 1, serial: e.target.value });
  }

  copyText(text) {
    var div = document.createElement("input");
    div.value = text;
    div.contentEditable = true;
    document.body.appendChild(div);

    div.select();
    div.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(div);
  }

  async clickEquote() {
    if (!checkMandatoryEquote()) return;

    let { id } = store_header.getState();
    let { ocdc_auto_calculate, ocdc } = store_pricing.getState();
    let { station } = store_header.getState();
    let authorized_stations = this.props.authorized_stations;
    let custom_list = this.props.custom_list;
    let station_ = authorized_stations.filter((s) => s.station === station)[0];

    if (store_margin.getState().updateMargin) {
      store_modals.dispatch({ type: 1, page_loading_spinner: true });

      if (ocdc_auto_calculate && ocdc.length > 0 && id) {
        await calculateOCDC();
        store_pricing.dispatch({ type: 1, ocdc_auto_calculate: false });
      }

      if (checkMandatory()) {
        await calculateMargin({ station: station_, custom_list: custom_list });
        store_margin.dispatch({ type: 1, updateMargin: false });
      }

      store_modals.dispatch({ type: 1, page_loading_spinner: false });
    }

    store_modals.dispatch({ type: 1, modalConfirmationEquote: true });

    await saveQuote();
  }

  async cloneQuote() {
    let { prefix, id } = store_header.getState();
    let { int_remarks, attachments } = store_remarks.getState();

    attachments = attachments ? attachments : [];
    let attachmentExist = attachments.filter((a) => a.file);
    attachmentExist =
      attachmentExist && attachmentExist.length > 0 ? true : false;

    store_remarks.dispatch({ type: 1, reset: true });
    store_remarks.dispatch({ type: 1, int_remarks: int_remarks, force: true });
    store_header.dispatch({ type: 1, reset: true });
    store_similar.dispatch({ type: 1, reset: true });
    store_header.dispatch({ type: 1, prefix: prefix });
    store_shipment.dispatch({ type: 1, ffr_sent: false, email_sent: false });
    store_pricing.dispatch({
      type: 1,
      from_date: Util.getCurrentDate(),
      to_date: Util.getCurrentDate(14),
    });
    store_pricing.dispatch({ type: 1, force: true });

    store_margin.dispatch({ type: 1, reset: true });

    document.title = "Quantum - Quote";

    if (attachmentExist) {
      store_remarks.dispatch({ type: 1, attachments: attachments });
      store_modals.dispatch({ type: 1, modalCloneAttachment: true });
    }

    let logs = {
      type: "CLONE_QUOTE",
      info: "From : " + (id ? id : ""),
    };

    if (id) await Routes.get("/Logger", logs, true);
  }
  async sendCargospot() {
    
    let routingPoints = checkRoutingsPoints(store_routes.getState().routes)
    if (!checkMandatory() || routingPoints.includes(false)) return;

    let { ocdc_auto_calculate, ocdc, carrier } = store_pricing.getState();
    let { id, station } = store_header.getState();
    let { authorized_stations } = store_station.getState();
    authorized_stations = authorized_stations
      ? authorized_stations
      : this.props.authorized_stations;
    let custom_list = this.props.custom_list;
    let station_ = authorized_stations.filter((s) => s.station === station)[0];

    store_alerts.dispatch({
      type: 1,
      alert: { title: "", msg: "", type: "", show: "false" },
      force: true,
    });

    if (!isUserCarrier())
      return store_alerts.dispatch({
        type: 1,
        name: "top",
        alert: {
          title: "Problem saving quote",
          msg: `
            You cannot send adhoc on this airline : ${carrier}.<br/>
            Reason : You're not Allowed.`,
          type: "error",
          show: "true",
        },
      });

    store_header.dispatch({ type: 1, send_adhoc_disable: true });

    await getSellingPrices({ authorized_stations: authorized_stations });

    if (ocdc_auto_calculate && ocdc.length > 0 && id) {
      await calculateOCDC();
      store_pricing.dispatch({ type: 1, ocdc_auto_calculate: false });
    }

    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    store_history.dispatch({ type: 1, send_adhoc: true });

    await calculateMargin({ station: station_, custom_list: custom_list });

    store_margin.dispatch({ type: 1, updateMargin: false });
    store_modals.dispatch({ type: 1, page_loading_spinner: false });

    displaySimilars();

    store_header.dispatch({ type: 1, send_adhoc_disable: false });
    store_modals.dispatch({ type: 1, modalConfirmationAdhoc: true });
  }

  async save() {
    store_errors.dispatch(checkForm());

    let routes = store_routes.getState().routes;
    let routingPoints = checkRoutingsPoints(routes)

    if ((!checkMandatory() && store_header.getState().status !== "DRAFT") || routingPoints.includes(false))
      return null;

    let pricing = store_pricing.getState();

    let { ocdc_auto_calculate, ocdc } = store_pricing.getState();
    let { id, station } = store_header.getState();
    let { authorized_stations } = store_station.getState();
    let custom_list = this.props.custom_list;
    authorized_stations = authorized_stations
      ? authorized_stations
      : this.props.authorized_stations;
    let station_ = authorized_stations.filter((s) => s.station === station)[0];

    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    store_routes.dispatch({ type: 1, force: true });
    store_shipment.dispatch({ type: 1, force: true });

    await getSellingPrices({ authorized_stations: authorized_stations });

    if (store_margin.getState().updateMargin) {
      await calculateMargin({ station: station_, custom_list: custom_list });
      store_margin.dispatch({ type: 1, updateMargin: false });

      if (ocdc_auto_calculate && ocdc.length > 0 && id) {
        await calculateOCDC();
        store_pricing.dispatch({ type: 1, ocdc_auto_calculate: false });
      }
    }

    let id_quote = await saveQuote(custom_list);
    id_quote = !id_quote ? store_header.getState().id : id_quote;

    if (store_remarks.getState().updateAttachment)
      await saveAttachment(id_quote);

    displaySimilars();

    store_modals.dispatch({ type: 1, page_loading_spinner: false });
  }

  newQuote() {


    store_errors.dispatch({ type: 1, reset: true });
    store_header.dispatch({ type: 1, reset: true, force: true });
    store_shipment.dispatch({ type: 1, reset: true, force: true });
    store_remarks.dispatch({ type: 1, reset: true });
    store_remarks.dispatch({
      type: 1,
      ext_remarks: "",
      int_remarks: "",
      force: true,
    });
    store_routes.dispatch({ type: 1, reset_: true, force: true });

    store_pricing.dispatch({ 
      type: 1, 
      reset: true, 
      force: true
    });



    store_margin.dispatch({ type: 1, reset: true, force: true });
    store_modals.dispatch({ type: 1, reset: true, force: true });
    store_similar.dispatch({ type: 1, reset: true, force: true });
    store_prices.dispatch({
      type: 1,
      reset: true,
      force: true,
      chargeable_weight: store_shipment.getState().chargeable_weight,
    });

    store_schedule.dispatch({ type: 1, reset: true, force: true });

    loadConfig(this.props.authorized_stations, this.props.custom_list);


    this.setState({ key: Date.now() });

    //window.location.hash = '/Editor'
    //window.location.reload()
  }

  async print() {
    store_errors.dispatch(checkForm());
    let routes = store_routes.getState().routes;
    let routingPoints = checkRoutingsPoints(routes)

    if ((!checkMandatory() && store_header.getState().status !== "DRAFT") || routingPoints.includes(false))
      return null;

    //store_margin.dispatch({type : 1, updateMargin : true})
    let { ocdc_auto_calculate, ocdc } = store_pricing.getState();
    let { id, station } = store_header.getState();
    let { authorized_stations } = store_station.getState();
    authorized_stations = authorized_stations
      ? authorized_stations
      : this.props.authorized_stations;
    let custom_list = this.props.custom_list;
    let station_ = authorized_stations.filter((s) => s.station === station)[0];

    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    await getSellingPrices({ authorized_stations: authorized_stations });
    store_shipment.dispatch({ type: 1, force: true });

    if (store_margin.getState().updateMargin) {
      await calculateMargin({ station: station_, custom_list: custom_list });
      store_margin.dispatch({ type: 1, updateMargin: false });

      if (ocdc_auto_calculate && ocdc.length > 0 && id) {
        await calculateOCDC();
        store_pricing.dispatch({ type: 1, ocdc_auto_calculate: false });
      }

      store_modals.dispatch({ type: 1, page_loading_spinner: false });
    }

    store_modals.dispatch({ type: 1, page_loading_spinner: false });
    store_margin.dispatch({ type: 1, updateMargin: false });
    store_modals.dispatch({ type: 1, modalPrint: true });
    await saveQuote();

    displaySimilars();
  }

  onChangeStation(e) {
    store_header.dispatch({ type: 1, station: e.target.value });
    let station = this.props.authorized_stations.filter(
      (s) => s.station === e.target.value
    )[0];
    store_station.dispatch({
      type: 1,
      station: e.target.value,
      sales_region: station ? station.sales_region : null,
      origin_zone: station ? station.origin_zone : null,
    });
    store_pricing.dispatch({ type: 1, ocdc_auto_calculate: true });
  }

  async onClickHistory(e) {
    let header = store_header.getState();

    if (!header.id) return;
    store_modals.dispatch({ type: 1, page_loading_spinner: true });
    let params = { id: header.id };
    let history = await Routes.get("/QuoteHistory", params, true);
    history = history ? history : [];
    store_history.dispatch({ type: 1, history: history });
    store_modals.dispatch({ type: 1, page_loading_spinner: false });
    store_modals.dispatch({ type: 1, modalQuoteHistory: true });
  }

  onClickCalculator() {
    let shipment = store_shipment.getState();
    let pricing = store_pricing.getState();
    let routing = store_routes.getState();
    let header = store_header.getState();
    let station = store_station.getState();
    //let calculator = store_calculator.getState()

    store_calculator.dispatch({
      type: 1,
      detailed_info: routing.detailed_info,
    });

    store_calculator.dispatch({
      type: 1,
      station: header.station,
      sales_region: station.sales_region,
      origin_zone: station.origin_zone,
      origin: shipment.origin,
      dest: shipment.dest,
      agent: shipment.agent,
      agent_code: shipment.agent_code,
      shc: shipment.shc,
      chargeable_weight: shipment.chargeable_weight,
      carrier: pricing.carrier,
      rate: pricing.per_kg ? pricing.price : pricing.price_total,
      all_in: pricing.all_in,
      currency: pricing.currency,
      per_kg: pricing.per_kg,
      from_date: pricing.from_date,
      to_date: pricing.to_date,
      routing: [...routing.routes],
    });

    store_modals.dispatch({ type: 1, modalCalculator: true });
  }

  render() {
    let header = store_header.getState();
    let shipment = store_shipment.getState();
    let {
      prefix,
      serial,
      station,
      status,
      id,
      external_id,
      adhoc_ref,
      adhoc_ref_buy,
      hq,
      user,
      creation_date,
      failure_reason,
      last_updated_by,
      last_update,
      follow_up,
    } = header;

    return (
      <div
        class="row row-header"
        style={{ verticalAlign: "middle" }}
        key={this.state.key}
        onBlur={() => this.setState({ current_input: null })}
      >
        <div class="col-9">
          <table class="table table-striped small-table">
            <thead class="thead-light">
              <tr class="">
                {cookies.region === "WLD" && <th>HQ</th>}
                <th>Station</th>
                <th>ID</th>
                <th>AdHoc Ref</th>
                <th>Status</th>
                <th>AWB Number</th>
                <th>Failure Reason</th>
                <th>Created By</th>
                <th>Creation Date</th>
                <th>Last Updated By</th>
                <th>Last Update</th>
                <th>To Follow Up</th>
              </tr>
            </thead>

            <tbody>
              {cookies.region === "WLD" && (
                <td
                  id="header_hq"
                  onFocus={(e) => {
                    this.setState({ current_input: e.target.id });
                  }}
                  onBlur={() => this.setState({ current_input: null })}
                >
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        color="primary"
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                        defaultChecked={hq}
                        onChange={(e) => {
                          store_header.dispatch({ type: 1, hq: !hq });
                        }}
                      />
                    }
                  />
                </td>
              )}

              <td>
                <select
                  class="form-control dropdown"
                  id="header_station"
                  style={{ maxWidth: "5em" }}
                  onFocus={(e) => {
                    this.setState({ current_input: e.target.id });
                  }}
                  onBlur={() => this.setState({ current_input: null })}
                  onChange={(e) => {
                    this.onChangeStation(e);
                  }}
                >
                  <option value={station}>{station} </option>
                  {this.props.authorized_stations
                    .filter((s) => s.station !== station)
                    .map((s) => {
                      return (
                        <option value={s.station}>
                          {s.country_code} - {s.station_name} - {s.station}{" "}
                        </option>
                      );
                    })}
                </select>
              </td>

              <td>
                <div class="input-group">
                  <input
                    id="header-id"
                    class="form-control"
                    style={{ maxWidth: "5em" }}
                    type="text"
                    value={id}
                    data-tip={
                      external_id ? "External ID: " + external_id : null
                    }
                    data-type="dark"
                    onClick={() => this.copyText(id)}
                    onDoubleClick={() => this.copyText(external_id)}
                  />
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text copy-prepend"
                      onClick={() => this.copyText(id)}
                      onDoubleClick={() => this.copyText(external_id)}
                    >
                      <FileCopyIcon fontSize="small" />
                    </span>
                  </div>
                  <ReactTooltip effect="solid" />
                </div>
              </td>

              <td
                data-type="dark"
                data-tip={
                  adhoc_ref_buy ? "Adhoc buy ref : " + adhoc_ref_buy : null
                }
              >
                <div class="input-group">
                  <input
                    id="header-adhoc-sell"
                    class="form-control"
                    style={{ maxWidth: "5em" }}
                    type="text"
                    value={header.adhoc_ref}
                    onClick={() => this.copyText(adhoc_ref)}
                    onDoubleClick={() => this.copyText(adhoc_ref_buy)}
                  />
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text copy-prepend"
                      onClick={() => this.copyText(adhoc_ref)}
                      onDoubleClick={() => this.copyText(adhoc_ref_buy)}
                    >
                      <FileCopyIcon fontSize="small" />
                    </span>
                  </div>
                </div>
                <ReactTooltip effect="solid" />
              </td>

              <td>
                <StatusDropdown />
              </td>

              <td style={{ maxWidth: "15em", paddingLeft: "1em" }}>
                <div class="row">
                  <div class="col-4">
                    <input
                      id="awb_prefix"
                      type="text"
                      class="form-control"
                      defaultValue={prefix ? prefix : ""}
                      onFocus={(e) => {
                        this.setState({ current_input: e.target.id });
                      }}
                      onBlur={() => this.setState({ current_input: null })}
                      onChange={(e) => this.onChangePrefix(e)}
                      maxlength="3"
                    />
                  </div>

                  <div class="col-8">
                    <input
                      id="awb_serial"
                      type="text"
                      class="form-control"
                      defaultValue={serial && serial !== "null" ? serial : ""}
                      onFocus={(e) => {
                        this.setState({ current_input: e.target.id });
                      }}
                      onBlur={() => this.setState({ current_input: null })}
                      onChange={(e) => this.onChangeSerial(e)}
                      maxlength="8"
                    />
                  </div>
                </div>
              </td>

              <td>
                {failure_reason && failure_reason.length > 20
                  ? failure_reason.substring(0, 20) + "..."
                  : failure_reason}
              </td>

              <td>{user ? user : ""}</td>

              <td>{!creation_date ? "" : Util.dateToString(creation_date)}</td>

              <td>
                <span>{last_updated_by ? last_updated_by : ""} </span>

                <HistoryIcon
                  color="black"
                  style={{ marginLeft: "1em", cursor: "pointer" }}
                  delay-hide={1000}
                  data-tip={"Open Quote History"}
                  data-type={"dark"}
                  disabled={id ? false : true}
                  onClick={async (e) => {
                    this.onClickHistory(e);
                  }}
                >
                  <ReactTooltip effect="solid" />
                </HistoryIcon>
              </td>

              <td>{last_update ? Util.dateToString(last_update) : ""}</td>

              <td>
                <FormControlLabel
                  value="bottom"
                  control={
                    <Checkbox
                      defaultChecked={follow_up}
                      color="primary"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                      onChange={(e) => followUp()}
                    />
                  }
                  labelPlacement="top"
                />
              </td>
            </tbody>
          </table>
        </div>

        <div class="col edit_menu">
          <SaveProgress />

          <button
            type="button"
            class="btn btn-primary"
            delay-hide={1000}
            data-type="error"
            data-background-color={
              !checkMandatory() && status !== "DRAFT"
                ? Util.COLORS.RED_TOOLTIP
                : "black"
            }
            data-tip={
              !checkMandatory() && status !== "DRAFT"
                ? Util.TEXTS.MANDATORY_FIELDS_TEXT
                : "Save quote"
            }
            onClick={this.save}
          >
            <SaveIcon fontSize="small" />
          </button>

          <button
            type="button"
            class="btn btn-primary"
            delay-hide={1000}
            data-background-color={
              !checkMandatory() && status !== "DRAFT"
                ? Util.COLORS.RED_TOOLTIP
                : "black"
            }
            data-tip={
              !checkMandatory() && status !== "DRAFT"
                ? Util.TEXTS.MANDATORY_FIELDS_TEXT
                : "View customer template"
            }
            data-type="error"
            onClick={this.print}
          >
            <PrintIcon fontSize="small" />
          </button>

          <button
            type="button"
            hidden={cookies.region === "WLD" ? false : true}
            class="btn btn-danger"
            delay-hide={1000}
            data-background-color={Util.COLORS.RED_TOOLTIP}
            data-tip={"Calculator"}
            data-type={"dark"}
            onClick={this.onClickCalculator}
          >
            <img src={require("../img/dollar-symbol.png")} alt="Calculator" />
            <ReactTooltip effect="solid" />
          </button>

          {external_id && user && user === Util.CONST.CARGOAI && (
            <button
              type="button"
              class={
                "btn " +
                (!checkMandatoryEquote() || shipment.equote_sent
                  ? "btn-secondary"
                  : "btn-danger")
              }
              delay-hide={1000}
              data-background-color={
                !checkMandatoryEquote() && status !== "DRAFT"
                  ? Util.COLORS.RED_TOOLTIP
                  : "black"
              }
              data-tip={
                false && shipment.equote_sent
                  ? "e-Quote already sent"
                  : !checkMandatoryEquote()
                  ? Util.TEXTS.MANDATORY_FIELDS_TEXT
                  : "Send e-Quote"
              }
              data-type={
                !checkMandatoryEquote() && status !== "DRAFT" ? "error" : "dark"
              }
              onClick={this.clickEquote}
            >
              <img
                style={{ maxWidth: "20px" }}
                src={require("../img/at.png")}
                alt="@"
              />
            </button>
          )}

          <button
            type="button"
            class="btn btn-danger"
            disabled={header.send_adhoc_disable}
            delay-hide={1000}
            data-background-color={
              !checkMandatory() && status !== "DRAFT"
                ? Util.COLORS.RED_TOOLTIP
                : ""
            }
            data-tip={
              !checkMandatory()
                ? Util.TEXTS.MANDATORY_FIELDS_TEXT
                : "Send AdHoc"
            }
            data-type={
              !checkMandatory() && status !== "DRAFT" ? "error" : "dark"
            }
            onClick={this.sendCargospot}
          >
            <img src={require("../img/cargospot.png")} alt="Save" />
            <ReactTooltip effect="solid" />
          </button>

          <button
            type="button"
            class="btn btn-danger"
            style={{ marginRight: "2.5em" }}
            delay-hide={1000}
            data-background-color={
              !checkMandatory() && status !== "DRAFT"
                ? Util.COLORS.RED_TOOLTIP
                : ""
            }
            data-tip={
              !checkMandatory() ? Util.TEXTS.MANDATORY_FIELDS_TEXT : "Send FFR"
            }
            data-type={
              !checkMandatory() && status !== "DRAFT" ? "error" : "dark"
            }
            onClick={async () => {
              let routingPoints = checkRoutingsPoints(store_routes.getState().routes)
              if (!checkMandatory() || routingPoints.includes(false)) return;
              store_modals.dispatch({ type: 1, modalBooking: true });
            }}
          >
            <img
              style={{ maxWidth: "20px" }}
              src={require("../img/paper-plane.png")}
              alt="Save"
            />
            <ReactTooltip effect="solid" />
          </button>

          <button
            type="button"
            class="btn btn-success"
            data-offset="{'top': 0, 'left': 10}"
            delay-hide={1000}
            data-type="dark"
            data-tip={"Clone quote"}
            onClick={this.cloneQuote}
          >
            <FileCopyIcon fontSize="small" />
          </button>

          <button
            type="button"
            class="btn btn-success"
            delay-hide={1000}
            data-tip={"Create new quote"}
            data-type="dark"
            onClick={this.newQuote}
          >
            <a href="#/Editor">
              <AddCircleIcon fontSize="small" />
            </a>
          </button>
        </div>
      </div>
    );
  }
}

class SellingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      key: Date.now(),
    };
  }

  componentDidMount() {
    store_prices.subscribe(() => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({ show: true, key: Date.now() });
    });

    let w = watch(store_modals.getState, "sellingTable");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_errors.getState, "sell_prices");
    store_errors.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  onClickRate(s) {
    if (s.selling_type && s.selling_type !== "ADHOC") {
      store_prices.dispatch({ type: 1, selected_rate: s });
      store_modals.dispatch({ type: 1, modalRateSelect: true });
    }
  }

  render() {
    let { sell_prices } = store_prices.getState();
    let shipment = store_shipment.getState();
    let errors = store_errors.getState();
    let html = [];

    if (!sell_prices || sell_prices.length < 1)
      return <span class="text-warning">{errors.sell_prices}</span>;

    let prices = sell_prices ? sell_prices : [];

    prices.forEach((s) => {
      let rates = null;

      if (s.rates) {
        rates = s.rates
          .replace(/N;|V;/g, "")
          .replace(/\|/g, " - ")
          .replace(/;/g, "kg ")
          .replace(/.0kg/g, "kg");
        rates = rates.split("-");
        rates = rates.filter((r) => r.indexOf(" 0.00") < 0);
        rates = rates.join(" - ");
      }

      let surcharges = [];
      if (s.surcharges)
        s.surcharges.split("|").forEach((s) => {
          surcharges.push(<span>{s}</span>);
          surcharges.push(<br />);
        });

      html.push(
        <tr
          style={{
            cursor:
              s.selling_type && s.selling_type !== "ADHOC" ? "pointer" : "",
          }}
          onClick={() => {
            this.onClickRate(s);
          }}
          data-type="dark"
          delay-hide={1000}
          data-tip={"Cargospot ID : " + s.seq.toString().substring(0, 8)}
        >
          <td>{s.for_carrier}</td>
          <td>{s.selling_type}</td>
          <td>{s.details}</td>
          <td>{s.currency}</td>
          <td>
            {s.rate_all_in
              ? (parseFloat(s.rate_all_in * 100) / 100).toFixed(2)
              : s.rate_all_in}
          </td>
          <td>{surcharges}</td>
          <td>
            {" "}
            MIN {s.min_amount} - {rates}
          </td>
          <td>{s.origin ? s.origin : s.origin_zone}</td>
          <td>{s.dest}</td>
          <ReactTooltip
            place="top"
            type="error"
            effect="solid"
            delayHide={500}
          />
        </tr>
      );
    });

    //add adhoc line to table
    let adhoc = store_similar.getState().prices.filter((s) => {
      let s_shipment = s.shipment;
      let shc = shipment.shc.filter((s) => s !== "");
      let s_shc = s_shipment.shc ? s_shipment.shc[0] : null;

      return (
        parseInt(s.id) !== parseInt(store_header.getState().id) &&
        shipment.agent_code.substring(0, 7) ===
          s.shipment.agent_code.substring(0, 7) &&
        ((s.pricing.price && s.pricing.price !== 0) ||
          (s.pricing.price_total && s.pricing.price_total !== 0)) &&
        (shc.indexOf(s_shc) >= 0 || (shc.length === 0 && s_shc === ""))
      );
    });

    if (adhoc && adhoc.length > 0) {
      let adhoc_price = adhoc.length ? adhoc[0].pricing : [];
      let adhoc_shipment = adhoc.length ? adhoc[0].shipment : [];
      let adhoc_margin = adhoc.length ? adhoc[0].margin : [];

      if (adhoc_price)
        html.push(
          <tr
            data-type="dark"
            data-tip={"ID : " + adhoc[0].id}
            delay-hide={1000}
          >
            <td>{adhoc_price.carrier}</td>
            <td>ADHOC</td>
            <td>{adhoc_shipment.shc}</td>
            <td>{adhoc_price.currency}</td>
            <td>{adhoc_margin.sell_all_in_rate}</td>
            <td></td>
            <td>
              {adhoc_shipment.chargeable_weight}kg {"-"}
              {adhoc_price.per_kg
                ? Util.CONST.PER_KG2 + " " + adhoc_price.price
                : Util.CONST.TOTAL + " " + adhoc_price.price_total}{" "}
              - {Util.convertDate(adhoc[0].last_follow_up)}
            </td>
            <td>{adhoc_shipment.origin} </td>
            <td>{adhoc_shipment.dest} </td>
            <ReactTooltip
              place="top"
              type="error"
              effect="solid"
              delayHide={500}
            />
          </tr>
        );
    }

    return (
      <div class="table-responsive" key={this.state.key}>
        <div id="selling_table_container">
          <table class="table table-striped small-table">
            <thead>
              <tr>
                <th scope="col">Carrier</th>
                <th scope="col">Type</th>
                <th scope="col">Details</th>
                <th scope="col">Curr</th>
                <th scope="col">All-in</th>
                <th scope="col">Surcharges</th>
                <th scope="col">Rates</th>
                <th scope="col">Origin</th>
                <th scope="col">Dest</th>
              </tr>
            </thead>
            <tbody>{html}</tbody>
          </table>
        </div>
        <br />
      </div>
    );
  }
}

class SaveProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
    this.style = {
      border: "green",
      top: -6,
      left: -6,
      zIndex: 2,
      color: "green",
    };
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "saveProgressBar");
    store_header.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let show = store_header.getState().saveProgressBar;

    if (!show) return null;
    else {
      return <span style={this.style}>Saved ✔ </span>;
    }
  }
}

class Eye extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: Date.now() };
  }

  componentDidMount() {
    let w = watch(store_pricing.getState, "display_ocdc_total");
    store_pricing.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let display_ocdc_total = store_pricing.getState().display_ocdc_total;

    if (!display_ocdc_total)
      return (
        <VisibilityOffIcon
          style={{ cursor: "pointer" }}
          data-type="info"
          onClick={(e) => {
            let display_ocdc_total =
              store_pricing.getState().display_ocdc_total;
            store_pricing.dispatch({
              type: 1,
              display_ocdc_total: !display_ocdc_total,
            });
          }}
          delay-hide={1000}
          data-tip={"Show Total Charges on Customer Template"}
        >
          <ReactTooltip
            place="top"
            type="error"
            effect="solid"
            delayHide={500}
          />
        </VisibilityOffIcon>
      );
    else {
      return (
        <VisibilityIcon
          style={{ cursor: "pointer" }}
          data-type="info"
          onClick={(e) => {
            let display_ocdc_total =
              store_pricing.getState().display_ocdc_total;
            store_pricing.dispatch({
              type: 1,
              display_ocdc_total: !display_ocdc_total,
            });
          }}
          delay-hide={1000}
          data-tip={"Show Total Charges on Customer Template"}
        >
          <ReactTooltip
            place="top"
            type="error"
            effect="solid"
            delayHide={500}
          />
        </VisibilityIcon>
      );
    }
  }
}

class ModalSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      withCarrier: false,
    };

    this.hide = this.hide.bind(this);
    this.RoutingView = this.RoutingView.bind(this);
    this.ScheduleView = this.ScheduleView.bind(this);
    this.fillRoutingNonDetailed = this.fillRoutingNonDetailed.bind(this);
    this.fillSchedule = this.fillSchedule.bind(this);
    this.emptySchedule = this.emptySchedule.bind(this);
    this.confirmRouting = this.confirmRouting.bind(this);
  }

  componentDidMount() {
    let w = watch(store_modals.getState, "modalSchedule");
    store_modals.subscribe(
      w((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_schedule.getState, "force");
    store_schedule.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  init() {}

  componentDidUpdate() {
    let input = document.getElementById(this.state.current_input);

    if (!input) return;
    input.focus();
  }

  handlewithCarrierChange = (event) => {
    this.setState({ withCarrier: event.target.checked });
  };

  hide(e) {
    store_modals.dispatch({ type: 1, modalSchedule: false });
    store_calculator.dispatch({ type: 1, schedule: false });
    store_schedule.dispatch({
      type: 1,
      schedule: [],
      selected_routing: [],
      current_carrier: null,
    });
  }

  handleForm(e) {
    let { selected_routing_string } = store_schedule.getState();
    let calculator = store_calculator.getState();
    let key = e.target.name;
    let val = e.target.value;

    if (key === "from_date") {
      val = new Date(val);
      val = val.toISOString();
    }

    let json = { type: 1 };
    json[key] = val;
    store_schedule.dispatch(json);

    //refresh routing
    let { detailed_info } = store_routes.getState();

    if (calculator.schedule) detailed_info = calculator.detailed_info;

    if (detailed_info) {
      store_modals.dispatch({ type: 1, modalSchedule: false });
      openSchedule(selected_routing_string);
    }
  }

  async fillSchedule(e) {
    e.preventDefault();
    let calculator = store_calculator.getState();
    let routing = e.target.getAttribute("name");
    let carrier = e.target.getAttribute("carrier");
    const { from_date } = store_schedule.getState();
    let { detailed_info } = store_routes.getState();
    if (calculator.schedule) detailed_info = calculator.detailed_info;

    store_schedule.dispatch({ type: 1, current_carrier: carrier });
    //keep routing in memory
    store_schedule.dispatch({ type: 1, selected_routing_string: routing });

    let params = {
      carrier: carrier,
      routing: routing,
      departure_date: from_date,
      range_of_dates: 7,
    };

    if (!detailed_info) params.request_type = 1;

    if (!detailed_info && params.departure_date)
      params.departure_date = params.departure_date.split("T")[0];

    store_modals.dispatch({ type: 1, page_loading_spinner: true });

    //call api search schedule
    let schedule = await Routes.get("/Schedules", params, true);
    schedule = schedule ? schedule : [];

    if (detailed_info) {
      store_schedule.dispatch({ type: 1, schedule: schedule, force: true });
    } else {
      let formatted_routing = [];
      let issue_carrier = schedule[0] ? schedule[0].carrier : "";

      for (let i = 0; i < schedule.length; i++) {
        let s = schedule[i];

        formatted_routing.push({
          origin: s.bpt,
          dest: s.opt,
          carrier: s.cr ? s.cr : s.carrier,
          flight_type: s.oper_typ === "F" ? "Flight" : "Truck",
          weekly_frequency: s.weekly_freq,
          operation_dow: s.oper_dow,
        });
      }

      formatted_routing = {
        routes: formatted_routing,
        detailed_info: false,
      };

      if (!calculator.schedule) {
        store_pricing.dispatch({
          type: 1,
          carrier: issue_carrier,
          force: true,
        });
        store_routes.dispatch({
          type: 1,
          routes: formatted_routing,
          force: true,
        });
        store_modals.dispatch({ type: 1, modalSchedule: false });
        store_schedule.dispatch({ type: 1, current_carrier: null });
        updateCarrierInfo(
          issue_carrier,
          this.props ? this.props.custom_list : []
        );
      } else {
        store_calculator.dispatch({
          type: 1,
          routing: formatted_routing.routes,
          force: true,
        });
      }
    }

    store_modals.dispatch({ type: 1, page_loading_spinner: false });
  }

  async fillRoutingNonDetailed() {
    //call api search schedule
    let { detailed_info, routes } = store_routes.getState();
    let calculator = store_calculator.getState();
    const { custom_list } = this.props;
    let { from_date } = store_schedule.getState();
    let routing = parseRouting();
    let carrier = parseCarrier();

    detailed_info = calculator.schedule
      ? calculator.detailed_info
      : detailed_info;
    routes = calculator.schedule ? calculator.routing : routes;

    store_modals.dispatch({ type: 1, page_loading_spinner: true });

    let params = {
      routing: routing,
      departure_date: from_date,
      range_of_dates: 7,
    };

    let schedule = [];

    if (!detailed_info) params.request_type = 1;

    if (!detailed_info && params.departure_date)
      params.departure_date = params.departure_date.split("T")[0];

    if (carrier.length === 1) {
      params.carrier = carrier[0];
      schedule = await Routes.get("/Schedules", params, true);

    } else {
      let index = 0;

      for (let i = 0; i < routes.length; i++) {
        params.carrier = routes[i].carrier;
        params.routing = routes[i].origin + "-" + routes[i].dest;

        let schedule_ = await Routes.get("/Schedules", params, true);

        for (let j = 0; j < schedule_.length; j++) {
          schedule_[j].id = index;
          schedule_[j].seg += i;
        }

        schedule = schedule.concat(schedule_);
        index++;
      }
    }

    let formatted_routing = formatSchedule(schedule);

    if (calculator.schedule) {
      store_calculator.dispatch({
        type: 1,
        routing: formatted_routing.routes,
        force: true,
      });
    } else {
      store_routes.dispatch({
        type: 1,
        routes: formatted_routing,
        force: true,
      });
      store_pricing.dispatch({ type: 1, carrier: carrier[0], force: true });
      updateCarrierInfo(carrier[0], custom_list);
    }

    store_modals.dispatch({
      type: 1,
      modalSchedule: false,
      page_loading_spinner: false,
    });
  }

  RoutingView() {
    let { carrier_routings, from_date } = store_schedule.getState();
    let { carriers_list } = this.props;
    let { station } = store_header.getState();
    let { station_carrier } = store_station.getState();
    let { detailed_info } = store_routes.getState();
    let calculator = store_calculator.getState();

    if (calculator.schedule) {
      station = calculator.station;
      detailed_info = calculator.detailed_info;
    }

    const style = {
      routing: {
        border: "2px solid #2b5876",
        marginTop: "3px",
        marginRight: "4px",
        cursor: "pointer",
        color: "#2b5876",
        fontWeight: "600",
        padding: "3px",
      },
      carrier: {
        textAlign: "center",
        fontWeight: "600",
        padding: "3px",
        color: "#57575",
      },
    };

    let tableHTML = [];
    let routingsHTML = [];
    let routing = parseRouting();

    carrier_routings = carrier_routings.filter(
      (r) =>
        station_carrier.filter(
          (r2) => r2.station === station && r2.carrier === r.carrier
        )[0]
    );

    if (!carrier_routings.length)
      tableHTML = (
        <div style={{ textAlign: "center" }}>
          {" "}
          <img src={require("../img/search_sad.png")}></img> <br />
          No Rounting found{" "}
        </div>
      );

    let current_carrier = carrier_routings.length
      ? carrier_routings[0].carrier
      : null;

    for (let i = 0; i < carrier_routings.length; i++) {
      let option = carrier_routings[i];
      let carrier = carriers_list.filter((c) => c.code === option.carrier)[0];
      let routing = option.routing;

      if (option.carrier === current_carrier && routingsHTML.length < 3) {
        routingsHTML.push(
          <div
            class="routing"
            style={style.routing}
            name={routing}
            carrier={carrier.code}
            onClick={this.fillSchedule}
          >
            {option.via !== "Direct" ? "Via " : ""}
            {option.via} / {routing}
          </div>
        );
      }

      if (
        option.carrier !== current_carrier ||
        i === carrier_routings.length - 1
      ) {
        let carrier_name = carriers_list.filter(
          (c) => c.code === current_carrier
        )[0];
        carrier_name = carrier_name ? carrier_name.name : "";

        tableHTML.push(
          <div class="row carrier_routings">
            <div class="col-6" style={style.carrier}>
              {carrier_name.toUpperCase()}
            </div>

            <div class="col-6 routing_">{routingsHTML}</div>
          </div>
        );

        if (
          option.carrier !== current_carrier &&
          i === carrier_routings.length - 1
        ) {
          let lastroutingsHTML = [];
          let carrier = carriers_list.filter(
            (c) => c.code === option.carrier
          )[0];
          let carrier_name = carrier ? carrier.name : "";

          lastroutingsHTML.push(
            <div
              class="routing"
              style={style.routing}
              name={routing}
              carrier={carrier.code}
              onClick={this.fillSchedule}
            >
              {option.via !== "Direct" ? "Via " : ""} {option.via} / {routing}
            </div>
          );

          tableHTML.push(
            <div class="row carrier_routings">
              <div class="col-6" style={style.carrier}>
                {carrier_name.toUpperCase()}
              </div>

              <div class="col-6 routing_">{lastroutingsHTML}</div>
            </div>
          );
        }

        routingsHTML = [];
        current_carrier = option.carrier;

        routingsHTML.push(
          <div
            class="routing"
            style={style.routing}
            name={routing}
            carrier={carrier.code}
            onClick={this.fillSchedule}
          >
            {option.via !== "Direct" ? "Via " : ""} {option.via} / {routing}
          </div>
        );
      }
    }

    return [
      <div class="row">
        <div class="col-3">
          <form tabindex="10" noValidate>
            <TextField
              id="date"
              name="from_date"
              type="date"
              label="From Date"
              defaultValue={Util.dateToString3(from_date)}
              onChange={this.handleForm}
            />
          </form>
        </div>

        {detailed_info || !routing ? (
          <div class="col-8">{tableHTML}</div>
        ) : (
          <button
            type="button"
            class="btn btn-success col-md-2 col-sm-4"
            onClick={(e) => {
              this.fillRoutingNonDetailed(e);
            }}
          >
            Confirm
          </button>
        )}
      </div>,
    ];
  }

  emptySchedule() {
    store_schedule.dispatch({
      type: 1,
      schedule: [],
      selected_routing: [],
      force: true,
    });
    openSchedule(null);
  }

  confirmRouting() {
    let calculator = store_calculator.getState();
    store_schedule.dispatch({ type: 1, schedule: [] });
    store_modals.dispatch({ type: 1, modalSchedule: false });

    let { selected_routing } = store_schedule.getState();
    let formatted_routing = [];
    let issue_carrier = selected_routing[0]
      ? selected_routing[0].carrier
      : null;

    for (let i = 0; i < selected_routing.length; i++) {
      let segment = selected_routing[i];
      let departure_date = new Date(segment.dep_loc);
      let dep = departure_date;
      departure_date.setHours(
        departure_date.getHours() + departure_date.getTimezoneOffset() / 60
      );

      let dep_time = departure_date.getHours() ? departure_date.getHours() : 0;
      let departure_time = util.dateToHour(departure_date);
      departure_date = util.dateToString2(departure_date);

      let arrival_date = new Date(segment.arv_loc);
      let arv = arrival_date;
      arrival_date.setHours(
        arrival_date.getHours() + arrival_date.getTimezoneOffset() / 60
      );

      let arv_time = arrival_date.getHours() ? arrival_date.getHours() : 0;
      let arrival_time = util.dateToHour(arrival_date);
      let extraDay = dep && arv ? (arv - dep) / (1000 * 86400) : 0;
      extraDay = dep && arv && dep.getDate() === arv.getDate() ? 0 : extraDay;
      let extraHours = arv_time && dep_time ? arv_time - dep_time : 0;
      let add = arv_time === dep_time ? 0 : 1;

      extraDay = extraHours <= 0 ? (extraDay ? add + parseInt(extraDay) : 0) : parseInt(extraDay);

      formatted_routing.push({
        origin: segment.bpt,
        dest: segment.opt,
        carrier: segment.cr ? segment.cr : segment.carrier,
        flight_number: segment.flt_nr,
        flight_date: util.dateToString2(segment.dep_loc),
        flight_type: segment.srv_typ === "F" ? "Flight" : "Truck",
        aicraft_type: segment.aft_typ,
        etd: departure_time,
        eta: arrival_time + (extraDay ? "(+" + extraDay + ")" : ""),
        shipment_split: "",
      });
    }

    formatted_routing = {
      routes: formatted_routing,
      detailed_info: true,
    };

    if (!calculator.schedule) {
      store_routes.dispatch({
        type: 1,
        routes: formatted_routing,
        force: true,
      });
      updateCarrierInfo(
        issue_carrier,
        this.props ? this.props.custom_list : []
      );
    } else if (calculator.schedule) {
      store_calculator.dispatch({
        type: 1,
        routing: formatted_routing.routes,
        carrier:
          formatted_routing.routes && formatted_routing.routes[0]
            ? formatted_routing.routes[0].carrier
            : calculator.carrier,
        force: true,
      });
    }

    store_schedule.dispatch({ type: 1, schedule: [], selected_routing: [] });
    store_calculator.dispatch({ type: 1, schedule: false });
  }

  selectFlight(e) {
    let { selected_routing, schedule } = store_schedule.getState();
    let flight_id = parseInt(e.target.getAttribute("flight_id"));
    let flight = schedule[flight_id];
    let element = document.querySelector(
      'div.schedule-flight[flight_id="' + flight_id + '"]'
    );

    if (!element) return;

    if (selected_routing.indexOf(flight) >= 0) {
      let index = selected_routing.indexOf(flight);
      selected_routing.splice(index, 1);

      element.style["border"] = "3px solid lightgray";
      element.style["background-color"] = "white";
      element.style["color"] = "black";
    } else {
      let flight_ = selected_routing.filter((f) => f.seg === flight.seg)[0];
      if (flight_) {
        let index_ = selected_routing.indexOf(flight_);
        selected_routing.splice(index_, 1);
        let ele = document.querySelector(
          'div.schedule-flight[flight_id="' + flight_.id + '"]'
        );
        ele.style["border"] = "3px solid lightgray";
        ele.style["background-color"] = "white";
        ele.style["color"] = "black";
      }

      element.style["background-color"] = "#e06b16";
      element.style["color"] = "white";

      let tmp = [];

      for (let i = 0; i < selected_routing.length; i++) {
        let flight__ = selected_routing[i];

        if (flight__.seg > flight.seg) tmp.push(flight);
        tmp.push(flight__);

        if (flight__.seg < flight.seg && i === selected_routing.length - 1)
          tmp.push(flight);
      }

      if (selected_routing.length === 0) tmp.push(flight);

      selected_routing = tmp;
    }

    store_schedule.dispatch({
      type: 1,
      selected_routing: selected_routing,
      force: true,
    });
  }

  ScheduleView() {
    let { schedule, from_date, selected_routing } = store_schedule.getState();
    const { key } = this.state;
    const { station_carrier } = store_station.getState();
    let { station } = store_header.getState();
    let calculator = store_calculator.getState;
    let pricing = store_pricing.getState();

    if (calculator.schedule) {
      station = calculator.station;
    }

    if (!schedule.length) return null;

    let style = {
      segment: {
        margin: "0",
      },
      flight: {
        padding: "4px",
        textAlign: "center",
        fontSize: "80%",
        border: "2px solid lightgray",
        margin: "2px",
        marginBottom: "4px",
      },
      title: {
        margin: "0",
        textAlign: "center",
        color: "#2b5876",
      },
      date: {
        color: "#424242",
        fontWeight: "700",
        textAlign: "center",
      },
    };

    let flightsHTML = [];
    let flights = [];

    if (!schedule[0].routing)
      flightsHTML = (
        <div style={{ textAlign: "center" }}>
          <img src={require("../img/search_sad.png")}></img> <br />
          No Schedule found
        </div>
      );
    else {
      let seg_index = 1;
      from_date = new Date(from_date);

      for (let i = 0; i < schedule.length; i++) {
        let departure_date = schedule[i].dep_loc
          ? new Date(schedule[i].dep_loc.split("T")[0])
          : new Date();
        let date_diff = util.days_diff(departure_date, from_date);
        departure_date = util.dateToString2(departure_date);
        let arrival_date = new Date(schedule[i].arv_loc);

        seg_index = schedule[i].seg;

        if (!flights[seg_index]) flights[seg_index] = [];

        if (!flights[seg_index][date_diff]) flights[seg_index][date_diff] = [];

        schedule[i].id = i;
        flights[seg_index][date_diff].push(schedule[i]);
      }

      store_schedule.dispatch({ type: 1, schedule_ordered: flights });

      for (let i = 1; i < flights.length; i++) {
        if (!flights[i]) continue;

        let flight_ = flights[i].filter((f) => f)[0];

        if (flight_) flight_ = flight_[0];
        else continue;

        let segment = flight_.bpt + "-" + flight_.opt;

        flightsHTML.push(
          <div class="row" style={style.title}>
            <h3>{segment}</h3>
          </div>
        );

        let row = [];
        //loop dates
        let current_date = new Date(from_date);

        for (let j = 0; flights[i] && j < 7; j++) {
          let block = [];
          let blockIsCarrier = [];

          block.push(
            <div style={style.date}>{util.dateToString2(current_date)}</div>
          );

          blockIsCarrier.push(
            <div style={style.date}>{util.dateToString2(current_date)}</div>
          );


          if (!flights[i][j]) {
            block.push(<div style={style.flight}> No Flight Available</div>);
            blockIsCarrier.push(
              <div style={style.flight}> No Flight Available</div>
            );
          }

          //loop flights
          for (let k = 0; flights[i][j] && k < flights[i][j].length; k++) {
            let flight = flights[i][j][k];

            let departure_date = new Date(flight.dep_loc);
            let dep = departure_date;
            
            departure_date.setHours(departure_date.getHours() + departure_date.getTimezoneOffset() / 60);

           
            let dep_time = departure_date.getHours()  ? departure_date.getHours()  : 0;
           
            let departure_time = util.dateToHour(departure_date);
            
            departure_date = util.dateToString2(departure_date);

            let arrival_date = new Date(flight.arv_loc);
            
            let arv = arrival_date;

            arrival_date.setHours( arrival_date.getHours() + arrival_date.getTimezoneOffset() / 60);

            let arv_time = arrival_date.getHours() ? arrival_date.getHours() : 0;
            let arrival_time = util.dateToHour(arrival_date);

            let extraDay = dep && arv ? (arv - dep) / (1000 * 86400) : 0;

            extraDay = dep && arv && dep.getDate() === arv.getDate() ? 0 : extraDay;


            let extraHours = arv_time && dep_time ? arv_time - dep_time : 0;
            let add = arv_time === dep_time ? 0 : 1;

           extraDay = extraHours <= 0 ? (extraDay ? add + parseInt(extraDay) : 0) : parseInt(extraDay);


            let flight_is_portfolio = station_carrier.filter(
              (c) => c.station === station && c.carrier === flight.carrier
            )[0];

            let lf_vol =
              100 *
              (1 - parseFloat(flight.vol_rem_cap) / parseFloat(flight.vol_cap));
            let lf_wgt =
              100 *
              (1 - parseFloat(flight.wgt_rem_cap) / parseFloat(flight.wgt_cap));

            let lf = parseInt(Math.max(lf_wgt, lf_vol));
            let lf_color = null;
            if (lf >= 100) lf_color = "#782832";
            else if (lf >= 80) lf_color = "#ad4971";
            else if (lf >= 50) lf_color = "orange";
            else lf_color = "#50874d";

            let flt_style = "";
            let flt_status = "";

            if (flight.inv_stat === "X") {
              flt_style = "cancelled-flight";
              flt_status = "CANCELLED";
            } else if (flight.inv_stat === "B") {
              //flt_style = "blocked-flight";
              flt_status = "BLOCKED";
            } else if (flight.inv_stat === "C") {
              // flt_style = "closed-flight";
              flt_status = "CLOSED";
            } else {
              flt_style = "";
              flt_status = "";
            }

            let selected = selected_routing.filter(
              (f) => f.id === flight.id
            )[0];
            let style_flight = { ...style.flight };

            if (selected) {
              style_flight.backgroundColor = "#e06b16";
              style_flight.border = "3px solid #e06b16";
              style_flight.color = "white";
            } else {
              style_flight.border = "3px solid lightgray";
              style_flight.backgroundColor = flt_style ? "lightgray" : "white";
              style_flight.color = "black";
            }

            block.push(
              <div
                style={style_flight}
                key={key}
                class={"schedule-flight " + flt_style}
                flight_id={flight.id}
                onClick={(e) => {
                  if (!flt_style) this.selectFlight(e);
                }}
              >
                {flt_status && (
                  <div class="to_rotate_90">
                    <center>
                      <strong>{flt_status ? flt_status : ""}</strong>
                    </center>
                  </div>
                )}
                {flight.cr + flight.flt_nr} / {flight.aft_typ} <br />
                {departure_time} - {arrival_time}{" "}
                {extraDay ? "(+" + extraDay + ")" : ""}
                {flight_is_portfolio && (
                  <div
                    style={{ color: "white", backgroundColor: lf_color }}
                    flight_id={flight.id}
                  >
                    {util.formatAmount3(flight.wgt_rem_cap)} kg
                    <br />
                    {flight.vol_rem_cap} cbm
                    <br />
                  </div>
                )}
              </div>
            );

            if (flight.cr === pricing.carrier) {
              blockIsCarrier.push(
                <div
                  style={style_flight}
                  key={key}
                  class={"schedule-flight " + flt_style}
                  flight_id={flight.id}
                  onClick={(e) => {
                    if (!flt_style) this.selectFlight(e);
                  }}
                >
                  {flt_status && (
                    <div class="to_rotate_90">
                      <center>
                        <strong>{flt_status ? flt_status : ""}</strong>
                      </center>
                    </div>
                  )}
                  {flight.cr + flight.flt_nr} / {flight.aft_typ} <br />
                  {departure_time} - {arrival_time}{" "}
                  {extraDay ? "(+" + extraDay + ")" : ""}
                  {flight_is_portfolio && (
                    <div
                      style={{ color: "white", backgroundColor: lf_color }}
                      flight_id={flight.id}
                    >
                      {util.formatAmount3(flight.wgt_rem_cap)} kg
                      <br />
                      {flight.vol_rem_cap} cbm
                      <br />
                    </div>
                  )}
                </div>
              );
            }
          }

          row.push(
            <div class="col" style={style.segment_date}>
              {this.state.withCarrier ? blockIsCarrier : block}
            </div>
          );
          current_date.setDate(current_date.getDate() + 1);
        }

        flightsHTML.push(
          <div class="row" style={style.segment}>
            {row}
          </div>
        );
        flightsHTML.push(<hr />);
      }
    }

    let nb_segments = flights ? flights.filter((s) => s).length : 0;
    let nb_flights = selected_routing.length;

    return (
      <div class="" key={key}>
        <div class="row">
          <button
            type="button"
            class="btn btn-primary col-md-2 col-sm-4"
            onClick={this.emptySchedule}
          >
            <ArrowBackIcon /> Go back to routing
          </button>

          <div class="col-md-7 col-sm-4" style={style.title}>
            <h2>
              {nb_flights
                ? "[" +
                  nb_flights +
                  "] flight" +
                  (nb_flights > 1 ? "s" : "") +
                  " selected "
                : "Select one or several flights"}
            </h2>
          </div>

          <button
            type="button"
            class="btn btn-success col-md-2 col-sm-4"
            onClick={this.confirmRouting}
            disabled={nb_flights < nb_segments}
          >
            Confirm routing <CheckIcon />
          </button>
        </div>

        <br />

        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <form tabindex="10" noValidate style={{ flex: 1 }}>
            <TextField
              id="date"
              name="from_date"
              type="date"
              label="From Date"
              defaultValue={Util.dateToString3(from_date)}
              onChange={this.handleForm}
              style={{ marginBottom: "2em" }}
            />
          </form>

          {/* kevser */}

          <FormControlLabel
            className="carrier_routings_checkbox"
            control={
              <Checkbox
                checked={this.state.withCarrier}
                onChange={this.handlewithCarrierChange}
              />
            }
            label={`Full routing with ${pricing.carrier}`}
            style={{
              marginRight: "120px",
            }}
          />
        </div>

        {flightsHTML}
      </div>
    );
  }

  render() {
    let show = store_modals.getState().modalSchedule;
    const { key } = this.state;
    const { schedule } = store_schedule.getState();
    let style = {
      modalBody: { marginLeft: "2em" },
    };

    return (
      <Modal show={show} onHide={this.hide} dialogClassName="modal-m">
        <Modal.Header className="modal_header" closeButton>
          <Modal.Title>{schedule.length ? "Schedule" : "Routing"}</Modal.Title>
        </Modal.Header>

        <Modal.Body key={key} style={style.modalBody}>
          {schedule.length ? <this.ScheduleView /> : <this.RoutingView />}
        </Modal.Body>
      </Modal>
    );
  }
}

function getFuelFromSell(sell) {
  let shipment = store_shipment.getState();
  let fuel_total = null;
  let fuel = null;
  let fuel_rate = null;
  let fuel_type = null;
  let fuel_currency = null;
  let fuel_code = null;
  let fuel_min = null;
  let fuel_max = null;
  let fuel_key = null;

  if (!sell)
    return {
      fuel_total: null,
      fuel: null,
      fuel_rate: null,
      fuel_type: null,
      fuel_currency: null,
      fuel_code: null,
      fuel_min: null,
      fuel_max: null,
    };

  if (sell.oc1_code && util.SURCHAGE_CODES.indexOf(sell.oc1_code) >= 0)
    fuel_key = 1;
  if (sell.oc2_code && util.SURCHAGE_CODES.indexOf(sell.oc2_code) >= 0)
    fuel_key = 2;
  if (sell.oc3_code && util.SURCHAGE_CODES.indexOf(sell.oc3_code) >= 0)
    fuel_key = 3;

  if (!fuel_key)
    return {
      fuel_total: null,
      fuel: null,
      fuel_rate: null,
      fuel_type: null,
      fuel_currency: null,
      fuel_code: null,
      fuel_min: null,
      fuel_max: null,
    };

  fuel_rate = parseFloat(sell["oc" + fuel_key + "_formula_kg"]);
  fuel_currency = sell["oc" + fuel_key + "_currency"];
  fuel_code = sell["oc" + fuel_key + "_code"];

  if (!isNaN(fuel_rate)) {
    let rates = [];
    let steps = sell["oc" + fuel_key + "_formula_kg"].split(";");
    let quit = 0;
    steps.forEach((step) => {
      let t = step.split("/");
      rates.push({ fuel_rate: t[0], weight: t[1] });
      if (
        !quit &&
        (t[1] === undefined ||
          (t[1] !== undefined && parseFloat(t[1]) > shipment.chargeable_weight))
      ) {
        fuel_rate = t[0];
        quit = 1;
      }
    });
  }

  fuel_rate = Math.round(fuel_rate * 100) / 100;
  fuel_rate = fuel_rate.toFixed(2);

  fuel_max = sell["oc" + fuel_key + "_max"];
  fuel_min = sell["oc" + fuel_key + "_min"];
  fuel_type = sell["oc" + fuel_key + "_weight_type"] === "C" ? "Chg" : "Grs";
  fuel_total =
    sell["oc" + fuel_key + "_weight_type"] === "C"
      ? fuel_rate * shipment.chargeable_weight
      : fuel_rate * shipment.gross_weight;

  fuel_total = fuel_total ? fuel_total : 0;
  fuel_min = fuel_min ? fuel_min : 0;
  fuel_max = fuel_max ? fuel_max : 0;

  if (fuel_total > fuel_max && fuel_max !== 0) {
    fuel_type = "max";
    fuel_total = fuel_max;
    fuel_rate = fuel_max;
  } else if (fuel_total < fuel_min && fuel_min !== 0) {
    fuel_type = "min";
    fuel_rate = fuel_min;
    fuel_total = fuel_min;
  }

  return {
    fuel_total: isNaN(parseFloat(fuel_total)) ? null : parseFloat(fuel_total),
    fuel: isNaN(parseFloat(fuel)) ? null : parseFloat(fuel),
    fuel_rate: isNaN(parseFloat(fuel_rate)) ? null : parseFloat(fuel_rate),
    fuel_type: fuel_type,
    fuel_currency: fuel_currency,
    fuel_code: fuel_code,
    fuel_min: isNaN(parseFloat(fuel_min)) ? null : parseFloat(fuel_min),
    fuel_max: isNaN(parseFloat(fuel_max)) ? null : parseFloat(fuel_max),
  };
}

function getSecurityFromSell(sell) {
  if (!sell)
    return {
      security_total: null,
      security: null,
      security_rate: null,
      security_type: null,
      security_currency: null,
      security_code: null,
      security_min: null,
      security_max: null,
    };

  let shipment = store_shipment.getState();
  let security_total = null;
  let security = null;
  let security_rate = null;
  let security_type = null;
  let security_currency = null;
  let security_code = null;
  let security_min = null;
  let security_max = null;
  let security_key = null;

  if (sell.oc1_code && util.SECURITY_CODES.indexOf(sell.oc1_code) >= 0)
    security_key = 1;
  if (sell.oc2_code && util.SECURITY_CODES.indexOf(sell.oc2_code) >= 0)
    security_key = 2;
  if (sell.oc3_code && util.SECURITY_CODES.indexOf(sell.oc3_code) >= 0)
    security_key = 3;

  if (!security_key)
    return {
      security_total: null,
      security: null,
      security_rate: null,
      security_type: null,
      security_currency: null,
      security_code: null,
      security_min: null,
      security_max: null,
    };

  security_rate = parseFloat(sell["oc" + security_key + "_formula_kg"]);
  security_currency = sell["oc" + security_key + "_currency"];
  security_code = sell["oc" + security_key + "_code"];

  if (!isNaN(security_rate)) {
    let rates = [];
    let steps = sell["oc" + security_key + "_formula_kg"].split(";");
    let quit = 0;
    steps.forEach((step) => {
      let t = step.split("/");
      rates.push({ security_rate: t[0], weight: t[1] });
      if (
        !quit &&
        (t[1] === undefined ||
          (t[1] !== undefined && parseFloat(t[1]) > shipment.chargeable_weight))
      ) {
        security_rate = t[0];
        quit = 1;
      }
    });
  }

  security_max = sell["oc" + security_key + "_max"];
  security_min = sell["oc" + security_key + "_min"];
  let security_weight_type = sell["oc" + security_key + "_weight_type"];
  security_type = security_weight_type === "C" ? "Chg" : "Grs";
  security_total =
    security_weight_type === "C"
      ? security_rate * shipment.chargeable_weight
      : security_rate * shipment.gross_weight;

  security_total = security_total ? security_total : 0;
  security_max = security_max ? security_max : 0;
  security_min = security_min ? security_min : 0;

  if (security_total > security_max && security_max !== 0) {
    security_type = "max";
    security_rate = security_max;
    security_total = security_max;
  } else if (security_total < security_min && security_min !== 0) {
    security_type = "min";
    security_rate = security_min;
    security_total = security_min;
  }

  return {
    security_total: isNaN(parseFloat(security_total))
      ? null
      : parseFloat(security_total),
    security: isNaN(parseFloat(security)) ? null : parseFloat(security),
    security_rate: isNaN(parseFloat(security_rate))
      ? null
      : parseFloat(security_rate),
    security_type: security_type,
    security_currency: security_currency,
    security_code: security_code,
    security_min: isNaN(parseFloat(security_min))
      ? null
      : parseFloat(security_min),
    security_max: isNaN(parseFloat(security_max))
      ? null
      : parseFloat(security_max),
  };
}

async function getPrices() {
  let station = store_station.getState();
  let routes = store_routes.getState().routes;
  let shipment = store_shipment.getState();
  let pricing = store_pricing.getState();
  let header = store_header.getState();
  let { deal_code } = store_margin.getState();
  let sell_rate = 0;
  let sell_rate_all_in = 0;
  let buy_rate = 0;
  let buy_rate_all_in = 0;
  let buy_rate_type = null;
  let buy_price_class = null;
  let sell_rate_type = null;
  let sell_carrier = null;
  let buy_carrier = null;
  let buy_all_in = "";
  let sell_all_in = "";
  let buy_min = 0;
  let sell_min = 0;
  let buy_currency = null;
  let loc_addon_sell = 0;
  let loc_addon_buy = 0;
  let truck_buy = 0;
  let truck_sell = 0;
  let handling_buy = 0;
  let handling_sell = 0;
  let station_ = station.authorized_stations.filter(
    (s) => s.station === header.station
  )[0];
  //RATES BUY & SELL & COMMISIONS
  let params = {
    agent: shipment.agent_code,
    station: header.station,
    sales_region: station_ ? station_.sales_region : undefined,
    deal_code: deal_code ? deal_code : undefined,
    price_template:
      shipment.dimension_type === Util.CONST.ULD ? "ULD" : undefined,
    origin_zone: station_ ? station_.origin_zone : undefined,
    chargeable_weight: shipment.chargeable_weight,
    origin: shipment.origin,
    dest: shipment.dest,
    carrier: pricing.carrier,
    shc: shipment.shc,
    from_date: pricing.from_date,
    until_date: pricing.to_date,
    routing: routes.map((r) => r.origin + "-" + r.dest).join(";"),
    flight_numbers: routes
      .map((r) => r.carrier + "-" + (!r.flight_number ? "" : r.flight_number))
      .join(";"),
  };

  let res = await Routes.get("/SellBuy", params, true);

  let sell = res && res.sell ? res.sell : null;
  let buy = res && res.buy ? res.buy : null;
  let res_commission = res && res.commission ? res.commission : null;
  let commission = res_commission
    ? parseFloat(res_commission.gsa_comm_perc)
    : 0;
  let commission_type = res_commission ? res_commission.gsa_comm_type : "";
  let oc = res && res.oc ? res.oc : null;

  //console.log("freeOC", oc);

  //console.log("freeCommision", res_commission);

  if (sell) {
    //console.log("freeSELL", sell);
    sell_rate = isNaN(sell.rate) ? 0 : parseFloat(sell.rate);
    sell_rate_all_in = isNaN(sell.rate_all_in)
      ? 0
      : parseFloat(sell.rate_all_in);
    sell_rate_type = sell.selling_type;
    sell_carrier = sell.for_carrier;
    sell_all_in = sell.all_in_rate;
    sell_min = !sell ? 0 : parseFloat(sell.min_amount);
    store_prices.dispatch({ type: 1, sell: sell });
  } else {
    sell_rate_type = null;
    sell_carrier = null;
    sell_min = 0;
    store_prices.dispatch({ type: 1, sell: null });
  }

  if (buy) {
    //console.log("freeBUY", buy);
    buy_rate = isNaN(buy.rate) ? 0 : parseFloat(buy.rate);
    buy_rate_all_in = isNaN(buy.rate_all_in) ? 0 : parseFloat(buy.rate_all_in);
    buy_rate = ((buy_rate * 100) / 100).toFixed(2);
    buy_all_in = !buy ? true : buy.all_in_rate;
    buy_min = !buy ? 0 : parseFloat(buy.min_amount);
    buy_currency = buy.currency;
    buy_rate_type = buy.selling_type;
    buy_carrier = buy.for_carrier;
    buy_price_class = buy.price_class;

    store_prices.dispatch({ type: 1, buy: buy });
  } else {
    buy_rate = null;
    buy_currency = null;
    buy_all_in = null;
    buy_min = null;
    buy_rate_type = null;
    buy_carrier = null;
    store_prices.dispatch({ type: 1, buy: buy });
  }

  /*
  let cost_params = {
    station: header.station,
    carrier: pricing.carrier,
    sell_buy: "SB",
    shc: shipment.shc,
    weight: shipment.chargeable_weight,
    origin: shipment.origin,
    dest: shipment.dest,
    from_date: pricing.from_date,
    until_date: pricing.to_date,
    routing: routes ? routes.map((r) => r.origin + "-" + r.dest).join(",") : "",
    routes: routes
      ? routes.map((r) => {
          return {
            origin: r.origin,
            dest: r.dest,
            carrier: r.carrier,
            flight_type: r.flight_type,
          };
        })
      : [],
  };
  */

  let {
    fuel_total,
    fuel_rate,
    fuel_type,
    fuel_currency,
    fuel_code,
    fuel_min,
    fuel_max,
  } = getFuelFromSell(oc);

  if (fuel_rate && (fuel_type === "min" || fuel_type === "max")) {
    fuel_rate = fuel_rate / shipment.chargeable_weight;
  }

  let {
    security_total,
    security_type,
    security_rate,
    security_min,
    security_max,
    security_currency,
    security_code,
  } = getSecurityFromSell(oc);

  if (security_rate && (security_type === "min" || security_type === "max")) {
    security_rate = security_rate / shipment.chargeable_weight;
  }

  //LOCATION ADDON
  if (sell) {
    loc_addon_sell =
      sell.loc_addon_rate > sell.loc_addon_min
        ? sell.loc_addon_rate
        : sell.loc_addon_min / shipment.chargeable_weight;
  }

  if (buy) {
    loc_addon_buy =
      buy.loc_addon_rate * shipment.chargeable_weight > buy.loc_addon_min
        ? buy.loc_addon_rate
        : buy.loc_addon_min / shipment.chargeable_weight;
  }

  var state_ = {
    sell_rate: sell_rate,
    sell_rate_all_in: sell_rate_all_in,
    truck_sell: truck_sell,
    handling_sell: handling_sell,
    loc_addon_sell: loc_addon_sell,

    buy_rate: buy_rate,
    buy_rate_all_in: buy_rate_all_in,
    truck_buy: truck_buy,
    handling_buy: handling_buy,
    loc_addon_buy: loc_addon_buy,

    fuel_rate: fuel_rate,
    security_rate: security_rate,
  };

  var state_per_kg = {
    ...state_,
    type: 1,
    marginPerkg: true,
    marginInTotal: false,
  };

  //console.log("#freeState01", state_per_kg, store_margin.getState());

  store_margin.dispatch(state_per_kg);

  //console.log("#freeState1", store_margin.getState());

  let state_total = { ...state_ };

  let keys = Object.keys(state_total);

  keys.forEach((k) => {
    state_total[k] = parseFloat(state_total[k])
      ? state_total[k] * shipment.chargeable_weight
      : 0;
  });

  state_total = {
    ...state_total,
    type: 1,
    marginInTotal: true,
    marginPerkg: false,
    fuel_rate: fuel_total,
    security_rate: security_total,
  };

  //console.log("#freeState02", state_total);

  store_margin.dispatch(state_total);

  //console.log("#freeState2", store_margin.getState());

  store_margin.dispatch({
    type: 1,
    buy_rate_type: buy_rate_type,
    buy_all_in: buy_all_in,
    sell_all_in: sell_all_in,
    sell_rate_type: sell_rate_type,
    buy_carrier: buy_carrier,
    buy_price_class: buy_price_class,
    sell_carrier: sell_carrier,
    buy_min: buy_min,
    sell_min: sell_min,
    buy_currency: buy_currency,
    commission: commission,
    commission_type: commission_type,
    fuel_type: fuel_type,
    fuel_currency: fuel_currency,
    fuel_code: fuel_code,
    security_code: security_code,
    security_type: security_type,
    security_currency: security_currency,
    fuel_min: fuel_min,
    fuel_max: fuel_max,
    security_min: security_min,
    security_max: security_max,
    marginInTotal: false,
    marginPerkg: false,
  });

  //console.log("#freeState3", store_margin.getState());
}

async function getSellingPrices(params) {
  let shipment = store_shipment.getState();
  let pricing = store_pricing.getState();
  let routes = store_routes.getState().routes;
  let header = store_header.getState();
  let errors = checkForm();
  let error = false;

  for (const err in errors) {
    switch (err) {
      case "chargeable_weight":
        error = true;
        break;
      case "dest":
        error = true;
        break;
      case "origin":
        error = true;
        break;
      case "carrier":
        error = true;
        break;
      default:
    }
  }

  if (error) return [];

  let authorized_stations = params.authorized_stations
    ? params.authorized_stations
    : [];
  let station_ = authorized_stations.filter(
    (s) => s.station === store_header.getState().station
  )[0];
  let sales_region = station_ ? station_.sales_region : undefined;
  let origin_zone = station_ ? station_.origin_zone : undefined;
  routes = routes.filter(
    (route) => route.flight_type.toLowerCase() === Util.CONST.FLIGHT
  );

  params = {
    station: header.station,
    sales_region: sales_region,
    origin_zone: origin_zone,
    chargeable_weight: shipment.chargeable_weight,
    carrier: pricing.carrier,
    dest: shipment.dest,
    origin: shipment.origin,
    agent: shipment.agent_code,
    shc: shipment.shc,
    from_date: pricing.from_date,
    until_date: pricing.to_date,
    routing: routes.map((r) => r.origin + "-" + r.dest).join(","),
    flight_numbers: routes
      .map((r) => r.carrier + "-" + (!r.flight_number ? "" : r.flight_number))
      .join(";"),
    week_days: routes
      .map((r) => (!r.operation_dow ? "" : r.operation_dow))
      .join(";"),
    price_class: shipment.price_class
      ? shipment.price_class
      : shipment.commodity_code,
    service_level: shipment.service_level,
  };

  let prices = await Routes.get("/SellingPrices", params, true);
  store_modals.dispatch({ type: 1, page_loading_spinner2: false });

  if (prices && prices.error) {
    store_errors.dispatch({
      type: 1,
      sell_prices: "We found an error when loading prices.",
    });
    return [];
  }

  prices = prices ? prices : [];
  let user_carriers = cookies.user_carriers ? cookies.user_carriers : null;
  let user_except_carriers = cookies.user_except_carriers
    ? cookies.user_except_carriers
    : null;

  if (user_carriers && user_carriers !== "ALL") {
    user_carriers = user_carriers.split(",");
    prices = prices.filter((r) => user_carriers.indexOf(r.carrier) >= 0);
  }

  if (user_except_carriers) {
    if (user_except_carriers === "ALL") prices = [];
    else {
      user_except_carriers = user_except_carriers.split(",");
      prices = prices.filter(
        (r) => user_except_carriers.indexOf(r.carrier) < 0
      );
    }
  }

  store_prices.dispatch({
    type: 1,
    sell_prices: prices,
    chargeable_weight: shipment.chargeable_weight,
  });
  return prices;
}

async function getBuyingPrices(params) {
  let shipment = store_shipment.getState();
  let pricing = store_pricing.getState();
  let routes = store_routes.getState().routes;
  let header = store_header.getState();
  let errors = checkForm();
  let error = false;

  for (const err in errors) {
    switch (err) {
      case "chargeable_weight":
        error = true;
        break;
      case "dest":
        error = true;
        break;
      case "origin":
        error = true;
        break;
      case "carrier":
        error = true;
        break;
      default:
    }
  }

  if (error) return [];

  let authorized_stations = params.authorized_stations
    ? params.authorized_stations
    : [];
  let station_ = authorized_stations.filter(
    (s) => s.station === store_header.getState().station
  )[0];
  let sales_region = station_ ? station_.sales_region : undefined;
  let origin_zone = station_ ? station_.origin_zone : undefined;
  routes = routes.filter(
    (route) => route.flight_type.toLowerCase() === Util.CONST.FLIGHT
  );

  params = {
    order: true,
    station: header.station,
    sales_region: sales_region,
    origin_zone: origin_zone,
    chargeable_weight: shipment.chargeable_weight,
    carrier: pricing.carrier,
    price_template:
      shipment.dimension_type === Util.CONST.ULD ? "ULD" : undefined,
    onlycarrier: pricing.carrier,
    dest: shipment.dest,
    origin: shipment.origin,
    agent: shipment.agent_code,
    shc: shipment.shc,
    from_date: pricing.from_date,
    until_date: pricing.to_date,
    routing: routes.map((r) => r.origin + "-" + r.dest).join(","),
    flight_numbers: routes
      .map((r) => r.carrier + "-" + (!r.flight_number ? "" : r.flight_number))
      .join(";"),
    week_days: routes
      .map((r) => (!r.operation_dow ? "" : r.operation_dow))
      .join(";"),
    price_class: shipment.price_class
      ? shipment.price_class
      : shipment.commodity_code,
    service_level: shipment.service_level,
  };

  let prices = await Routes.get("/BuyingPrices", params, true);
  store_modals.dispatch({ type: 1, page_loading_spinner2: false });

  if (prices && prices.error) {
    return [];
  }

  prices = prices ? prices : [];
  let user_carriers = cookies.user_carriers ? cookies.user_carriers : null;
  let user_except_carriers = cookies.user_except_carriers
    ? cookies.user_except_carriers
    : null;

  if (user_carriers && user_carriers !== "ALL") {
    user_carriers = user_carriers.split(",");
    prices = prices.filter((r) => user_carriers.indexOf(r.carrier) >= 0);
  }

  if (user_except_carriers) {
    if (user_except_carriers === "ALL") prices = [];
    else {
      user_except_carriers = user_except_carriers.split(",");
      prices = prices.filter(
        (r) => user_except_carriers.indexOf(r.carrier) < 0
      );
    }
  }

  store_prices.dispatch({ type: 1, buy_prices: prices });
  return prices;
}

function restrictInputAccess() {
  if (cookies && cookies.rights && cookies.rights.quote_edit) return;

  let fields = document.getElementsByTagName("input");

  for (let i = 0; i < fields.length; i++) {
    //il y a un droit spécifique pour les champs du routing
    if (
      fields[i].id.indexOf("rout") >= 0 &&
      cookies &&
      cookies.rights &&
      cookies.rights.quote_edit_routing
    )
      continue;
    else if (
      fields[i].id.indexOf("agent") >= 0 &&
      cookies &&
      cookies.rights &&
      cookies.rights.quote_edit_agent
    )
      continue;
    else fields[i].disabled = true;
  }
  //il faut désactiver les champs de type select (dropdown) aussi
  fields = document.getElementsByTagName("select");
  for (let i = 0; i < fields.length; i++) {
    //il y a un droit spécifique pour les champs du routing
    if (
      fields[i].id.indexOf("rout") >= 0 &&
      cookies &&
      cookies.rights &&
      cookies.rights.quote_edit_routing
    )
      continue;
    else fields[i].disabled = true;
  }
  //remarks
  fields = document.getElementsByTagName("textarea");
  for (let i = 0; i < fields.length; i++) {
    //il y a un droit spécifique pour les champs du routing
    if (
      fields[i].id.indexOf("rout") >= 0 &&
      cookies &&
      cookies.rights &&
      cookies.rights.quote_edit_routing
    )
      continue;
    else fields[i].disabled = true;
  }
  //buttons routing
  fields = document.getElementsByTagName("button");
  for (let i = 0; i < fields.length; i++) {
    if (
      fields[i].id.indexOf("rout") >= 0 &&
      (!cookies || !cookies.rights || !cookies.rights.quote_edit_routing)
    ) {
      fields[i].disabled = true;
      fields[i].hidden = true;
    } else if (
      fields[i].id.indexOf("packing") >= 0 &&
      (!cookies || !cookies.rights || !cookies.rights.quote_edit)
    ) {
      fields[i].disabled = true;
      fields[i].hidden = true;
    } else if (
      fields[i].id.indexOf("ocdc") >= 0 &&
      (!cookies || !cookies.rights || !cookies.rights.quote_edit)
    ) {
      fields[i].disabled = true;
      fields[i].hidden = true;
    } else continue;
  }
}

async function calculateMargin(params) {
  let errors = checkForm();
  let error_margin = false;

  for (const err in errors) {
    switch (err) {
      case "chargeable_weight":
        error_margin = true;
        break;
      case "origin":
        error_margin = true;
        break;
      case "dest":
        error_margin = true;
        break;
      case "volume":
        error_margin = true;
        break;
      case "price":
        error_margin = true;
        break;
      case "carrier":
        error_margin = true;
        break;
      default:
    }
  }

  if (error_margin) {
    store_errors.dispatch({ type: 1, errors: errors });
    return;
  }

  if (!isUserCarrier()) {
    return;
  }

  store_errors.dispatch({ type: 1, margin: null, buy_min: null });

  await getPrices();

  let pricing = store_pricing.getState();
  let { all_in, per_kg } = pricing;
  let shipment = store_shipment.getState();
  let shc = shipment.shc;
  let { exch_rate } = store_station.getState();

  let index_pro = shc.indexOf("PRO");
  let index_dyn = shc.indexOf("DYN")

  if (index_pro >= 0) shc[index_pro] = "";
  if(index_dyn >= 0) shc[index_dyn] = ""

  store_shipment.dispatch({
    type: 1,
    calculate_price_class: true,
    carrier: pricing.carrier,
    sales_region: params.station ? params.station.sales_region : null,
  });

  let rates = store_margin.getState();

  //console.log("#freeRates", rates);

  let markup = 0;
  let buy_total = 0;
  let sell_total = 0;
  let create_buy = false;

  //TOTAL PRICE
  let selling_price = pricing.per_kg
    ? parseFloat(pricing.price)
    : parseFloat(pricing.price_total / shipment.chargeable_weight);
  selling_price = Math.round(selling_price * 100) / 100;
  let priceTotal = pricing.per_kg
    ? selling_price * parseFloat(shipment.chargeable_weight)
    : pricing.price_total
    ? parseFloat(pricing.price_total)
    : 0;

  let {
    buy_rate_type,
    buy_currency,
    fuel_currency,
    security_currency,
    edit_buy_currency,
    margin_currency_selected,
    commission,
    margin_per_kg,
    margin_in_total,
  } = rates;

  let {
    sell_rate,
    sell_rate_all_in,
    sell_net_net_rate,
    sell_all_in_rate,
    sell_min,
    truck_sell,
    handling_sell,
    loc_addon_sell,
    truck_sell_edit,
    handling_sell_edit,
    loc_addon_sell_edit,

    buy_rate,
    buy_rate_all_in,
    buy_min,
    buy_net_net_rate,
    buy_all_in_rate,
    all_in_buy_edit,
    net_buy_edit,
    truck_buy,
    handling_buy,
    loc_addon_buy,
    truck_buy_edit,
    handling_buy_edit,
    loc_addon_buy_edit,
    fuel_rate,
    security_rate,
    commission_value,
    margin,
  } = rates.per_kg ? margin_per_kg : margin_in_total;

  //console.log("#freeMarginValue", margin_per_kg, margin_in_total);

  let fuel_total = margin_in_total.fuel_rate;
  let security_total = margin_in_total.security_rate;

  //COMMISSION

  if (commission) {
    commission_value = (commission / 100) * selling_price;
    //console.log("#freeCommission", commission, commission_value);
  }

  //TRUCK
  truck_buy = truck_buy_edit ? truck_buy_edit : truck_buy;
  truck_sell = truck_sell_edit ? truck_sell_edit : truck_sell;

  //HANDLING
  handling_buy = handling_buy_edit ? handling_buy_edit : handling_buy;
  handling_sell = handling_sell_edit ? handling_sell_edit : handling_sell;

  //LOC ADDON
  loc_addon_buy = loc_addon_buy_edit ? loc_addon_buy_edit : loc_addon_buy;
  loc_addon_sell = loc_addon_sell_edit ? loc_addon_sell_edit : loc_addon_sell;

  console.log("##FREE1", buy_rate, loc_addon_buy, loc_addon_buy_edit);

  //CONVERT

  //BUY_RATE
  buy_rate =
    buy_rate && buy_currency && buy_currency !== pricing.currency
      ? util.convert_currency(
          buy_rate,
          buy_currency,
          pricing.currency,
          exch_rate
        )
      : buy_rate;

  //BUY_RATE_ALL_IN

  buy_rate_all_in =
    buy_rate_all_in && buy_currency && buy_currency !== pricing.currency
      ? util.convert_currency(
          buy_rate_all_in,
          buy_currency,
          pricing.currency,
          exch_rate
        )
      : buy_rate_all_in;

  //FUEL

  fuel_rate =
    fuel_rate && fuel_currency && fuel_currency !== pricing.currency
      ? util.convert_currency(
          fuel_rate,
          fuel_currency,
          pricing.currency,
          exch_rate
        )
      : fuel_rate;

  //SECURIY

  security_rate =
    security_rate && security_currency && security_currency !== pricing.currency
      ? util.convert_currency(
          security_rate,
          security_currency,
          pricing.currency,
          exch_rate
        )
      : security_rate;

  //TRUCK
  truck_buy =
    truck_buy && edit_buy_currency && edit_buy_currency !== pricing.currency
      ? util.convert_currency(
          truck_buy,
          edit_buy_currency,
          pricing.currency,
          exch_rate
        )
      : truck_buy;

  truck_sell =
    truck_sell && edit_buy_currency && edit_buy_currency !== pricing.currency
      ? util.convert_currency(
          truck_sell,
          edit_buy_currency,
          pricing.currency,
          exch_rate
        )
      : truck_sell;

  let truck_markup = Math.round((truck_sell - truck_buy) * 100) / 100;
  truck_markup = isNaN(truck_markup) ? 0 : truck_markup;

  //HANDLING

  handling_sell =
    handling_sell && edit_buy_currency && edit_buy_currency !== pricing.currency
      ? util.convert_currency(
          handling_sell,
          edit_buy_currency,
          pricing.currency,
          exch_rate
        )
      : handling_sell;

  handling_buy =
    handling_buy && edit_buy_currency && edit_buy_currency !== pricing.currency
      ? util.convert_currency(
          handling_buy,
          edit_buy_currency,
          pricing.currency,
          exch_rate
        )
      : handling_buy;

  let handling_markup = Math.round((handling_sell - handling_buy) * 100) / 100;
  handling_markup = isNaN(handling_markup) ? 0 : handling_markup;

  //LOC ADDON

  loc_addon_sell =
    loc_addon_sell &&
    edit_buy_currency &&
    edit_buy_currency !== pricing.currency
      ? util.convert_currency(
          loc_addon_sell,
          edit_buy_currency,
          pricing.currency,
          exch_rate
        )
      : loc_addon_sell;

  loc_addon_buy =
    loc_addon_buy && edit_buy_currency && edit_buy_currency !== pricing.currency
      ? util.convert_currency(
          loc_addon_buy,
          edit_buy_currency,
          pricing.currency,
          exch_rate
        )
      : loc_addon_buy;

  let loc_addon_markup =
    Math.round((loc_addon_sell - loc_addon_buy) * 100) / 100;
  loc_addon_markup = isNaN(loc_addon_markup) ? 0 : loc_addon_markup;

  // CALCULATE BUY/SELL ALL IN & TOTAL

  //EDIT BUY
  if (net_buy_edit || all_in_buy_edit) {
    create_buy = true;

    buy_rate = !pricing.all_in ? net_buy_edit : all_in_buy_edit;
    buy_rate =
      buy_rate && edit_buy_currency && edit_buy_currency !== pricing.currency
        ? util.convert_currency(
            buy_rate,
            edit_buy_currency,
            pricing.currency,
            exch_rate
          )
        : buy_rate;
    buy_currency = edit_buy_currency ? edit_buy_currency : pricing.currency;

    console.log("##FREE2", buy_rate);
  } else if (buy_rate || buy_rate_all_in) {
    //CSP BUY

    if (
      (all_in &&
        ((rates.per_kg && selling_price - buy_rate_all_in !== 0) ||
          (!rates.per_kg && priceTotal - buy_rate_all_in !== 0))) ||
      (!all_in &&
        ((rates.per_kg && selling_price - buy_rate !== 0) ||
          (!rates.per_kg && priceTotal - buy_rate !== 0)))
    ) {
      //Buy rate diff of selling rate

      create_buy = false;
      let { authorized_stations } = store_station.getState();
      let buy_prices = await getBuyingPrices({
        authorized_stations: authorized_stations,
      });
      let buy_rate_ = buy_prices ? buy_prices[0] : null;
      buy_rate_type = buy_rate_ ? buy_rate_.selling_type : buy_rate_type;

      //TEST
      /*
      buy_rate_.rate = 1.5
      buy_rate_.selling_type = 'PROMO'
      buy_rate_type = "PROMO"
      */

      if (
        buy_rate_ &&
        buy_rate_.rate &&
        buy_rate_.selling_type &&
        (buy_rate_.selling_type.toUpperCase() === "PROMO" || buy_rate_.selling_type.toUpperCase() === "DYNAMIC" ) &&
        !net_buy_edit &&
        !all_in_buy_edit
      ) {
        buy_rate = buy_rate_.rate;
        buy_rate = rates.per_kg
          ? buy_rate
          : buy_rate * shipment.chargeable_weight;
        //TO TRAITE NET ALL IN CASE
        buy_currency = buy_rate_.currency;
        buy_rate =
          buy_rate && buy_currency && buy_currency !== pricing.currency
            ? util.convert_currency(
                buy_rate,
                buy_currency,
                pricing.currency,
                exch_rate
              )
            : buy_rate;
        create_buy = false;

        let index = shc.indexOf("");


        if(buy_rate_.selling_type.toUpperCase() === "PROMO"){

          if (index >= 0 && shc.indexOf("PRO") < 0)
            store_shipment.dispatch({
              type: 1,
              index: index,
              shc: "PRO",
              calculate_price_class: true,
              force: true,
            });
          else if (shc.indexOf("PRO") < 0)
            store_shipment.dispatch({
              type: 1,
              index: shc.length + 1,
              shc: "PRO",
              calculate_price_class: true,
              force: true,
            });
  
        }

        if(buy_rate_.selling_type.toUpperCase() === "DYNAMIC"){

          if (index >= 0 && shc.indexOf("DYN") < 0)
            store_shipment.dispatch({
              type: 1,
              index: index,
              shc: "DYN",
              calculate_price_class: true,
              force: true,
            });
          else if (shc.indexOf("DYN") < 0)
            store_shipment.dispatch({
              type: 1,
              index: shc.length + 1,
              shc: "DYN",
              calculate_price_class: true,
              force: true,
            });
        }       

      }

      console.log("##FREE3", buy_rate);
    } else if (
      (all_in &&
        (selling_price === buy_all_in_rate || priceTotal == buy_all_in_rate)) ||
      (!all_in && (selling_price === buy_rate || priceTotal == buy_rate))
    ) {
      //commision
      //buy_rate = pricing.per_kg ? selling_price : priceTotal;

      if (
        buy_rate_type &&
        (buy_rate_type === "PROMO" ||
          buy_rate_type === "STREET" ||
          buy_rate_type === "DYNAMIC" ||
          buy_rate_type === "CONTRACT")
      )
        create_buy = false;
      else create_buy = true;

      console.log("##FREE4", buy_rate);
    }

    if (shipment.dimension_type === Util.CONST.ULD && commission) {
      console.log("#freeCommisionULD", commission);
      buy_rate = pricing.per_kg ? selling_price : priceTotal;
      create_buy = true;
    }
  }

  buy_rate = Math.round(buy_rate * 100) / 100;

  //SELL && BUY ALL IN RATE && NET NET RATE

  if (!pricing.all_in) {
    sell_net_net_rate = rates.per_kg ? selling_price : priceTotal;
    sell_all_in_rate = sell_net_net_rate + (security_rate + fuel_rate);
    sell_total = rates.per_kg
      ? sell_net_net_rate * shipment.chargeable_weight +
        (fuel_total + security_total)
      : sell_all_in_rate;

    buy_net_net_rate = buy_rate;
    buy_all_in_rate = buy_rate + (security_rate + fuel_rate);
    buy_total = rates.per_kg
      ? buy_net_net_rate * shipment.chargeable_weight +
        (fuel_total + security_total)
      : buy_all_in_rate;

    console.log(
      "##FREE05",
      fuel_total,
      security_total,
      sell_net_net_rate,
      sell_all_in_rate,
      sell_total,
      buy_total
    );
  } else if (pricing.all_in) {
    sell_all_in_rate = rates.per_kg ? selling_price : priceTotal;
    sell_net_net_rate = sell_all_in_rate - (fuel_rate + security_rate);
    sell_total = rates.per_kg ? (sell_all_in_rate*shipment.chargeable_weight) : sell_all_in_rate

    buy_all_in_rate = buy_rate;
    buy_net_net_rate = buy_rate - (security_rate + fuel_rate);
    buy_total = rates.per_kg
      ? buy_net_net_rate * shipment.chargeable_weight +
        (fuel_total + security_total)
      : buy_all_in_rate;
  }

  markup = sell_all_in_rate - buy_all_in_rate;
  // MARGIN TOTAL

  markup = markup ? parseFloat(markup).toFixed(2) : 0;
  markup = markup ? Math.round(markup * 100) / 100 : 0;

  margin = rates.per_kg
    ? markup + commission_value
    : markup + commission_value * shipment.chargeable_weight;

  margin = margin ? parseFloat(margin).toFixed(2) : 0;
  margin = Math.round(margin * 100) / 100;

  buy_total = buy_total ? parseFloat(buy_total).toFixed(2) : buy_total;
  sell_total = sell_total ? parseFloat(sell_total).toFixed(2) : sell_total;

  console.log(
    "##FREE5",
    selling_price,
    sell_net_net_rate,
    sell_all_in_rate,
    sell_total,
    fuel_total,
    security_total,
    fuel_rate,
    security_rate
  );
  console.log(
    "##FREE6",
    buy_rate,
    buy_net_net_rate,
    buy_all_in_rate,
    buy_total
  );
  console.log("##FREE7", markup, margin, commission_value);

  var state_values = {
    type: 1,
    markup: markup,
    margin: margin,
    commission_value: commission_value,
    sell_net_net_rate: sell_net_net_rate,
    sell_all_in_rate: sell_all_in_rate,
    truck_sell: truck_sell,
    handling_sell: handling_sell,
    loc_addon_sell: loc_addon_sell,
    truck_sell_edit: truck_sell_edit,
    handling_sell_edit: handling_sell_edit,
    loc_addon_sell_edit: loc_addon_sell_edit,
    buy_net_net_rate: buy_net_net_rate,
    buy_all_in_rate: buy_all_in_rate,
    all_in_buy_edit: all_in_buy_edit,
    net_buy_edit: net_buy_edit,
    truck_buy: truck_buy,
    handling_buy: handling_buy,
    loc_addon_buy: loc_addon_buy,
    truck_buy_edit: truck_buy_edit,
    handling_buy_edit: handling_buy_edit,
    loc_addon_buy_edit: loc_addon_buy_edit,
  };

  var state_values_per_kg = { ...state_values };

  var state_values_in_total = { ...state_values };

  if (rates.per_kg) {
    let keys = Object.keys(state_values_in_total);

    keys.forEach((k) => {
      let total_value = state_values_in_total[k];
      state_values_in_total[k] = parseFloat(total_value)
        ? parseFloat(total_value) * shipment.chargeable_weight
        : 0;
    });
  } else {
    let keys = Object.keys(state_values_per_kg);

    keys.forEach((k) => {
      let per_kg_value = state_values_per_kg[k];
      state_values_per_kg[k] = parseFloat(per_kg_value)
        ? parseFloat(per_kg_value) / shipment.chargeable_weight
        : 0;
    });
  }

  state_values_per_kg = { ...state_values_per_kg, type: 1, marginPerkg: true };
  store_margin.dispatch(state_values_per_kg);

  state_values_in_total = {
    ...state_values_in_total,
    type: 1,
    marginInTotal: true,
  };

  store_margin.dispatch(state_values_in_total);

  var states = {
    type: 1,
    sell_total: sell_total,
    buy_total: buy_total,
    updateMargin: false,
    commission: commission,
    create_buy: create_buy,
    edit_buy_currency: edit_buy_currency ? edit_buy_currency : buy_currency,
    margin_currency_selected: margin_currency_selected
      ? margin_currency_selected
      : pricing.currency,
    force: true,
  };

  store_margin.dispatch(states);

  errors = {
    type: 1,
    margin: margin <= 0.0 ? "Margin should be positive" : undefined,
    buy_min:
      rates.buy_min > buy_total ? "Buy min. amount not respected" : undefined,
  };

  store_shipment.dispatch({ type: 1, force: true });
  store_margin.dispatch({ type: 1, updateMargin: false });
  store_errors.dispatch(errors);

  console.log("##FREE8", store_margin.getState());
}

function adhocParams() {
  let _shipment = store_shipment.getState();
  let _pricing = store_pricing.getState();
  let _header = store_header.getState();
  let _margin = store_margin.getState();
  let _remarks = store_remarks.getState();

  let shipment = {
    origin: _shipment.origin,
    dest: _shipment.dest,
    agent_code: _shipment.agent_code,
    price_class: _shipment.price_class,
    service_level: _shipment.service_level,
    dimension_type: _shipment.dimension_type,
  };

  let pricing = {
    carrier: _pricing.carrier,
    currency: _pricing.currency,
    from_date: _pricing.from_date,
    to_date: _pricing.to_date,
    per_kg: _pricing.per_kg,
    min_amount: _pricing.min_amount,
    price_total: _pricing.price_total,
    min_chargeable_weight: _pricing.min_chargeable_weight,
    all_in: _pricing.all_in,
    uld_price: _pricing.uld_price,
    price_template:
      _shipment.dimension_type === Util.CONST.ULD
        ? Util.CONST.ULD.toUpperCase()
        : Util.CONST.ADHOC.toUpperCase(),
  };

  let { margin_per_kg } = _margin;

  let margin = {
    all_in_buy_edit: margin_per_kg.all_in_buy_edit,
    net_buy_edit: margin_per_kg.net_buy_edit,
    buy_currency: _margin.buy_currency,
    edit_buy_currency: _margin.edit_buy_currency,
    per_kg: _margin.per_kg,
  };

  let header = {
    id: _header.id,
    user: _header.user,
    adhoc_ref: _header.adhoc_ref,
    adhoc_ref_buy: _header.adhoc_ref_buy,
    update_history: _header.update_history,
  };

  let remarks = {
    int_remarks: _remarks.int_remarks,
    ext_remarks: _remarks.ext_remarks,
  };

  return {
    shipment: shipment,
    margin: margin,
    pricing: pricing,
    header: header,
    remarks: remarks,
  };
}

async function updateAdhocSell(authorized_stations) {
  let shipment = store_shipment.getState();

  if (shipment.dimension_type === Util.CONST.ULD) calculateULDPrice();

  let pricing = store_pricing.getState();
  let header = store_header.getState();
  let station = store_station.getState();

  store_shipment.dispatch({
    type: 1,
    calculate_price_class: true,
    sales_region: station.sales_region,
    carrier: pricing.carrier,
  });

  if (!store_header.getState().id) await saveQuote();

  let params = adhocParams();

  params.rate = pricing.per_kg ? pricing.price : "NULL";
  params.sell_buy = "S";
  params.sales_region = authorized_stations.filter(
    (s) => s.station === header.station
  )[0].sales_region;

  if (!params.sales_region) return;

  let adhoc_ref = await Routes.post("/Adhoc", params, true);

  if (!header.adhoc_ref)
    store_header.dispatch({ type: 1, adhoc_ref: adhoc_ref ? adhoc_ref : "" });

  store_modals.dispatch({ type: 1, updateAdhoc: false });

  return adhoc_ref;
}

async function updateAdhocBuy(prefix = null, serial = null) {
  let shipment = store_shipment.getState();
  let deny_station_carriers = util.DENY_ADHOC_BUY;

  if (shipment.dimension_type === Util.CONST.ULD) calculateULDPrice();

  if (!store_header.getState().id) await saveQuote();

  let station = store_station.getState();
  let pricing = store_pricing.getState();
  let margin = store_margin.getState();
  let { margin_per_kg, margin_in_total, create_buy } = margin;
  let header = store_header.getState();
  let station_ = station.authorized_stations.filter(
    (s) => s.station === header.station
  )[0];
  let deny = deny_station_carriers
    ? deny_station_carriers.filter(
        (sc) => sc.station === header.station && sc.carrier === pricing.carrier
      )[0]
    : null;
  let { all_in_buy_edit, net_buy_edit, buy_net_net_rate, buy_all_in_rate } =
    margin_per_kg;
  store_shipment.dispatch({
    type: 1,
    calculate_price_class: true,
    sales_region: station_.sales_region,
    carrier: pricing.carrier,
  });

  //console.log("freeMargin", margin);

  prefix = prefix ? prefix : header.prefix;
  serial = serial ? serial : header.serial;

  if (!prefix || !serial || serial.length !== 8) return;

  let new_buy = null;
  let buy_price_total = null;
  let price = pricing.price ? parseFloat(pricing.price).toFixed(2) : 0;
  let price_total = pricing.price_total
    ? parseFloat(pricing.price_total).toFixed(2)
    : 0;

  if (all_in_buy_edit || net_buy_edit) {
    //1er cas on on a un edit manuel du prix BUY
    new_buy = pricing.all_in ? all_in_buy_edit : net_buy_edit;
    buy_price_total = pricing.all_in
      ? margin_in_total.all_in_buy_edit
      : margin_in_total.net_buy_edit;
    new_buy = margin.per_kg ? new_buy : 0;
    console.log("xfreeEdit", new_buy, buy_price_total, buy_price_total);
  } else if (deny) {
    console.log("xfreeDeny", deny);
    return;
  } else {
    if (create_buy) {
      new_buy = pricing.all_in ? buy_all_in_rate : buy_net_net_rate;
      new_buy = margin.per_kg ? new_buy : 0;
      buy_price_total = pricing.all_in
        ? parseFloat(margin_in_total.buy_all_in_rate).toFixed(2)
        : parseFloat(margin_in_total.buy_net_net_rate).toFixed(2);
      console.log("xfreeOthers", new_buy, buy_price_total);
    }
  }

  let awb_serial = serial;
  let params = adhocParams();
  let pricing_ = { ...params.pricing }; //Remplacer le buy price_total
  pricing_.price_total =
    buy_price_total && !margin.per_kg ? buy_price_total : pricing_.price_total;
  pricing_.min_amount =
    buy_price_total && !margin.per_kg ? buy_price_total : pricing_.min_amount;

  params.pricing = pricing_;
  params.rate = new_buy;
  params.sales_region = station_ ? station_.sales_region : null;
  params.sell_buy = "B";
  params.awb_serial = awb_serial;

  if (!params.sales_region || new_buy === null) return;

  //console.log("freeAdhocBUY", params);

  let adhoc_ref_buy = await Routes.post("/Adhoc", params, true);

  if (!header.adhoc_ref_buy)
    store_header.dispatch({
      type: 1,
      adhoc_ref_buy: adhoc_ref_buy ? adhoc_ref_buy : "",
    });

  return adhoc_ref_buy;
}

function getConfig(custom_list) {
  custom_list = custom_list ? custom_list : [];
  let header = store_header.getState();
  let pricing = store_pricing.getState();
  let ecs_main = Util.COLOR_PALETTE.ECS_MAIN;
  let default_config = custom_list.filter((c) => c.station === "***" && c.carrier === "***")[0];
  let station_config = custom_list.filter((c) => c.station === header.station && c.carrier === "***")[0];
  let carrier_config = custom_list.filter((c) => c.station === header.station && c.carrier === pricing.carrier)[0];

  //TEMPLATE COLOR CONFIG
  default_config = default_config ? {...default_config.config, gha_info : default_config.gha_info} : null;
  station_config = station_config ? {...station_config.config,  gha_info : station_config.gha_info} : null;
  carrier_config = carrier_config ? {...carrier_config.config, gha_info : carrier_config.gha_info} : null;

  let config = {
    sales_conditions: null,
    airline_conditions: null,
    logo: null,
  };

  // CARRIER CONFIG
  if (carrier_config) config = { ...carrier_config };
  else if (station_config) config = { ...station_config };
  else {
    config = { ...default_config };
  }

  //couleurs de police
  if (
    carrier_config &&
    carrier_config.quote_font_color &&
    carrier_config.quote_main_color
  ) {
    config.quote_font_color = carrier_config.quote_font_color;
    config.quote_main_color = carrier_config.quote_main_color;
  } else if (
    station_config &&
    station_config.quote_font_color &&
    station_config.quote_main_color
  ) {
    config.quote_font_color = station_config.quote_font_color;
    config.quote_main_color = station_config.quote_main_color;
  } else if (
    default_config &&
    default_config.quote_main_color &&
    default_config.quote_font_color
  ) {
    config.quote_font_color = default_config.quote_font_color;
    config.quote_main_color = default_config.quote_main_color;
  } else {
    config.quote_font_color = "white";
    config.quote_main_color = ecs_main;
  }

  return config;
}

function formatQuoteBeforeSave() {
  let shipment = store_shipment.getState();
  let routes = store_routes.getState();
  let pricing = store_pricing.getState();
  let margin = store_margin.getState();
  let header = store_header.getState();
  let remarks = store_remarks.getState();

  let _shipment = {
    service_level: shipment.service_level,
    price_class: shipment.price_class,
    stackable: shipment.stackable,
    all_stackable: shipment.all_stackable,
    main_deck: shipment.main_deck,
    screened: shipment.screened,
    exw: shipment.exw,
    is_min_chargeable_weight: shipment.is_min_chargeable_weight,
    agent: shipment.agent,
    agent_code: shipment.agent_code,
    origin: shipment.origin,
    dest: shipment.dest,
    pieces: shipment.pieces,
    gross_weight: shipment.gross_weight,
    volume: shipment.volume,
    chargeable_weight: shipment.chargeable_weight,
    density: shipment.density,
    shc: shipment.shc,
    commodity: shipment.commodity,
    commodity_code: shipment.commodity_code,
    nature_goods: shipment.nature_goods,
    packing: shipment.packing,
    packing_text: shipment.packing_text,
    uld: shipment.uld,
    dimension_type: shipment.dimension_type,
    packing_stackable: shipment.packing_stackable,
    target_per_kg: shipment.target_per_kg,
    weight_system: shipment.weight_system,
    length_system: shipment.length_system,
  };

  let _routes = {
    detailed_info: routes.detailed_info,
    check: routes.check,
    expectedTime: routes.expectedTime,
    routes: routes.routes,
  };

  let _pricing = {
    price: pricing.price,
    price_total: pricing.price_total,
    target_price: pricing.target_price,
    target_currency: pricing.target_currency,
    target_per_kg: pricing.target_per_kg,
    carrier: pricing.carrier,
    carrier_name: pricing.carrier_name,
    carrier_prefix: pricing.carrier_prefix,
    from_date: pricing.from_date,
    to_date: pricing.to_date,
    min_amount: pricing.min_amount,
    min_chargeable_weight: pricing.min_chargeable_weight,
    currency: pricing.currency,
    all_in: pricing.all_in,
    per_kg: pricing.per_kg,
    ocdc: pricing.ocdc,
    uld_price: pricing.uld_price,
    display_ocdc_total: pricing.display_ocdc_total,
    price_template:
      shipment.dimension_type === Util.CONST.ULD
        ? Util.CONST.ULD.toUpperCase()
        : Util.CONST.ADHOC.toUpperCase(),
  };

  let margin_per_kg =
    margin && margin.margin_per_kg ? margin.margin_per_kg : null;
  let _margin = {
    ...margin,
  };

  if (margin_per_kg) {
    _margin = { ..._margin, ...margin_per_kg };
  }


  return {
    hq: header.hq,
    id: header.id,
    external_id: header.external_id,
    shipment: _shipment,
    routing: _routes,
    pricing: _pricing,
    margin: _margin,
    station: header.station,
    status: header.status,
    int_remarks: remarks.int_remarks,
    ext_remarks: remarks.ext_remarks,
    failure_reason: header.failure_reason,
    failure_reason_exp:
      header.status === "REJECTED" && header.failure_reason_exp
        ? header.failure_reason_exp
        : "",
    last_follow_up: header.last_follow_up,
    adhoc_ref: header.adhoc_ref,
    adhoc_ref_buy: header.adhoc_ref_buy,
    awb_number: header.awb_number,
    user: cookies.account,
    update_history: header.update_history,
    last_updated_by: header.last_updated_by,
    ffr_sent: shipment.ffr_sent,
    ffr_sent_date: shipment.ffr_sent_date,
    email_sent: shipment.email_sent,
    equote_sent: shipment.equote_sent,
    chat_messages:
      remarks.chat_messages && remarks.chat_messages.length > 0
        ? {
            messages: remarks.chat_messages.map((m) => {
              return {
                user: m.user,
                timestamp: m.timestamp,
                message: m.message,
              };
            }),
            links: remarks.chat_links ? remarks.chat_links : "",
          }
        : null,
  };


}

async function formatEquote(custom_list) {
  let quote = formatQuoteBeforeSave();
  custom_list = custom_list ? custom_list : [];
  let config = getConfig(custom_list);
  quote.airline_conditions = config.airline_conditions;
  quote.sales_conditions = config.sales_conditions;

  try {
    quote.ffr = await generateFFR("XXX", "XXXXXXXX", "Adhoc");
    quote.ffrTo = "cargospot@quito.aero";
    let emails = (await getBookingEmail(custom_list)) || "";
    quote.ffrCc = `quantum.support@quito.aero;tech.ebooking@quito.aero;${emails}`;
    let messages = store_remarks
      .getState()
      .chat_messages.filter((m) => m.new && m.new === true);
    messages = messages ? messages : [];
    quote.chat_messages = {
      messages: messages.map((m) => {
        return { user: m.user, timestamp: m.timestamp, message: m.message };
      }),
      links: store_remarks.getState().chat_links,
    };
    quote.int_remarks = undefined;
  } catch (e) {
    console.log(e);
  }

  return quote;
}

async function getBookingEmail(custom_list) {
  let { hq, station } = store_header.getState();
  let { agent_code } = store_shipment.getState();
  let { carrier } = store_pricing.getState();

  hq = hq === "0" || hq === "false" ? 0 : hq;
  hq = hq === "1" || hq === "true" ? 1 : hq;

  if (hq || agent_code === "111111110030" || agent_code === "11111111-0030")
    return cookies.email_contact ? cookies.email_contact : "";

  let station_config = custom_list.filter(
    (c) => c.station === station && c.carrier === "***"
  )[0];
  let carrier_config = custom_list.filter(
    (c) => c.station === station && c.carrier === carrier
  )[0];
  let booking_email = "";

  if (carrier_config && carrier_config.config.booking_contact_email)
    //Carrier level
    booking_email = carrier_config.config.booking_contact_email;
  else if (station_config && station_config.config.booking_contact_email)
    // Station lever
    booking_email = station_config.config.booking_contact_email;

  return (
    booking_email + ";" + (cookies.email_contact ? cookies.email_contact : "")
  ); //user email
}

async function sendEquote(authorized_stations, custom_list) {
  let { status, station } = store_header.getState();
  let station_ = authorized_stations.filter((s) => s.station === station)[0];

  store_modals.dispatch({ type: 1, modalConfirmationEquote: false });
  store_modals.dispatch({ type: 1, page_loading_spinner: true });

  if (status === "DRAFT" || status === "QUEUED")
    store_header.dispatch({ type: 1, status: "QUOTED" });

  updateSendContact();

  if (status !== "REJECTED") {
    await updateAdhocSell(authorized_stations);
  }

  await calculateMargin({ station: station_, custom_list: custom_list });

  if (!checkMandatoryEquote())
    return store_modals.dispatch({ type: 1, page_loading_spinner: false });

  let params = { quote: await formatEquote(custom_list) };
  let res = await Routes.sendEquote(params);

  store_alerts.dispatch({
    type: 1,
    alert: { title: "", msg: "", type: "", show: "false" },
    force: true,
  });

  if (res && res.error) {
    store_alerts.dispatch({
      type: 1,
      name: "top",
      alert: {
        title: "Problem sending e-Quote",
        msg: `
          <strong>«${res.message}»</strong><br/>
          This can be because you are logged out or report to quantum.support@quito.aero.
        `,
        type: "error",
        show: "true",
      },
    });

    return store_modals.dispatch({ type: 1, page_loading_spinner: false });
  }

  let messages = store_remarks.getState().chat_messages;
  messages = messages ? messages : [];

  for (let i = 0; i < messages.length; i++) {
    if (messages[i].new && messages[i].new === true) {
      messages[i].new = false;
    }
  }

  store_shipment.dispatch({ type: 1, equote_sent: true });
  store_history.dispatch({ type: 1, send_equote: true });
  store_remarks.dispatch({ type: 1, force: true, chat_messages: messages });
  store_modals.dispatch({ type: 1, page_loading_spinner: false });

  await saveQuote();
}

async function saveQuote(custom_list) {
  const { adhoc_ref, adhoc_ref_buy } = store_header.getState();
  const { updateAdhoc } = store_modals.getState();
  let pricing = store_pricing.getState();
  let { carrier } = pricing;
  let header = store_header.getState();
  let { authorized_stations } = store_station.getState();

  store_alerts.dispatch({
    type: 1,
    alert: { title: "", msg: "", type: "", show: "false" },
    force: true,
  });

  if (!isUserCarrier())
    return store_alerts.dispatch({
      type: 1,
      name: "top",
      alert: {
        title: "Problem saving quote",
        msg: `
      You cannot save quote on this airline : ${carrier}.<br/>
      Reason : You're not Allowed.`,
        type: "error",
        show: "true",
      },
    });

  if (checkMandatory() && header.status === "DRAFT") {
    store_header.dispatch({ type: 1, status: "QUOTED" });
    header.status = "QUOTED";
  }

  let ocdc = pricing.ocdc;

  ocdc.forEach((o) => {
    if (!o.total_charges || isNaN(o.total_charges)) {
      store_pricing.dispatch({ type: 1, display_ocdc_total: false });
    }
  });

  let params = formatQuoteBeforeSave();


  let res = await Routes.saveQuote(params);

  store_alerts.dispatch({
    type: 1,
    alert: { title: "", msg: "", type: "", show: "false" },
    force: true,
  });

  if (res.error)
    return store_alerts.dispatch({
      type: 1,
      name: "top",
      alert: {
        title: "Problem saving quote",
        msg: `
          <strong>«${res.message}»</strong><br/>
          This can be because you are logged out.<br/>
          Please Report to quantum.support@quito.aero if needed.
        `,
        type: "error",
        show: "true",
      },
    });

  let id = res;

  if (id) store_header.dispatch({ type: 1, id: id });

  await saveQuoteHistory(id);

  if (
    header.status !== "QUEUED" &&
    header.external_id &&
    header.user &&
    header.user === Util.CONST.CARGOAI &&
    authorized_stations &&
    custom_list
  ) {
    await sendEquote(authorized_stations, custom_list);
  }
  updateUrl();
  tabName();

  store_errors.dispatch(checkForm());
  store_routes.dispatch({ type: 1, force: true });

  if (adhoc_ref && updateAdhoc) {
    await updateAdhocSell(authorized_stations);
  }

  if (adhoc_ref_buy && updateAdhoc) {
    await updateAdhocBuy();
  }

  store_modals.dispatch({ type: 1, quote_saved: true, updateAdhoc: false });
  store_pricing.dispatch({ type: 1, ocdc_auto_calculate: false });
  store_header.dispatch({ type: 1, saveProgressBar: true });
  await Util.sleep(800);
  store_header.dispatch({ type: 1, saveProgressBar: false });

  return id;
}

function updateUrl() {
  let { id } = store_header.getState();
  if (id && window.location.href.indexOf(id.toString()) < 0) {
  }
  window.history.pushState(
    {},
    document.title,
    config.front_host + "/#/Editor/" + id
  );
}

function tabName() {
  let header = store_header.getState();
  let pricing = store_pricing.getState();
  let shipment = store_shipment.getState();
  let { carrier } = pricing;
  let { id } = header;
  let { dest, agent } = shipment;

  if (header.id) {
    let tab =
      id +
      (!carrier ? "" : "-" + carrier) +
      (dest ? "-" + dest : "") +
      (agent && agent.length > 4 ? "-" + agent.substring(0, 4) : "");
    document.title = tab;
  }
}

function calTotalCharge(formula, min, max, weight, pieces) {
  let total_charge = 0;
  let total = 0;
  let basic = "0";
  let add = "0";
  let hawb = "0";
  // let piece = "0"

  if (!formula || formula === "0") {
    console.log("Case formula = 0");
    return min;
  }

  if (
    !formula.includes("B") &&
    !formula.includes("H") &&
    !formula.includes("%") &&
    !formula.includes("=") &&
    !formula.includes("/") &&
    !formula.includes("P")
  ) {
    console.log("Don't conatains anything");
    total = parseFloat(formula) * parseFloat(weight);
  } else if (formula.includes("B")) {
    //Contains B

    basic = formula.split(";")[0];
    basic = basic.replace("B", "");

    if (formula.includes("H")) {
      // Contains B and H

      hawb = formula.split(";")[1];
      hawb = hawb.replace("H", "");
      add = formula.split(";").length === 3 ? formula.split(";")[2] : 0;
      console.log("B and H", add, hawb);
    } else if (formula.includes("P")) {
      // Contains B and P
      //add = add = formula.split(";").length === 2 ? formula.split(";")[1] : 0
    } else {
      //Contains B but not H and P

      add =
        formula.split(";").length === 2
          ? formula.split(";")[1]
          : console.log("B but not H and P", add);
    }

    total = parseFloat(basic) + parseFloat(add) * parseFloat(weight);
  } else if (formula.includes("P")) {
    // Contains P

    console.log("Contains P");
    formula = formula.replace("P", "");
    total = parseFloat(pieces) * parseFloat(formula);
  } else if (formula.includes("%")) {
    // No use
    total = 0;
  } else if (formula.includes("=")) {
    console.log("Contains =");
    let weight_prices = formula.split(";");
    let w = [];
    let p = [];
    let i = 0;

    for (i = 0; i < weight_prices.length; i++) {
      w.push(weight_prices[i].split("=")[0]);
      p.push(weight_prices[i].split("=")[1]);
      if (parseFloat(weight) <= parseFloat(w[i])) {
        total = p[i];
      }
    }
  } else if (formula.includes("/")) {
    total = 0;
  } else if (formula.includes("H")) {
    total = 0;
  } else {
    total = 0;
  }

  if (min !== 0 && total < min) total_charge = min;
  else if (max !== 0 && total > max) total_charge = max;
  else total_charge = total;

  return total_charge;
}

async function calculateOCDC() {
  store_pricing.dispatch({ type: 1, get_ocdc: [] });

  let shipment = store_shipment.getState();
  let pricing = store_pricing.getState();
  let header = store_header.getState();
  let { routes } = store_routes.getState();
  routes = routes ? routes : [];

  if (!isUserCarrier()) {
    return;
  }

  let flight_type =
    routes.length > 0
      ? routes.map((r) =>
          r.flight_type === "Truck"
            ? "T"
            : r.flight_type === "Freighter"
            ? "F"
            : "P"
        )[0]
      : "";

  if (!shipment.chargeable_weight || !shipment.gross_weight) return; // THE TOTAL CHARGE CALCUL IS IMPOSSIBLE WITHOUT WEIGHT

  if (!pricing.carrier) return; // THE TOTAL CHARGE CALCUL DEPENDS OF CARRIER

  let transits = [];

  if (routes.length > 1) {
    for (let i = 1; i < routes.length; i++) {
      transits.push(routes[i]);
    }
  }

  transits = transits.map((r) => {
    return r.origin;
  });

  let params = {
    agent: shipment.agent_code,
    origin: shipment.origin,
    dest: shipment.dest,
    carrier: pricing.carrier,
    shc: shipment.shc,
    from_date: pricing.from_date,
    until_date: pricing.to_date,
    station: header.station,
    routing: transits ? transits.join(";") : "",
    price_class: !shipment.price_class ? "" : shipment.price_class,
    service_level: !shipment.service_level ? "" : shipment.service_level,
    flight_type: flight_type,
  };

  let ocdc = await Routes.get("/OCDC", params, true);

  if (ocdc && ocdc.length > 0) {
    let ocdc_ = ocdc.map((o) => {
      let oc = formatOCDC(o);

      if (!oc) return null;

      return {
        name: oc.name,
        code: oc.code,
        based_on: oc.based_on,
        currency: oc.currency,
        value: oc.value,
        total_charges: oc.total,
      };
    });
    ocdc_ = ocdc_.filter((o) => o !== null);
    store_pricing.dispatch({ type: 1, get_ocdc: ocdc_ });
  }
}

async function ocdcByCode(code, i) {
  if (!code) return;

  if (code && code.length < 3) return;

  if (!isUserCarrier()) return;

  let shipment = store_shipment.getState();
  let pricing = store_pricing.getState();
  let header = store_header.getState();
  let { routes } = store_routes.getState();
  routes = routes ? routes : [];
  let flight_type =
    routes.length > 0
      ? routes.map((r) =>
          r.flight_type === "Truck"
            ? "T"
            : r.flight_type === "Freighter"
            ? "F"
            : "P"
        )[0]
      : "";

  if (!shipment.chargeable_weight || !shipment.gross_weight) return; // THE TOTAL CHARGE CALCUL IS IMPOSSIBLE WITHOUT WEIGHT

  if (!pricing.carrier) return; // THE TOTAL CHARGE CALCUL DEPENDS OF CARRIER

  let transits = [];

  if (routes.length > 1) {
    for (let i = 1; i < routes.length; i++) {
      transits.push(routes[i]);
    }
  }

  transits = transits.map((r) => {
    return r.origin;
  });

  let params = {
    oc_code: code,
    agent: shipment.agent_code,
    origin: shipment.origin,
    dest: shipment.dest,
    carrier: pricing.carrier,
    shc: shipment.shc,
    from_date: pricing.from_date,
    until_date: pricing.to_date,
    station: header.station,
    routing: transits ? transits.join(";") : "",
    price_class: !shipment.price_class ? "" : shipment.price_class,
    service_level: !shipment.service_level ? "" : shipment.service_level,
    flight_type: flight_type,
  };

  let oc = await Routes.get("/OCDCByCode", params, true);

  if (!oc) return;

  oc = formatOCDC(oc);

  if (!oc) return;

  store_pricing.dispatch({
    type: 1,
    index: i,
    ocdc: true,
    name: oc.name,
    currency: oc.currency,
    based_on: oc.based_on,
    value: oc.value,
    total_charges: oc.total,
  });

  try {
    document.getElementById("name_" + i).value = oc.name;
    document.getElementById("base_on_" + i).value = oc.based_on;
    document.getElementById("currency_" + i).value = oc.currency;
    document.getElementById("value_" + i).value = oc.value;
    document.getElementById("total_charges_" + i).value = oc.total;
  } catch (error) {}
}

function formatOCDC(o) {
  if (!o) return null;

  let shipment = store_shipment.getState();
  let name = !o.name ? "" : FirstWordUppercase(o.name);
  let type = !o.weight_type ? "" : o.weight_type.toUpperCase();
  
  let weight = type === "F" || type === 'G' ? shipment.gross_weight : shipment.chargeable_weight;

  let unit = type === "P" || (o.formula_kg && o.formula_kg.includes("H"))? "" : Util.CONST.KG;

  let based_on = "TBD"

  based_on = o.per_awb === 'Y' ? "Per AWB" 
  : (o.formula_kg && o.formula_kg.includes("H")) ? "Per HAWB" 
  : (type === "F" || type === "G") ? "Gross Weight" 
  : type === "P" ? "Per UN" 
  : type === "C" ? "Chargeable Weight" : based_on

  let formula_kg = !o.formula_kg ? 0 : o.formula_kg;
  let min = !o.min_amount ? 0 : o.min_amount;
  let max = !o.max_amount ? 0 : o.max_amount;
  let value = !min ? "" : "Min: " + min;
  value =
    value +
    (!formula_kg
      ? ""
      : !min
      ? formula_kg + " " + unit
      : " | " + formula_kg + " " + unit);
  value = value + (!max ? "" : !min && !formula_kg ? "" : " | Max: " + max);
  let total = 0;
  let pieces = !shipment.pieces ? 0 : shipment.pieces;
  total = calTotalCharge(formula_kg, min, max, weight, pieces);
  total = Math.round(total * 100) / 100;
  total = total.toFixed(2);
  total =
    type !== "F" && type !== "C" && type !== "G"
      ? !formula_kg
        ? total
        : "TBD"
      : total;

  return {
    name: name,
    code: o.oc_code,
    based_on: based_on,
    currency: o.currency,
    value: value,
    total: total,
  };
}

function checkMandatoryEquote() {
  let header = store_header.getState();
  let errors = checkForm();
  let error = false;

  for (const err in errors) {
    switch (err) {
      case "chargeable_weight":
        error = true;
        break;
      case "gross_weight":
        error = true;
        break;
      case "dest":
        error = true;
        break;
      case "origin":
        error = true;
        break;
      case "issue_carrier":
        error = true;
        break;
      case "agent":
        error = true;
        break;
      case "agent_code":
        error = true;
        break;
      case "volume":
        error = true;
        break;
      case "flight_date":
        error = true;
        break;
      case "price":
        if (header.status !== "REJECTED" && header.status !== "CANCELLED")
          error = true;
        break;
      case "from_date":
        error = true;
      case "to_date":
        error = true;
        break;
      default:
    }
  }

  if (error) return false;

  let routes = store_routes.getState().routes;
  if (routes.length === 0) return false;

  for (let i = 0; i < routes.length; i++) {
    let r = routes[i];
    if (
      !r.origin ||
      !r.dest ||
      !r.carrier ||
      !r.flight_number ||
      !r.flight_type ||
      !r.flight_date ||
      !r.etd ||
      !r.eta
    ) {
      return false;
    }
  }
  return true;
}

function checkMandatory() {
  let errors = checkForm();
  let error = false;
  let header = store_header.getState();

  
  for (const err in errors) {
    switch (err) {
      case "chargeable_weight":
        error = true;
        break;
      case "gross_weight":
        error = true;
        break;
      case "dest":
        error = true;
        break;
      case "origin":
        error = true;
        break;
      case "issue_carrier":
        error = true;
        break;
      case "price":
        if (
          !header.external_id ||
          (header.external_id &&
            header.user &&
            header.user !== Util.CONST.CARGOAI)
        )
          error = true;
        break;
      case "agent":
        error = true;
        break;
      case "agent_code":
        error = true;
        break;
      case "volume":
        error = true;
        break;
      case "from_date":
        error = true;
        break;
      case "to_date":
        error = true;
        break;
      default:
    }
  }

  return !error;
}

function checkForm() {
  let shipment = store_shipment.getState();
  let pricing = store_pricing.getState();
  let margin = store_margin.getState();
  let { margin_per_kg, margin_in_total } = margin;
  let header = store_header.getState();
  let routes = store_routes.getState().routes;
  let action = {
    type: 1,
    reset: true,
    carriers_idxs: [],
    flight_date_idxs: [],
    flight_number_idxs: [],
    flight_eta_idxs: [],
    flight_etd_idxs: [],
    board_points: [],
    off_points: [],
    selling_rate_idxs: [],
  };

  if (!shipment.agent) {
    action.agent = "Missing";
  }

  if (shipment.agent && !shipment.agent_code) {
    action.agent_code = "Agent IATA Cass doesn't exist";
  }

  if (!shipment.origin) action.origin = "Missing";
  else {
    if (!shipment.origin.match(/[A-z][A-z][A-z]/))
      action.origin = "Wrong format";
    if (shipment.exw && shipment.origin === "EXW")
      action.origin = "Wrong origin";
  }

  if (!shipment.dest) action.dest = "Missing";
  else if (!shipment.dest.match(/[A-z][A-z][A-z]/))
    action.dest = "Wrong format";

  if (!shipment.gross_weight && shipment.dimension_type === Util.CONST.LOOSE)
    action.gross_weight = "Missing";

  if (
    !isNaN(shipment.gross_weight) &&
    !isNaN(shipment.chargeable_weight) &&
    shipment.dimension_type === Util.CONST.LOOSE
  ) {
    if (
      shipment.chargeable_weight < shipment.gross_weight ||
      shipment.chargeable_weight < shipment.volume * 166
    )
      action.chargeable_weight = "Chg. weight is too small";
  }

  if (!shipment.chargeable_weight) {
    action.chargeable_weight = "Missing";
  }

  // if (!shipment.volume || !parseFloat(shipment.volume))
  //   action.volume = "Missing";

  if (pricing.per_kg && !pricing.price) action.price = "Missing";

  if (!pricing.per_kg && !pricing.price_total) action.price = "Missing";

  if (!pricing.carrier.match(/([A-z]|[0-9])([A-z]|[0-9])/g))
    action.issue_carrier = "Wrong format";

  if (pricing.carrier === undefined || pricing.carrier === "")
    action.issue_carrier = "Missing";

  if (
    (margin_per_kg && margin_per_kg.margin < 0) ||
    (margin_in_total && margin_in_total.margin < 0)
  )
    action.margin = "Margin negative";
  else action.margin = "";

  if (shipment.packing.length > 0) {
    if (
      shipment.packing_total_wgt !== shipment.gross_weight &&
      shipment.packing_total_wgt
    )
      action.weight_mismatch = "Mismatch with packing list";

    if (
      (shipment.packing.length === 1 &&
        !shipment.packing[0].pieces &&
        !shipment.packing[0].length &&
        !shipment.packing[0].weight &&
        !shipment.packing[0].width) ||
      (!shipment.packing_total_wgt && !shipment.gross_weight)
    )
      action.weight_mismatch = "";
    //Volume Mismatch
    if (
      parseFloat(shipment.packing_total_vol) !== parseFloat(shipment.volume) &&
      shipment.packing_total_vol
    )
      action.volume_mismatch = "Mismatch with packing list";

    if (
      (shipment.packing.length === 1 &&
        !shipment.packing[0].pieces &&
        !shipment.packing[0].length &&
        !shipment.packing[0].weight &&
        !shipment.packing[0].width) ||
      (!shipment.packing_total_vol && !shipment.volume)
    )
      action.volume_mismatch = "";
    //Stackable Mismatch
    if (shipment.packing_stackable !== shipment.stackable) {
      action.stackable_mismatch = "Mismatch with packing list";
    }

    if (
      (shipment.packing.length === 1 &&
        !shipment.packing[0].pieces &&
        !shipment.packing[0].length &&
        !shipment.packing[0].weight &&
        !shipment.packing[0].width) ||
      shipment.packing_stackable === shipment.stackable
    ) {
      action.stackable_mismatch = "";
    }
    //Pieces Mismatch
    if (
      parseFloat(shipment.packing_total_pcs) !== parseFloat(shipment.pieces) &&
      shipment.packing_total_pcs
    ) {
      action.pieces_mismatch = "Mismatch with packing list";
    }

    if (
      (shipment.packing.length === 1 &&
        !shipment.packing[0].pieces &&
        !shipment.packing[0].length &&
        !shipment.packing[0].weight &&
        !shipment.packing[0].width) ||
      shipment.pieces === shipment.packing_total_pcs
    ) {
      action.pieces_mismatch = "";
    }
  }

  if (!pricing.from_date) {
    action.from_date = "From Date must be selected.";
  }

  if (!pricing.to_date) {
    action.to_date = "To Date must be selected.";
  }

  if (
    pricing.from_date &&
    pricing.to_date &&
    pricing.to_date < pricing.from_date
  ) {
    action.from_date = "Invalid dates. From Date must be less than To Date.";
  }

  if (pricing.from_date && pricing.to_date) {
    let from_date_time = new Date(pricing.from_date).getTime();
    let to_date_time = new Date(pricing.to_date).getTime();
    let diff_date = (to_date_time - from_date_time) / (1000 * 3600 * 24);

    if (diff_date > 31) action.to_date = "Maximum 31 days validity allowed.";
  }

  if (
    margin.buy_min > 0 &&
    margin.buy_total &&
    margin.buy_min > margin.buy_total
  )
    action.buy_min = "Buy min amount not respected.";

  if (
    pricing.per_kg &&
    pricing.price &&
    pricing.min_amount > pricing.price * shipment.chargeable_weight
  )
    action.price = "Min.amount not respected.";

  if (pricing.min_chargeable_weight > shipment.chargeable_weight)
    action.min_chargeable_weight = "Min. Chg. Wgt. not respected.";

  if (
    header.external_id &&
    header.user &&
    header.user === Util.CONST.CARGOAI &&
    routes.length === 0
  )
    action.routes = "Routes missing.";


  // let connectPoints = [];
  // let hasError = false;

  // for (let i = 1; i < routes.length; i++) {
  //   let previousLeg = routes[i - 1];
  //   let currentLeg = routes[i];
    
  //   if (currentLeg.origin === previousLeg.dest) {
  //     connectPoints.push(true);
      
  //   } else {
  //     connectPoints.push(false);
  //     hasError = true;
  //   }
  // }

  // if (hasError) {
  //   connectPoints.forEach((point, index) => {
  //     if (!point) {
  //       action.board_points.push(index)
  //     }
  //   });
  // }

  routes.forEach((r) => {
    let index = routes.indexOf(r);

    if (
      r.flight_date &&
      !r.flight_date.match(
        /^[0-9][0-9](JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[0-9][0-9]/
      )
    ) {
      action.flight_date = action.flight_date
        ? action.flight_date + " " + index
        : "Wrong format, use DDMMMYY";
      action.flight_date_idxs.push(index);
    }

    if (r.flight_date) {
      let flight_date = formatDate(r.flight_date);
      flight_date = new Date(flight_date);
      let from_date = new Date(pricing.from_date);
      let to_date = new Date(pricing.to_date);

      if (from_date > to_date) {
        action.from_date = "Invalid dates. From Date must be less than To Date";
      } else if (flight_date && from_date && from_date > flight_date) {
        action.from_date =
          "From Date validity is incorrect with flight " + r.flight_date;
      } else if (flight_date && to_date && flight_date > to_date) {
        // action.to_date = "To Date validity is incorrect with flight " + r.flight_date
        action.to_date_1 = "Validity ending date is before flying date";
      }
    }

    if (r.carrier && !r.carrier.match(/([A-z]|[0-9])([A-z]|[0-9])/g)) {
      action.routing_carrier = "Wrong format";
      action.carriers_idxs.push(index);
    }

    if (shipment.exw && (index === 0 || index === 1) && !r.origin)
      action.board_points.push(index);

    if (shipment.exw && (index === 0 || index === 1) && !r.dest)
      action.off_points.push(index);

    if (header.external_id && header.user === Util.CONST.CARGOAI) {
      if (!r.origin) action.board_points.push(index);
      if (!r.dest) action.off_points.push(index);
      if (!r.carrier) action.carriers_idxs.push(index);
      if (!r.flight_number) action.flight_number_idxs.push(index);
      if (!r.flight_date) action.flight_date_idxs.push(index);
      if (!r.eta) action.flight_eta_idxs.push(index);
      if (!r.etd) action.flight_etd_idxs.push(index);
    }

   
  });


  if (shipment.dimension_type === Util.CONST.ULD && pricing.uld_price) {
    let prices = pricing.uld_price;

    prices.forEach((p) => {
      let index = prices.indexOf(p);

      if (!p.selling_rate) {
        action.selling_rate_idxs.push(index);
        action.price = "Missing";
      } else if (isNaN(parseFloat(p.selling_rate))) {
        action.selling_rate_idxs.push(index);
        action.price = "Invalid";
      }
    });
  }

  return action;
}

function checkRoutingsPoints(routes) {
  
  let errorMessages = [];

  const connectPoints = routes.slice(1).map((leg, i) => {
    const isEqual = leg.origin === routes[i].dest
    if (!isEqual) {
      errorMessages.push(`Line ${i + 2}: ${leg.origin} doesn't match ${routes[i].dest}`);
    }
    return isEqual
  });

  if (errorMessages.length > 0) {
    store_alerts.dispatch({
      type: 1,
      name: "top",
      alert: {
        title: "Problem in routing section",
        msg: `Mismatch on the following lines: <br/>${errorMessages.join('<br/>')}.`,
        type: "error",
        show: "true",
      },
    });
  }

  return connectPoints;
}


function routeHasEmpty() {
  let routes = store_routes.getState().routes;
  let exw = store_shipment.getState().exw;
  let empty = false;

  if (!exw) {
    empty = false;
  } else if (routes.length < 2) {
    empty = true;
  } else {
    if (
      !routes[0].origin ||
      !routes[0].dest ||
      !routes[1].origin ||
      !routes[1].dest
    )
      empty = true;
  }

  return empty;
}

function loadConfig(authorized_stations, custom_list) {
  if (!authorized_stations.length) return null;

  let header = store_header.getState();
  let default_config = custom_list.filter(
    (c) => c.station === "***" && c.carrier === "***"
  )[0].config;

  

  let station_config = custom_list.filter(
    (c) => c.station === header.station && c.carrier === "***"
  )[0];

  

  let default_currency = util.CURRENCIES[0];
  station_config = !station_config ? undefined : station_config.config;

  if (station_config !== undefined && station_config !== null) {
    default_currency = station_config.default_currency;
    
  } else {
    default_currency = default_config.default_currency;
   
  }

  store_pricing.dispatch({
    type: 1,
    currency: default_currency,
    target_currency: default_currency,
  });
  store_modals.dispatch({ type: 1, quote_saved: true });
}

function FirstWordUppercase(sentence) {
  return sentence
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function isSimilarQuote(id) {
  let similarQuotes = store_similar.getState().similarQuotes;
  let result = false;
  if (similarQuotes)
    similarQuotes.forEach((q) => {
      if (id && q.id === id) {
        result = true;
      }
    });

  return result;
}

function hasSimilarQuotes() {
  return store_similar.getState().similarQuotes.length > 0 ? true : false;
}

function hasExactQuotes() {
  let { similarQuotes } = store_similar.getState();
  let { volume, gross_weight, chargeable_weight, dimension_type } =
    store_shipment.getState();
  let result = false;

  similarQuotes = similarQuotes ? similarQuotes : [];
  similarQuotes.forEach((q) => {
    let s = q.shipment;
    if (
      (s.volume === volume ||
        s.volume === parseFloat(parseFloat(s.volume).toFixed(2))) &&
      ((gross_weight && s.gross_weight === gross_weight) ||
        (!gross_weight &&
          chargeable_weight &&
          s.chargeable_weight === chargeable_weight &&
          dimension_type === Util.CONST.ULD))
    )
      result = true;
  });

  return result;
}

function isExactQuote(id, shipment) {
  let { volume, gross_weight, chargeable_weight, dimension_type } =
    store_shipment.getState();
  let result = false;

  if (
    id &&
    isSimilarQuote(id) &&
    ((gross_weight && shipment.gross_weight === gross_weight) ||
      (dimension_type === Util.CONST.ULD &&
        !gross_weight &&
        chargeable_weight &&
        shipment.chargeable_weight === chargeable_weight)) &&
    shipment.volume &&
    (shipment.volume === volume ||
      parseFloat(parseFloat(shipment.volume).toFixed(2)) === volume)
  ) {
    result = true;
  }

  return result;
}

async function displaySimilars() {
  let { carrier } = store_pricing.getState();
  let {
    origin,
    dest,
    commodity_code,
    gross_weight,
    volume,
    chargeable_weight,
    dimension_type,
  } = store_shipment.getState();
  let { id, station } = store_header.getState();

  if (
    carrier &&
    origin &&
    dest &&
    commodity_code &&
    (gross_weight ||
      (dimension_type === Util.CONST.ULD && chargeable_weight)) &&
    volume &&
    station
  ) {
    let params = {
      exclude_id: id ? id : 0,
      region: cookies.region,
      station: station,
      origin: origin,
      dest: dest,
      carrier: carrier,
      gross_weight: gross_weight,
      chargeable_weight: chargeable_weight,
      volume: volume,
      commodity_code: commodity_code,
      dimension_type: dimension_type,
    };
    let similars = await Routes.get("/SimilarQuotes", params, true);

    store_similar.dispatch({
      type: 1,
      similarQuotes: similars ? similars.quotes : [],
    });

    if (hasExactQuotes()) {
      store_similar.dispatch({ type: 1, exact: true, similar: false });
    } else if (hasSimilarQuotes()) {
      store_similar.dispatch({ type: 1, similar: true, exact: false });
    } else {
      store_similar.dispatch({ type: 1, similar: false, exact: false });
    }
  } else {
    store_similar.dispatch({
      type: 1,
      similarQuotes: [],
      similar: false,
      exact: false,
    });
  }
}

async function saveQuoteHistory(quote_id) {
  let shipment = store_shipment.getState();
  let pricing = store_pricing.getState();
  let quote = store_history.getState().quote;
  let header = store_header.getState();
  let margin = store_margin.getState();
  let { margin_per_kg } = margin;
  let change = false;
  var changes = [];

  var params = {
    id: header.id,
    changes: ["all"],
    status: header.status,
    adhoc_ref: header.adhoc_ref,
    adhoc_ref_buy: header.adhoc_ref_buy,
    awb_serial: header.serial,
    agent: shipment.agent,
    origin: shipment.origin,
    dest: shipment.dest,
    pieces: shipment.pieces,
    gross_weight: shipment.gross_weight,
    volume: shipment.volume,
    chargeable_weight: shipment.chargeable_weight,
    shc: shipment.shc,
    target_price: pricing.target_price,
    target_currency: pricing.target_currency,
    target_per_kg: pricing.target_per_kg,
    carrier: pricing.carrier,
    currency: pricing.currency,
    selling_rate: pricing.per_kg ? pricing.price : pricing.price_total,
    all_in: pricing.all_in,
    per_kg: pricing.per_kg,
    min_weight: pricing.min_chargeable_weight,
    min_amount: pricing.min_amount,
    edit_buy_kg: pricing.all_in
      ? margin_per_kg.all_in_buy_edit
      : margin_per_kg.net_buy_edit,
    edit_buy_currency: margin.edit_buy_currency
      ? margin.edit_buy_currency
      : pricing.currency,
    update_by: cookies.account,
    send_adhoc: quote.send_adhoc,
    send_ffr: quote.send_ffr,
    send_equote: quote.send_equote,
  };

  if (!header.id) {
    //First save
    var q = params;
    q.id = quote_id;
    change = true;
    store_history.dispatch({ type: 1, quote: q, first_save: true });
    if (quote_id) {
      await Routes.post("/QuoteHistory", q, true);
    }
  } else {
    let selling_rate = pricing.per_kg ? pricing.price : pricing.price_total;
    let edit_buy_kg = pricing.all_in
      ? margin_per_kg.all_in_buy_edit
      : margin_per_kg.net_buy_edit;
    let edit_buy_currency = margin.edit_buy_currency
      ? margin.edit_buy_currency
      : pricing.currency;

    if (quote.status !== header.status) {
      changes.push("status");
      change = true;
    }

    if (quote.adhoc_ref !== header.adhoc_ref) {
      changes.push("adhoc_ref");
      change = true;
    }

    if (quote.adhoc_ref_buy !== header.adhoc_ref_buy) {
      changes.push("adhoc_buy");
      change = true;
    }

    if (quote.awb_serial !== header.serial) {
      changes.push("awb_serial");
      change = true;
    }

    if (quote.agent !== shipment.agent) {
      changes.push("agent");
      change = true;
    }

    if (quote.origin !== shipment.origin) {
      changes.push("origin");
      change = true;
    }

    if (quote.dest !== shipment.dest) {
      changes.push("dest");
      change = true;
    }

    if (quote.pieces !== shipment.pieces) {
      changes.push("pieces");
      change = true;
    }

    if (quote.volume !== shipment.volume) {
      changes.push("volume");
      change = true;
    }

    if (quote.gross_weight !== shipment.gross_weight) {
      changes.push("gross_weight");
      change = true;
    }

    if (quote.chargeable_weight !== shipment.chargeable_weight) {
      changes.push("chargeable_weight");
      change = true;
    }

    if (
      quote.shc[0] !== shipment.shc[0] ||
      quote.shc[1] !== shipment.shc[1] ||
      quote.shc[2] !== shipment.shc[2] ||
      quote.shc[3] !== shipment.shc[3]
    ) {
      changes.push("shc");
      change = true;
    }

    if (
      parseFloat(quote.target_price ? quote.target_price : 0) !==
      parseFloat(pricing.target_price ? pricing.target_price : 0)
    ) {
      changes.push("target_price");
      change = true;
    }

    if (quote.target_currency !== pricing.target_currency) {
      changes.push("target_currency");
      change = true;
    }

    if (quote.target_per_kg !== pricing.target_per_kg) {
      changes.push("target_per_kg");
      change = true;
    }

    if (quote.carrier !== pricing.carrier) {
      changes.push("carrier");
      change = true;
    }

    if (quote.currency !== pricing.currency) {
      changes.push("pricing_currency");
      change = true;
    }

    if (
      parseFloat(quote.selling_rate ? quote.selling_rate : 0) !==
      parseFloat(selling_rate ? selling_rate : 0)
    ) {
      changes.push("selling_rate");
      change = true;
    }

    if (quote.all_in !== pricing.all_in) {
      changes.push("all_in");
      change = true;
    }

    if (quote.per_kg !== pricing.per_kg) {
      changes.push("selling_per_kg");
      change = true;
    }

    quote.min_weight = quote.min_weight ? quote.min_weight : 0;

    if (quote.min_weight !== pricing.min_chargeable_weight) {
      changes.push("min_weight");
      change = true;
    }

    quote.min_amount = quote.min_amount ? quote.min_amount : 0;
    let min_amount = pricing.min_amount ? pricing.min_amount : 0;

    if (quote.min_amount !== min_amount) {
      changes.push("min_amount");
      change = true;
    }

    if (quote.edit_buy_kg !== edit_buy_kg) {
      changes.push("edit_buy_kg");
      change = true;
    }

    if (quote.edit_buy_currency !== edit_buy_currency) {
      changes.push("edit_buy_currency");
      change = true;
    }

    if (quote.send_adhoc) {
      changes.push("send_adhoc");
      change = true;
    }

    if (quote.send_equote) {
      changes.push("send_equote");
      change = true;
    }

    if (quote.send_ffr) {
      changes.push("send_ffr");
      change = true;
    }

    params.changes = changes;
    q = params;

    store_history.dispatch({ type: 1, quote: q, first_save: false });

    if (change) {
      await Routes.post("/QuoteHistory", q, true);
    }
  }

  store_history.dispatch({
    type: 1,
    send_ffr: false,
    send_adhoc: false,
    send_equote: false,
  });
}

async function saveAttachment(quote_id) {
  let { attachments, attachment_id } = store_remarks.getState();

  let params = {};
  params.id = attachment_id;
  params.quote_id = quote_id;
  params.file_1_name =
    !attachments[0] || !attachments[0].file_name
      ? ""
      : attachments[0].file_name;
  params.file_1 =
    !attachments[0] || !attachments[0].file ? "" : attachments[0].file;
  params.file_2_name =
    !attachments[1] || !attachments[1].file_name
      ? ""
      : attachments[1].file_name;
  params.file_2 =
    !attachments[1] || !attachments[1].file ? "" : attachments[1].file;
  params.file_3_name =
    !attachments[2] || !attachments[2].file_name
      ? ""
      : attachments[2].file_name;
  params.file_3 =
    !attachments[2] || !attachments[2].file ? "" : attachments[2].file;

  if (!quote_id) {
    store_modals.dispatch({ type: 1, page_loading_spinner: false });
    return;
  }

  if (!params.id && !params.file_1 && !params.file_2 && !params.file_3) {
    return;
  }

  await Routes.post("/Attachment", params, true);

  store_modals.dispatch({ type: 1, page_loading_spinner: false });
  store_remarks.dispatch({ type: 1, updateAttachment: false });
}

async function generateFFR(prefix, serial, osi_remarks, is_kk = false) {
  let shipment = store_shipment.getState();
  let { routes } = store_routes.getState();
  let pricing = store_pricing.getState();
  let header = store_header.getState();
  let { authorized_stations } = store_station.getState();
  let station = authorized_stations.filter(
    (s) => s.station === store_header.getState().station
  )[0];
  let params = {
    agent: shipment.agent_code,
    sales_region:
      station && station.sales_region
        ? station.sales_region
        : cookies.sales_region,
  };
  let agents = await Routes.get("/Agent", params, true);
  let agent = agents ? agents[0] : [];
  let agent_name = agent ? agent.name.toUpperCase() : "";
  let agent_city = agent ? agent.city.toUpperCase() : "";

  const default_route =
    pricing.carrier +
    "000/" +
    util.dateToString2(pricing.from_date) +
    "/" +
    shipment.origin +
    shipment.dest +
    "/KK";

  //Mandatory fields control
  if (!shipment.origin) throw "Origin missing";
  if (!shipment.dest) throw "Destination missing.";
  if (!shipment.pieces) throw "Pieces missing.";
  //if (!shipment.volume) throw "Volume missing.";
  if (!shipment.agent_code) throw "Agent code missing.";

  let packing = shipment.packing.filter((p) => p.height || p.width || p.length);

  for (let i = 0; i < packing.length; i++) {
    let p = packing[i];
    if (!p.height) throw "Packing height missing.";
    if (!p.width) throw "Packing width missing.";
    if (!p.length) throw "Packing length missing.";
  }

  //SHC
  var shc = shipment.shc;
  shc = shc.filter((s) => s !== "EXW");

  if (shipment.exw) shc.push("EXW");

  if (header.external_id && header.external_id.indexOf("CAI") >= 0)
    shc.push("CAI");

  //ROUTING

  let routing = routes.map((r) => {
    let i = routes.indexOf(r);
    let status =
      (is_kk && i === 0) ||
      (is_kk && i >= 1 && routes[i - 1].flight_type === "Truck")
        ? "/KK"
        : "/NN";
    return (
      (r.carrier ? r.carrier : pricing.carrier) +
      (r.flight_number ? r.flight_number : "000") +
      "/" +
      (r.flight_date ? r.flight_date : util.dateToString2(pricing.from_date)) +
      "/" +
      (r.origin && r.origin !== "EXW" ? r.origin : shipment.origin) +
      (r.dest ? r.dest : shipment.dest) +
      status +
      "\n"
    );
  });

  //ADD REF TO OSI REMARKS (Ref_Buy and Ref_Sell)
  osi_remarks = osi_remarks ? osi_remarks : "";

  let osi =
    "QUANTUM " +
    (header.adhoc_ref ? "Ref Sell " + header.adhoc_ref : "ID " + header.id) +
    (header.adhoc_ref_buy ? " Ref Buy " + header.adhoc_ref_buy : "");
  let uld_text = pricing.uld_price
    ? pricing.uld_price.map((u) => {
        return u.pieces + "" + u.position + " ";
      })
    : "";
  osi_remarks =
    shipment.dimension_type === Util.CONST.ULD
      ? osi + " " + uld_text.join("") + " " + osi_remarks
      : osi + " " + osi_remarks;
  // GENERATE FFR

  let ffr =
    "FFR/6\n" +
    prefix +
    "-" +
    serial +
    shipment.origin +
    shipment.dest +
    "/T" +
    shipment.pieces +
    "K" +
    (shipment.gross_weight ? shipment.gross_weight.toFixed(1) : "0.00001") +
    "MC" +
    parseFloat(shipment.volume).toFixed(3) +
    "/" +
    (shipment.nature_goods ? shipment.nature_goods : shipment.commodity) +
    "\n" +
    (shc.filter((s) => s !== "").length === 0
      ? ""
      : "/" + shc.filter((s) => s !== "").join("/") + "\n") +
    (routes.length === 0 ? default_route : routing.join("")) +
    "OSI/" +
    osi_remarks +
    "\n" +
    (packing.length === 0
      ? ""
      : "DIM" +
        packing
          .map(
            (p) =>
              "/K" +
              (p.weight ? p.weight : 0) +
              (shipment.length_system === Util.CONST.IMPERIAL
                ? Util.CONST.PER_INH
                : Util.CONST.PER_CM) +
              p.length +
              "-" +
              p.width +
              "-" +
              p.height +
              "/" +
              p.pieces
          )
          .join("\n") +
        "\n") +
    "REF/CDGCSXH\n" +
    "CUS//" +
    shipment.agent_code.substring(0, 7) +
    "/" +
    shipment.agent_code.substring(7, 12) +
    "\n" +
    (agent_name ? "/" + util.toLetter(agent_name) + "\n" : "") +
    (agent_city ? "/" + agent_city : "");

  return ffr;

  /*
    FFR/6
    881-78502480FRALPA/T2K32MC0.11/TEST NATURE
    /PIL/ICE
    DE000/15JUL/FRAXXX/NN
    OSI/TEST QUANTUM OSI REMARKS
    DIM/K0/CMT60-40-40/1
    REF/CDG
    /K0/CMT31-22-22/1
    CUS//2347041/6815
  */
}

async function followUp() {
  let header = store_header.getState();

  if (!header.id || !cookies.user) return;

  let params = {
    user: cookies.account,
    quote_id: header.id,
    active: !header.follow_up,
    id: header.follow_up_id,
  };

  let res = await Routes.post("/FollowUp", params, true);

  store_header.dispatch({
    type: 1,
    follow_up: !header.follow_up,
    follow_up_id: res.id,
  });
}

function groupBy(key) {
  return function group(array) {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
}

function groupULDPrice(uld_price) {
  let grouByPosition = groupBy("position");
  let result = grouByPosition(uld_price);
  let keys = Object.keys(result);
  result = JSON.parse(JSON.stringify(result));
  keys = JSON.parse(JSON.stringify(keys));
  let uld_price_grouped = [];
  let currency = store_pricing.getState().currency;
  let uld_settings = store_shipment.getState().uld_settings;
  let uld = store_shipment.getState().uld;

  if (keys && keys.length > 0) {
    keys.forEach((k) => {
      let setting = uld_settings.filter((p) => p.position === k)[0];
      let pivot_weight =
        setting && setting.pivot_weight ? setting.pivot_weight : "";
      let item = uld_price.filter((p) => p.position === k)[0];
      pivot_weight =
        item && item.pivot_weight ? item.pivot_weight : pivot_weight;
      let selling_rate = item && item.selling_rate ? item.selling_rate : "";
      let uld_rate = item && item.uld_rate ? item.uld_rate : "";
      let per_kg = item && item.per_kg !== undefined ? item.per_kg : false;
      let over_pivot_rate =
        item && item.over_pivot_rate ? item.over_pivot_rate : "";
      let weight = item && item.weight ? item.weight : "";
      let price_currency = item && item.currency ? item.currency : currency;
      let pcsT = 0;

      for (let i = 0; i < uld.length; i++) {
        if (k === uld[i].position) {
          let pc = isNaN(parseInt(uld[i].pieces)) ? 0 : parseInt(uld[i].pieces);
          pcsT = pcsT + pc;
        }
      }

      uld_price_grouped.push({
        position: k,
        pivot_weight: pivot_weight,
        per_kg: per_kg,
        selling_rate: selling_rate,
        uld_rate: uld_rate,
        over_pivot_rate: over_pivot_rate,
        currency: price_currency,
        pieces: pcsT,
      });
    });
  }

  return uld_price_grouped;
}

function formatULDPrice(reset = false) {
  let { uld, uld_settings } = store_shipment.getState();
  let { currency, uld_price } = store_pricing.getState();
  let new_uld_price = [];

  let first_over_pivot_rate = uld_price[0] ? uld_price[0].over_pivot_rate : "";

  new_uld_price = uld.map((u) => {
    let setting = uld_settings.filter((p) => p.position === u.position)[0];
    let pivot_weight =
      setting && setting.pivot_weight ? setting.pivot_weight : null;
    let item = uld_price.filter((p) => p.position === u.position)[0];
    pivot_weight =
      item && item.pivot_weight && !reset ? item.pivot_weight : pivot_weight;
    pivot_weight = pivot_weight ? parseFloat(pivot_weight) : null;
    let uld_rate = item && item.uld_rate ? parseFloat(item.uld_rate) : null;
    let weight = item && u.weight ? parseFloat(u.weight) : null;
    let pieces = item && u.pieces ? parseInt(u.pieces) : 0;
    let over_pivot_rate =
      item && item.over_pivot_rate ? parseFloat(item.over_pivot_rate) : 0;
    let per_kg = item && item.per_kg !== undefined ? item.per_kg : false;
    let price_currency = item && item.currency ? item.currency : currency;

    //console.log("freeULDFormat", item, pieces, weight);

    let selling_rate = null;

    if (weight <= pivot_weight) {
      selling_rate = per_kg ? pieces * uld_rate * weight : uld_rate * pieces;
    } else {
      selling_rate = per_kg
        ? (pivot_weight * uld_rate +
            (weight - pivot_weight) * over_pivot_rate) *
          pieces
        : (uld_rate + (weight - pivot_weight) * over_pivot_rate) * pieces;
    }

    return {
      position: u.position,
      pivot_weight: pivot_weight,
      selling_rate: selling_rate,
      uld_rate: uld_rate,
      per_kg: per_kg,
      over_pivot_rate: over_pivot_rate,
      currency: price_currency,
    };
  });

  //console.log("freeULDItem", new_uld_price);

  new_uld_price = groupULDPrice(new_uld_price);

  //console.log("freeULDItem1", new_uld_price);

  store_pricing.dispatch({ type: 1, uld_price: new_uld_price });

  calculateULDPrice();
}

function loadULDConfig(custom_list) {
  const header = store_header.getState();
  const pricing = store_pricing.getState();
  let all_config = custom_list.filter(
    (c) => c.station === "***" && c.carrier === "***"
  )[0];
  let station_config = custom_list.filter(
    (c) => c.station === header.station && c.carrier === "***"
  )[0];
  let carrier_config = custom_list.filter(
    (c) => c.station === header.station && c.carrier === pricing.carrier
  )[0];
  let uld_settings = [];

  if (
    carrier_config &&
    carrier_config.config.uld_pricing &&
    carrier_config.config.uld_pricing.length > 0
  ) {
    uld_settings = carrier_config.config.uld_pricing;
  } else if (
    station_config &&
    station_config.config.uld_pricing &&
    station_config.config.uld_pricing.length > 0
  ) {
    uld_settings = station_config.config.uld_pricing;
  } else {
    uld_settings =
      all_config &&
      all_config.config.uld_pricing &&
      all_config.config.uld_pricing.length > 0
        ? all_config.config.uld_pricing
        : [];
  }

  store_shipment.dispatch({ type: 1, uld_settings: uld_settings });
}

function calculateULDPrice() {
  let uld = store_shipment.getState().uld;
  let uld_price = store_pricing.getState().uld_price;
  let total_freight_charges = 0;
  let total_pivot_weight = 0;

  //console.log("freeULD", uld, uld_price);

  if (!uld_price || uld_price.length === 0) return;

  uld_price.forEach((up) => {
    let items = uld.filter((u) => u.position === up.position);

    //console.log("freeItem", items, up);

    if (!items || items.length === 0) return;

    let pivot_weight = isNaN(parseInt(up.pivot_weight))
      ? 0
      : parseInt(up.pivot_weight);
    let selling_rate = isNaN(parseFloat(up.selling_rate))
      ? 0
      : parseFloat(up.selling_rate);

    //Total pivot weight
    total_pivot_weight = total_pivot_weight + pivot_weight;
    total_freight_charges = total_freight_charges + selling_rate;
  });
  //set pour force min_ch si per_kg = false
  store_pricing.dispatch({
    type: 1,
    price_total: total_freight_charges,
    set: true,
    min_chargeable_weight: total_pivot_weight,
    per_kg: false,
  });

  //console.log("freeULDTOTAL", total_freight_charges);

  return total_freight_charges;
}

function isUserCarrier() {
  let carrier = store_pricing.getState().carrier;
  carrier = carrier ? carrier : "";
  let result = true;

  let user_carriers = cookies.user_carriers;
  let user_except_carriers = cookies.user_except_carriers;

  if (user_except_carriers && user_except_carriers === "ALL") {
    result = false;
  } else if (user_except_carriers && user_except_carriers !== "ALL") {
    user_except_carriers = user_except_carriers.split(",");

    if (
      user_except_carriers.length > 0 &&
      user_except_carriers.indexOf(carrier) >= 0
    )
      result = false;
  }

  if (!result) return result;

  if (user_carriers && user_carriers === "ALL") {
    return true;
  } else {
    user_carriers =
      user_carriers && user_carriers !== "ALL" ? user_carriers.split(",") : [];

    if (user_carriers.length > 0 && user_carriers.indexOf(carrier) >= 0)
      result = true;
    else result = false;
  }

  return result;
}

function parseCarrier() {
  let calculator = store_calculator.getState();
  let { carrier } = store_pricing.getState();
  let { routes } = store_routes.getState();

  if (calculator.schedule) {
    carrier = calculator.carrier;
    routes = calculator.routing;
  }

  let result = [];

  if (carrier) result.push(carrier);

  if (routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].carrier && result.indexOf(routes[i].carrier) < 0)
        result.push(routes[i].carrier);
    }
  }

  return result;
}

function parseRouting() {
  let calculator = store_calculator.getState();
  let pricing = store_pricing.getState();
  let { routes } = store_routes.getState();
  let { carrier } = pricing;

  let routing = "";

  if (calculator.schedule) {
    routes = calculator.routing;
    carrier = calculator.carrier;
  } else {
  }

  let routing_complete = true;

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].carrier) carrier = routes[i].carrier;

    if (!routes[i].origin || !routes[i].dest) {
      routing_complete = false;
      break;
    }

    routing += routes[i].origin + "-";

    if (i === routes.length - 1) routing += routes[i].dest;
  }

  if (!carrier) routing_complete = false;

  if (routing_complete) return routing;
}

function formatSchedule(schedule) {
  //transform schedule table into routes table
  let formatted_routing = [];
  let calculator = store_calculator.getState();
  let { routes } = store_routes.getState();
  schedule = schedule ? schedule : [];
  routes = calculator.schedule ? calculator.routing : routes;
  //keep filled segments
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].origin && routes[i].dest && routes[i].carrier)
      formatted_routing.push(routes[i]);
  }

  for (let i = 0; i < schedule.length; i++) {
    let s = schedule[i];
    let json = {
      origin: s.bpt,
      dest: s.opt,
      carrier: s.cr ? s.cr : s.carrier,
      flight_type: s.oper_typ === "F" ? "Flight" : "Truck",
      weekly_frequency: s.weekly_freq,
      operation_dow: s.oper_dow,
    };

    let route_ = formatted_routing.filter(
      (r) =>
        r.origin === json.origin &&
        r.dest === json.dest &&
        r.carrier === s.carrier
    )[0];

    if (route_)
      formatted_routing.splice(formatted_routing.indexOf(route_), 1, json);
    else formatted_routing.push(json);
  }

  formatted_routing = {
    routes: formatted_routing,
    detailed_info: false,
  };

  return formatted_routing;
}

async function openSchedule(routing) {
  let calculator = store_calculator.getState();
  let { detailed_info, routes } = store_routes.getState();
  let { origin, dest } = store_shipment.getState();
  let { from_date, current_carrier } = store_schedule.getState();

  if (calculator.schedule) {
    detailed_info = calculator.detailed_info;
    routes = calculator.routing;
    origin = calculator.origin;
    dest = calculator.dest;
  }

  //API only shows flights starting from next day
  //  from_date = new Date(from_date)
  //  from_date.setDate(from_date.getDate() - 1)
  //  from_date = from_date.toISOString();

  if (!origin || !dest) return;

  store_modals.dispatch({ type: 1, page_loading_spinner: true });

  let params = {};
  let carrier = parseCarrier();

  if (!routing) {
    params = {
      origin: origin,
      destination: dest,
      range_of_dates: 7,
    };

    if (carrier.length) params.carrier = carrier[0];

    if (from_date) params.departure_date = from_date;

    let carrier_routings = await Routes.get("/Routings", params, true);
    carrier_routings = carrier_routings ? carrier_routings : [];

    store_schedule.dispatch({ type: 1, carrier_routings: carrier_routings });
    await util.sleep(200);
    store_modals.dispatch({
      type: 1,
      modalSchedule: true,
      page_loading_spinner: false,
    });
  } else {
    let params = {
      routing: routing,
      departure_date: from_date,
      range_of_dates: 7,
    };

    if (!detailed_info) params.request_type = 1;

    if (!detailed_info && params.departure_date)
      params.departure_date = params.departure_date.split("T")[0];

    let schedule = [];

    if (current_carrier || carrier.length === 1) {
      params.carrier = current_carrier ? current_carrier : carrier[0];
      schedule = await Routes.get("/Schedules", params, true);
      schedule = schedule ? schedule : [];
    }

    if (carrier.length > 1 || !schedule.length) {
      let index = 0;

      for (let i = 0; i < routes.length; i++) {
        params.carrier = routes[i].carrier;
        params.routing = routes[i].origin + "-" + routes[i].dest;
        let schedule_ = await Routes.get("/Schedules", params, true);
        schedule_ = schedule_ ? schedule_ : [];

        for (let j = 0; j < schedule_.length; j++) {
          schedule_[j].id = index;
          schedule_[j].seg += i;
        }
        schedule = schedule.concat(schedule_);
        index++;
      }
    }

    if (!detailed_info) {
      store_modals.dispatch({
        type: 1,
        modalSchedule: true,
        page_loading_spinner: false,
      });
    } else {
      store_schedule.dispatch({ type: 1, selected_routing_string: routing });
      store_schedule.dispatch({ type: 1, schedule: schedule });
      await util.sleep(200);
      store_modals.dispatch({
        type: 1,
        modalSchedule: true,
        page_loading_spinner: false,
      });
    }
  }
}

async function updateCarrierInfo(carrier_, custom_list) {
  let pricing = store_pricing.getState();
  let { carriers_list } = store_station.getState();
  let { authorized_stations } = store_station.getState();
  let carrier_new = carrier_ ? carrier_.toUpperCase().trim() : pricing.carrier;
  carrier_new = carrier_new === "RFS" ? pricing.carrier : carrier_new;
  let issue_carrier = Util.CARRIER_TO_ISSUE_CARRIER[carrier_new]
    ? Util.CARRIER_TO_ISSUE_CARRIER[carrier_new]
    : carrier_new;
  let carrier = carriers_list.filter((c) => c.code === issue_carrier)[0];
  custom_list = custom_list ? custom_list : [];
  store_pricing.dispatch({
    type: 1,
    carrier: carrier_new,
    carrier_name: carrier ? carrier.name : "",
    carrier_prefix: carrier ? carrier.prefix : "",
    all_in_rate_oc_list: carrier ? carrier.all_in_rate_oc_list : "",
    def_oc_list: carrier ? carrier.def_oc_list : "",
    force: true,
  });

  //populate prefix
  let awb_prefix = carrier && carrier.prefix !== "ECS" ? carrier.prefix : "";
  let awb_serial = store_header.getState().serial;
  let awb_number =
    awb_prefix + "-" + awb_serial === "-" ? "" : awb_prefix + "-" + awb_serial;
  store_header.dispatch({
    type: 1,
    prefix: awb_prefix,
    awb_number: awb_number,
  });

  //get prices

  store_modals.dispatch({ type: 1, page_loading_spinner2: true });
  await getSellingPrices({ authorized_stations: authorized_stations });
  store_modals.dispatch({ type: 1, page_loading_spinner2: false });

  //update all in rate
  let prices = store_prices.getState().sell_prices;
  prices = prices ? prices : [];

  let current_price = store_pricing.getState();
  let price = prices.filter((p) => p.carrier === current_price.carrier)[0];

  let config = getConfig(custom_list);

  if (config && config.all_in)
    store_pricing.dispatch({ type: 1, force: true, all_in: true });
  else if (price && price.all_in_rate === "Y")
    store_pricing.dispatch({ type: 1, force: true, all_in: true });
  else store_pricing.dispatch({ type: 1, force: true, all_in: false });
}

function updateSendContact() {
  let header = store_header.getState();
  let user = cookies.account ? cookies.account : "";
  user = user.length > 4 ? user.substring(0, 4) : user;

  let update_history = header.update_history ? header.update_history : user;

  if (user && update_history.length <= 24 && !update_history.endsWith(user)) {
    update_history = update_history + "/" + user;
  }
  store_header.dispatch({ type: 1, update_history: update_history });
}

function formatDate(dateString) {
  if (!dateString) return null;

  if (
    !dateString.match(/^[0-2][1-9][A-z][A-z][A-z][1-9][1-9]$/) &&
    !dateString.match(/^[1-3][0-1][A-z][A-z][A-z][1-9][1-9]$/)
  )
    return null;

  let day = dateString.substring(0, 2);
  day = parseInt(day);
  day = day < 9 ? "0" + day : day;

  let month_input = dateString.substring(2, 5).toUpperCase();
  let month_input_num = Util.MONTHS.indexOf(month_input) + 1;
  month_input_num =
    month_input_num < 9 ? "0" + month_input_num : month_input_num;

  let year = dateString.substring(5, 7).toUpperCase();

  if (!month_input || !month_input_num || !year) return null;

  year = parseInt(year) + 2000;

  return year + "-" + month_input_num + "-" + day;
}

function formatText(text) {
  let formated = text ? text.split("\n") : "";
  formated = formated ? formated : [];
  formated = formated.filter((f) => f !== "");
  formated = formated.map((f) => {
    if (f && !f.endsWith("."))
      return f.charAt(0).toUpperCase() + f.slice(1) + ".";
    else return f.charAt(0).toUpperCase() + f.slice(1);
  });
  //formated = formated ? formated.join('\n') : ''
  if (formated && formated.length <= 3) {
    formated = formated.join("\n");
  } else if (formated && formated.length > 3) {
    formated = formated.join(" ");
  } else {
    formated = "";
  }

  return formated;
}

async function saveAndCalculatePacking() {
  let { packing, uld, dimension_type, length_system, weight_system } =
    store_shipment.getState();
  let header = store_header.getState();

  if (dimension_type === Util.CONST.ULD) {
    //ULD CALCAL

    if (
      uld &&
      uld.length === 1 &&
      !uld[0].pieces &&
      !uld[0].position &&
      !uld[0].weight
    ) {
      store_modals.dispatch({ type: 1, modalDimensions: false });
    } else {
      let u = [...uld];
      let count = 0;

      while (count < 5) {
        for (let i = 0; i < u.length; i++) {
          if (!u[i].position) {
            u.splice(i, 1);
          }
        }
        count++;
      }

      store_shipment.dispatch({ type: 1, uld: u, packing: [] });
      formatULDPrice();
      store_shipment.dispatch({
        type: 1,
        showModal: false,
        calculateUld: true,
        force: true,
      });
      store_errors.dispatch({ type: 1, current_input: "shipment_chg_wgt" });
      store_modals.dispatch({
        type: 1,
        modalDimensions: false,
        modalUpdatePacking: false,
      });
    }

    store_pricing.dispatch({ type: 1, force: true });
  } else {
    //PACKING CALCUL

    if(!packing || (packing && packing.length === 0))
      store_modals.dispatch({ type: 1, modalDimensions: false });


    if (
      packing &&
      packing.length === 1 &&
      !packing[0].pieces &&
      !packing[0].length &&
      !packing[0].height &&
      !packing[0].weight
    ) {
      store_modals.dispatch({ type: 1, modalDimensions: false });
    } else {
      let p = [...packing];
      let count = 0;

      while (count < 5) {
        for (let i = 0; i < p.length; i++) {
          if (!p[i].pieces && !p[i].length && !p[i].height && !p[i].weight) {
            p.splice(i, 1);
          }
        }
        count++;
      }

      store_shipment.dispatch({
        type: 1,
        showModal: false,
        packing: p,
        uld: [],
        calculatePacking: true,
        force: true,
        packing_change: false,
      });
      store_margin.dispatch({ type: 1, updateMargin: true });
      store_errors.dispatch({ type: 1, current_input: "shipment_chg_wgt" });
      store_modals.dispatch({
        type: 1,
        modalDimensions: false,
        modalUpdatePacking: false,
      });
    }
  }

  let logs = {
    type: "PACKING_LIST",
    packing: dimension_type === Util.CONST.ULD ? uld : packing,
    info: header.id ? header.id : "",
    length_system: length_system,
    weight_system: weight_system,
  };
  await Routes.get("/Logger", logs, true);

  store_shipment.dispatch({ type: 1, force: true });
}

async function calculateCost() {
  let calculator = store_calculator.getState();
  let {
    origin,
    dest,
    from_date,
    to_date,
    per_kg,
    currency,
    sell_buy,
    chargeable_weight,
    shc,
    agent_code,
    carrier,
    station,
  } = calculator;

  let routes = calculator.routing;

  let { authorized_stations } = store_station.getState().authorized_stations;

  authorized_stations = authorized_stations ? authorized_stations : [];

  let station_ = authorized_stations.filter(
    (s) => s.station === calculator.station
  )[0];

  let calculator_errors = {};
  let error = false;

  let default_routing =
    !routes || routes.length === 1 ? origin + "-" + dest : "";
  let default_routes =
    !routes || routes.length === 1
      ? [
          {
            origin: routes[0] && routes[0].origin ? routes[0].origin : origin,
            dest: routes[0] && routes[0].dest ? routes[0].dest : dest,
            carrier:
              routes[0] && routes[0].carrier ? routes[0].carrier : carrier,
            flight_type:
              routes[0] && routes[0].flight_type
                ? routes[0].flight_type
                : "Flight",
          },
        ]
      : [];

  let params = {
    station: station,
    carrier: carrier,
    agent: agent_code,
    sales_region: station_ ? station_.sales_region : undefined,
    origin_zone: station_ ? station_.origin_zone : undefined,
    sell_buy: sell_buy,
    shc: shc,
    chargeable_weight: chargeable_weight,
    origin: origin,
    dest: dest,
    from_date: from_date,
    until_date: to_date,
    routing:
      !routes || routes.length === 1
        ? default_routing
        : routes.map((r) => r.origin + "-" + r.dest).join(","),
    routes:
      !routes || routes.length === 1
        ? default_routes
        : routes.map((r) => {
            return {
              origin: r.origin,
              dest: r.dest,
              carrier: r.carrier,
              flight_type: r.flight_type,
            };
          }),
  };

  if (!params.station) {
    calculator_errors.station = "Missing";
    error = true;
  }

  if (!params.origin) {
    calculator_errors.origin = "Missing";
    error = true;
  }

  if (!params.dest) {
    calculator_errors.dest = "Missing";
    error = true;
  }

  if (!params.carrier) {
    calculator_errors.carrier = "Missing";
    error = true;
  }

  if (!params.agent) {
    calculator_errors.agent = "Missing";
    error = true;
  }

  if (!params.chargeable_weight) {
    calculator_errors.chargeable_weight = "Missing";
    error = true;
  }

  let costs = null;
  store_calculator.dispatch({ type: 1, search: true });
  if (!error) costs = await Routes.get("/TruckHandleCost", params, true);
  let truck_cost = costs ? costs.truck_cost : [];
  let handle_cost = costs ? costs.handle_cost : [];
  let oc_cost = costs ? costs.oc_cost : [];

  store_calculator.dispatch({
    type: 1,
    truck_cost: truck_cost,
    handle_cost: handle_cost,
    oc_cost: oc_cost,
    costs: costs,
    calculator_errors: calculator_errors,
    search: false,
  });
  store_calculator.dispatch({ type: 1, force: true });
}

function rateInfo(rate_) {
  let pricing = store_pricing.getState();
  let shipment = store_shipment.getState();
  let s = rate_;

  if (!s) return {};

  let carrier = s.for_carrier ? s.for_carrier : "";
  let rate = s.rate ? parseFloat(s.rate).toFixed(2) : 0;

  if (s.loc_addon_rate) {
    rate = parseFloat(rate) + parseFloat(s.loc_addon_rate);
  }

  if (s.add_rate) {
    rate = parseFloat(rate) + parseFloat(s.add_rate);
  }

  if (s.add_perc) {
    rate =
      parseFloat(rate) + parseFloat(rate) * parseFloat(s.add_perc / 100 - 1);
  }
  let rate_all_in = s.rate_all_in ? parseFloat(s.rate_all_in).toFixed(2) : 0;
  let min_amount = s.min_amount ? parseFloat(s.min_amount).toFixed(2) : "";

  if (s.all_in_rate === "Y") {
    rate = rate_all_in;
  }

  let price =
    pricing.per_kg &&
    parseFloat(rate) * shipment.chargeable_weight < parseFloat(min_amount)
      ? parseFloat(min_amount) / shipment.chargeable_weight
      : rate;
  let price_total =
    !pricing.per_kg &&
    parseFloat(rate) * shipment.chargeable_weight < parseFloat(min_amount)
      ? parseFloat(min_amount)
      : parseFloat(rate) * shipment.chargeable_weight;

  let all_in = s.all_in_rate && s.all_in_rate === "Y" ? true : false;
  let currency = s.currency ? s.currency : pricing.currency;
  let selling_type = s.selling_type;
  let deal_code = s.selling_type !== "STREET" ? "" : s.selling_type;

  let rateinfo = {
    carrier: carrier,
    price: price,
    price_total: price_total,
    rate: rate,
    all_in: all_in,
    min_amount: min_amount,
    currency: currency,
    selling_type: selling_type,
    deal_code: deal_code,
  };

  return rateinfo;
}
